<script setup>

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon
} from "@heroicons/vue/24/outline/index.js";
import {useRoute, useRouter} from "vue-router";
import {computed, nextTick, ref} from "vue";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import {onClickOutside} from "@vueuse/core";

const route = useRoute()
const router = useRouter()

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['movePage'])


const movePage = (pageNo) => {
  pageNo = parseInt(pageNo)
  if (isNaN(pageNo)) {alert('페이지 번호가 올바르지 않습니다.'); return;}
  if (pageNo < 1 || pageNo > props.data.last_page) {
    alert('페이지 번호가 올바르지 않습니다.')
    movePage(1)
    return;
  }
  let query = {...route.query}
  query['page'] = pageNo
  router.push({name: route.name, query: query})
}
const movePageFromNumber = (event) => {
  togglePageInput()
  let pageNo = event.target.value
  movePage(pageNo)
}

const setLimit = (limit) => {
  let query = {...route.query}
  query['page'] = 1
  query['limit'] = limit
  router.push({name: route.name, query: query})
}

const currentLimit = computed(() => {
  return route.query.limit ? Number(route.query.limit) : 50
})

// const limitList = [20, 50, 100, 200, 500]
const limitList = computed(() => [
  {label: '50개', action: () => setLimit(50), current: currentLimit.value === 50},
  {label: '100개', action: () => setLimit(100), current: currentLimit.value === 100},
  {label: '250개', action: () => setLimit(250), current: currentLimit.value === 250},
  {label: '500개', action: () => setLimit(500), current: currentLimit.value === 500, rightIcon: ExclamationTriangleIcon},
])
const pageInput = ref(null)

const pageInputShow = ref(false)

const togglePageInput = () => {
  pageInputShow.value = !pageInputShow.value
  nextTick(() => {
    if (pageInputShow.value) {
      pageInput.value.focus()
    }
  })
}

onClickOutside(pageInput, event => {
  togglePageInput()
}, {immediate: false})
</script>

<template>
  <div class="flex h-10 min-h-10 max-w-full border-t bg-slate-50">
    <div class="mx-2 flex items-center justify-between">
      <button :class="[props.data.has_prev ? '':'cursor-not-allowed']"
              :disabled="!props.data.has_prev"
              class="rounded p-0.5 hover:bg-slate-200 mr-2 text-slate-400" @click="movePage(props.data.page_no-1)">
        <ChevronLeftIcon class="size-5 min-w-5"/>
      </button>
      <div class="flex min-w-max items-center">
        <span class="mr-2 text-sm text-slate-900">Page</span>
        <template v-if="pageInputShow">
          <input ref="pageInput" @keyup.enter="movePageFromNumber"
              class="input-text text-sm size-7 rounded-md border-gray-300 p-0 text-center focus:outline-none focus:border-gray-300 focus:ring-0">
        </template>
        <template v-else>
        <span class="cursor-pointer rounded p-1 text-sm text-slate-900 hover:bg-slate-200" @click="togglePageInput">
          {{ props.data.page_no }}<!-- --> / <!-- -->{{ props.data.last_page }}
        </span>
        </template>
      </div>
      <button :class="[props.data.has_next ? '':'cursor-not-allowed']"
              :disabled="!props.data.has_next"
              class="rounded p-0.5 hover:bg-slate-200 mx-2 text-slate-400" @click="movePage(props.data.page_no+1)">
        <ChevronRightIcon class="size-5 min-w-5"/>
      </button>
      <div class="flex w-6">
        <DropdownComponent :icon="ChevronUpIcon" :rowActions="limitList" header="페이지당 표시 갯수"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>