<script setup>

import DrawerCore from "@/components/drawer/DrawerCore.vue";
import dayjs from "dayjs";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import {nextTick, onBeforeMount, reactive, ref, watch} from "vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {externalValidator} from "@/validators/fields.js";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import AffiliateEmployeeSelector from "@/components/selector/apiSelector/AffiliateEmployeeSelector.vue";
import MapModal from "@/components/modals/MapModal.vue";
import AddressSelectModal from "@/components/modals/AddressSelectModal.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import AddressInput from "@/components/forms/AddressInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import {APIError} from "@/exceptions.js";
import CustomerSelector from "@/components/selector/apiSelector/CustomerSelector.vue";

const props = defineProps({
    title: {type: String, required: false, default: '소형배차 등록'},
    open: {type: Boolean, required: true}
})

const emits = defineEmits(['close', 'create:smallWork'])

const close = () => {
    emits('close')
}
// 'waste_class', 'waste_type', 'volume',
const data = reactive({
    campaign: null,
    performance_date: dayjs().format('YYYY-MM-DD'),
    waste_class: null,
    waste_type: null,
    volume: null,
    company: null,
    company_manager: null,
    company_manager_name: null,
    company_manager_cellphone: null,
    address_poi: null,
    address_detail: null,
    address_description: null,
    at_date: null,
    at_time: null,
    waypoint_description: null,
    arrival: null,
    arrival_memo: null,
})
const reset = () => {
    let temp = {
        campaign: null,
        performance_date: dayjs().format('YYYY-MM-DD'),
        waste_class: null,
        waste_type: null,
        volume: null,
        company: null,
        company_manager: null,
        company_manager_name: null,
        company_manager_cellphone: null,
        address_poi: null,
        address_detail: null,
        address_description: null,
        at_date: null,
        at_time: null,
        waypoint_description: null,
        arrival: null,
        arrival_memo: null,
    }
    Object.keys(data).forEach(key => {
        data[key] = temp[key]
    })
}

onBeforeMount(reset)

const model = {
    campaign: {
        required: helpers.withMessage('캠페인을 선택해주세요.', required)
    },
    performance_date: {
        required: helpers.withMessage('기준일을 선택해주세요.', required)
    },
    waste_class: {externalValidator},
    waste_type: {externalValidator},
    volume: {externalValidator},
    company: {
        required: helpers.withMessage('거래처를 선택해주세요.', required)
    },
    company_manager: {externalValidator},
    company_manager_name: {externalValidator},
    company_manager_cellphone: {externalValidator},
    address_poi: {
        required: helpers.withMessage('주소를 입력해주세요.', required)
    },
    address_detail: {externalValidator},
    address_description: {externalValidator},
    at_date: {externalValidator},
    at_time: {externalValidator},
    waypoint_description: {externalValidator},
    arrival: {externalValidator},
    arrival_memo: {externalValidator},
}

const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})


watch(() => data.company_manager, (newValue, oldValue) => {
    if (newValue === oldValue) return
    data.company_manager_name = newValue?.name || null
    data.company_manager_cellphone = newValue?.cellphone || null
})


const prepareData = () => {
    let prepare = {...data}
    prepare['campaign'] = prepare['campaign'].id
    prepare['waste_class'] = prepare?.waste_class?.value ? prepare.waste_class.value : null
    prepare['waste_type'] = prepare?.waste_type?.value ? prepare.waste_type.value : null
    prepare['volume'] = prepare?.volume?.value ? prepare.volume.value : null
    prepare['company'] = prepare?.company?.id ? prepare.company.id : null
    prepare['company_manager'] = prepare?.company_manager?.id ? prepare.company_manager.id : null
    prepare['manager_name'] = prepare.company_manager_name === "" ? null : prepare.company_manager_name
    prepare['manager_cellphone'] = prepare.company_manager_cellphone === "" ? null : prepare.company_manager_cellphone
    prepare['address_poi'] = prepare.address_poi
    prepare['address_detail'] = prepare.address_detail === "" ? null : prepare.address_detail
    prepare['address_description'] = prepare.address_description === "" ? null : prepare.address_description
    prepare['at_date'] = prepare.at_date
    prepare['arrival'] = prepare.arrival?.id || null
    return prepare
}

const save = async () => {
    // validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    console.log(validator.value)
    console.log(validator.value.$errors)
    if (!valid) return
    let options = {
        method: 'PUT',
        url: `/app/v1/work/small/`,
        data: prepareData(data),
    }
    const response = await useAuthAxios(options, false, $externalResults.value)
    validator.value.$reset()
    emits('create:smallWork', response)
    close()
}


const clearAddressDetail = () => {
    data.address_detail = null
}

</script>

<template>
    <DrawerCore :open="props.open" :title="props.title" mdWClass="md:max-w-4xl" @close="close">
        <form class="px-4" @submit.prevent="save">
            <div class="pb-4">
                <div class="text-sm font-semibold mb-3">기준</div>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
                    <CampaignSelector v-model="data.campaign" :errors="validator.campaign.$errors.map(e => e.$message)"
                                      :required="true" work_type="S1"/>
                    <DatePickerInput v-model="data.performance_date"
                                     :errors="validator.performance_date.$errors.map(e => e.$message)"
                                     :readonly="true" label="기준일"/>
                </div>
            </div>
            <div class="pb-4">
                <div class="text-sm font-semibold mb-3">성상</div>
                <div class="grid grid-cols-3 md:grid-cols-4 gap-x-4">
                    <WasteClassSelector v-model="data.waste_class"
                                        :errors="validator.waste_class.$errors.map(e => e.$message)"
                                        label="성상"/>
                    <WasteTypeSelector v-model="data.waste_type"
                                       :errors="validator.waste_type.$errors.map(e => e.$message)"
                                       :wasteClass="data.waste_class" label="성상 상세"/>
                    <VolumeSelector v-model="data.volume" :errors="validator.volume.$errors.map(e => e.$message)"
                                    :work-type="data.campaign?.work_type || null" label="부피"/>
                </div>
            </div>
            <div class="pb-4">
                <div class="text-sm font-semibold mb-3">진입</div>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-x-4">
                    <DatePickerInput v-model="data.at_date"
                                     :errors="validator.at_date.$errors.map(e => e.$message)"
                                     :min-date="dayjs().format('YYYY-MM-DD')" label="진입일"/>
                    <TimePickerInput v-model="data.at_time"
                                     :errors="validator.at_time.$errors.map(e => e.$message)" label="진입 시간"/>
                    <TextInput v-model="data.waypoint_description" :errors="validator.waypoint_description.$errors.map(e => e.$message)" class="col-span-2"
                               label="특이사항"/>
                </div>
            </div>
            <div class="pb-4">
                <div class="text-sm font-semibold mb-3">현장</div>
                <div class="mb-4 grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-4">
                    <CustomerSelector v-model="data.company_manager" :add="true" :errors="validator.company_manager.$errors.map(e => e.$message)" :required="true"
                                      label="담당자"/>
                    <TextInput v-model="data.company_manager_name"
                               :errors="validator.company_manager_name.$errors.map(e => e.$message)"
                               :readonly="!!data?.company_manager?.name" label="담당자명"/>
                    <TextInput v-model="data.company_manager_cellphone"
                               :errors="validator.company_manager_cellphone.$errors.map(e => e.$message)"
                               :readonly="!!data?.company_manager?.cellphone" label="담당자 연락처"/>
                    <AffiliateSelector v-model="data.company" :add="true" :required="true"
                                       :errors="validator.company.$errors.map(e => e.$message)" label="거래처"/>
                </div>
                <div class="mb-4 grid grid-cols-1 md:grid-cols-7 gap-x-4">
                    <div class="col-span-3 ">
                        <AddressInput v-model="data.address_poi"
                                      :errors="validator.address_poi.$errors.map(e => e.$message)"
                                      :required="true" label="주소" @clear="clearAddressDetail"/>
                    </div>
                    <div v-if="data.address_poi !== null" class="col-span-4">
                        <TextInput v-model="data.address_detail"
                                   :errors="validator.address_detail.$errors.map(e => e.$message)"
                                   label="상세주소"/>
                    </div>
                </div>
                <div>
                    <TextArea v-model="data.address_description" label="현장 상세"/>
                </div>
            </div>
            <div class="pb-4">
                <div class="text-sm font-semibold mb-3">하차지</div>
                <div class="mb-4 grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-4">
                    <AffiliateSelector v-model="data.arrival" :errors="validator.arrival.$errors.map(e => e.$message)"
                                       :required="false" class="col-span-1" label="하차지"/>
                    <TextInput v-model="data.arrival_memo" :errors="validator.arrival_memo.$errors.map(e => e.$message)"
                               :required="false" class="col-span-1 md:col-span-3" label="하차지 상세"/>
                </div>
            </div>
            <button class="btn btn-primary" type="submit">저장</button>
        </form>
    </DrawerCore>
</template>

<style scoped>

</style>