<script setup>

import dayjs from "dayjs";
import {ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import { useClipboard } from '@vueuse/core'
import DeviceDrawer from "@/components/drawer/DeviceCreateDrawer.vue";
import CallLogDrawer from "@/components/drawer/CallLogDrawer.vue";

const router = useRouter()
const route = useRoute()

// const copyKey = (key) => {
//   const { text, copy, copied, isSupported } = useClipboard({ source: key })
//   copy(key)
//   if (copied) alert('복사되었습니다.')
// }

const fields = {
  date: {label: '일시', sortable: false, width: 150, type: 'datetime'},
  device_name: {label: '기기명', sortable: false, width: 100, type: 'text'},
  type_display: {label: '유형', sortable: false, width: 50, type: 'text'},
  name: {label: '발신자', sortable: false, width: 150, type: 'text'},
  number: {label: '번호', sortable: false, width: 150, type: 'text'},
  manager_name: {label: '담당자', sortable: false, width: 150, type: 'text'},
  process: {label: '처리', sortable: false, width: 150, type: 'boolean'}
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const tableKey = ref(1)

const fetch = async (query = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/call_log/`,
    params: query,
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  data['number'] = formatPhoneNumber(data.number)
  data['type_display'] = data.missed_reason === "0" ? data.call_type_display : `${data.call_type_display}(${data.missed_reason_display})`
  data['manager_name'] = data.manager_name ? data.manager_name : '미지정'
  data['process'] = data.processed_at !== null
  return data
}

const queryList = () => {
  let query = {...route.query}
  router.push({query: query})
}

// drawer
const selectedCallLog = ref(null)

const setCallLog = (callLog) => {
  selectedCallLog.value = callLog
}

const update = () => {
  tableKey.value += 1
}
</script>

<template>
  <CallLogDrawer v-if="selectedCallLog" :open="selectedCallLog !== null" :callLog="selectedCallLog" @close="setCallLog(null)" @update="update"/>
<!--  <DeviceDrawer :open="sele" title="기기 상세" @close="hideDeviceCreate"/>-->
  <DefaultLayoutBase title="휴대전화 / 통화기록">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields"
                      :rowActions="rowActions" :query-filter="queryList" @update="update"
                      :rowsAction="rowsAction" :tableActions="tableActions"
                      :key="tableKey" :open-action="setCallLog"
                      entityName="통화기록" entityUnit="건">
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>