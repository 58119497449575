<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator, dateStringValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, nullBrnValidator, phoneValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import CompanySelector from "@/components/selector/apiSelector/CompanySelector.vue";
import CustomerSelector from "@/components/selector/apiSelector/CustomerSelector.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextArea from "@/components/forms/TextArea.vue";
import {DatePicker} from "v-calendar";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  sales: {type: [null, Object], required: true}
})


const data = reactive({
  campaign: props.sales.target.campaign,
  company: props.sales.target.company,
  customer: props.sales.target.customer,
  performance_date: props.sales.performance_date,
  detail: props.sales.detail,
  status: props.sales.status,
  manager: props.sales.manager || null,
  channel: props.sales.channel
})


const $externalResults = ref({})

const model = {
  campaign: {
    required: requiredWithMessage('필수 항목입니다.')
  },
  company: {externalValidator},
  customer: {externalValidator},
  performance_date: {format: dateStringValidator},
  detail: {required: requiredWithMessage('필수 항목입니다.')},
  status: {required: requiredWithMessage('필수 항목입니다.')},
  manager: {required: requiredWithMessage('필수 항목입니다.')},
  channel: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults})

const setCampaign = selected => {
  data.campaign = selected
}
const setCompany = selected => {
  data.company = selected
}
const setCustomer = selected => {
  data.customer = selected
}
const setPerformanceDate = event => {
  data.performance_date = event.target.value
}
const setDetail = event => {
  data.detail = event.target.value
}
const setStatus = selected => {
  data.status = selected
}

const setChannel = selected => {
  data.channel = selected
}


const getPrepare = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare.campaign = prepare.campaign.id
  prepare.company = prepare?.company?.id ? prepare.company.id : null
  prepare.customer = prepare?.customer?.id ? prepare.customer.id : null
  prepare.status = typeof prepare.status === 'string' ? prepare.status : prepare.status.value
  prepare.manager = prepare.manager.id
  if (prepare.channel) {
    prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
  }
  // prepare.brn = prepare.brn ? prepare.brn : null
  // prepare.tel = prepare.tel ? prepare.tel : null
  // prepare.company_type = typeof prepare.company_type === 'string' ? prepare.company_type : prepare.company_type.value
  // prepare.company_type_detail = prepare.company_type_detail ? prepare.company_type_detail : null
  // prepare.description = prepare.description ? prepare.description : null
  // prepare.rep_name = prepare.rep_name ? prepare.rep_name : null
  // prepare.rep_tel = prepare.rep_tel ? prepare.rep_tel : null
  // prepare.mng_name = prepare.mng_name ? prepare.mng_name : null
  // prepare.mng_tel = prepare.mng_tel ? prepare.mng_tel : null
  // prepare.mng_email = prepare.mng_email ? prepare.mng_email : null
  // prepare.manager = prepare.manager?.user ? prepare.manager.user.id : null
  // if (prepare.channel) {
  //   prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
  // }
  return prepare
}


const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    const sales = await useAuthAxios({
      method: 'POST',
      url: `/app/v1/sales/${props.sales.id}/`,
      data: getPrepare(data)
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>

<template>
  <form class="w-full p-4 space-y-4" @submit.prevent="save">
    <CampaignSelector v-model="data.campaign" :errors="validator.campaign.$errors.map(err => err.$message)"
                      :label-class="'w-16'" :required="true" label="캠페인"/>
    <CompanySelector v-model="data.company"
                     :errors="validator.company.$errors.map(err => err.$message)" label="거래처"/>
    <CustomerSelector v-model="data.customer" :errors="validator.customer.$errors.map(err => err.$message)"/>
    <TextArea v-model="data.detail" :errors="validator.detail.$errors.map(err => err.$message)" :required="true"
              label="상세"/>

    <DatePickerInput v-model="data.performance_date" :errors="validator.performance_date.$errors.map(e=>e.$message)"
                     :required="true" label="기준일"/>
    <ChoiceSelectorBase v-model="data.status" :errors="validator.status.$errors.map(e=>e.$message)" :required="true"
                        choice-type="SalesStatus" label="상태"/>
    <EmployeeSelector v-model="data.manager" :errors="validator.manager.$errors.map(err => err.$message)"
                      :required="true" label="담당자"/>
    <ChoiceSelectorBase v-model="data.channel" :errors="validator.channel.$errors.map(e=>e.$message)"
                        :required="true" choice-type="AcquireChannelChoice" label="인입경로"/>
    <button class="btn btn-primary" type="submit">저장</button>
  </form>

  <!--  <div class="px-4 input-group mb-4">-->
  <!--    <label class="w-16" for="id-for-registerer">최초 등록자</label>-->
  <!--    <div class="text-sm">-->
  <!--      {{ props?.sales?.registerer?.name }}-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="px-4 input-group mb-4">-->
  <!--    <label class="w-16" for="id-for-registerer">등록일</label>-->
  <!--    <div class="text-sm">-->
  <!--      {{ props?.sales?.created_at ? formatDateTime(props.sales.created_at) : '-' }}-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<style scoped>

</style>