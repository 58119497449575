<script setup>

import TextInput from "@/components/forms/TextInput.vue";
import {ref, reactive, watch} from "vue";
import {toReactive} from "@vueuse/core";
import {helpers, numeric} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import ConfirmDialogComponent from "@/components/commons/Confirm/ConfirmDialogComponent.vue";

const props = defineProps({
    bill: {type: Object, required: true},
    value: {type: Object, required: true},
})
const emits = defineEmits(['updateRow', 'update'])

const data = toReactive({...props.value})

watch(data, (nv, ov) => {
    emits('updateRow', nv)
}, {deep: true})


watch(() => data.reconcile_amount, (newValue, oldValue) => {
    newValue = isNaN(Number(newValue)) ? 0 : Number(newValue)
    data.next_remain_amount = data.remain_amount - newValue
    // if (data.remain_amount >= newValue) {
    //     data.next_remain_amount = data.remain_amount - newValue
    // } else {
    //     data.reconcile_amount = data.remain_amount
    //     data.next_remain_amount = 0
    // }
})

watch(() => props.value, (newValue) => {
    Object.assign(data, newValue)
    reconcileList.value = null
}, {deep: true})


const model = {
    reconcile_amount: {
        required: true,
        max: helpers.withMessage(
            "수입액은 미수액보다 클 수 없습니다.",
            (value) => {
                return data.remain_amount >= Number(value)
            }
        ),
        numeric: helpers.withMessage('숫자만 입력 가능합니다.', (value) => {
            return isNaN(Number(value)) ? false : true
        }),
    },
}

const validator = useVuelidate(model, data)

const reconcileList = ref(null)

const toggleReconcileList = () => {
    if (reconcileList.value !== null) {
        reconcileList.value = null
    } else {
        openReconcileList()
    }
}
const openReconcileList = async () => {
    reconcileList.value = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/accounting/reconcile/?bill_entry=${data.id}`,
    })
}

const confirmData = reactive({
    show: false,
    summary: null,
    detail: null,
    onAccept: () => {},
})

const showDeleteReconcileConfirm = (reconcileId) => {
    confirmData.show = true
    confirmData.summary = '수입 이력을 삭자하시겠습니까?'
    confirmData.detail = '이 작업은 취소할 수 없습니다.'
    confirmData.onAccept = () => deleteReconcile(reconcileId)
}

const closeConfirm = () => {
    confirmData.show = false
    confirmData.summary = null
    confirmData.detail = null
    confirmData.onAccept = () => {}
}

const deleteReconcile = async (reconcileId) => {
    const option = {
        method: 'DELETE',
        url: `/app/v1/accounting/reconcile/${reconcileId}/`,
    }
    const response = await useAuthAxios(option)
    emits('update')
    if (reconcileList.value !== null) {
        reconcileList.value = reconcileList.value.map(r => {
            if (r.id === reconcileId) {
                r = response
            }
            return r
        })
    }
}

const setRemain = (remainAmount) => {
    data.reconcile_amount = remainAmount
}
</script>

<template>
    <tr>
        <td class="font-semibold cursor-pointer" @click="toggleReconcileList">{{ data.serial }}</td>
        <td class="text-right">{{ data.total.toLocaleString() }}</td>
        <td class="text-right cursor-pointer" @click="() => setRemain(data.remain_amount)">{{ data.remain_amount.toLocaleString() }}</td>
        <td>
            <TextInput v-model="data.reconcile_amount"
                       :errors="validator.reconcile_amount.$errors.map(err => err.$message)" auto-select
                       class="col-span-3 md:col-span-4" :readonly="!['S', 'B'].includes(bill.status)"
                       label="" type="number"/>
        </td>
        <td class="text-right">
            <span :class="[data.next_remain_amount < 0 ? 'text-red-500 font-semibold':'']">
            {{
                    isNaN(Number(data.next_remain_amount)) ? data.remain_amount.toLocaleString() : data.next_remain_amount.toLocaleString()
                }}
            </span>
        </td>
    </tr>
    <template v-if="reconcileList !== null">
        <tr>
            <td colspan="5">
                <table class="w-full">
                    <tbody>
                    <template v-if="reconcileList.length === 0">
                        <tr>
                            <td class="text-center" colspan="6">수입 이력이 없습니다.</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(reconcile, index) in reconcileList" :key="reconcile.id">
                            <td class="sub">{{index === 0 ? '수입이력':''}}</td>
                            <td class="sub">{{ reconcile.date }}</td>
                            <td class="sub">{{ reconcile.amount.toLocaleString() }}</td>
                            <td class="sub">{{ reconcile.user ? reconcile.user.name:'-' }}</td>
                            <td class="sub">
                                <template v-if="reconcile.is_delete">
                                    삭제됨
                                </template>
                                <template v-else>
                                    <button class="btn btn-error btn-xs" type="button" @click="() => showDeleteReconcileConfirm(reconcile.id)">삭제</button>
                                </template>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </td>
        </tr>
    </template>
    <ConfirmDialogComponent v-bind="confirmData" @close="closeConfirm"/>

    <!--    <tr>-->
    <!--        <td class="border-t border-gray-200" colspan="5">-->
    <!--            <pre>{{ data }}</pre>-->
    <!--        </td>-->
    <!--    </tr>-->
</template>

<style scoped>

td {
    @apply whitespace-nowrap px-3 py-4 text-sm text-gray-500
}

td.sub {
    @apply whitespace-nowrap px-3 py-1 text-sm text-gray-500
}
</style>