<script setup>

import {onMounted, reactive, watch} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import {
  cellphoneValidator,
  maxLengthWithMessage,
  minLengthWithMessage, requiredWithMessage,
} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import Toggle from "@/components/forms/Toggle.vue";
import InfoBlock from "@/components/forms/InfoBlock.vue";

const authStore = useAuthStore()

const props = defineProps({
  userData: Object
})

const data = reactive({
  is_driver: props.userData.is_driver
})

const $externalResults = ref({})

const model = {
  is_driver: {
    required: requiredWithMessage('필수 항목입니다.'),
  }
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()


const save = async (value) => {
  await useAuthAxios({
      method: 'POST',
      url: `/app/v1/employment/${props.userData.id}/`,
      data: {is_driver: value}
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
}

const setValue = async (value) => {
  await save(value)
  data.is_driver = value
}
</script>

<template>
  <div>
    <span class="mr-4 text-lg text-slate-900">기사</span>
    <InfoBlock
        :description="[{text: '기사 권한이 부여된 사용자에게만 차량을 배정할 수 있습니다.'}]"/>
    <form class="w-96" method="post" @submit.prevent="save">
      <Toggle :disabled="!props.userData.is_active" :enabled="data.is_driver"
              :errors="validator.is_driver.$errors.map(err => err.$message)"
              class="mt-4" name="is_driver"
              @update:value="setValue">
        <template #label>
          <span class="text-sm font-medium text-gray-900">기사</span>
        </template>
      </Toggle>
    </form>
  </div>
</template>

<style scoped>

</style>