<script setup>
import NavLayoutBase from "@/layout/base/NavLayoutBase.vue";
import TopbarComponent from "@/layout/partials/TopbarComponent.vue";

const props = defineProps({
  title: {
    type: String,
    default: "페이지 타이틀"
  }
})
</script>

<template>
  <NavLayoutBase>
    <TopbarComponent :title="props.title"/>
    <slot name="body"/>
  </NavLayoutBase>
</template>

<style scoped>
</style>
