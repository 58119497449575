<script setup>
import {CheckIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps(['value'])
</script>

<template>
  <template v-if="Boolean(props.value)">
    <CheckIcon class="size-5 text-green-500"/>
  </template>
  <template v-else>
    <XMarkIcon class="size-5 text-red-500"/>
  </template>
</template>

<style scoped>

</style>