<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import {useDraggable} from "@vueuse/core";

const props = defineProps({
  title: {type: String, required: true},
  open: {type: Boolean, required: true},
  mdWClass: {type: String, required: false, default: 'md:max-w-xl'},
  bodyClass: {type: [String, Object. Array], required: false, default: 'py-3'}
})

const emits = defineEmits(['close'])

// open and close
const drawer = ref(null)

const close = () => {
  emits('close')
}

const resize = ref(false)
const widthStyle = ref({maxWidth: '100%'})
const resizeHandler = ref(null)


const {x} = useDraggable(resizeHandler, {
  initialValue: {x: 0, y: 0},
})

watch(() => x, (newValue, oldValue) => {
    resize.value = true
    widthStyle.value = {maxWidth: `calc(100% - ${newValue.value}px)`}
}, {deep: true})

</script>

<template>
  <TransitionRoot :show="open" as="template">
    <Dialog ref="drawer" class="relative z-50 overflow-y-auto" @close="close">
      <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 md:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-300"
                             enter-from="translate-x-full" enter-to="translate-x-0"
                             leave="transform transition ease-in-out duration-300"
                             leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen" :class="props.mdWClass">
                <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl flex-grow">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-primary-500 px-4 py-2">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-white">{{ props.title }}
                          </DialogTitle>
                        </div>
                        <div class="flex h-7 items-center">
                          <button class="relative text-white" type="button" @click="close" tabindex="-1">
                            <XMarkIcon aria-hidden="true" class="size-6"/>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div :class="props.bodyClass">
                      <slot/>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>