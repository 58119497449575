import {defineStore} from "pinia";
import axios from "axios";
import router from "@/router";
import {useLocalStorage} from "@vueuse/core";
import {RoutesData} from "@/router";
import {API_URL} from "@/service/api.js";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

const serializer = {
    read: (v) => v ? JSON.parse(v) : null,
    write: (v) => JSON.stringify(v),
}
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        access: useLocalStorage("auth_access", null, {serializer}),
        refresh: useLocalStorage("auth_refresh", null, {serializer}),
        user: useLocalStorage("user", null, {serializer})
    }),
    actions: {
        async setUser() {
            this.user = await useAuthAxios({
                method: "GET",
                url: `/app/v1/user/profile/`,
            })
        },
        async logout(title=null, detail = null) {
            const layoutStore = useLayoutStore()
            layoutStore.navOpens = []
            this.user = null;
            this.access = null;
            this.refresh = null;
            // layoutStore.addErrorNotification(title || '인증 오류', detail || '로그인이 필요한 서비스 입니다.', 3000)
            await router.push(RoutesData.Auth.children.Login.path)
        },
        async refreshToken() {
            const refreshResponse = await axios({
                method: "POST",
                url: `/app/v1/user/token/refresh/`,
                data: {
                    refresh: this.refresh,
                },
                validateStatus: (status) => {
                    return [200, 401].indexOf(status) > -1;
                },
            });
            if (refreshResponse.status === 401) {
                await this.logout();
                return null
            }
            this.access = refreshResponse.data.access;
            return this.access;
        },
        async verifyToken() {
            if (this.access === null || this.refresh === null) {
                await this.logout();
                return null
            }
            try {
                const verifyResponse = await axios({
                    method: "POST",
                    url: `/app/v1/user/token/verify/`,
                    data: {
                        token: this.access,
                    },
                    validateStatus: (status) => {
                        return [200, 401].indexOf(status) > -1;
                    },
                });
                if (verifyResponse.status === 200) {
                    // access 토큰이 유효함
                    return true;
                }
                await this.refreshToken();
                return true;
            } catch (e) {
                return false
            }
        },
    },
});
