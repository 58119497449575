<script setup>

import {computed} from "vue";
import {CheckIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import {Severities} from "@/constant.js";
import Toggle from "@/components/forms/Toggle.vue";
import dayjs from "dayjs";

const props = defineProps({
  fieldKey: {
    type: String,
    required: true
  },
  fieldData: {
    type: Object,
    required: true,
  },
  row: {
    type: Object,
    required: true
  },
  filter: {type: Function, required: false, default: (row) => row},
})

const data = computed(() => {
  return props.filter(props.row)
})


const cellStyle = computed(() => {
  let width = props.fieldData.width ? `${props.fieldData.width}px` : `200px`
  return {width: width, minWidth: width}
})

const cellType = computed(() => {
  return props.fieldData.type || 'text'
})

const fieldValue = computed(() => {
  return data.value[props.fieldKey]
})

const setToggleValue = async (value) => {
  await props.fieldData.toggle(props.row, value)
}

const alignClass = computed(() => {
  if (props.fieldData.align === 'right') {
    return 'justify-end'
  } else if (props.fieldData.align === 'center') {
    return 'justify-center'
  } else {
    // left
    return 'justify-start'
  }
})
</script>

<template>
  <div :style="cellStyle" :class="[alignClass]"
       class="border-r flex items-center truncate px-2 hover:bg-primary-50">
    <template v-if="cellType === 'text'">
      <span class="truncate text-sm text-slate-900">
          {{ fieldValue }}
      </span>
    </template>
    <template v-else-if="cellType === 'boolean'">
      <template v-if="Boolean(fieldValue)">
        <CheckIcon class="size-5 text-green-500"/>
      </template>
      <template v-else>
        <XMarkIcon class="size-5 text-red-500"/>
      </template>
    </template>
    <template v-else-if="cellType === 'toggle'">
      <Toggle :enabled="fieldValue" name="" @update:value="setToggleValue"/>
    </template>
    <template v-else-if="cellType === 'button'">
      <button
          :class="['btn', 'btn-xs', `btn-${props.fieldData.severity || Severities.PRIMARY}`, 'flex', 'items-center', 'gap-x-1']"
          type="button" @click.prevent.stop="props.fieldData.action(props.row)">
        <Component :is="props.fieldData.icon" v-if="props.fieldData.icon" class="size-4"/>
        {{ props.fieldData.label }}
      </button>
    </template>
    <template v-else-if="cellType === 'datetime'">
      <span class="truncate text-sm text-slate-900">
      <template v-if="fieldValue">
        {{ dayjs(fieldValue).isValid() ? dayjs(fieldValue).format("YYYY-MM-DD HH:mm") : fieldValue }}
      </template>
      <template v-else>-</template>
      </span>
    </template>
    <template v-else-if="cellType === 'date'">
      <span class="truncate text-sm text-slate-900">
      <template v-if="fieldValue">
        {{ dayjs(fieldValue).isValid() ? dayjs(fieldValue).format("YYYY-MM-DD") : fieldValue }}
      </template>
      <template v-else>-</template>
      </span>
    </template>
    <template v-else-if="cellType === 'number'">
      <span class="truncate text-sm text-slate-900">
      <template v-if="fieldValue">
        {{Number(fieldValue).toLocaleString()}}
      </template>
      <template v-else>-</template>
      </span>
    </template>
  </div>
</template>

<style scoped>

</style>