<script setup>

import {nextTick, onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
    cellphoneValidator, dateStringValidator,
    emailValidator,
    externalValidator,
    maxLengthWithMessage,
    minLengthWithMessage, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

import dayjs from "dayjs";
import {formatDateTime} from "../../../utils/datetime.js";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import Toggle from "@/components/forms/Toggle.vue";


onMounted(async () => {
    await fetch()
})
const layoutStore = useLayoutStore()

const props = defineProps({
    campaign: {type: [null, Object], required: true}
})

const emits = defineEmits(['update:campaign'])

const data = reactive({
    fields: []
})


const fetch = async () => {
    let response = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/campaign/${props.campaign.id}/setting/`
    })
    data.fields = response.map(f => {
        f.errors = []
        return f
    })
}

const validateField = (field) => {
    field.errors = []
    if (field.value_exists === false) {
        field.value = null
        return field
    }
    if (field.allow_null === false && field.value === null) {
        field.errors.push('이 값은 null일 수 없습니다.')
    }
    if (field.allow_blank === false && field.value === '') {
        field.errors.push('이 값은 blank일 수 없습니다.')
    }
    return field
}


const save = async () => {
    let field = data.fields.map(f => validateField(f))
    let valid = field.every(f => f.errors.length === 0)
    if (!valid) {
        return
    }
    try {
        const campaign = await useAuthAxios({
            method: 'POST',
            url: `/app/v1/campaign/${props.campaign.id}/setting/`,
            data: data.fields
        })
        layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
        // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    } catch (e) {
        throw e
    }
}

const toggle = (field, value) => {
    field.value_exists = value
    if (value === true && field.field_type === 'B') {
        field.value = false
    } else {
        field.value = null
    }
}
</script>

<template>
    <form class="w-full p-4 mb-8" @submit.prevent="save">
        <template v-for="field in data.fields" :key="field.id">
            <div class="grid grid-cols-8 gap-x-6 mb-3">
                <div class="col-span-2">
                    {{ field.name }}
                </div>
                <div class="col-span-4">
                    <div>
                        <template v-if="field.field_type === 'B'">
                        <Toggle :disabled="!field.value_exists" :enabled="field.value === true" :name="field.key"
                                @update:value="v => field.value = v">
                        </Toggle>
                    </template>
                    <template v-else>
                        <TextInput v-model="field.value" :label="''" :readonly="!field.value_exists"
                                   :type="field.field_type === 'S' ? 'text' : 'number'"/>
                    </template>
                    </div>
                    <div v-if="field.errors.length > 0" class="text-red-600">
                        <div v-for="error in field.errors" :key="error">
                            {{error}}
                        </div>
                    </div>
                </div>
                <div class="col-span-2">
                    <Toggle :enabled="field.value_exists" :name="`use-${field.id}`"
                            @update:value="v => toggle( field, v)"/>
                </div>
            </div>
        </template>
        <button class="btn btn-primary" type="submit">저장</button>
    </form>

    <!--  <div class="px-4 input-group mb-4">-->
    <!--    <label class="w-16" for="id-for-registerer">최초 등록자</label>-->
    <!--    <div class="text-sm">-->
    <!--      {{ props?.campaign?.registerer?.name }}-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="px-4 input-group mb-4">-->
    <!--    <label class="w-16" for="id-for-registerer">등록일</label>-->
    <!--    <div class="text-sm">-->
    <!--      {{ props?.campaign?.created_at ? formatDateTime(props.campaign.created_at) : '-' }}-->
    <!--    </div>-->
    <!--  </div>-->

</template>

<style scoped>

</style>