<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage,
  nullBrnValidator
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {formatBrn} from "@/utils/formatter.js";
import {Severities} from "@/constant.js";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import Toggle from "@/components/forms/Toggle.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  affiliate: {type: [null, Object], required: true}
})

const emits = defineEmits(['update:affiliate'])

const operationHour = ref(null)

const data = reactive({
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
  sun: true,
  lunch: true,
  mon_open: null,
  mon_close: null,
  tue_open: null,
  tue_close: null,
  wed_open: null,
  wed_close: null,
  thu_open: null,
  thu_close: null,
  fri_open: null,
  fri_close: null,
  sat_open: null,
  sat_close: null,
  sun_open: null,
  sun_close: null,
  lunch_open: null,
  lunch_close: null,
  holiday: null,
  detail: null,
})

const weeks = {
  mon: "월요일", tue: "화요일", wed: "수요일", thu: "목요일", fri: "금요일", sat: "토요일", sun: "일요일", lunch: "점심시간"
}

onMounted(async () => {
  operationHour.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/affiliate/${props.affiliate.id}/operation_hour/`
  })
  if (operationHour.value) {
    Object.entries(data).map(([key, value]) => {
      if (Object.keys(weeks).includes(key)) {
        data[key] = operationHour.value[key] !== null
      } else if (operationHour.value[key]) {
        data[key] = operationHour.value[key].length === 5 ? operationHour.value[key] : operationHour.value[key].slice(0, 5)
      } else {
        data[key] = null
      }
    })
  }
})

const $externalResults = ref({})

const model = {
  mon: {externalValidator},
  tue: {externalValidator},
  wed: {externalValidator},
  thu: {externalValidator},
  fri: {externalValidator},
  sat: {externalValidator},
  sun: {externalValidator},
  lunch: {externalValidator},
  mon_open: {externalValidator},
  mon_close: {externalValidator},
  tue_open: {externalValidator},
  tue_close: {externalValidator},
  wed_open: {externalValidator},
  wed_close: {externalValidator},
  thu_open: {externalValidator},
  thu_close: {externalValidator},
  fri_open: {externalValidator},
  fri_close: {externalValidator},
  sat_open: {externalValidator},
  sat_close: {externalValidator},
  sun_open: {externalValidator},
  sun_close: {externalValidator},
  lunch_open: {externalValidator},
  lunch_close: {externalValidator},
  holiday: {externalValidator},
  detail: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults})


const getPrepare = (value) => {
  // let prepare = JSON.parse(JSON.stringify(value))
  // prepare.brn = prepare.brn ? prepare.brn : null
  // prepare.tel = prepare.tel ? prepare.tel : null
  // prepare.company_type = typeof prepare.company_type === 'string' ? prepare.company_type : prepare.company_type.value
  // prepare.company_type_detail = prepare.company_type_detail ? prepare.company_type_detail : null
  // prepare.description = prepare.description ? prepare.description : null
  // prepare.manager = prepare.manager?.id || null
  // if (prepare.channel) {
  //   prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
  // }
  // return prepare
}


const save = async () => {
  try {
    validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    if (!valid) return
    const affiliate = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/affiliate/${props.affiliate.id}/operation_hour/`,
      data: data
    }, false, $externalResults)
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    emits('update:affiliate', affiliate)
  } catch (e) {
    $externalResults.value = e?.field_errors || {}
    throw e
  }
}

const toggleWeek = (week, value) => {
  if (value === false) {
    data[`${week}_open`] = value ? data[`${week}_open`] : null
    data[`${week}_close`] = value ? data[`${week}_close`] : null
  }
  data[week] = value
}
</script>

<template>

  <form class="w-full p-2" @submit.prevent="save">
    <template v-if="!operationHour">
      <InfoBlock :description="{text: '영업시간 정보가 등록되지 않았습니다.', severity: Severities.SECONDARY}" class="mb-4"/>
    </template>
    <div v-for="[week, label] in Object.entries(weeks)" class="grid grid-cols-9 gap-x-2 items-center mb-4">
      <div class="text-sm font-semibold col-span-1">{{ label }}</div>
      <Toggle :enabled="data[week]" :name="week" class="col-span-2" @update:value="(value) => toggleWeek(week, value)">
        <template #label>운영</template>
      </Toggle>
      <TimePickerInput v-model="data[`${week}_open`]" :errors="validator[`${week}_open`].$errors.map(e=>e.$message)"
                       :readonly="!data[week]" class="w-full col-span-3"
                       label="시작" placeholder="시작"/>
      <TimePickerInput v-model="data[`${week}_close`]" :errors="validator[`${week}_close`].$errors.map(e=>e.$message)"
                       :readonly="!data[week]" class="w-full col-span-3"
                       label="종료" placeholder="종료"/>
    </div>
    <TextInput v-model="data.holiday" :errors="validator.holiday.$errors.map(e=>e.$message)" class="mb-4" label="휴무일"/>
    <TextInput v-model="data.detail" :errors="validator.detail.$errors.map(e=>e.$message)" class="mb-4" label="기타안내"/>
    <button class="btn btn-primary w-full mt-4" type="submit">저장</button>
  </form>

</template>
<style scoped>

</style>