<script setup>
import EmployeePermissionAdmin from "@/views/settings/partials/employee/EmployeePermissionAdmin.vue";
import EmployeePermissionDriver from "@/views/settings/partials/employee/EmployeePermissionDriver.vue";
import EmployeePermissionCompanyAdmin from "@/views/settings/partials/employee/EmployeePermissionCompanyAdmin.vue";
import {computed, onMounted, reactive, watch} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import {
  cellphoneValidator,
  maxLengthWithMessage,
  minLengthWithMessage, requiredWithMessage,
} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import Toggle from "@/components/forms/Toggle.vue";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import PermissionToggle from "@/views/settings/partials/permissionToggle.vue";

const authStore = useAuthStore()

const props = defineProps({
  userData: Object
})

const data = reactive({
  is_admin: false,
  has_affiliate_create_modify: false,
  has_employment_create_modify: false,
  has_employment_permission_modify: false,
  has_company_create_modify: false,
})

const $externalResults = ref({})

const model = {
  is_admin: {
    required: requiredWithMessage('필수 항목입니다.'),
  }
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()

const fetch = async () => {
  let permission = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/employment/${props.userData.id}/permission/`,
  })
  data.is_admin = permission.is_admin
  data.has_affiliate_create_modify = permission.has_affiliate_create_modify
  data.has_employment_create_modify = permission.has_employment_create_modify
  data.has_employment_permission_modify = permission.has_employment_permission_modify
  data.has_company_create_modify = permission.has_company_create_modify
}

const save = async (fieldName, value) => {
  let data = {}
  data[fieldName] = value
  await useAuthAxios({
    method: 'POST',
    url: `/app/v1/employment/${props.userData.id}/permission/`,
    data: data
  })
  layoutStore.addSuccessNotification('변경 되었습니다.', null, 1000)
}

const setValue = async (fieldName, value) => {
  try {
    await save(fieldName, value)
    data[fieldName] = value
  } catch (e) {
    data[fieldName] = !value
    throw e
  }
}

onMounted(async () => {
  await fetch()
})

const isDisabled = computed(() => {
  return !props.userData.is_active || data.is_admin
})
</script>

<template>
  <div>
    <span class="mr-4 text-lg text-slate-900">권한</span>
    {{ data.value }}
    <div class="mb-8">
      <InfoBlock
          :description="[{text: '사용자에게 부여된 관리자 권한을 설정합니다.'}, {text: '관리자 권한이 있는 사용자는 하위 권한과 무관하게 전체 권한을 가집니다.'}]"/>
      <form class="w-full" method="post" @submit.prevent="save">
        <div class="mb-4">
          <permission-toggle :checked="data.is_admin" :disabled="!props.userData.is_active"
                             @update:value="value => setValue('is_admin', value)">
            <span class="text-sm font-medium text-gray-900">관리자 권한</span> {{ ' ' }}
            <span class="text-sm text-gray-500">전체 기능에 접근 가능한 권한</span>
          </permission-toggle>
        </div>
        <div class="grid grid-cols-4">
          <div class="col-span-1 text-sm font-semibold">사업장</div>
          <div class="col-span-1 text-sm font-semibold">사용자(임직원)</div>
          <div class="col-span-1 text-sm font-semibold">거래처</div>
        </div>
        <div class="grid grid-cols-4">
          <!--사업장-->
          <div class="col-span-1 text-md font-semibold">
            <permission-toggle :checked="data.has_company_create_modify" :disabled="isDisabled"
                               @update:value="value => setValue('has_company_create_modify', value)">
              <span class="text-sm font-medium text-gray-900">추가/수정</span>
            </permission-toggle>
          </div>
          <!--사용자(임직원)-->
          <div class="col-span-1 text-md font-semibold">
            <permission-toggle :checked="data.has_employment_create_modify" :disabled="isDisabled"
                               @update:value="value => setValue('has_employment_create_modify', value)">
              <span class="text-sm font-medium text-gray-900">추가/수정</span>
            </permission-toggle>
            <permission-toggle :checked="data.has_employment_permission_modify" :disabled="isDisabled"
                               @update:value="value => setValue('has_employment_permission_modify', value)">
              <span class="text-sm font-medium text-gray-900">권한 변경</span>
            </permission-toggle>
          </div>
          <!--거래처-->
          <div class="col-span-1 text-md font-semibold">
            <permission-toggle :checked="data.has_affiliate_create_modify" :disabled="isDisabled"
                               @update:value="value => setValue('has_affiliate_create_modify', value)">
              <span class="text-sm font-medium text-gray-900">추가/수정</span>
            </permission-toggle>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>