<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ExclamationTriangleIcon, CheckIcon, InformationCircleIcon} from '@heroicons/vue/24/outline'
import {Severities} from "@/constant.js";
import {computed} from "vue";

const props = defineProps({
  open: {type: Boolean, required: true},
  severity: {type: [String, null], required: false, default: null},
  summary: {type: [String, null], required: false, default: '확인'},
  detail: {type: [String, Array], required: false, default: '진행하시겠습니까?'},
  acceptString: {type: String, required: false, default: '승인'},
  rejectString: {type: [String, null], required: false, default: null},
  closeString: {type: String, required: false, default: '취소'},
})

const emits = defineEmits(['accept', 'reject', 'close'])

const close = () => {
  emits('close')
}
const accept = () => {
  emits('accept')
  close()
}
const reject = () => {
  emits('reject')
  close()
}

const buttonClass = computed(() => {
  if (props.severity === Severities.ERROR) {
    return 'btn-error'
  } else if (props.severity === Severities.PRIMARY) {
    return 'btn-primary'
  } else if (props.severity === Severities.SECONDARY) {
    return 'btn-secondary'
  } else {
    return 'btn-primary'
  }
})

</script>

<template>
  <!--  <Teleport to="body">-->
  <TransitionRoot :show="props.open" as="template" @close="close">
    <Dialog class="relative z-50">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
          <DialogPanel
              class="relative  w-full transform overflow-hidden bg-white text-left shadow-xl transition-all md:my-8 md:w-full md:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 md:p-6 md:pb-4">
              <div class="md:flex md:items-start">
                <div v-if="props.severity === Severities.ERROR"
                     class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
                  <ExclamationTriangleIcon aria-hidden="true" class="h-6 w-6 text-red-600"/>
                </div>
                <div v-else-if="props.severity === Severities.PRIMARY"
                     class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 md:mx-0 md:h-10 md:w-10">
                  <CheckIcon aria-hidden="true" class="h-6 w-6 text-green-600"/>
                </div>
                <div v-else-if="props.severity === Severities.SECONDARY"
                     class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 md:mx-0 md:h-10 md:w-10">
                  <InformationCircleIcon aria-hidden="true" class="h-6 w-6 text-primary-600"/>
                </div>
                <div class="mt-3 text-center md:ml-4 md:mt-0 md:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    {{ props.summary }}
                  </DialogTitle>
                  <div class="mt-2">
                    <template v-if="Array.isArray(props.detail)">
                      <p v-for="detail in props.detail" class="text-sm text-gray-500">{{ detail }}</p>
                    </template>
                    <template v-else>
                      <p class="text-sm text-gray-500">{{ props.detail }}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 flex gap-x-2 items-center justify-between w-full">
              <button class="btn btn-secondary" type="button" @click="close">{{
                  props.closeString
                }}
              </button>
              <div class="flex gap-x-2">
                <button :class="buttonClass" class="btn" type="button" @click="accept">{{
                    props.acceptString
                  }}
                </button>
                <template v-if="props.rejectString">
                  <button class="btn btn-error" type="button" @click="reject">{{
                      props.rejectString
                    }}
                  </button>
                </template>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!--  </Teleport>-->
</template>

<style scoped>
</style>