<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
    externalValidator,
    maxLengthWithMessage,
    minLengthWithMessage,
    nullBrnValidator
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {formatBrn} from "@/utils/formatter.js";

const layoutStore = useLayoutStore()

const props = defineProps({
    affiliate: {type: [null, Object], required: true}
})

const emits = defineEmits(['update:affiliate'])
const affiliate = ref(null)

const data = reactive({
    name: '',
    brn: '',
    tel: '',
    company_type: null,
    company_type_detail: '',
    description: '',
    manager: null,
    channel: null,
    biz_category: [],
    biz_type: [],
})

onMounted(async () => {
    affiliate.value = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/affiliate/${props.affiliate.id}/`
    })
    data.name = affiliate.value.name
    data.brn = affiliate.value.brn
    data.tel = affiliate.value.tel
    data.company_type = affiliate.value.company_type
    data.company_type_detail = affiliate.value.company_type_detail
    data.description = affiliate.value.description
    data.manager = affiliate.value.manager || null
    data.channel = affiliate.value.channel
    data.biz_category = affiliate.value.biz_category
    data.biz_type = affiliate.value.biz_type
})

const $externalResults = ref({})

const model = {
    name: {
        required: helpers.withMessage('거래처명 입력해주세요.', required),
        minLength: minLengthWithMessage('거래처명을 2글자 이상으로 입력해주세요.', 2),
        maxLength: maxLengthWithMessage('거래처명을 30글자 이하로 입력해주세요.', 30)
    },
    brn: {format: nullBrnValidator},
    tel: {externalValidator},
    company_type: {required: helpers.withMessage('타입을 선택해주세요.', required)},
    company_type_detail: {externalValidator},
    biz_category: {externalValidator},
    biz_type: {externalValidator},
    description: {externalValidator},
    manager: {externalValidator},
    channel: {externalValidator}
}

const validator = useVuelidate(model, data, {$externalResults})


const getPrepare = (value) => {
    let prepare = JSON.parse(JSON.stringify(value))
    prepare.brn = prepare.brn ? prepare.brn : null
    prepare.tel = prepare.tel ? prepare.tel : null
    prepare.company_type = typeof prepare.company_type === 'string' ? prepare.company_type : prepare.company_type.value
    prepare.company_type_detail = prepare.company_type_detail ? prepare.company_type_detail : null
    prepare.description = prepare.description ? prepare.description : null
    prepare.manager = prepare.manager?.id || null
    if (prepare.channel) {
        prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
    }
    return prepare
}


const save = async () => {
    validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    if (!valid) return
    try {
        const affiliate = await useAuthAxios({
            method: 'POST',
            url: `/app/v1/affiliate/${props.affiliate.id}/`,
            data: getPrepare(data)
        })
        layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
        emits('update:affiliate', affiliate)
    } catch (e) {
        if (e instanceof APIError && e?.code === 'V0001') {
            $externalResults.value = e.field_errors
        }
        throw e
    }
}
</script>

<template>


    <form class="w-96 p-2" @submit.prevent="save">
        <TextInput v-model="data.name"
                   :errors="validator.name.$errors.map(e => e.$message)" :required="true" label="거래처명"
                   placeholder="거래처명"/>
        <ChoiceSelectorBase v-model="data.company_type" :required="true" choice-type="CompanyTypeChoice" class="mt-4"
                            label="거래처 타입"/>
        <TextInput v-model="data.company_type_detail"
                   :errors="validator.company_type_detail.$errors.map(e => e.$message)" class="mt-4" label="거래처 타입 상세"
                   placeholder="거래처 타입 상세"/>
        <TextInput v-model="data.tel"
                   :errors="validator.tel.$errors.map(e => e.$message)" class="mt-4" label="대표번호" placeholder="대표번호"/>
        <TextInput v-model="data.brn" :errors="validator.brn.$errors.map(e => e.$message)"
                   :readonly="affiliate?.brn" class="mt-4" label="사업자등록번호" placeholder="사업자등록번호"/>
        <TextInput v-model="data.biz_category"
                   :errors="validator.biz_category.$errors.map(e => e.$message)" class="mt-4" label="업태"/>
        <TextInput v-model="data.biz_type"
                   :errors="validator.biz_type.$errors.map(e => e.$message)" class="mt-4" label="업종"/>
        <TextInput v-model="data.description"
                   :errors="validator.description.$errors.map(e => e.$message)" class="mt-4" label="메모"
                   placeholder="메모"/>
        <EmployeeSelector v-model="data.manager" :errors="validator.manager.$errors.map(e => e.$message)" class="mt-4"
                          label="담당자" placeholder="담당자 선택"/>
        <ChoiceSelectorBase v-model="data.channel" :errors="validator.channel.$errors.map(e=>e.$message)"
                            choice-type="AcquireChannelChoice" class="mt-4" label="인입경로"/>
        <button class="btn btn-primary w-full mt-4" type="submit">저장</button>
    </form>
</template>

<style scoped>

</style>