<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ExclamationTriangleIcon, CheckIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {Severities} from "@/constant.js";

const props = defineProps({
    show: Boolean,
    summary: String,
    detail: [String, Array],
    severity: {
        type: String,
        default: Severities.PRIMARY,
    },
    acceptString: {
        type: String,
        default: "확인",
    },
    rejectString: {
        type: String,
        default: "거부",
    },
    cancelString: {
        type: String,
        default: "취소",
    },
    onAccept: {
        type: [Function, null],
        default: () => {},
    },
    onReject: {
        type: [Function, null],
        default: () => {},
    },
    onCancel: {
        type: [Function, null],
        default: () => {},
    },
});

const handleAccept = async () => {
    console.log('handleAccept ', handleAccept)
    if (props.onAccept) {
        await props.onAccept();
    }
    emits('close');
};

const handleReject = async () => {
    console.log('handleReject ', handleReject)
    if (props.onReject) {
        await props.onReject();
    }
    emits('close');
};

const handleCancel = async () => {
    if (props.onCancel) {
        await props.onCancel();
    }
    emits('close');
};

const emits = defineEmits(['close'])

// 왜 빌드가 실패하지?
</script>

<template>
    <Teleport to="body">
        <TransitionRoot :show="show" as="template">
            <Dialog class="relative">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                </TransitionChild>
                <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
                        <TransitionChild as="template" enter="ease-out duration-300"
                                         enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                                         enter-to="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200"
                                         leave-from="opacity-100 translate-y-0 md:scale-100"
                                         leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                            <DialogPanel
                                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 md:w-full md:max-w-lg">
                                <!--Dialog Header-->
                                <div class="bg-white px-4 pb-4 pt-5 md:p-6 md:pb-4">
                                    <div class="md:flex md:items-start">
                                        <!-- Severity Icon -->
                                        <div v-if="severity === Severities.ERROR"
                                             class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
                                            <ExclamationTriangleIcon aria-hidden="true" class="h-6 w-6 text-red-600"/>
                                        </div>
                                        <div v-if="severity === Severities.PRIMARY"
                                             class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 md:mx-0 md:h-10 md:w-10">
                                            <CheckIcon aria-hidden="true" class="h-6 w-6 text-green-600"/>
                                        </div>
                                        <div v-if="severity === Severities.SECONDARY"
                                             class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 md:mx-0 md:h-10 md:w-10">
                                            <InformationCircleIcon aria-hidden="true" class="h-6 w-6 text-primary-600"/>
                                        </div>
                                        <!-- Content -->
                                        <div class="mt-3 text-center md:ml-4 md:mt-0 sm:text-left">
                                            <DialogTitle as="h3"
                                                         class="text-base font-semibold leading-6 text-gray-900">
                                                {{ summary }}
                                            </DialogTitle>
                                            <div class="mt-2">
                                                <template v-if="Array.isArray(detail)">
                                                    <p v-for="d in detail" :key="d" class="text-sm text-gray-500">{{
                                                            d
                                                        }}</p>
                                                </template>
                                                <template v-else>
                                                    <p class="text-sm text-gray-500">{{ detail }}</p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Dialog Footer-->
                                <div class="bg-gray-50 px-4 py-3 gap-x-2 sm:flex sm:flex-row-reverse sm:px-6" @click.stop="">
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        @click="handleAccept">
                                        {{ acceptString }}
                                    </button>
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        @click="handleReject">
                                        {{ rejectString }}
                                    </button>
                                    <button type="button" class="btn btn-secondary" @click="handleCancel">
                                        {{ cancelString }}
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </Teleport>
</template>

<style scoped>
</style>