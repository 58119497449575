import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import useNoAuthAxios from "@/composables/useNoAuthAxios.js";
import axios from "axios";

const fileUpload = async (fileData, onUploadProgress) => {
    const prepare = await useAuthAxios({
        method: 'PUT',
        url: `/app/v1/common/file/`,
        data: {filename: fileData.name}
    }, false)
    let uploadOptions = {
        method: "PUT",
        url: prepare.upload_url,
        data: fileData.file,
        headers: {"Content-Type": fileData.type},
    }
    if (onUploadProgress) {
        uploadOptions.onUploadProgress = onUploadProgress
    }
    const uploadResponse = await useNoAuthAxios(uploadOptions, false)
    return await useAuthAxios({
        method: 'POST',
        url: `/app/v1/common/file/`,
        data: prepare
    }, false)
}

export default fileUpload
