<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator, dateStringValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, nullBrnValidator, phoneValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InlinePagingTableComponent from "@/components/Inlinepagingtable/InlinePagingTableComponent.vue";
import dayjs from "dayjs";
import {useAuthStore} from "@/stores/authStore.js";
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import CustomerSelector from "@/components/selector/apiSelector/CustomerSelector.vue";
import FileDropzone from "@/components/forms/FileDropzone.vue";
import useFileUpload from "@/composables/useFileUpload.js";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import {useRoute} from "vue-router";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextArea from "@/components/forms/TextArea.vue";

const route = useRoute()

const layoutStore = useLayoutStore()
const authStore = useAuthStore()
const props = defineProps({
  affiliate: {type: [null, Object], required: true}
})

const getPerformanceDate = () => {
  return route.query?.date ? route.query.date : dayjs().format('YYYY-MM-DD')
}
const data = reactive({
  campaign: null,
  customer: null,
  performance_date: getPerformanceDate(),
  detail: '',
  status: 'lead',
  manager: props.affiliate.manager || authStore.user,
  channel: null,
  files: [],
})


const model = {
  campaign: {required: requiredWithMessage('필수 항목입니다.')},
  customer: {externalValidator},
  performance_date: {dateStringValidator},
  detail: {required: requiredWithMessage('필수 항목입니다.')},
  status: {required: requiredWithMessage('필수 항목입니다.')},
  manager: {
    required: requiredWithMessage('필수 항목입니다.')
  },
  channel: {externalValidator},
  files: {externalValidator}
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})


const setCustomer = (selected) => {
  data.customer = selected ? selected : null
}


const setFiles = (files) => {
  data.files = files
}

const getPrepare = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare.campaign = prepare.campaign.id
  prepare.customer = prepare.customer ? prepare.customer.id : null
  prepare.manager = prepare.manager.id
  prepare.status = prepare.status?.value ? prepare.status.value : null
  if (prepare.channel) {
    prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
  }
  return prepare
}
const clean = () => {
  data.campaign = null
  data.customer = null
  data.performance_date = getPerformanceDate()
  data.detail = ''
  data.status = 'lead'
  data.manager = props.affiliate.manager.employment || authStore.user.employment
  data.channel = null
  data.files = []
  validator.value.$reset()
}

const uploadProgress = ref({})

const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  layoutStore.showOverlay()
  try {
    uploadProgress.value = {}
    const uploadedResults = []
    data.files.map(file => {
      uploadProgress.value[file.id] = 0
    })
    const uploadPromise = data.files.map(async (file) => {
      const uploaded = await useFileUpload(file, (event) => {
        uploadProgress.value[file.id] = {progress: parseInt(event.progress * 100), complete: event.progress === 1}
      })
      uploadProgress.value[file.id] = {progress: 100, complete: true}
      uploadedResults.push(uploaded)
    })
    await Promise.all(uploadPromise)
    const prepare = getPrepare(data)
    prepare['files'] = uploadedResults.map(result => result.id)
    const salesResponse = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/affiliate/${props.affiliate.id}/sales/`,
      data: prepare
    })
    uploadProgress.value = {}
    tableKey.value += 1
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    clean()
    validator.value.$reset()
    layoutStore.hideOverlay()
  } catch (e) {
    layoutStore.hideOverlay()
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

// 테이블 갱신을 위한 키
const tableKey = ref(1)


const fetchFunction = async (query, data) => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/affiliate/${props.affiliate.id}/sales/`,
    params: query,
    data: data
  })
}

const fetchSales = async (salesId) => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/sales/${salesId}/`,
  })
}

const selectedSales = ref(null)
const isSalesOpen = ref(false)

const showSales = (sales) => {
  selectedSales.value = sales
  isSalesOpen.value = true
}
const hideSales = () => {
  selectedSales.value = null
  isSalesOpen.value = false
}

const tableFields = {
  show: {label: '보기', width: 100, type: 'button', action: showSales},
  campaign: {label: '캠페인', width: 100, type: 'text'},
  company: {label: '거래처', width: 100, type: 'text'},
  customer: {label: '고객', width: 100, type: 'text'},
  performance_date: {label: '영업기준일', width: 100, type: 'text'},
  status_display: {label: '상태', width: 100, type: 'text'},
  manager: {label: '담당자', width: 100, type: 'text'},
  channel: {label: '인입채널', width: 100, type: 'text'},
  detail: {label: '상세', width: 200, type: 'text'}
}

const salesFilter = (row) => {
  let newRow = JSON.parse(JSON.stringify(row))
  newRow.campaign = newRow.target.campaign.name
  newRow.company = newRow.target.company.name
  newRow.customer = newRow.target.customer ? newRow.target.customer.name : ''
  newRow.manager = newRow.manager.employment.user.name
  newRow.channel = newRow.channel ? newRow.channel_display : ''
  return newRow
}
</script>

<template>
  <SalesDrawer v-if="selectedSales" :open="isSalesOpen" :sales="selectedSales" title="영업 상세" @close="hideSales"/>
  <form class="py-4 space-y-4" method="post" @submit.prevent.stop="save">
    <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-x-2">
      <CampaignSelector :errors="validator.campaign.$errors.map(e => e.$message)" :required="true"
                        v-model="data.campaign"/>

      <CustomerSelector :errors="validator.customer.$errors.map(e => e.$message)" v-model="data.customer"/>

      <DatePickerInput v-model="data.performance_date" label="영업기준일" :required="true"
                       :errors="validator.performance_date.$errors.map(e => e.$message)" />
    </div>
    <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-x-2">
      <ChoiceSelectorBase v-model="data.status" choice-type="SalesStatus" :key="3" :required="true"
                          label="진행 상태" :errors="validator.status.$errors.map(e=>e.$message)"/>
      <EmployeeSelector :errors="validator.manager.$errors.map(e => e.$message)" :required="true"
                        v-model="data.manager" label="영업 담당자"/>
      <ChoiceSelectorBase v-model="data.channel" choice-type="AcquireChannelChoice"
                          label="인입경로" :errors="validator.channel.$errors.map(e=>e.$message)"/>
    </div>
    <TextArea v-model="data.detail" :rows="5" :required="true" :errors="validator.detail.$errors.map(e=>e.$message)"/>
    <FileDropzone @update:files="setFiles" :files="data.files" :uploadProgress="uploadProgress"/>
    <button class="btn btn-primary" type="submit">저장</button>
  </form>
  <InlinePagingTableComponent :key="tableKey" :fetch="fetchFunction" :fields="tableFields" :filter="salesFilter"/>
</template>

<style scoped>

</style>