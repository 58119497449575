<script setup>


import DefaultLayout from "@/layout/DefaultLayout.vue";
import FileDropzone from "@/components/forms/FileDropzone.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {nextTick, onMounted, ref} from "vue";
import StaticSelectorBase from "@/components/selector/base/StaticSelectorBase.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import FileInlineDropzone from "@/components/forms/FileInlineDropzone.vue";
import Supercluster from "supercluster";
import dayjs from "dayjs";

const data = ref(null)
const map = ref(null)
const filename = ref(null)
const polyline = ref(null)
const departureMarker = ref(null)
const arrivalMarker = ref(null)

const loadMap = () => {
  // let latlng = new naver.maps.LatLng(props.lat, props.lng)//
  map.value = new naver.maps.Map("map_div", {
    // center: latlng,
    zoomControl: true,
    zoomControlOptions: {
      position: naver.maps.Position.TOP_LEFT,
      style: naver.maps.ZoomControlStyle.SMALL
    },
    // width: "100%",
    // height: `${window.innerHeight - 52}px`,
    zoom: 12
  });
}


onMounted(() => {
  document.getElementById('map_div').innerHTML = null
  loadMap()
  nextTick(async () => {
    let bounds = map.value.getBounds()
  })
})


const draw = (event) => {
  showFile.value = false
  if (polyline.value) {
    polyline.value.setMap(null)
  }
  if (departureMarker.value) {
    departureMarker.value.setMap(null)
  }
  if (arrivalMarker.value) {
    arrivalMarker.value.setMap(null)
  }
  departureMarker.value = null
  arrivalMarker.value = null
  data.value = null
  polyline.value = null
  filename.value = null
  let file = event.target.files[0]
  if (!file) {
    return null
  }
  let reader = new FileReader()
  reader.onload = (e) => {
    filename.value = file.name.split(".")[0]
    data.value = JSON.parse(e.target.result)
    polyline.value = new naver.maps.Polyline({
      map: map.value,
      path: data.value.waypoints.map(waypoint => new naver.maps.LatLng(waypoint.lat, waypoint.lon)),
      clickable: false,
      strokeColor: '#ff0000',
      strokeStyle: 'solid',
      strokeOpacity: 0.5,
      strokeWeight: 5
    });
    departureMarker.value = new naver.maps.Marker({
      position: new naver.maps.LatLng(data.value.waypoints[0].lat, data.value.waypoints[0].lon),
      map: map.value,
      title: 'Unary Spot!!',
      icon: {
        content: '<div style="background-color: white; border: 1px solid black">출발</div>',
        size: new naver.maps.Size(100, 35),
        anchor: new naver.maps.Point(11, 35)
      }
    });
    arrivalMarker.value = new naver.maps.Marker({
      position: new naver.maps.LatLng(data.value.waypoints[data.value.waypoints.length - 1].lat, data.value.waypoints[data.value.waypoints.length - 1].lon),
      map: map.value,
      title: 'Unary Spot!!',
      icon: {
        content: '<div style="background-color: white; border: 1px solid black">도착</div>',
        size: new naver.maps.Size(100, 35),
        anchor: new naver.maps.Point(11, 35)
      }
    });
  }
  reader.readAsText(file)
}
const showFile = ref(true)
</script>

<template>
  <DefaultLayout title="삼성생명 / 대전">
    <template #content>
      <input type="file"  @change="draw" v-show="showFile">
      <div class="mt-2 flex gap-x-6">
        <div>
          <div class="font-semibold" @click="showFile = true">차량</div>
          <div class="">{{ filename }}</div>
        </div>
        <div>
          <div class="font-semibold">배출지 출발</div>
          <div class="">{{ dayjs(data?.summary?.departure).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
        <div>
          <div class="font-semibold">처리장 도착</div>
          <div class="">{{ dayjs(data?.summary?.arrival).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
        <div>
          <div class="font-semibold">소요시간</div>
          <div class="">{{ data?.summary?.time.toLocaleString() }}초</div>
        </div>
        <div>
          <div class="font-semibold">주행거리</div>
          <div class="">{{ data?.summary?.distance.toLocaleString() }}m</div>
        </div>
      </div>
      <div id="map_div" class="h-10/12 mt-2" style="height: 100svh"></div>
    </template>
  </DefaultLayout>
</template>
<style scoped>
</style>