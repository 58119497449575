import {emailValidator, requiredWithMessage} from "@/validators/fields.js";
import {helpers, required} from "@vuelidate/validators";

export default {
    email: {
        required: helpers.withMessage('필수 항목입니다.', required),
        email: emailValidator()
        // minLength: minLengthWithMessage('사용자명은 4자 이상이어야 합니다.', 4)
    },
    password: {
        required: requiredWithMessage('비밀번호는 필수 항목입니다.')
    }
}