<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator, dateStringValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, nullBrnValidator, phoneValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InlinePagingTableComponent from "@/components/Inlinepagingtable/InlinePagingTableComponent.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import Toggle from "@/components/forms/Toggle.vue";
import dayjs from "dayjs";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  affiliate: {type: [null, Object], required: true}
})

const data = reactive({
  date: dayjs().format('YYYY-MM-DD'),
  vehicle_type: null,
  vehicle_size: null,
  waste_class: null,
  waste_type: null,
  free: false,
  min_price: null,
  max_price: null,
  per_unit: null,
  etc: null
})

const clear = () => {
  data.date = dayjs().format('YYYY-MM-DD')
  data.vehicle_type = null
  data.vehicle_size = null
  data.waste_class = null
  data.waste_type = null
  data.free = false
  data.min_price = null
  data.max_price = null
  data.per_unit = null
  data.etc = null
}

const isRange = ref(false)

const setFree = (isFree) => {
  if (isFree) {
    isRange.value = false
    data.min_price = null
    data.max_price = null
    data.per_unit = null
  } else {

  }
  data.free = isFree
}
const fetchFunction = async (query, data) => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/affiliate/${props.affiliate.id}/price/`,
    params: query,
    data: data
  })
}


const $externalResults = ref({})

const model = {
  vehicle_type: {
    required: () => {
      if (data.vehicle_size !== null) {
        return requiredWithMessage('필수 항목입니다.')
      } else {
        return externalValidator()
      }
    }
  },
  vehicle_size: {externalValidator},
  waste_class: {required: requiredWithMessage('필수 항목입니다.')},
  waste_type: {required: requiredWithMessage('필수 항목입니다.')},
  date: {
    required: requiredWithMessage('필수 항목입니다.'),
    format: dateStringValidator
  },
  free: {externalValidator},
  min_price: {
    required: () => {
      if (data.free === false) {
        return requiredWithMessage('필수 항목입니다.')
      } else {
        return externalValidator()
      }
    }
  },
  max_price: {
    required: () => {
      if (isRange.value === true && data.free === false) {
        return requiredWithMessage('필수 항목입니다.')
      } else {
        return externalValidator()
      }
    }
  },
  per_unit: {
    required: () => {
      if (data.free === false) {
        return requiredWithMessage('필수 항목입니다.')
      } else {
        return externalValidator()
      }
    }
  },
  etc: {externalValidator}
}

const validator = useVuelidate(model, data, {$externalResults})


const getPrepare = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare.vehicle_size = prepare?.vehicle_size ? prepare.vehicle_size.value : null
  prepare.vehicle_type = prepare?.vehicle_type ? prepare.vehicle_type.value : null
  // prepare.min_price = prepare.min_price
  // prepare.max_price = prepare.max_price
  prepare.per_unit = prepare.per_unit ? prepare.per_unit.value : null
  prepare.waste_class = prepare.waste_class ? prepare.waste_class.value : null
  prepare.waste_type = prepare.waste_type ? prepare.waste_type.value : null
  return prepare
}

// 테이블 갱신을 위한 키
const tableKey = ref(1)

const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    const priceResponse = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/affiliate/${props.affiliate.id}/price/`,
      data: getPrepare(data)
    })
    tableKey.value += 1
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const setPerUnit = (selected) => {
  data.per_unit = selected ? selected.value : null
}

const tableFields = {
  date: {label: '기준일자', width: 100, type: 'text'},
  vehicle_type_display: {label: '차량타입', width: 60, type: 'text'},
  vehicle_size_display: {label: '차량중량', width: 60, type: 'text'},
  waste_class_display: {label: '성상', width: 50, type: 'text'},
  waste_type_display: {label: '세부 성상', width: 100, type: 'text'},
  price: {label: '가격', width: 200, type: 'text'},
}

const priceFilter = (row) => {
  row.price = ''
  let unitString = `원/${row.per_unit_display}`
  if (row.free) {
    row.price = '무상'
  } else if (row.max_price === null) {
    row.price = row.min_price ? `${row.min_price.toLocaleString()}${unitString}` : null
  } else {
    row.price = `${row.min_price ? row.min_price.toLocaleString() : null} ~ ${row.max_price ? row.max_price.toLocaleString() : null}${unitString}`
  }
  return row
}
</script>

<template>
  <form class="mb-4" method="post" @submit.prevent="save">
    <div class="grid grid-cols-1 md:grid-cols-3 w-full items-center gap-x-2 mb-4">
      <ChoiceSelectorBase v-model="data.vehicle_type" :errors="validator.vehicle_type.$errors.map(e => e.$message)" choice-type="VehicleTypeChoice"
                          label="차량 타입"/>
      <ChoiceSelectorBase v-model="data.vehicle_size" :errors="validator.vehicle_size.$errors.map(e => e.$message)" choice-type="VehicleSizeChoice"
                          label="차량 중량"/>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 w-full items-center gap-x-2 mb-4">
      <WasteClassSelector v-model="data.waste_class"
                          :errors="validator.waste_class.$errors.map(e => e.$message)" :required="true"/>
      <WasteTypeSelector v-model="data.waste_type" :errors="validator.waste_type.$errors.map(e => e.$message)"
                         :required="true" :waste-class="data.waste_class"/>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 w-full items-center gap-x-2 mb-4">
      <DatePickerInput v-model="data.date" :errors="validator.date.$errors.map(e => e.$message)"
                       label="기준일자"/>
      <div class="flex items-center justify-start gap-x-4">
        <div class="flex items-center justify-start gap-x-2">
          <div class="default-label">무상</div>
          <Toggle :enabled="data.free" :errors="validator.free.$errors.map(e => e.$message)" name="무상"
                  @update:value="setFree"/>
        </div>
        <div v-if="!data.free" class="flex items-center justify-start gap-x-2">
          <div class="default-label">범위</div>
          <Toggle :enabled="isRange" name="범위" @update:value="value => isRange = value"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 w-full items-center gap-x-2 mb-4">
      <ChoiceSelectorBase v-if="!data.free" v-model="data.per_unit"
                          :errors="validator.per_unit.$errors.map(e => e.$message)"
                          choice-type="PerUnitChoice" label="단위"/>
      <TextInput v-if="!data.free" v-model="data.min_price" :errors="validator.min_price.$errors.map(e => e.$message)"
                 :label="isRange ? '최소 단가':'단가'" type="number"/>
      <TextInput v-if="!data.free && isRange" v-model="data.max_price" :errors="validator.max_price.$errors.map(e => e.$message)"
                 :label="isRange ? '최대 단가':'단가'" type="number"/>
    </div>
    <div class="mb-4">
      <TextInput v-model="data.etc" :errors="validator.etc.$errors.map(e => e.$message)" label="비고"/>
    </div>
    <div class="flex w-full items-start justify-start gap-x-2">
      <button class="btn btn-primary" type="submit">저장</button>
      <button class="btn btn-secondary" type="button" @click="clear">초기화</button>
    </div>
  </form>

  <InlinePagingTableComponent :key="tableKey" :fetch="fetchFunction" :fields="tableFields" :filter="priceFilter"/>
</template>

<style scoped>

</style>