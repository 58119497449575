<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  customer: {type: [null, Object], required: true}
})

const data = reactive({
  name: props.customer.name,
  cellphone: props.customer.cellphone,
  role: props.customer.role,
  rank: props.customer.rank,
  email: props.customer.email,
  phone: props.customer.phone,
  memo: props.customer.memo,
  manager: props?.customer?.manager || null,
  channel: props.customer.channel
})


onMounted(async () => {
  employment.value = await fetchEmployment()
})

const $externalResults = ref({})

const model = {
  name: {
    required: helpers.withMessage('고객명을 입력해주세요.', required),
    minLength: minLengthWithMessage('고객명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('고객명을 30글자 이하로 입력해주세요.', 30)
  },
  role: {externalValidator},
  rank: {externalValidator},
  email: {
    format: emailValidator()
  },
  cellphone: {
    format: cellphoneValidator(),
  },
  phone: {externalValidator},
  memo: {externalValidator},
  manager: {required: helpers.withMessage('담당자를 선택해주세요.', required)},
  channel: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults})


const setName = event => {
  data.name = event.target.value
}

const setRole = event => {
  data.role = event.target.value
}

const setRank = event => {
  data.rank = event.target.value
}
const setEmail = event => {
  data.email = event.target.value
}

const setCellphone = event => {
  data.cellphone = event.target.value
}
const setPhone = event => {
  data.phone = event.target.value
}
const setMemo = event => {
  data.memo = event.target.value
}

const prepareData = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare.manager = prepare.manager?.id || null
  if (prepare.channel) {
    prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
  }
  return prepare
}

const save = async () => {
  await validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    const customer = await useAuthAxios({
      method: 'POST',
      url: `/app/v1/customer/${props.customer.id}/`,
      data: prepareData(data)
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

// 소속 업체
const fetchEmployment = async () => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/customer/${props.customer.id}/employment/`
  })
}

const employment = ref([])
const employmentFilter = employee => {
  return {
    name: employee.company.name,
    company_type_display: employee.company?.company_type_display || '',
    company_type_detail: employee.company?.company_type_detail || '',
    employee_type_display: employee?.employee_type_display || '',
    employee_type_detail: employee?.employee_type_detail || '',
  }
}
const employmentFields = {
  name: {type: 'text', width: 100, label: '회사명'},
  company_type_display: {type: 'text', width: 100, label: '회사타입'},
  company_type_detail: {type: 'text', width: 100, label: '회사타입 상세'},
  employee_type_display: {type: 'text', width: 100, label: '타입'},
  employee_type_detail: {type: 'text', width: 100, label: '타입 상세'},
}

</script>

<template>


  <!--  <CustomerDefaultInfoManager :customer="props.customer" v-if="props.customer"/>-->
  <form class="w-full p-4" @submit.prevent="save">
    <div :class="{invalid: validator.name.$error}" class="input-group">
      <label class="w-16" for="id-for-name">고객명 <span class="text-red-600">*</span></label>
      <div>
        <input id="id-for-name" ref="nameInput" :value="data.name" data-1p-ignore name="name"
               type="text" @input="setName">
        <template v-for="error in validator.name.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div :class="{invalid: validator.cellphone.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-cellphone">휴대전화</label>
      <div>
        <input id="id-for-cellphone" :value="data.cellphone" data-1p-ignore name="cellphone" type="text"
               @input="setCellphone">
        <template v-for="error in validator.cellphone.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div class="input-group mt-4">
      <label class="w-16" for="id-for-employment">소속</label>
    </div>
    <InlineTableComponent :fields="employmentFields" :filter="employmentFilter" :rows="employment"/>
    <div :class="{invalid: validator.role.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-role">직책</label>
      <div>
        <input id="id-for-role" :value="data.role" data-1p-ignore name="role" type="text"
               @input="setRole">
        <template v-for="error in validator.role.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div :class="{invalid: validator.rank.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-rank">직급</label>
      <div>
        <input id="id-for-rank" :value="data.rank" data-1p-ignore name="rank" type="text" @input="setRank">
        <template v-for="error in validator.rank.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div :class="{invalid: validator.email.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-email">이메일</label>
      <div>
        <input id="id-for-email" :value="data.email" data-1p-ignore name="email" type="email" @input="setEmail">
        <template v-for="error in validator.email.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div :class="{invalid: validator.phone.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-phone">전화</label>
      <div>
        <input id="id-for-phone" :value="data.phone" data-1p-ignore name="phone" type="text" @input="setPhone">
        <template v-for="error in validator.phone.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div :class="{invalid: validator.memo.$error}" class="input-group mt-4">
      <label class="w-16" for="id-for-memo">메모</label>
      <div>
        <textarea id="id-for-memo" :value="data.memo" class="textarea" data-1p-ignore name="memo" rows="5"
                  type="text" @input="setMemo"/>
        <template v-for="error in validator.memo.$errors">
          <p class="error">{{ error.$message }}</p>
        </template>
      </div>
    </div>
    <div class="mt-4">
      <EmployeeSelector :errors="validator.manager.$errors.map(e => e.$message)"
                            v-model="data.manager" label="담당자" placeholder="담당자 선택"/>
    </div>
    <div class="mt-4">
      <ChoiceSelectorBase v-model="data.channel" choice-type="AcquireChannelChoice"
                          label="인입경로" :errors="validator.channel.$errors.map(e=>e.$message)"/>
    </div>
    <button class="btn btn-primary mt-4" type="submit">저장</button>
  </form>
</template>

<style scoped>

</style>