<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import AffiliateDefaultInfo from "@/components/drawer/partial/AffiliateDefaultInfo.vue";
import AffiliateAddressInfo from "@/components/drawer/partial/AffiliateAddressInfo.vue";
import AffiliatePriceInfo from "@/components/drawer/partial/AffiliatePriceInfo.vue";
import AffiliateSalesInfo from "@/components/drawer/partial/AffiliateSalesInfo.vue";
import AffiliateEmployeeInfo from "@/components/drawer/partial/AffiliateEmployeeInfo.vue";
import {onMounted, ref, watch} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import BillDefault from "@/components/drawer/accounting/BillDefault.vue";
import BillReconcile from "@/components/drawer/accounting/BillReconcile.vue";

const props = defineProps({
  title: {type: String, required: false, default: '청구서 상세'},
  open: {type: Boolean, required: true},
  bill: {type: [null, Object, Number], required: true},
  initialTab: {type: String, required: false, default: 'default'},
})

const emits = defineEmits(['close', 'update:bill'])

const close = () => {
  emits('close')
}
const tabItems = [
  {name: '기본정보', key: 'default'},
  {name: '수입처리', key: 'reconcile'},
  // {name: '주소', key: 'address'},
  // {name: '임직원', key: 'employee'},
  // {name: '영업', key: 'sales'},
  // {name: '가격', key: 'price'},
  {name: '메모', key: 'note'},
  // {name: '파일', key: 'file'},
  // {name: 'SMS', key: 'sms'},
  // {name: '히스토리', key: 'history'},
]

const update = (data) => {
  fetch(bill.value.id)
  emits('update:bill', data)
}

const bill = ref(null)

const fetch = async (billId) => {
    bill.value = await useAuthAxios({
        method: "GET",
        url: `/app/v1/accounting/bill/${billId}/`,
    })
  return bill.value
}

onMounted(async () => {
  if (props.bill?.id) {
    await fetch(props.bill.id)
  } else {
    bill.value = null
  }
})

watch(() => props.bill, async (newValue, oldValue) => {
  if (newValue?.id) {
    await fetch(newValue.id)
  } else {
    bill.value = null
  }
})
</script>

<template>
  <DrawerBase :open="props.open" :target="props.bill" :title="props.title" @close="close" :tab-items="tabItems" :initialTab="initialTab">
    <template #default>
      <BillDefault :bill="bill" @update="update"/>
<!--      <AffiliateDefaultInfo :affiliate="props.affiliate" @update:affiliate="update"/>-->
    </template>
    <template #reconcile>
      <BillReconcile :bill="bill" @update="update"/>
<!--      <AffiliateDefaultInfo :affiliate="props.affiliate" @update:affiliate="update"/>-->
    </template>
<!--    <template #employee>-->
<!--      <AffiliateEmployeeInfo :affiliate="props.affiliate" @create="emp => emits('create:employee', emp)"/>-->
<!--    </template>-->
<!--    <template #address>-->
<!--      <AffiliateAddressInfo :affiliate="props.affiliate"/>-->
<!--    </template>-->
<!--    <template #sales>-->
<!--      <AffiliateSalesInfo :affiliate="props.affiliate"/>-->
<!--    </template>-->
<!--    <template #price>-->
<!--      <AffiliatePriceInfo :affiliate="props.affiliate"/>-->
<!--    </template>-->
  </DrawerBase>
</template>