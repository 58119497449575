<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, onMounted, ref, watch} from "vue";
import TextInput from "@/components/forms/TextInput.vue";
import AddressSelectModal from "@/components/modals/AddressSelectModal.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import MapModal from "@/components/modals/MapModal.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  label: {type: [String], required: false, default: '주소'},
  placeholder: {type: [String, null], default: '주소 검색'},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: false},
})

const emits = defineEmits(['focus', 'blur', 'clear', 'change'])

const model = defineModel()

const focused = ref(false)

const addressSelectModalShow = ref(false)

const fetch = async (poiId) => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/common/poi/${poiId}/`,
  }, false)
}

onMounted(async () => {
  if (Number.isInteger(Number(model.value)) && Number(model.value) !== 0) {
    model.value = await fetch(model.value)
  }
})

watch(model, async (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  if (!newValue) {
    model.value = null
  } else if (Number.isInteger(Number(newValue)) && Number(newValue) !== 0) {
    model.value = await fetch(newValue)
  } else {
    model.value = newValue
  }
})

const showAddressSelectModal = () => {
  if (readonly === true) {
    return null
  }
  addressSelectModalShow.value = true
}

const hideAddressSelectModal = () => {
  addressSelectModalShow.value = false
}

const setAddressPoi = (poi) => {
  model.value = poi || null
  hideAddressSelectModal()
}

const focus = event => {
  focused.value = true
  emits('focus', event)
}
const blur = event => {
  focused.value = false
  emits('blur', event)
}


const showMap = ref(false)

const openMap = () => {
  showMap.value = true
}
const closeMap = () => {
  showMap.value = false
}

const clearAddress = () => {
  model.value = null
  emits('clear')
}

const readonly = computed(() => {
  return props.readonly || false
})
</script>

<template>
  <div class="flex items-end gap-x-2">
    <TextInput v-model="model" :autoSelect="props.autoSelect" :class="props.class"
               :displayFunction="(poi) => poi?.full_address || null" :errors="props.errors"
               :inputClass="props.inputClass"
               :label="props.label" :labelClass="props.labelClass" :messages="props.messages"
               :name="props.name" :placeholder="props.placeholder"
               :readonly="true" :required="props.required"
               class="cursor-pointer flex-grow"
               label="주소" placeholder="주소"
               @blur="blur"
               @click="showAddressSelectModal"
               @enter="showAddressSelectModal" @focus="focus"/>
    <div v-if="model?.full_address" class="flex gap-x-2">
      <button class="btn btn-secondary"  type="button" @click="openMap">
        <MapIcon class="size-4"/>
      </button>
      <button class="btn btn-error" type="button" @click="clearAddress" :disabled="readonly">
        <XMarkIcon class="size-4"/>
      </button>
    </div>
  </div>
  <AddressSelectModal :isOpen="addressSelectModalShow" @close="hideAddressSelectModal"
                      @select="setAddressPoi"/>

  <template v-if="model">
    <MapModal :address="model?.full_address" :lat="model?.front_lat"
              :lng="model?.front_lon" :show="showMap" @close="closeMap"/>
  </template>
</template>

<style scoped>
</style>