<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ExclamationTriangleIcon, CheckIcon, InformationCircleIcon} from '@heroicons/vue/24/outline'
import {useLayoutStore} from "@/stores/layoutStore.js";
import {Severities} from "@/constant.js";

const layoutStore = useLayoutStore()


const accept = async () => {
  await layoutStore.confirmAccept()
}
const reject = async () => {
  await layoutStore.confirmReject()
}
</script>

<template>
  <Teleport to="body">
    <TransitionRoot :show="layoutStore.confirm !== null" as="template">
      <Dialog class="relative z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
            <DialogPanel
                class="relative  w-full transform overflow-hidden bg-white text-left shadow-xl transition-all md:my-8 md:w-full md:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 md:p-6 md:pb-4">
                <div class="md:flex md:items-start">
                  <div v-if="layoutStore.confirm?.severity === Severities.ERROR"
                       class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" class="h-6 w-6 text-red-600"/>
                  </div>
                  <div v-if="layoutStore.confirm?.severity === Severities.PRIMARY"
                       class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 md:mx-0 md:h-10 md:w-10">
                    <CheckIcon aria-hidden="true" class="h-6 w-6 text-green-600"/>
                  </div>
                  <div v-else-if="layoutStore.confirm?.severity === Severities.SECONDARY"
                       class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 md:mx-0 md:h-10 md:w-10">
                    <InformationCircleIcon aria-hidden="true" class="h-6 w-6 text-primary-600"/>
                  </div>
                  <div class="mt-3 text-center md:ml-4 md:mt-0 md:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                      {{ layoutStore.confirm?.summary }}
                    </DialogTitle>
                    <div class="mt-2">
                      <template v-if="Array.isArray(layoutStore.confirm?.detail)">
                        <p v-for="detail in layoutStore.confirm?.detail" class="text-sm text-gray-500">{{ detail }}</p>
                      </template>
                      <template v-else>
                        <p class="text-sm text-gray-500">{{ layoutStore.confirm?.detail }}</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 flex gap-x-2 items-center justify-end w-full">
                <template v-if="layoutStore.confirm?.severity === Severities.ERROR">
                  <button class="btn btn-error" type="button" @click="accept">{{ layoutStore.confirm?.acceptString }}</button>
                </template>
                <template v-else-if="layoutStore.confirm?.severity === Severities.PRIMARY">
                  <button class="btn btn-primary" type="button" @click="accept">{{ layoutStore.confirm?.acceptString }}</button>
                </template>
                <template v-else-if="layoutStore.confirm?.severity === Severities.SECONDARY">
                  <button class="btn btn-secondary" type="button" @click="accept">{{ layoutStore.confirm?.acceptString }}</button>
                </template>
                <button class="btn btn-secondary" type="button" @click="reject">{{ layoutStore.confirm?.rejectString }}</button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>

<style scoped>
</style>