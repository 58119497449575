<script setup>
import {Severities} from "@/constant.js";
import {CheckIcon, ExclamationTriangleIcon,} from "@heroicons/vue/24/outline";
import {computed} from "vue";

const props = defineProps({
  "description": {type: [Array, Object], required: true},
})

const icon = computed(() => {
  switch (props.severity) {
    case Severities.PRIMARY:
      return CheckIcon
    case Severities.SECONDARY:
      return ExclamationTriangleIcon
    case Severities.ERROR:
      return ExclamationTriangleIcon
    case Severities.DISABLED:
      return ExclamationTriangleIcon
    default:
      return CheckIcon
  }
})

const getIcon = (severity) => {
  switch (severity) {
    case Severities.PRIMARY:
      return CheckIcon
    case Severities.SECONDARY:
      return ExclamationTriangleIcon
    case Severities.ERROR:
      return ExclamationTriangleIcon
    case Severities.DISABLED:
      return ExclamationTriangleIcon
    default:
      return CheckIcon
  }
}

const getIconColor = (severity) => {
  switch (severity) {
    case Severities.PRIMARY:
      return 'text-emerald-600'
    case Severities.SECONDARY:
      return 'text-amber-600'
    case Severities.ERROR:
      return 'text-red-500'
    case Severities.DISABLED:
      return 'text-emerald-600'
    default:
      return 'text-emerald-600'
  }
}


// PRIMARY: 'primary',
// SECONDARY: 'secondary',
// ERROR: 'error',
// DISABLED: 'light',
</script>

<template>
  <div class="mt-2 rounded bg-slate-100 p-4">
    <template v-if="Array.isArray(props.description)">
      <template v-for="(desc, index) in props.description">
        <div :class="[index === 0 ? '':'mt-2']" class="flex items-center">
          <component :is="getIcon(desc.severity || Severities.PRIMARY)"
                     class="mr-2 size-5 stroke-2" :class="getIconColor(desc.severity)"></component>
          <span class="text-sm text-slate-900">{{ desc.text }}</span>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="flex items-center">
        <component :is="getIcon(props.description.severity || Severities.PRIMARY)" class="mr-2 size-5 stroke-2" :class="getIconColor(props.description.severity)"></component>
        <span class="text-sm text-slate-900">{{ props.description.text }}</span>
      </div>
    </template>
  </div>
</template>

<style scoped>

</style>