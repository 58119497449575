<script setup>
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {onMounted, reactive, ref} from "vue";
import {externalValidator, maxLengthWithMessage, minLengthWithMessage} from "@/validators/fields.js";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Toggle from "@/components/forms/Toggle.vue";
import TextArea from "@/components/forms/TextArea.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  callLog: {type: [Object, null], required: false, default: null}
})

const emits = defineEmits(['update'])

onMounted(() => {
  data.process_memo = props.callLog.process_memo
  data.processed = props.callLog.processed_at !== null
})

const data = reactive({
  process_memo: null,
  processed: false
})

const model = {
  process_memo: {
    required: helpers.withMessage('처리내용을 입력해주세요.', required),
    minLength: minLengthWithMessage('처리내용을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('처리내용을 100글자 이하로 입력해주세요.', 100)
  },
  processed: {
    required: {externalValidator}
  }
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})


const save = () => {
  useAuthAxios({
    method: 'POST',
    url: `/app/v1/call_log/${props.callLog.id}/`,
    data: data
  })
  layoutStore.addSuccessNotification('처리되었습니다.')
  emits('update')
}
</script>

<template>
  <div class="mb-2 font-semibold text-sm">처리</div>
  <div class="mb-4">
    <TextArea :errors="validator.process_memo.$errors.map(err => err.$message)" label="처리내용"
              v-model="data.process_memo" :required="true"/>
  </div>
  <div class="flex items-center justify-between">
    <div>
      <Toggle :enabled="data.processed"
              :errors="validator.processed.$errors.map(err => err.$message)" name="processed"
              @update:value="value => data.processed = value">
        <template #label>처리완료</template>
      </Toggle>
    </div>
    <div>
      <button class="btn btn-primary" type="button" @click="save">저장</button>
    </div>
  </div>
  <!--  <div class="mb-4 flex items-center justify-start gap-x-3">-->
  <!--    <div class="font-semibold text-sm">담당자 <span class="font-normal">: {{ props.callLog.manager_name }}</span></div>-->
  <!--    <button class="btn btn-primary btn-sm" @click="setMine">나에게 배정</button>-->
  <!--  </div>-->
</template>

<style scoped>

</style>