<script setup>
import {computed, ref, toRef, watch} from 'vue'
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'


const props = defineProps({
  class: {
    type: [String, Object, Array],
    required: false,
    default: ''
  },
  enabled: {
    type: Boolean,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  errors: {
    type: Array,
    required: false,
    default: []
  },
})


const elemId = computed(() => {
  let uid = Math.random().toString(36).substring(2)
  return `id-for-${props.name}-${uid}`
})


const emits = defineEmits(['update:value'])

const enabled = computed({
  get() {
    return props.enabled
  },
  async set(value) {
    emits('update:value', value)
    return value
  }
})


</script>

<template>
  <div :class="props.class">
    <SwitchGroup as="div" class="switch-group">
      <Switch v-model="enabled" :class="[enabled ? 'enabled' : '', 'switch']"
              :disabled="props.disabled">
        <span :class="[enabled ? 'enabled' : '', 'switch-transition']"
              aria-hidden="true"/>
      </Switch>
      <SwitchLabel as="span" class="ml-3 text-sm">
        <slot name="label"/>
      </SwitchLabel>
    </SwitchGroup>
    <p v-for="(error, index) in props.errors" :id="`${props.name}-error-${index}`" class="mt-2 text-sm text-red-600">
      {{ error }}
    </p>
    <input :id="elemId" :checked="enabled" :name="props.name" class="hidden" type="checkbox"/>
  </div>
</template>