<script setup>
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import {computed, ref} from "vue";
import {useElementSize} from "@vueuse/core";
import dayjs from "dayjs";
import WeeklyAffiliate from "@/views/dashboard/partials/WeeklyAffiliate.vue";

const selectedView = ref(null)
const selectorElem = ref(null)

const {width, height: selectorHeight} = useElementSize(selectorElem, 0, {immediate: false})

const baseDate = ref(dayjs().format('YYYY-MM-DD'))

</script>

<template>
  <DefaultLayoutBase title="대시보드">
    <template #body>
      <div class="flex w-full grow flex-col items-center  overflow-auto bg-slate-100">
        <div class="flex flex-col w-full grow max-2 md:w-full space-y-4 py-4">

          <div class="mx-4 border bg-white p-4">
            <WeeklyAffiliate :baseDate="baseDate"/>
          </div>

        </div>
      </div>
    </template>
  </DefaultLayoutBase>
</template>
