<script setup>

import dayjs from "dayjs";
import {ArrowPathIcon, FolderArrowDownIcon} from '@heroicons/vue/20/solid'


import {formatBrn} from "@/utils/formatter.js";
import {reactive, ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import CustomerRegisterModal from "@/components/modals/CustomerRegisterModal.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import CustomerDrawer from "@/components/drawer/CustomerDrawer.vue";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const route = useRoute()

// group = models.ForeignKey(Group, null=False, blank=False, verbose_name='등록 그룹', on_delete=models.PROTECT)
// name = models.CharField('고객명', max_length=256, null=False, blank=False)
//     organization = models.CharField('소속', max_length=256, null=True, blank=True)
//     role = models.CharField('직책', max_length=256, null=True, blank=True)
//     rank = models.CharField('직급', max_length=256, null=True, blank=True)
// email = models.EmailField('이메일', max_length=256, null=True, blank=True)
// cellphone = models.CharField('휴대전화', max_length=11, null=True, blank=True)
// phone = models.CharField('전화', max_length=12, null=True, blank=True)
// memo = models.TextField('메모', null=True, blank=True)
// manager = models.ForeignKey(
//     User, null=True, blank=True, on_delete=models.PROTECT, verbose_name='담당자', related_name='customer_manager'
// )
// registerer = models.ForeignKey(
//     User, null=True, blank=True, on_delete=models.PROTECT, verbose_name='등록자', related_name='customer_registerer'
// )
// 컬럼 목록
const fields = {
  name: {label: '성명', sortable: false, width: 100, type: 'text'},
  organization: {label: '소속', sortable: false, width: 100, type: 'company'},
  rank: {label: '직급', sortable: false, width: 50, type: 'text'},
  role: {label: '직책', sortable: false, width: 50, type: 'text'},
  cellphone: {label: '휴대전화번호', sortable: false, width: 130, type: 'cellphone'},
  email: {label: '이메일', sortable: false, width: 100, type: 'text'},
  phone: {label: '전화번호', sortable: false, width: 100, type: 'text'},
  // memo: {label: '담당자', sortable: false, width: 200, type: 'text'},
  manager: {label: '담당', sortable: false, width: 100, type: 'user'},
  channel_display: {label: '인입경로', sortable: false, width: 100, type: 'text'},
  registerer: {label: '등록', sortable: false, width: 100, type: 'user'},
  created_at: {label: '등록일시', sortable: false, width: 150, type: 'datetime'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]
// filter
const queryDict = reactive({
  query: route.query.query || null,
  organization: route.query.organization || null
})

watch(() => route.query, (newValue) => {
  queryDict.query = newValue.query || null
  queryDict.organization = newValue.organization || null
})

const queryList = () => {
  let query = {}
  query['query'] = queryDict?.query || null
  query['organization'] = queryDict.organization?.id || null
  router.push({query: query})
}

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/customer/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  // data['organization'] = data.organization ? data.organization.name : ''
  // data['manager'] = data.manager ? data.manager.name : ''
  // data['registerer'] = data.registerer ? data.registerer.name : ''
  return data
}

// drawer
const isCustomerOpen = ref(false)
const selectedCustomer = ref(null)

const showCustomer = (customer) => {
  selectedCustomer.value = customer
  isCustomerOpen.value = true
}

const hideCustomer = () => {
  selectedCustomer.value = null
  isCustomerOpen.value = false
}

const tableKey = ref(1)

const update = () => tableKey.value++
</script>

<template>
  <CustomerDrawer v-if="selectedCustomer" :customer="selectedCustomer" :open="isCustomerOpen" title="고객 상세"
                  @close="hideCustomer"/>
  <CustomerRegisterModal :isOpen="showAdd" title="고객 등록" @close="() => showAdd = false" @showCustomer="showCustomer"/>
  <DefaultLayoutBase title="영업 / 고객">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields" :openAction="showCustomer"
                      :openAdd="openAdd" :query-filter="queryList" @update="update" :key="tableKey"
                      :rowActions="rowActions" :rowsAction="rowsAction" :tableActions="tableActions"
                      entityName="고객" entityUnit="명">
        <template #filter>
          <TextInput v-model="queryDict.query" label="검색" placeholder="검색"/>
          <AffiliateSelector v-model="queryDict.organization" label="소속" placeholder="소속"/>
        </template>
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>