<script setup>
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {helpers, required} from "@vuelidate/validators";
import {externalValidator} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import AffiliateEmployeeSelector from "@/components/selector/apiSelector/AffiliateEmployeeSelector.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import AddressInput from "@/components/forms/AddressInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import Toggle from "@/components/forms/Toggle.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  work: {type: [null, Object], required: true, default: null},
  canUpdateArrival: {type: Boolean, required: false, default: false},
})
const emits = defineEmits(['update', 'create'])

const data = reactive({
  company: props.work?.arrival?.id || null,
  description: props.work?.arrival?.description || null,
})

const assignData = (original) => {
  data.company = original?.arrival?.id || null
  data.description = original?.arrival?.description || null
}

onMounted(() => {
  assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  assignData(newValue)
})


const model = {
  company: {externalValidator},
  description: {externalValidator},
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const saveArrival = async (notify) => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return null
  let prepare = {
    arrival: data.company?.id || null,
    description: data.description || null,
  }
  let options
  if (prepare.arrival) {
    options = {
      method: 'POST',
      url: `/app/v1/work/small/${props.work.id}/arrival/`,
      data: prepare,
    }
  } else {
    options = {
      method: 'DELETE',
      url: `/app/v1/work/small/${props.work.id}/arrival/`,
    }
  }
  const response = await useAuthAxios(options, false, $externalResults)
  validator.value.$reset()
  emits('update')
  layoutStore.addSuccessNotification('저장되었습니다.')
}


const readonly = computed(() => {
  return !props.canUpdateArrival
})

watch(() => data.company, (newValue, oldValue) => {
  if (newValue === oldValue) return null
  if (data.company === null) {
    data.description = null
  }
}, {deep: true})
</script>

<template>
  <!--현장 정보-->
  <form class="pt-4 mt-4" @submit.prevent="saveArrival">
    <div class="flex items-center justify-between gap-x-3 mb-3">
      <div class="text-sm font-semibold">하차지</div>
    </div>
    <div class="mb-4 grid grid-cols-6 md:grid-cols-10 gap-x-4 gap-y-4">
      <AffiliateSelector v-model="data.company" :errors="validator.company.$errors.map(e => e.$message)"
                         :readonly="readonly" class="col-span-2 md:col-span-3" label="거래처"/>
      <TextInput v-model="data.description" :errors="validator.description.$errors.map(e => e.$message)"
                 :readonly="data.company === null || readonly"
                 class="col-span-4 md:col-span-6" label="하차지 설명"/>
      <button :disabled="readonly" class="btn btn-primary col-span-2 md:col-span-1" type="submit">저장</button>
    </div>
  </form>
</template>

<style scoped>

</style>