<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import {onMounted, ref} from "vue";
import {API_URL} from "@/service/api.js";
import useAuthAxios from "@/composables/useAuthAxios.js";
import SalesDefaultInfo from "@/components/drawer/partial/SalesDefaultInfo.vue";
import CustomerDefaultInfo from "@/components/drawer/partial/CustomerDefaultInfo.vue";

const props = defineProps({
  title: {type: String, required: false, default: '영업 상세'},
  open: {type: Boolean, required: true},
  sales: {type: [null, Object], required: true}
})

const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}

const sales = ref(null)

const fetch = async () => {
  let options = {
    method: 'GET',
    url: `/app/v1/sales/${props.sales.id}/`,
  }
  sales.value = await useAuthAxios(options, false)
}

onMounted(async () => {
  await fetch()
})

</script>

<template>
  <DrawerBase v-if="sales" :open="props.open" :target="sales" :title="props.title" @close="close">
    <template #default>
      <SalesDefaultInfo :sales="sales"/>
    </template>
  </DrawerBase>
</template>