<script setup>

import dayjs from "dayjs";
import {ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import { useClipboard } from '@vueuse/core'
import DeviceDrawer from "@/components/drawer/DeviceCreateDrawer.vue";

const router = useRouter()
const route = useRoute()

            // "id": 1,
            // "created_at": "2024-10-31T11:14:16.367265+09:00",
            // "updated_at": "2024-10-31T11:14:16.367500+09:00",
            // "name": "test",
            // "phone_no": "01024846313",
            // "key": "b03ace02d7e0c1537a313f584ca064119fc11270",
            // "user": null
const copyKey = (key) => {
  const { text, copy, copied, isSupported } = useClipboard({ source: key })
  copy(key)
  if (copied) alert('복사되었습니다.')
}

const fields = {
  name: {label: '기기명', sortable: false, width: 150, type: 'text'},
  user: {label: '사용자', sortable: false, width: 150, type: 'user'},
  phone_no: {label: '전화번호', sortable: false, width: 150, type: 'text'},
  key: {label: '토큰', btnLabel: '복사', width: 150, type: 'button', action: (row) => {copyKey(row.key)}},
  created_at: {label: '생성일', sortable: false, width: 150, type: 'datetime'},

  // end_date: {label: '종료', sortable: false, width: 150, type: 'date'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const tableKey = ref(1)

const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/call_log/device/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  data['phone_no'] = formatPhoneNumber(data.phone_no)
  return data
}

const queryList = () => {
  let query = {...route.query}
  router.push({query: query})
}


// drawer
const isDeviceCreateOpen = ref(false)

const showDeviceCreate = () => {
  isDeviceCreateOpen.value = true
}

const hideDeviceCreate = () => {
  isDeviceCreateOpen.value = false
  tableKey.value += 1
}

const update = () => {
  tableKey.value += 1
}
</script>

<template>
  <DeviceDrawer :open="isDeviceCreateOpen" title="기기 상세" @close="hideDeviceCreate"/>
  <DefaultLayoutBase title="휴대전화 / 기기">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields"
                      :rowActions="rowActions" :query-filter="queryList" @update="update"
                      :rowsAction="rowsAction" :tableActions="tableActions"
                      :openAdd="showDeviceCreate" :key="tableKey"
                      entityName="기기" entityUnit="개">
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>