<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import TextInput from "@/components/forms/TextInput.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import TextArea from "@/components/forms/TextArea.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import dayjs from "dayjs";

const props = defineProps({
    work: {type: [Object, null], required: true},
    entries: {type: Array, required: true},
})

const campaignSetting = ref(null)
const work = ref(null)
const entries = ref([])
const supplier = reactive({
    name: null,
    brn: null,
    rep: null,
    address: null,
    biz_category: null,
    biz_type: null,
})


const issueData = reactive({
    template: 'small1_estimate',
    version: 1,
    origin: 'core.Work',
    origin_id: props.work.id,
    receiver_name: null,
    receiver_cellphone: null,
    sender_cellphone: null,
    date: dayjs().format('YYYY-MM-DD'),
    merge: false,
    memo: null,
    bank: {
        id: null,
        bank: null,
        account_holder: null,
        account_no: null,
        description: null,
        bank_display: null
    }
})

const estimateData = computed(() => {
    return {
            ...issueData,
            work: {
                serial: props.work.serial,
                at_datetime: [props.work.departure.at_date, props.work.departure.at_time].filter(i => !!i).join(' ') || null,
                address: [props.work.departure.address, props.work.departure.address_detail].filter(i => !!i).join(' ') || null,
            },
            buyer: {
                name: work.value.departure.company_name,
                brn: work.value.departure.company_brn,
                manager_name: work.value.departure.manager_name,
                manager_cellphone: work.value.departure.manager_cellphone,
            },
            supplier: {
                name: supplier.name,
                brn: supplier.brn,
                rep_name: supplier.rep,
                address: supplier.address,
                biz_category: supplier.biz_category,
                biz_type: supplier.biz_type,
                work_manager: work.value.work_manager,
                work_manager_cellphone: work.value.work_manager_cellphone,
                accounting_manager_name: campaignSetting.value?.accounting_manager_name,
                accounting_manager_cellphone: campaignSetting.value?.accounting_manager_cellphone,
            },
            entries: selectedEntries.value,
            summary: summary.value,
        }
})

const save = async () => {
    let data = {
        ...issueData,
        campaignSetting: {...campaignSetting.value},
        work: work.value,
        supplier: supplier.value,
        entries: entries.value.filter(entry => entry.selected),
    }
    console.log(data)
}
watch(() => props.work, async (newValue, oldValue) => {
    if (newValue === oldValue) return
    work.value = newValue
    issueData.receiver_name = work.value.departure.manager_name
    issueData.receiver_cellphone = work.value.departure.manager_cellphone
    issueData.sender_cellphone = work.value.work_manager_cellphone
    await fetchCampaignSetting(work.value.campaign)
}, {deep: true})

watch(() => props.entries, async (newValue, oldValue) => {
    if (newValue === oldValue) return
    entries.value = newValue.map(entry => {
        entry.selected = false
        return entry
    })
}, {deep: true})

onMounted(async () => {
    work.value = props.work
    issueData.receiver_name = work.value.departure.manager_name
    issueData.receiver_cellphone = work.value.departure.manager_cellphone
    issueData.sender_cellphone = work.value.work_manager_cellphone
    entries.value = props.entries.map(entry => {
        entry.selected = false
        return entry
    })
    await fetchCampaignSetting(work.value.campaign)
})

const fetchCampaignSetting = async (campaignId) => {
    const response = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/campaign/${campaignId}/setting/`
    })
    campaignSetting.value = response.reduce((acc, cur) => {
        acc[cur.key] = cur.value
        return acc
    }, {})
    const defaultSupplierId = campaignSetting.value['default_supplier_id']
    if (defaultSupplierId) {
        const supplierResponse = await useAuthAxios({
            method: 'GET',
            url: `/app/v1/affiliate/${defaultSupplierId}/`
        })
        supplier.name = supplierResponse.name
        supplier.brn = supplierResponse.brn
        supplier.rep = supplierResponse.rep_name
        const address = supplierResponse.address
        if (address) {
            supplier.address = `${address.main}`
            if (address.detail) {
                supplier.address += ` (${address.detail})`
            }
        }
        supplier.biz_category = supplierResponse.biz_category
        supplier.biz_type = supplierResponse.biz_type
    }
    const defaultBankId = campaignSetting.value['default_bank_account_id']
    if (defaultBankId) {
        const supplierResponse = await useAuthAxios({
            method: 'GET',
            url: `/app/v1/common/bank/${defaultBankId}/`
        })
        issueData['bank'] = supplierResponse
    }
    console.log(defaultBankId)
}

const summary = computed(() => {
    let data = {
        amount: entries.value.reduce((acc, cur) => {
            if (cur.selected !== true) return acc
            acc = acc + Number(cur.amount)
            return acc
        }, 0),
        tax: entries.value.reduce((acc, cur) => {
            if (cur.selected !== true) return acc
            if (cur.tax === null) return acc
            acc = acc + Number(cur.tax)
            return acc
        }, 0),
    }
    data['total'] = data.amount + data.tax
    return data
})

const selectedEntries = computed(() => {
    if (issueData.merge) {
        return [{
            code_name: campaignSetting.value['estimate_merge_item_name'],
            spec: null,
            unit: null,
            count: null,
            amount: summary.value.amount,
            tax: summary.value.tax,
            total: summary.value.total,
            memo: null,
            id: null,
        }]
    } else {
        return entries.value.filter(entry => entry.selected === true)
    }
})
</script>

<template>
    <template v-if="!!work?.work_manager">
        <div>
            <div class="w-full flex items-center justify-start gap-x-2 mb-4">
                <TextInput v-model="issueData.sender_cellphone" label="발신자 번호"/>
                <TextInput v-model="issueData.receiver_name" label="수신자"/>
                <TextInput v-model="issueData.receiver_cellphone" label="수신자 번호"/>
                <button class="btn btn-primary" type="button" @click="save">발급</button>
            </div>
            <div v-if="work && campaignSetting" class="border border-1 border-gray-200 p-4 text-sm">
                <h1 class="mb-4 font-bold text-center text-2xl">견적서</h1>
                <div class="grid grid-cols-12 gap-x-2 gap-y-4 w-full mb-4">
                    <div class="col-span-6">
                        <table class="buyer w-full">
                            <tbody>
                            <tr>
                                <th>발급일자</th>
                                <td>
                                    <DatePickerInput v-model="issueData.date" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>발급번호</th>
                                <td>발급시 부여</td>
                            </tr>
                            <tr>
                                <th>수신</th>
                                <td>
                                    <TextInput v-model="work.departure.company_name" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>사업자등록번호</th>
                                <td>
                                    <TextInput v-model="work.departure.company_brn" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td>
                                    <div class="mb-1">
                                        <TextInput v-model="work.departure.manager_name" label=""/>
                                    </div>
                                    <div>
                                        <TextInput v-model="work.departure.manager_cellphone" label=""/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>작업번호</th>
                                <td>{{ props.work.serial }}</td>
                            </tr>
                            <tr>
                                <th>작업 일(시)</th>
                                <td>{{ props.work.departure.at_date }}
                                    <template v-if="props.work.departure.at_time">{{
                                            props.work.departure.at_time
                                        }}
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th>현장 주소</th>
                                <td>{{ props.work.departure.address }}
                                    <template v-if="props.work.departure.address_detail">{{
                                            props.work.departure.address_detail
                                        }}
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td class="center pt-4" colspan="2">
                                    <span class="font-semibold">아래와 같이 견적합니다.</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-span-6">
                        <table class="supplier w-full">
                            <tbody>
                            <tr>
                                <th class="vertical" rowspan="7">공급자</th>
                                <th>상호</th>
                                <td colspan="5">
                                    <TextInput v-model="supplier.name" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>사업자번호</th>
                                <td colspan="2">
                                    <TextInput v-model="supplier.brn" label=""/>
                                </td>
                                <th>대표자</th>
                                <td class="flex items-center justify-start gap-x-2" colspan="2">
                                    <TextInput v-model="supplier.rep" label=""/>
                                    <div>(인)</div>
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colspan="5">
                                    <TextInput v-model="supplier.address" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>업태</th>
                                <td colspan="2">
                                    <TextInput v-model="supplier.biz_category" label=""/>
                                </td>
                                <th>업종</th>
                                <td colspan="2">
                                    <TextInput v-model="supplier.biz_type" label=""/>
                                </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td colspan="2">
                                    <TextInput v-model="work.work_manager" label=""/>
                                </td>
                                <th>전화번호</th>
                                <td colspan="2">
                                    <TextInput v-model="work.work_manager_cellphone" label=""/>
                                </td>
                            </tr>
                            <!--                        <tr>-->
                            <!--                            <th>배차담당자</th>-->
                            <!--                            <td colspan="2">-->
                            <!--                                <TextInput v-model="campaignSetting.work_manager_name" label=""/>-->
                            <!--                            </td>-->
                            <!--                            <th>전화번호</th>-->
                            <!--                            <td colspan="2">-->
                            <!--                                <TextInput v-model="campaignSetting.work_manager_cellphone" label=""/>-->
                            <!--                            </td>-->
                            <!--                        </tr>-->
                            <tr>
                                <th>회계담당자</th>
                                <td colspan="2">
                                    <TextInput v-model="campaignSetting.accounting_manager_name" label=""/>
                                </td>
                                <th>전화번호</th>
                                <td colspan="2">
                                    <TextInput v-model="campaignSetting.accounting_manager_cellphone" label=""/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="mb-4">
                    <table class="summary w-full border-collapse border border-slate-300">
                        <tbody>
                        <tr class="divide-x divide-slate-300">
                            <th class="bg-gray-300/60" width="15%">총액</th>
                            <td width="18%">{{ summary.total.toLocaleString() }}</td>
                            <th width="15%">공급가액</th>
                            <td width="18%">{{ summary.amount.toLocaleString() }}</td>
                            <th width="15%">세액</th>
                            <td width="19%">{{ summary.tax.toLocaleString() }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-4">
                    <div class="mb-2 font-semibold">매출매입</div>
                    <table class="entries w-full border-collapse border border-slate-300 table-fixed mb-2">
                        <thead class="border border-b-slate-300">
                        <tr class="divide-x divide-slate-300">
                            <th width="8%">선택</th>
                            <th width="15%">항목</th>
                            <th width="10%">규격</th>
                            <th width="10%">단위</th>
                            <th width="10%">수량</th>
                            <th width="10%">공급가</th>
                            <th width="10%">세액</th>
                            <th width="10%">계</th>
                            <th>비고</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in entries" :key="entry.id" class="divide-x divide-slate-300">
                            <!--                    {{entry}}-->
                            <td class="text-center">
                                <input :checked="entry.selected" type="checkbox"
                                       @change="entry.selected = !entry.selected"/>
                            </td>
                            <td>
                                <TextInput v-model="entry.code_name" label=""/>
                            </td>
                            <td>
                                <TextInput v-model="entry.spec" label=""/>
                            </td>
                            <td>
                                <TextInput v-model="entry.unit" label=""/>
                            </td>
                            <td>
                                <TextInput v-model="entry.count" label="" type="number"/>
                            </td>
                            <td class="text-right">
                                {{ entry.amount.toLocaleString() }}
                            </td>
                            <td class="text-right">
                                <template v-if="entry.tax === null">면세</template>
                                <template v-else>
                                    {{ entry.tax.toLocaleString() }}
                                </template>
                            </td>
                            <td class="text-right">
                                {{
                                    entry.tax === null ? entry.amount.toLocaleString() : (Number(entry.amount) + Number(entry.tax)).toLocaleString()
                                }}
                            </td>
                            <td>
                                <TextInput v-model="entry.memo" label="" type="number"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-4">
                    <div class="mb-2 font-semibold">항목 미리보기</div>
                    <table class="entries w-full border-collapse border border-slate-300 table-fixed mb-2">
                        <thead class="border border-b-slate-300">
                        <tr class="divide-x divide-slate-300">
                            <th width="15%">항목</th>
                            <th width="10%">규격</th>
                            <th width="10%">단위</th>
                            <th width="10%">수량</th>
                            <th width="10%">공급가</th>
                            <th width="10%">세액</th>
                            <th width="10%">계</th>
                            <th>비고</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in selectedEntries" :key="entry.id" class="divide-x divide-slate-300">
                            <td>
                                {{ entry.code_name }}
                            </td>
                            <td>
                                {{ entry.spec }}
                            </td>
                            <td>
                                {{ entry.unit }}
                            </td>
                            <td>
                                {{ entry.count }}
                            </td>
                            <td class="text-right">
                                {{ entry.amount.toLocaleString() }}
                            </td>
                            <td class="text-right">
                                <template v-if="entry.tax === null">면세</template>
                                <template v-else>
                                    {{ entry.tax.toLocaleString() }}
                                </template>
                            </td>
                            <td class="text-right">
                                {{
                                    entry.tax === null ? entry.amount.toLocaleString() : (Number(entry.amount) + Number(entry.tax)).toLocaleString()
                                }}
                            </td>
                            <td>
                                {{ entry.memo }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="flex items-center justify-start gap-x-2">
                        <input id="merge" :checked="issueData.merge" type="checkbox"
                               @change="issueData.merge = !issueData.merge"/>
                        <label for="merge">묶음 발행</label>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="mb-2 font-semibold">입금계좌 정보</div>
                    <table class="summary w-full border-collapse border border-slate-300">
                        <tbody>
                        <tr class="divide-x divide-slate-300">
                            <th width="10%">은행</th>
                            <td width="15%">{{ issueData.bank.bank_display }}</td>
                            <th width="15%">예금주</th>
                            <td width="20%">{{ issueData.bank.account_holder }}</td>
                            <th width="15%">계좌번호</th>
                            <td width="25%">{{ issueData.bank.account_no }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-4">
                    <div class="mb-2 font-semibold">안내사항</div>
                    <TextArea v-model="issueData.memo" label="" placeholder="안내사항"/>
                </div>
            </div>
            <div>
                <pre>{{ estimateData }}</pre>
            </div>
            <div>
                <pre>{{ campaignSetting }}</pre>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="w-full flex flex-col items-center justify-center gap-x-2 m-4" text-sm>
            <div>담당자가 배당되지 않았습니다.</div>
            <div>담당자 배당 후 견적서 발급이 가능합니다.</div>
        </div>
    </template>
    <div>
        <pre>{{ work }}</pre>
    </div>
</template>

<style scoped>


table.buyer {
    border-collapse: separate; /* border-spacing이 적용되도록 설정 */
    border-spacing: 0 10px; /* 열 간격은 0, 행 간격은 10px */
}

table.buyer > tbody > tr > td {
    text-align: left;
    vertical-align: top;
}

table.buyer > tbody > tr > td.center {
    text-align: center;
    vertical-align: center;
}

table.buyer > tbody > tr > th {
    text-align: left;
    vertical-align: top;
}


table.supplier {
    border-collapse: separate; /* border-spacing이 적용되도록 설정 */
    border-spacing: 10px 10px; /* 열 간격은 0, 행 간격은 10px */
}

table.supplier > tbody > tr > td {
    text-align: left;
    vertical-align: top;
}

table.supplier > tbody > tr > td.center {
    text-align: center;
    vertical-align: center;
}

table.supplier > tbody > tr > th {
    text-align: left;
    vertical-align: top;
}

table.supplier > tbody > tr > th.vertical {
    background-color: #f5f5f5;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: center;
    vertical-align: center;
}

table.summary > tbody > tr > th {
    padding: 10px
}

table.summary > tbody > tr > td {
    padding: 10px
}

table.entries > thead > tr > th {
    text-align: left;
    padding: 10px
}

table.entries > tbody > tr > td {
    padding: 10px
}


</style>