<script setup>

import DrawerCore from "@/components/drawer/DrawerCore.vue";
import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  dateStringValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {APIError} from "@/exceptions.js";
import TextInput from "@/components/forms/TextInput.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
  open: {
    type: Boolean,
    required: false,
    default: false
  }
})
const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}

const data = reactive({
  name: null,
  phone_no: null,
  user: null,
})

const model = {
  name: {
    required: helpers.withMessage('기기명을 입력해주세요.', required),
    minLength: minLengthWithMessage('기기명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('기기명을 10글자 이하로 입력해주세요.', 10)
  },
  phone_no: {
    required: helpers.withMessage('휴대전화번호를 입력해주세요', required),
  },
  user: {externalValidator}
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})

const reset = () => {
  data.name = null
  data.phone_no = null
  data.user = null
  validator.value.$reset()
}
const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  let prepare = {
    ...data
  }
  prepare['user'] = prepare?.user?.id || null
  let options = {
    method: 'PUT',
    url: `/app/v1/call_log/device/`,
    data: prepare
  }
  try {
    const newDevice = await useAuthAxios(options, false)
    layoutStore.addSuccessNotification('기기가 성공적으로 저장되었습니다.')
    reset()
    close()
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }

}
</script>

<template>
  <DrawerCore :open="props.open" title="기기" @close="close">
    <form class="w-96 p-4" @submit.prevent="save">
      <TextInput v-model="data.name" :errors="validator.name.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="기기명"/>
      <TextInput v-model="data.phone_no" :errors="validator.phone_no.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="휴대전화번호"/>
      <EmployeeSelector :required="true" v-model="data.user" class="mb-4" label="소유자"/>
      <button class="btn btn-primary" type="submit">저장</button>
    </form>
  </DrawerCore>
</template>

<style scoped>

</style>