<script setup>
import ModalBase from "@/components/modals/ModalBase.vue";
import {Severities, Sizes} from "@/constant.js";
import {nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {
    cellphoneValidator, emailValidator,
    externalValidator,
    maxLengthWithMessage,
    minLengthWithMessage,
    nullBrnValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRouter} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import {useAuthStore} from "@/stores/authStore.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import DrawerCore from "@/components/drawer/DrawerCore.vue";
import TextInput from "@/components/forms/TextInput.vue";
import TextArea from "@/components/forms/TextArea.vue";

const authStore = useAuthStore()
const layoutStore = useLayoutStore()
const router = useRouter()

const props = defineProps({
    isOpen: {type: Boolean, required: true},
    title: {type: String, required: false, default: '고객 등록'},
})
const emits = defineEmits(['close', 'showCustomer', 'create'])

const data = reactive({
    name: '',
    organization: null,
    role: '',
    rank: '',
    email: '',
    cellphone: '',
    phone: '',
    memo: '',
    manager: authStore.user,
    channel: null
})

const $externalResults = ref({})

const model = {
    name: {
        required: helpers.withMessage('고객명을 입력해주세요.', required),
        minLength: minLengthWithMessage('고객명을 2글자 이상으로 입력해주세요.', 2),
        maxLength: maxLengthWithMessage('고객명을 30글자 이하로 입력해주세요.', 30)
    },
    organization: {externalValidator},
    role: {externalValidator},
    rank: {externalValidator},
    email: {
        format: emailValidator()
    },
    cellphone: {
        format: cellphoneValidator(),
    },
    phone: {externalValidator},
    memo: {externalValidator},
    manager: {
        required: requiredWithMessage('필수 항목입니다.')
    },
    channel: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const getPrepare = (value) => {
    let prepare = JSON.parse(JSON.stringify(value))
    prepare.organization = prepare.organization ? prepare.organization.id : null
    prepare.manager = prepare.manager?.id || null
    if (prepare.channel) {
        prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
    }
    return prepare
}

const save = async () => {
    await validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    if (!valid) return
    try {
        const customer = await useAuthAxios({
            method: 'PUT',
            url: `/app/v1/customer/`,
            data: getPrepare(data)
        })
        layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
        clean()
        emits('close')
        emits('create', customer)
        showCustomer(customer)
        // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    } catch (e) {
        if (e instanceof APIError && e?.code === 'V0001') {
            $externalResults.value = e.field_errors
        }
        throw e
    }
}

const setName = event => {
    data.name = event.target.value
}
const setRole = event => {
    data.role = event.target.value
}
const setRank = event => {
    data.rank = event.target.value
}
const setEmail = event => {
    data.email = event.target.value
}
const setPhone = event => {
    data.phone = event.target.value
}
const setMemo = event => {
    data.memo = event.target.value
}

const setCellphone = event => {
    data.cellphone = event.target.value
}

// show Customer
const showCustomer = (customer) => {
    emits('showCustomer', customer)
}

// 자동완성
const similarCustomer = ref([])

const fetchSimilar = async (name, cellphone) => {
    let options = {
        method: 'POST',
        url: `/app/v1/customer/`,
        data: {name: name, cellphone: cellphone},
    }
    similarCustomer.value = await useAuthAxios(options, false)
}

watch(() => [data.name, data.cellphone], ([newName, newCellphone]) => {
    fetchSimilar(newName, newCellphone)
})

const similarField = {
    name: {label: '성명', width: 100, type: 'text'},
    cellphone: {label: '휴대전화', width: 120, type: 'text'},
    organization: {label: '소속', width: 120, type: 'text'},
    // memo: {label: '메모', width: 120, type: 'text'},
    open: {
        label: '선택', width: 100, type: 'button', severity: Severities.PRIMARY,
        action: (row) => showCustomer(row)
    }
}

const similarFilter = (row) => {
    return {name: row.name, cellphone: row.cellphone, organization: row.organization?.name, memo: row.memo}
}
// 초기화

const clean = () => {
    data.name = ''
    data.organization = authStore.user.employment.company
    data.role = ''
    data.rank = ''
    data.email = ''
    data.cellphone = ''
    data.phone = ''
    data.memo = ''
    data.channel = null
    data.manager = authStore.user
    similarCustomer.value = []
}

const close = () => {
    clean()
    emits('close')
}

const nameInput = ref(null)
onMounted(() => {
    data.manager = authStore.user
})

watch(() => props.isOpen, (newValue, oldValue) => {
    if (newValue === oldValue) return null
    if (!newValue) return null
    nextTick(() => {
        nameInput.value.focus()
    })
})

</script>

<template>
    <DrawerCore :title="props.title" :open="props.isOpen" @close="close">
            <form class="grid grid-cols-1 md:grid-cols-3 p-4 gap-4" @submit.prevent="save">

                <TextInput v-model="data.name" label="담당자명" class="col-span-1"
                               :errors="validator.name.$errors.map(e => e.$message)"/>

                <TextInput v-model="data.cellphone" class="col-span-2"
                               :errors="validator.cellphone.$errors.map(e => e.$message)"  label="휴대전화"/>

                <AffiliateSelector v-model="data.organization" class="col-span-1"
                                   :errors="validator.organization.$errors.map(e => e.$message)" label="소속"/>

                <TextInput v-model="data.role" class="col-span-1"
                               :errors="validator.role.$errors.map(e => e.$message)"  label="직책"/>

                <TextInput v-model="data.rank" class="col-span-1"
                               :errors="validator.rank.$errors.map(e => e.$message)"  label="직급"/>

                <TextInput v-model="data.email" type="email" class="col-span-1"
                               :errors="validator.email.$errors.map(e => e.$message)"  label="이메일"/>

                <TextInput v-model="data.phone" type="text" class="col-span-2"
                               :errors="validator.phone.$errors.map(e => e.$message)"  label="전화번호"/>

                <TextArea v-model="data.memo" class="col-span-full"
                               :errors="validator.memo.$errors.map(e => e.$message)"  label="메모"/>

                <EmployeeSelector v-model="data.manager" :errors="validator.manager.$errors.map(e => e.$message)"
                                   class="col-span-1"
                                  :required="true" label="담당자" placeholder="영업 담당자"/>
                <ChoiceSelectorBase v-model="data.channel" :errors="validator.channel.$errors.map(e=>e.$message)" choice-type="AcquireChannelChoice"
                                     class="col-span-1"
                                    label="인입경로"/>
                <button class="btn btn-primary w-full col-span-1" type="submit">저장</button>
            </form>
            <div class="border-l border-gray-200 flex-grow h-full p-4 overflow-y-auto">
                <div class="text-sm mb-2 font-semibold">중복 고객 확인</div>
                <InlineTableComponent :fields="similarField" :filter="similarFilter" :rows="similarCustomer"/>
            </div>
    </DrawerCore>
</template>

<style scoped>

</style>