<script setup>
import {useRoute} from "vue-router";
import DefaultButton from "@/components/buttons/DefaultButton.vue";
import {ref, onMounted} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import EmployeeDefaultInfo from "@/views/settings/partials/employee/EmployeeDefaultInfo.vue";
import {ExclamationTriangleIcon} from "@heroicons/vue/20/solid/index.js";
import EmployeeDangerZone from "@/views/settings/partials/employee/EmployeeDangerZone.vue";
import EmployeeCompany from "@/views/settings/partials/employee/EmployeeCompany.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import HorizontalBar from "@/components/forms/HorizontalBar.vue";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import {Severities} from "@/constant.js";
import EmployeePermissions from "@/views/settings/partials/employee/EmployeePermissions.vue";
import EmployeePermissionDriver from "@/views/settings/partials/employee/EmployeePermissionDriver.vue";

const route = useRoute()

const groupEmployeeId = route.params.id
const data = ref(null)
const fetch = async () => {
  data.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/employment/${groupEmployeeId}/`
  })
}

onMounted(async () => {
  await fetch()
})

</script>

<template>
  <DefaultLayout title="설정 / 그룹 사용자 관리">
    <template v-if="data" #content>
      <div class="space-y-8">
        <InfoBlock v-if="!data.is_active" :description="{severity: Severities.SECONDARY, text: '퇴사자 정보 변경은 제한됩니다. 필요한 경우 관리자에게 문의주세요.'}"
                   class="mb-4"/>
        <EmployeeDefaultInfo :userData="data"/>
        <HorizontalBar/>
        <EmployeeCompany :userData="data" @fetch="fetch"/>
        <HorizontalBar/>
        <EmployeePermissionDriver :userData="data"/>
        <HorizontalBar/>
        <EmployeePermissions :userData="data"/>
        <HorizontalBar/>
        <EmployeeDangerZone :userData="data" @update="fetch"/>
      </div>
    </template>
  </DefaultLayout>
</template>