<script setup>
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";

const props = defineProps({
  title: {
    type: String,
    default: "페이지 타이틀"
  }
})
</script>

<template>
  <DefaultLayoutBase :title="props.title">
    <template #body>
      <div class="w-full overflow-auto bg-white p-4">
        <slot name="content"/>
      </div>
    </template>
  </DefaultLayoutBase>
</template>

<style scoped>

</style>