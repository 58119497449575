<script setup>
import TextInput from "@/components/forms/TextInput.vue";
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import {formatDateTime} from "@/utils/datetime.js";
import FileInlineDropzone from "@/components/forms/FileInlineDropzone.vue";
import dayjs from "dayjs";
import useAuthAxios from "@/composables/useAuthAxios.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import {Severities} from "@/constant.js";
import fileUpload from "@/composables/useFileUpload.js";
import ConfirmDialogComponent from "@/components/commons/Confirm/ConfirmDialogComponent.vue";
import {useViewer} from "@/components/commons/Viewer/useViewer.js";
import {useConfirm} from "@/components/commons/Confirm/useConfirm.js";
import {DatePicker} from "v-calendar";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import {helpers} from "@vuelidate/validators";
import ReconcileRow from "@/components/drawer/accounting/ReconcileRow.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import AlertDialogComponent from "@/components/commons/Alert/AlertDialogComponent.vue";
import {useFocus} from '@vueuse/core'


const props = defineProps({
    bill: {type: [null, Object], required: true, default: null}
})
const emits = defineEmits(['update', 'create'])
const reconcileAmountInputFocused = ref(false)


const update = (data) => {
    emits('update', data)
}
const reconcileData = ref(null)

const fetchReconcile = async () => {
    let response = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/accounting/bill/${props.bill.id}/reconcile/`,
    })
    reconcileData.value = response.map(r => {
        r.reconcile_amount = 0
        return r
    })
}

onMounted(async () => {
    await fetchReconcile()
})

watch(() => props.bill, async (newValue, oldValue) => {
    await fetchReconcile()
    clear()
}, {deep: true})


watch(reconcileData, (newValue, oldValue) => {
    data.reconcile_amount = newValue.reduce((acc, curr) => {
        acc += isNaN(Number(curr.reconcile_amount)) ? 0 : Number(curr.reconcile_amount)
        return acc
    }, 0)
}, {deep: true})

const alertData = reactive({
    show: false,
    severity: null,
    summary: null,
    detail: null,
    onAccept: null,
    onCancel: null,
})

const closeAlert = () => {
    Object.assign(alertData, {
        show: false,
        severity: null,
        summary: null,
        detail: null,
        onAccept: null,
        onCancel: null,
    })
}
const baseData = {
    "reconcile_amount": 0,
    "reconcile_date": dayjs().format('YYYY-MM-DD'),
}

const data = reactive({
    ...baseData
})

const clear = () => {
    Object.assign(data, baseData)
}

const model = {
    reconcile_amount: {
        required: requiredWithMessage('수입 총액을 입력해주세요.'),
        matchIndividualReconcileAmount: helpers.withMessage(
            '입금 금액과 수입액의 합이 일치하지 않습니다.',
            (value) => {
                const reconcile_amount = reconcileData.value.reduce((acc, curr) => {
                    acc += curr.reconcile_amount ? Number(curr.reconcile_amount) : 0
                    return acc
                }, 0)
                return reconcile_amount === Number(value)
            }
        ),
        smallerThanRemainAmount: helpers.withMessage(
            "입금 금액의 총 합이 미수액 총 합보다 큽니다.",
            (value) => {
                const reconcile_amount = reconcileData.value.reduce((acc, curr) => {
                    acc += curr.reconcile_amount ? Number(curr.reconcile_amount) : 0
                    return acc
                }, 0)
                return reconcile_amount <= Number(value)
            }
        )
    },
    reconcile_date: {required: requiredWithMessage('수입 일자를 입력해주세요.')},
}

const divideAmount = () => {
    if (isNaN(Number(data.reconcile_amount))) {
        return
    }
    let reconcileAmount = Number(data.reconcile_amount)
    const remainTotal = reconcileData.value.reduce((acc, curr) => {
        acc += isNaN(Number(curr.remain_amount)) ? 0 : Number(curr.remain_amount)
        return acc
    }, 0)
    if (data.reconcile_amount > remainTotal) {
        alertData.show = true
        alertData.summary = '입금 총액 오류'
        alertData.detail = `입금 총액(${reconcileAmount.toLocaleString()})이 미수금 총액(${remainTotal.toLocaleString()})을 초과합니다.`
        alertData.severity = Severities.ERROR
        alertData.onAccept = () => {
            reconcileAmountInputFocused.value = true
            // console.log(reconcileAmountInput.value.focus())
        }
        return;
    }
    let currentAmount = 0
    reconcileData.value.forEach(billEntry => {
        if (billEntry.remain_amount > reconcileAmount) {
            currentAmount = reconcileAmount
        } else {
            currentAmount = billEntry.remain_amount
        }
        billEntry.reconcile_amount = currentAmount
        reconcileAmount -= currentAmount
    })
}
const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})

const register = async () => {
    validator.value.$reset()
    validator.value.$clearExternalResults()
    let isValid = await validator.value.$validate()
    if (!isValid) {
        return
    }
    let response = await useAuthAxios({
        method: 'POST',
        url: `/app/v1/accounting/bill/${props.bill.id}/reconcile/`,
        data: {
            date: data.reconcile_date,
            bill_entries: reconcileData.value.map(r => {
                return {
                    bill_entry: r.id,
                    reconcile_amount: r.reconcile_amount,
                }
            })
        }
    })
    update()
}

const completeReconcile = async () => {
    const response = await useAuthAxios({
        method: 'PUT',
        url: `/app/v1/accounting/bill/${props.bill.id}/`,
    })
    update()
}

const updateRow = (originalEntry, newEntry) => {
    Object.assign(originalEntry, newEntry)
    originalEntry.reconcile_amount = isNaN(Number(newEntry.reconcile_amount)) ? 0 : Number(newEntry.reconcile_amount)
}

onBeforeUnmount(() => {
    reconcileData.value = null
})
</script>

<template>
    <!--    <pre>-->
    <!--        {{props.bill}}-->
    <!--    </pre>-->
    <div v-if="props.bill" class="px-2 space-y-6">
        <form v-if="['S', 'B'].includes(props.bill.status)"
              class="grid grid-cols-6 md:grid-cols-8 gap-2 mb-4 gap-y-4 border-b border-gray-200 pb-4"
              @submit.prevent="register">
            <TextInput v-model="data.reconcile_amount" :errors="validator.reconcile_amount.$errors.map(e => e.$message)"
                       auto-select class="col-span-2 md:col-span-2"
                       label="입금 금액" type="number"/>
            <DatePickerInput v-model="data.reconcile_date"
                             :errors="validator.reconcile_date.$errors.map(e => e.$message)"
                             class="col-span-3 md:col-span-2"
                             label="입금 일자"/>
            <button class="col-span-full md:col-span-1 btn btn-primary-outline" type="button" @click="divideAmount">분배
            </button>
            <button class="col-span-2 md:col-span-2 btn btn-primary" type="submit">수입 등록
            </button>
        </form>
<!--        {{props.bill.receivable_amount}}-->
<!--        {{props.bill.status}}-->
        <div v-if="props.bill.receivable_amount === 0 && props.bill.status === 'B'">
            <button
                class="col-span-2 md:col-span-2 btn btn-primary-outline" type="button"
                @click="completeReconcile">수입 완료
            </button>
        </div>
        <div v-if="reconcileData">
            <table class="w-full divide-y divide-gray-300">
                <thead>
                <tr>
                    <th>매출 일련번호</th>
                    <th>총액</th>
                    <th>미수액</th>
                    <th>수입액</th>
                    <th>수입 후 잔액</th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <template v-for="billEntry in reconcileData" :key="billEntry.id">
                    <ReconcileRow :value="billEntry" :bill="props.bill" @update="() => {fetchReconcile(); update()}"
                                  @updateRow="value => {updateRow(billEntry, value)}"/>
                </template>
                <!--                <tr>-->
                <!--                    <td>{{ billEntry.serial }}</td>-->
                <!--                    <td class="text-right">{{ billEntry.total.toLocaleString() }}</td>-->
                <!--                    <td class="text-right">{{ billEntry.remain_amount.toLocaleString() }}</td>-->
                <!--                    <td>-->
                <!--                        <TextInput v-model="billEntry.reconcile" auto-select class="col-span-3 md:col-span-4" label=""-->
                <!--                                   type="number"/>-->
                <!--                    </td>-->
                <!--                    <td class="text-right">-->
                <!--                        {{ calcRemain(billEntry.remain_amount, billEntry.reconcile).toLocaleString() }}-->
                <!--                    </td>-->
                <!--                </tr>-->
                <tr>
                    <td>계</td>
                    <td class="text-right">{{
                            reconcileData.reduce((acc, curr) => {
                                acc += curr.total
                                return acc
                            }, 0).toLocaleString()
                        }}
                    </td>
                    <td class="text-right">{{
                            reconcileData.reduce((acc, curr) => {
                                acc += curr.remain_amount
                                return acc
                            }, 0).toLocaleString()
                        }}
                    </td>
                    <td class="text-right">{{
                            reconcileData.reduce((acc, curr) => {
                                acc += curr.reconcile_amount ? Number(curr.reconcile_amount) : 0
                                return acc
                            }, 0).toLocaleString()
                        }}
                    </td>
                    <td class="text-right">{{
                            reconcileData.reduce((acc, curr) => {
                                acc += curr.remain_amount - (isNaN(Number(curr.reconcile)) ? 0 : Number(curr.reconcile))
                                return acc
                            }, 0).toLocaleString()
                        }}
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="flex justify-end gap-x-2">

            </div>
            <!--            <template >-->
            <!--                {{billEntry}}-->
            <!--                <div class="flex items-center justify-start gap-x-2">-->
            <!--                    -->
            <!--                </div>-->
            <!--            </template>-->
            <!--            <pre>{{// reconcileData}}</pre>.-->
        </div>

        <!--        <InlineTableComponent :fields="entryTableFields"-->
        <!--                              :rows="props.bill?.bill_entry_list ? props.bill.bill_entry_list.map(bill_entry => bill_entry.entry) : []"/>-->
        <!--        <ConfirmDialogComponent :on-accept="cancelBill"-->
        <!--                                :on-cancel="() => {confirm = false}"-->
        <!--                                :severity="Severities.ERROR"-->
        <!--                                :show="confirm" detail="이 작업은 되돌릴 수 없습니다."-->
        <!--                                summary="청구를 취소하시겠습니까?"/>-->
        <AlertDialogComponent v-bind="alertData" @close="closeAlert"/>
    </div>
</template>


<style scoped>
th {
    @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900
}

td {
    @apply whitespace-nowrap px-3 py-4 text-sm text-gray-500
}
</style>