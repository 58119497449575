<script setup>
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {onMounted, reactive, ref, watch} from "vue";
import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {helpers, required} from "@vuelidate/validators";
import {externalValidator} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import AddressInput from "@/components/forms/AddressInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import SmallWorkWaste from "@/components/drawer/work/small/SmallWorkWaste.vue";
import SmallWorkWaypoint from "@/components/drawer/work/small/SmallWorkWaypoint.vue";
import SmallWorkSite from "@/components/drawer/work/small/SmallWorkSite.vue";
import SmallWorkDriver from "@/components/drawer/work/small/SmallWorkDriver.vue";
import SmallWorkArrival from "@/components/drawer/work/small/SmallWorkArrival.vue";

const props = defineProps({
  work: {type: [null, Object], required: true, default: null}
})
const emits = defineEmits(['update', 'create'])

const update = (data) => {
  emits('update', data)
}

const data = reactive({})

const assignData = (original) => {
  if (!original) {
     Object.assign(data, {})
    return null
  }
  // let temp = {
  //   at_date: original?.departure?.at_datetime?.date,
  // }
  Object.assign(data, original)
  // let temp = JSON.parse(JSON.stringify(original))
  // temp['at_date'] = temp?.departure?.at_datetime?.date
  // temp['at_time'] = temp?.departure?.at_datetime?.time
  // temp['company'] = temp?.departure.site.company
  // temp['company_manager'] = temp?.departure.site.manager
  // temp['company_manager_name'] = temp?.departure?.site?.manager_name
  // temp['company_manager_cellphone'] = temp?.departure?.site?.manager_cellphone
  // temp['address_poi'] = temp?.departure?.site?.poi
  // temp['address_detail'] = temp?.departure?.site?.detail
  // temp['address_description'] = temp?.departure?.site?.description
  // Object.assign(data, temp)
}

onMounted(() => {
  assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  assignData(newValue)
}, {deep: true})

const model = {
  waste_class: {externalValidator},
  waste_type: {externalValidator},
  volume: {externalValidator},
  at_date: {externalValidator},
  at_time: {externalValidator},
}
const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})

const create = async (workData) => {
  emits('update')
  emits('create', workData)
}
</script>

<template>

  <div v-if="data" class="px-2 divide-y divide-gray-300">
    <!--기사/차량정보-->
<!--    <SmallWorkDriver :work="props.work" @update="update"/>-->
    <!--성상정보-->
    <SmallWorkWaste :work="props.work" @update="update" :canUpdateWaste="props.work?.action_available?.update_waste || false"/>
    <!--진입 정보-->
    <SmallWorkWaypoint :work="props.work" @update="update" :canUpdateWaypoint="props.work?.action_available?.update_waypoint || false"/>
    <!--현장 정보-->
    <SmallWorkSite :work="props.work" @update="update" @create="create" :canUpdateSite="props.work?.action_available?.update_site || false"/>
    <!--하차지 정보-->
    <SmallWorkArrival :work="props.work" @update="update" @create="create" :canUpdateArrival="props.work?.action_available?.update_arrival || false"/>
  </div>
<!--  <div>-->
<!--    <pre>{{ work }}</pre>-->
<!--  </div>-->
</template>

<style scoped>

</style>