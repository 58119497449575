<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, onBeforeMount, onMounted, ref, toRef, watch} from "vue";
import StaticSelectorBase from "@/components/selector/base/StaticSelectorBase.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";

const props = defineProps({
  wasteClass: {type: [String, Object, null], required: false, default: null},
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '상세 성상'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
})

const emits = defineEmits(['update:options'])

const model = defineModel()

const options = ref([])

onMounted(async () => {
  options.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/common/choice/`,
    params: {type: 'WasteTypeChoice'}
  }, false)
  model.value = converter(model.value)
  emits('update:options', options.value)
})

watch(model, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  model.value = converter(newValue)
})

const converter = (value) => {
  if (typeof value === 'string') {
    return options.value.find(option => option.value === value) || null
  }
  return value
}
const toRepresentation = (obj) => {
  return obj.label
}

watch(() => props.wasteClass, (nv, ov) => {
  if (nv === ov) {
    return null
  }
  if (!nv) {
    model.value = null
  }
  if (!model.value) {
    return null
  }
  let target = typeof model.value === 'string' ? model.value : model.value.value
  if (target.startsWith(nv?.value || nv) === false) {
    model.value = null
  }
})

const filteredOption = computed(() => {
  if (props?.wasteClass === null) {
    return options.value
  } else if (props?.wasteClass?.value) {
    return options.value.filter(option => option.value.startsWith(props?.wasteClass?.value))
  } else {
    return options.value.filter(option => option.value === props.wasteClass)
  }
})
</script>

<template>
  <StaticSelectorBase v-model="model" :autoSelect="props.autoSelect"
                      :class="props.class" :errors="props.errors"
                      :inputClass="props.inputClass" :label="props.label"
                      :labelClass="props.labelClass" :messages="props.messages"
                      :name="props.name" :nullable="props.nullable"
                      :options="filteredOption" :placeholder="props.placeholder"
                      :readonly="props.readonly" :required="props.required"
                      :toRepresentation="toRepresentation"/>
</template>

<style scoped>
</style>