<script setup>
import SmallWorkDrawer from "@/components/drawer/work/small/SmallWorkDrawer.vue";
import {ref} from "vue";

const emits = defineEmits(['update'])
const props = defineProps({
  fieldKey: {
    type: String,
    required: true
  },
  fieldValue: {
    type: [null, String, Boolean, Date, Object, Number],
    required: true
  },
  fieldData: {
    type: Object,
    required: true,
  },
  row: {
    type: Object,
    required: true
  }
})

const showDrawer = ref(false)

const openDrawer = () => {
  showDrawer.value = true
}

const closeDrawer = () => {
  showDrawer.value = false
}

const update = (event) => {
  emits('update', event)
}
</script>

<template>
  <template v-if="props.row.origin_work">
    <button type="button" @click.prevent.stop="openDrawer" class="text-primary-600">
      {{ props.row.origin_work_serial }}
    </button>
    <template v-if="showDrawer">
    <SmallWorkDrawer :work="props.row.origin_work" :open="showDrawer" @close="closeDrawer" @update="update"/>
    </template>
  </template>
  <template v-else>
    {{ props.fieldValue }}
  </template>
</template>

<style scoped>

</style>