<script lang="ts" setup>
import {useDropZone, useEventListener} from '@vueuse/core'
import {ref} from 'vue'
import {XMarkIcon} from "@heroicons/vue/20/solid";
import generateUuidString from '@/utils/generateUuidString';

const props = defineProps({
  label: {type: String, required: false, default: '파일'},
  labelClass: {
    type: [String, Array, Object, null],
    required: false,
    default: 'block text-sm font-medium leading-6 text-gray-900 mb-2'
  },
  files: {type: Array, required: false, default: []},
  uploadProgress: {type: [Object, null], required: false, default: null},
})
const emits = defineEmits(['update:files'])


const onChange = (event) => {
  onDrop(Array.from(event.target.files))
}

const onDrop = (files) => {
  if (files) {
    let fileList = files.map(file => ({
      id: generateUuidString(),
      file: file,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
    }))
    emits('update:files', [...props.files, ...fileList])
  }
}


const dropZoneRef = ref<HTMLElement>()

let options = {onDrop: onDrop}
//
// if (props.dataTypes) {
//   options = {...options, dataTypes: props.dataTypes}
// }

const {isOverDropZone} = useDropZone(dropZoneRef, options)

const removeFile = (file) => {
  let fileList = props.files.filter(f => f !== file)
  emits('update:files', fileList)
}
// const { isOverDropZone: isOverImageDropZone } = useDropZone(imageDropZoneRef, { dataTypes: ['image/png'], onDrop: onDrop })

</script>

<template>
  <div class="mb-2">
    <label :class="props.labelClass" for="dropzone">{{ props.label }}</label>
    <div ref="dropZoneRef" :class="[isOverDropZone ? 'dz-input hover':'dz-input']">
      <div class="text-center">
        <div class="flex text-sm leading-6 text-gray-600">
          <label :class="[isOverDropZone ? 'over':'']" class="dz-label" for="file-input">
            {{ props.label }}을 선택
          </label>
          <input id="file-input" hidden="hidden" multiple type="file" @change="onChange" tabindex="-1">
          <p class="pl-1">하거나 끌어다 놓으세요.</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ul class="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-4 md:gap-x-6 lg:grid-cols-4 md:gap-x-8" role="list">
      <li v-for="file in props.files" :key="file.name" class="relative">
        <div class="flex w-full items-center justify-between">
          <p class="pointer-events-none block truncate text-sm font-medium text-gray-900">{{ file.id }}</p>
          <button type="button" @click="removeFile(file)">
            <XMarkIcon class="size-5"/>
          </button>
        </div>

        <p class="pointer-events-none block text-sm font-medium text-gray-500">
          <template v-if="Math.floor((file.size/1024)/1024) < 1">
            {{ Math.floor(file.size / 1024) }}KB
          </template>
          <template v-else>
            {{ Math.floor((file.size / 1024) / 1024) }}MB
          </template>
          <template v-if="props.uploadProgress.hasOwnProperty(file.id)">
            <template v-if="props?.uploadProgress[file.id].complete">
              <span class="text-green-500">완료</span>
            </template>
            <template v-else>
              <span class="text-primary-600">{{ props.uploadProgress[file.id].progress }}%</span>
            </template>
          </template>
        </p>
      </li>
    </ul>

  </div>
</template>


<style scoped>
.dz-input {
  @apply mt-2 flex justify-center rounded border-[1px] border-gray-300 px-6 py-6
}

.dz-input.hover {
  @apply border-primary-600 bg-primary-400/25
}

.dz-label {
  @apply relative cursor-pointer rounded-md font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500
}

</style>
