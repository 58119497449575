<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import CustomerDefaultInfo from "@/components/drawer/partial/CustomerDefaultInfo.vue";
import CampaignDefaultInfo from "@/components/drawer/partial/CampaignDefaultInfo.vue";
import CampaignSetting from "@/components/drawer/partial/CampaignSetting.vue";

const props = defineProps({
  title: {type: String, required: false, default: '캠페인 상세'},
  open: {type: Boolean, required: true},
  campaign: {type: [null, Object], required: true}
})

const emits = defineEmits(['close', 'update:campaign'])

const close = () => {
  emits('close')
}


const tabItems = [
  {name: '기본정보', key: 'default'},
  {name: '설정', key: 'setting'},
  {name: '참조자', key: 'referrer'},
  {name: '메모', key: 'note'},
  {name: '파일', key: 'file'},
  {name: 'SMS', key: 'sms'},
  {name: '히스토리', key: 'history'},
]
</script>

<template>
  <DrawerBase v-if="props.campaign" :open="props.open" :title="props.title" @close="close" :target="props.campaign" :tab-items="tabItems">
    <template #default>
      <CampaignDefaultInfo :campaign="props.campaign" @update:campaign="() => emits('update:campaign')"/>
<!--      <CustomerDefaultInfo :customer="props.campaign"/>-->
    </template>
    <template #setting>
      <CampaignSetting :campaign="props.campaign" @update:campaign="() => emits('update:campaign')"/>
    </template>
    <template #referrer>
      기능 준비중
    </template>
  </DrawerBase>
</template>