<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import {provide} from "vue";




const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greater('md')
const isMobile = breakpoints.smallerOrEqual('md')
provide('isMobile', isMobile)
provide('isDesktop', isDesktop)
// const isMobile = inject('isMobile')
// const isDesktop = inject('isDesktop')

</script>

<template>
  <RouterView />
</template>

<style scoped>

</style>
