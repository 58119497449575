<script setup xmlns="http://www.w3.org/1999/html">
import {computed, onMounted, onUnmounted, inject} from "vue";
import {useRoute, useRouter} from "vue-router";
import LayoutBase from "@/layout/base/LayoutBase.vue"
import {useAuthStore} from "@/stores/authStore.js";
import {RoutesData} from "@/router/index.js";
import {UserCircleIcon} from "@heroicons/vue/24/outline";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {APP_STAGE} from "@/service/api.js";
import {Bars3Icon} from "@heroicons/vue/24/outline/index.js";
import Logo from "@/assets/image/logo.svg"
import MenuItems from "@/components/navigation/MenuItems.vue";

const router = useRouter()

onMounted(() => {
  let body = document.body
  let html = document.documentElement
  body.classList.add('h-full')
  html.classList.add('h-full')
})

onUnmounted(() => {
  let body = document.body
  let html = document.documentElement
  body.classList.remove('h-full')
  html.classList.remove('h-full')
})

const navIconSet = inject('icons')
const authStore = useAuthStore()
const layoutStore = useLayoutStore()

const route = useRoute()

const navigation = computed(() => {
  return [
    {
      name: '대시보드',
      href: '/',
      current: route.path === '/',
      icon: navIconSet.cilChart
    },
    {
      name: '소형배차', href: null, current: route.path.startsWith(RoutesData.SmallWork.path),
      icon: navIconSet.cilTruck,
      key: 'work_small',
      children: [
        {
          name: '1톤 배차', href: RoutesData.SmallWork.children.List.path,
          current: route.path.startsWith(RoutesData.Work.children.Small.path)
        },
        {
          name: '2.5톤 배차', href: RoutesData.SmallWork.children.List.path,
          current: route.path.startsWith(RoutesData.Work.children.Small.path)
        },
      ]
    },
    // {
    //   name: '업무', href: null, current: route.path.startsWith('/work'), icon: navIconSet.cilTruck, key: 'work',
    //   children: [
    //     {
    //       name: '소형배차', current: route.path.startsWith('/work/small'),
    //       children: [
    //         {
    //           name: '목록', href: RoutesData.Work.children.Small.path,
    //           current: route.path.startsWith(RoutesData.Work.children.Small.path)
    //         },
    //       ]
    //     },
    //     // {name: '차량관리', href: '/carrier/vehicle', current: route.path.startsWith('/carrier/vehicle')},
    //     // {name: '기사관리', href: '#', current: false},
    //   ]
    // },
    {
      name: '영업', href: null, current: route.path.startsWith('/sales'), icon: navIconSet.cilPhone, key: 'sales',
      children: [
        {
          name: '영업', href: RoutesData.Sales.children.Sales.path,
          current: route.path.startsWith(RoutesData.Sales.children.Sales.path)
        },
        {
          name: '고객', href: RoutesData.Sales.children.Customer.path,
          current: route.path.startsWith(RoutesData.Sales.children.Customer.path)
        },
        // {name: '운행', href: '/place/transport', current: route.path.startsWith('/place/transport')},
        // {name: '사업장 관리', href: '#', current: false},
      ]
    },
    {
      name: '거래처', href: null, icon: navIconSet.cilFactory, key: 'affiliate',
      current: route.path.startsWith(RoutesData.Affiliate.children.Affiliate.path),
      children: [
        {name: '목록', href: RoutesData.Affiliate.children.Affiliate.path},
        {name: '가격정보', href: RoutesData.Affiliate.children.AffiliatePrice.path}
      ]
    },
    {
      name: '휴대전화',
      href: null,
      current: route.path.startsWith(RoutesData.CallLog.path),
      icon: navIconSet.cilAssistiveListeningSystem,
      key: 'callLog',
      children: [
        {
          name: '통화기록', href: RoutesData.CallLog.children.CallLog.path,
          current: route.path.startsWith(RoutesData.CallLog.children.CallLog.path)
        },
        {
          name: '기기', href: RoutesData.CallLog.children.Device.path,
          current: route.path.startsWith(RoutesData.CallLog.children.Device.path)
        },
      ]
    },
    {
      name: '차량', href: null, current: route.path.startsWith('/vehicle'), icon: navIconSet.cilGarage, key: 'vehicle',
      children: [
        {
          name: '목록', href: RoutesData.Vehicle.children.List.path,
          current: route.path.startsWith(RoutesData.Vehicle.children.List.path)
        },
      ]
    },
    {
      name: '회계', href: null, current: route.path.startsWith('/accounting'), icon: navIconSet.cilDollar, key: 'accounting',
      children: [
        {
          name: '미청구 매출', href: RoutesData.Accounting.children.EntryUnbilled.path,
          current: route.path.startsWith(RoutesData.Accounting.children.EntryUnbilled.path)
        },
        {
          name: '청구서', href: RoutesData.Accounting.children.Bill.path,
          current: route.path.startsWith(RoutesData.Accounting.children.Bill.path)
        },
        // {
        //   name: '미지급 매입', href: RoutesData.Accounting.children.EntryUnbilled.path,
        //   current: route.path.startsWith(RoutesData.Accounting.children.EntryUnbilled.path)
        // },
        {
          name: '코드', href: RoutesData.Accounting.children.Code.path,
          current: route.path.startsWith(RoutesData.Accounting.children.Code.path)
        },
      ]
    },
    {
      name: '설정', href: null, current: route.path.startsWith('/settings'), icon: navIconSet.cilCog, key: 'settings',
      children: [
        {
          name: '사업장 관리',
          href: RoutesData.Settings.children.Company.path,
          current: route.path.startsWith(RoutesData.Settings.children.Company.path)
        },
        {
          name: '사용자 관리',
          href: RoutesData.Settings.children.Employee.path,
          current: route.path.startsWith(RoutesData.Settings.children.Employee.path)
        },
        {
          name: '캠페인 관리',
          href: RoutesData.Settings.children.Campaign.path,
          current: route.path.startsWith(RoutesData.Settings.children.Campaign.path)
        },
      ]
    },
    // {
    //   name: '개인 설정', href: null, current: route.path.startsWith('/personal'), icon: UserIcon, key: 'psettings',
    //   children: [
    //     {
    //       name: '기본정보',
    //       href: RoutesData.Personal.children.Base.path,
    //       current: route.path.startsWith(RoutesData.Personal.children.Base.path)
    //     },
    //     {
    //       name: '비밀번호 변경',
    //       href: RoutesData.Personal.children.PasswordChange.path,
    //       current: route.path.startsWith(RoutesData.Personal.children.PasswordChange.path)
    //     },
    //     {
    //       name: '로그아웃',
    //       action: () => authStore.logout('로그아웃', '로그아웃 되었습니다.')
    //     },
    //   ]
    // },
  ]
})

const to = async (item) => {
  if (item.action) {
    await item.action()
  } else {
    await router.push(item.href)
  }
}

const bgColor = computed(() => {
  // return 'bg-slate-50'
  if (APP_STAGE === 'PRODUCTION') {
    return 'bg-slate-50'
  } else if (APP_STAGE === 'DEVELOPMENT') {
    return 'bg-red-100'
  } else if (APP_STAGE === 'LOCAL') {
    return 'bg-green-100'
  }
})

const title = computed(() => {
  if (APP_STAGE === 'PRODUCTION') {
    return null
  } else if (APP_STAGE === 'DEVELOPMENT') {
    return 'DEV'
  } else if (APP_STAGE === 'LOCAL') {
    return 'LOCAL'
  }
})
</script>

<template>
  <LayoutBase>
    <div class="flex w-full h-dvh">
      <!-- 네비게이션 시작-->
      <!--      <div class="flex w-[13.75rem] min-w-[13.75rem] grow flex-col border-r" :class="bgColor">-->
      <div :class="[bgColor, layoutStore.mobileNav ? 'fixed md:relative md:flex':'hidden']"
           class="w-[13.75rem] min-w-[13.75rem] grow flex-col border-r z-50 md:z-0 h-dvh md:h-100">
        <!--네비게이션 헤더 시작-->
        <div class="flex h-[3.25rem] min-h-[3.25rem] items-center">
          <div class="group mx-2 mt-2 flex h-9 w-full items-center rounded-md pl-2">
            <div class="grow">
              <div>
                <div class="relative">
                  <div :class="bgColor" class="relative flex items-center justify-between border-slate-50 pl-3 pr-1"
                       type="button">
                    <img :src="Logo" alt="지구하다" class="h-5"/>
                    <span
                        v-if="title" class="w-full text-md font-bold text-slate-900">{{ title }}</span>
                    <button class="block" @click="layoutStore.toggleMobileNav()">
                      <Bars3Icon class="size-6"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--네비게이션 헤더 끝-->
        <!--메뉴 시작-->
        <MenuItems :navigation="navigation"/>
        <!--메뉴 끝-->
        <!--네비게이션 푸터 시작-->
        <router-link v-if="authStore.user" :to="{name: 'profile'}"
                     class="mt-2 flex h-10 min-h-10 cursor-pointer items-center border-t px-2 hover:bg-slate-100">
          <UserCircleIcon class="mr-2 size-5 text-slate-600"/>
          <span class="text-sm text-slate-600">{{ authStore.user.name }}</span>
        </router-link>
        <!--네비게이션 푸터 끝-->
      </div>
      <!-- 네비게이션 끝 -->

      <!-- 메인 영역 시작 -->
      <div :class="[layoutStore.mobileNav ? 'md:w-[calc(100%_-_13.75rem)]':'w-full']"
           class="flex md:min-w-[1060px] flex-col overflow-auto">
        <slot/>
      </div>
      <!-- 메인 영역 끝 -->
    </div>
  </LayoutBase>
</template>

<style scoped>
</style>
