<script setup>
import DefaultLayout from "@/layout/DefaultLayout.vue";
import InfoBlock from "@/components/forms/InfoBlock.vue";

import {onMounted, reactive} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import {requiredWithMessage} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

const authStore = useAuthStore()
const data = reactive({
  password: '',
  password1: '',
  password2: ''
})
const $externalResults = ref({})
const model = {
  password: {
    required: requiredWithMessage('필수 항목입니다.'),
  },
  password1: {
    required: requiredWithMessage('필수 항목입니다.'),
  },
  password2: {
    required: requiredWithMessage('필수 항목입니다.'),
    same: {
      $validator: (value) => value === data.password1,
      $message: '비밀번호가 일치하지 않습니다.'
    }
  },
}
const validator = useVuelidate(model, data, {$externalResults})
const layoutStore = useLayoutStore()

const save = async () => {
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    await useAuthAxios({
      method: 'POST',
      url: `/app/v1/user/password/update/`,
      data: data
    })
    await authStore.setUser()
    layoutStore.addSuccessNotification('비밀번호가 변경되었습니다.', null, 1000)
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>

<template>
      <div>
        <div class="mb-4">
          <span class="mr-4 text-lg text-slate-900">비밀번호 변경</span>
        </div>
        <InfoBlock :description="{text: '정기적인 비빌번호 변경은 계정 보호에 도움이 됩니다.'}"/>
        <form class="w-96" method="post" @submit.prevent="save">
          <div :class="{invalid: validator.password.$errors.length > 0}" class="input-group mt-4">
            <label class="w-20" for="id-for-password">현재 비밀번호</label>
            <div>
              <input id="id-for-password" v-model="data.password" name="password" type="password">
              <template v-for="error in validator.password.$errors">
                <p class="error">{{ error.$message }}</p>
              </template>
            </div>
          </div>
          <div :class="{invalid: validator.password1.$errors.length > 0}" class="input-group mt-4">
            <label class="w-20" for="id-for-password1">새 비밀번호</label>
            <div>
              <input id="id-for-password1" v-model="data.password1" class="grow" name="password1"
                     type="password">
              <template v-for="error in validator.password1.$errors">
                <p class="error">{{ error.$message }}</p>
              </template>
            </div>
          </div>
          <div :class="{invalid: validator.password2.$errors.length > 0}" class="input-group mt-4">
            <label class="w-20" for="id-for-password2">비밀번호 확인</label>
            <div>

              <input id="id-for-password2" v-model="data.password2" class="grow" name="password2"
                     type="password">
              <template v-for="error in validator.password2.$errors">
                <p class="error">{{ error.$message }}</p>
              </template>
            </div>
          </div>
          <div class="flex justify-end mt-4">
            <button class="btn btn-primary" type="submit">저장</button>
          </div>
        </form>
      </div>
</template>