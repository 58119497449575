<script setup>
import {computed, onMounted, onUnmounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import LayoutBase from "@/layout/base/LayoutBase.vue";


onMounted(() => {
  let body = document.body
  let html = document.documentElement
  body.classList.add('h-dvh')
  html.classList.add('h-dvh', 'bg-white')
})

onUnmounted(() => {
  let body = document.body
  let html = document.documentElement
  body.classList.remove('h-dvh')
  html.classList.remove('h-dvh', 'bg-white')
})

const route = useRoute()

</script>

<template>
  <LayoutBase>
  <div class="flex justify-center items-center h-screen w-full">
    <router-view/>
  </div>
  </LayoutBase>
</template>

<style scoped>
</style>
