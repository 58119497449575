<script setup>
import '@vuepic/vue-datepicker/dist/main.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, ref} from "vue";
import {ClockIcon} from "@heroicons/vue/24/outline/index.js";
import dayjs from "dayjs";
import {XMarkIcon} from "@heroicons/vue/24/solid/index.js";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  label: {type: [String], required: false, default: '입력'},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  displayFunction: {type: Function, required: false, default: (value) => value}
})

const emits = defineEmits(['focus', 'blur', 'enter', 'input', 'change'])

const model = defineModel()

const focused = ref(false)
const inputId = `id_for_${props.name}`
const placeholder = props.placeholder ? props.placeholder : props.label

const invalid = computed(() => {
  return props.errors.length > 0
})

const labelClass = computed(() => {
  if (props.readonly === true) {
    return ['text-gray-700', 'font-medium', 'z-20']
  }
  let validClass = invalid.value ? ['text-red-600'] : ['text-gray-700']
  let focusedClass = focused.value ? ['font-semibold', 'text-primary-600'] : ['font-medium']
  return [
    props.labelClass,
    ...validClass,
    ...focusedClass,
    'z-20'
  ]
})

const inputClass = computed(() => {
  let validClass = []
  if (props.readonly === true) {
    validClass = ['border-gray-300', 'bg-gray-900']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
    return validClass
  }
  if (invalid.value) {
    validClass = ['text-red-600', 'border-red-300',]
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-red-600', 'border-red-600']
    }
  } else {
    validClass = ['text-gray-700', 'border-gray-300']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
  }
  return [props.inputClass, ...validClass]
})

const focus = (event, onFocus) => {
  onFocus()
  focused.value = true
  emits('focus', event)
  nextTick(() => {

    if (props.autoSelect) {
      event.target.select()
    }
  })
}
const blur = (event, onBlur) => {
  onBlur()
  // focused.value = false
  // closeCalendar()
  emits('blur', event)
}

const format = 'HH:mm'

const timeInput = (event, onInput) => {
  const value = event.target.value
  let dj = dayjs(value, 'HH:mm', true)
  if (dj.isValid()) {
    model.value = dj.format('HH:mm')
  }
}

const picker = ref(null)
const isOpen = ref(false)

const toggleCalendar = ($event, isMenuOpen) => {
  if (isMenuOpen) {
    picker.value.closeMenu()
  } else {
    picker.value.openMenu()
  }
}

const closeCalendar = () => {
  picker.value.closeMenu()
}

const clear = () => {
  picker.value.clearValue()
}

const onDateInput = (event, onInput) => {
  onInput(event)
}
</script>

<template>
  <div :class="props.class" class="relative">
    <label :class="[...labelClass, props.readonly ? 'readonly':'']" :for="inputId">
      {{ props.label }} <span v-if="props.required" class="text-red-600">*</span>
    </label>

    <div :class="[...inputClass, props.readonly ? 'readonly':'']" class="input-wrapper">
      <VueDatePicker ref="picker" v-model="model"
                     :auto-apply="true" :date-picker="false"
                     :disabled="props.readonly"
                     :format="format" :input-class="inputClass"
                     :input-id="inputId" arrow-navigation as="template"
                     locale="ko-KR" model-type="HH:mm" teleport="body" text-input time-picker
                     @closed="isOpen = false"
                     @open="isOpen = true">
        <template
            #dp-input="{ value, isMenuOpen, onInput, onEnter, onTab, onClear, onFocus, onBlur, onKeypress, onPaste, openMenu, closeMenu, toggleMenu }">
          <input :id="inputId" :name="props.name" :placeholder="placeholder" :readonly="readonly"
                 :value="value" type="text"
                 @blur="blur($event, onBlur)"
                 @click="toggleCalendar($event, isMenuOpen)"
                 @focus="focus($event, onFocus)"
                 @input="onDateInput($event, onInput)"
                 @keydown.prevent.down="toggleCalendar($event, isMenuOpen)"
                 @keydown.enter="toggleCalendar($event, onEnter)"
                 @keydown.tab="onTab"
          />
        </template>
        <template #clear-icon>
        </template>
      </VueDatePicker>
      <slot name="button">
        <ClockIcon v-if="model === null" class="size-4 cursor-pointer"/>
        <button v-if="model !== null" type="button" @click="clear">
          <XMarkIcon aria-hidden="true" class="h-5 w-5"/>
        </button>
      </slot>
    </div>
    <template v-if="invalid">
      <div class="message-wrapper error">
        <p v-for="error in props.errors">- {{ error }}</p>
      </div>
    </template>
    <template v-else-if="props.messages.length > 0">
      <div class="message-wrapper">
        <p v-for="message in props.messages">- {{ message }}</p>
      </div>
    </template>
  </div>
</template>

<style scoped>
label {
  @apply absolute text-xs -top-2 left-2 inline-block bg-white px-1 py-0
}

label.readonly {
  @apply bg-transparent
}

.input-wrapper {
  @apply flex w-full leading-5 px-2 py-0 bg-white rounded h-[2rem] border-[1px] gap-x-2 items-center
}

.input-wrapper.readonly {
  @apply bg-gray-300
}

input {
  all: unset;
  @apply focus:ring-0 flex-grow min-w-0 w-full text-sm
}

input::placeholder {
  @apply text-gray-400/70
}

input:read-only {
  @apply bg-gray-300
}


.message-wrapper {
  @apply mt-[2px] text-xs text-gray-500
}

.message-wrapper.error {
  @apply text-red-500
}
</style>