<script setup>
import { onMounted, ref } from "vue";
import { useAuthStore } from "@/stores/authStore.js";

const authStore = useAuthStore();

onMounted(() => {
  setTimeout(async function () {
    await authStore.logout('로그아웃', '로그아웃 되었습니다.');
  }, 0);
});
</script>

<template>
  <div
    class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="flex flex-column align-items-center justify-content-center">
      <div
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color) 10%,
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="w-full surface-card py-8 px-5 md:px-8"
          style="border-radius: 53px"
        >
          <div class="text-center">
            <div class="text-900 text-3xl font-medium mb-3">천일에너지</div>
            <span class="text-600 font-medium">로그아웃 중입니다...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
