<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, ref} from "vue";
import BaseSelector from "@/components/selector/base/BaseSelector.vue";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '입력'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
  options: {type: Array, required: false, default: []},
  toRepresentation: {type: [Function, null], required: false, default: null},
  toRepresentationSub: {type: [Function, null], required: false, default: null}
})

const emits = defineEmits(['focus', 'blur', 'input:query'])

const model = defineModel()
const query = ref(null)

const filteredOptions = computed(() => {
  if (query.value) {
    return props.options.filter(object => {
      if (props.toRepresentationSub) {
        return props.toRepresentation(object).includes(query.value) || props.toRepresentationSub(object).includes(query.value)
      }
      return props.toRepresentation(object).includes(query.value)
    })
  } else {
    return props.options
  }
})


const setQuery = (value) => {
  query.value = value
}

const focus = (event) => {
  emits('focus', event)
}
const blur = (event) => {
  emits('blur', event)
}

</script>

<template>
  <BaseSelector v-model="model" :autoSelect="props.autoSelect" :class="props.class" :errors="props.errors"
                :inputClass="props.inputClass" :label="props.label" :labelClass="props.labelClass"
                :messages="props.messages" :name="props.name" :nullable="props.nullable" :readonly="props.readonly"
                :options="filteredOptions" :placeholder="props.placeholder" :required="props.required"
                :toRepresentation="props.toRepresentation" :toRepresentationSub="props.toRepresentationSub"
                @blur="blur" @focus="focus" @input:query="setQuery"/>
</template>

<style scoped>
</style>