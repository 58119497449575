<script setup>
import {computed, ref, watch} from "vue";
import TableCellComponent from "@/components/tables/TableCellComponent.vue";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import {EllipsisHorizontalIcon} from "@heroicons/vue/24/solid";
import {MenuButton} from "@headlessui/vue";
import EntryOriginCell from "@/components/tables/cells/EntryOriginCell.vue";

const props = defineProps({
  index: {type: Number, required: false},
  item: {type: Object, required: true},
  fields: {type: Object, required: true},
  openAction: {type: [Function, null], default: null, required: false},
  rowActions: {type: Array, default: [], required: false},
  rowsAction: {type: Array, default: [], required: false},
  selectedItems: {type: Array, default: [], required: false},
  row: {type: Object, required: true}
})

const emits = defineEmits(['update:select', 'update'])

const selected = computed({
  get: () => {
    return props.selectedItems.includes(props.item)
  },
  set: (value) => {
    emits('update:select', value)
  }
})

const openAction = () => {
  if (props.openAction) {
    props.openAction(props.item)
  } else {
    return null
  }
}

const update = (event) => {
  emits('update', event)
}

</script>

<template>
  <div class="border-b flex min-w-fit min-h-8 h-8 bg-white hover:bg-slate-100 w-full">
    <template v-if="props.rowsAction.length > 0">
      <div class="min-w-8 sticky left-0 border-r bg-inherit border-r-slate-300">
        <label class="flex size-full cursor-pointer items-center justify-center focus:ring-0">
          <input v-model="selected" class="input-checkbox" type="checkbox">
        </label>
      </div>
    </template>
    <template v-for="[fieldKey, fieldData] in Object.entries(props.fields)" v-bind:key="fieldKey">
        <TableCellComponent :fieldData="fieldData" :fieldKey="fieldKey" :fieldValue="props.item[fieldKey] ? props.item[fieldKey] : null" :row="props.row"  @update="update"/>
    </template>
    <div class="grow"></div>
    <template v-if="props.openAction">
      <template v-if="props.rowActions.length > 0">
      <DropdownComponent :class="'w-8 sticky right-12 border-l bg-inherit border-l-slate-300'"
                         :data="props.item" :rowActions="props.rowActions">
        <template #button>
          <MenuButton as="button"
                      class="rounded bg-white hover:bg-slate-100 flex items-center justify-center size-[30px] min-h-[30px] min-w-[30px]"
                      type="button">
            <EllipsisHorizontalIcon class="size-4"/>
          </MenuButton>
        </template>
      </DropdownComponent>
    </template>
      <button
          :class="'select-none w-12 sticky right-0 border-l bg-inherit border-l-slate-300 text-sm flex items-center justify-center text-primary-800'"
          @click="openAction">
        열기
      </button>
    </template>
  </div>
</template>

<style scoped>

</style>