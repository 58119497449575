<script setup>
const props = defineProps({
  user: {
    type: [Object, null],
    required: true
  }
})
</script>

<template>
<template v-if="props.user">
  {{ props.user.name }}
</template>
</template>

<style scoped>

</style>