<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import AffiliateDefaultInfo from "@/components/drawer/partial/AffiliateDefaultInfo.vue";
import AffiliateAddressInfo from "@/components/drawer/partial/AffiliateAddressInfo.vue";
import AffiliatePriceInfo from "@/components/drawer/partial/AffiliatePriceInfo.vue";
import AffiliateSalesInfo from "@/components/drawer/partial/AffiliateSalesInfo.vue";
import AffiliateEmployeeInfo from "@/components/drawer/partial/AffiliateEmployeeInfo.vue";
import AffiliateOperationHour from "@/components/drawer/partial/AffiliateOperationHour.vue";

const props = defineProps({
  title: {type: String, required: false, default: '고객 상세'},
  open: {type: Boolean, required: true},
  affiliate: {type: [null, Object, Number], required: true},
  initialTab: {type: String, required: false, default: 'default'},
})

const emits = defineEmits(['close', 'update:affiliate', 'create:employee'])

const close = () => {
  emits('close')
}
const tabItems = [
  {name: '기본정보', key: 'default'},
  {name: '주소', key: 'address'},
  {name: '영업시간', key: 'operationhour'},
  {name: '임직원', key: 'employee'},
  {name: '영업', key: 'sales'},
  {name: '가격', key: 'price'},
  {name: '메모', key: 'note'},
  {name: '파일', key: 'file'},
  {name: 'SMS', key: 'sms'},
  {name: '히스토리', key: 'history'},
]

const update = (affiliate) => {
  emits('update:affiliate', affiliate)
}
</script>

<template>
  <DrawerBase :open="props.open" :target="props.affiliate" :title="props.title" @close="close" :tab-items="tabItems" :initialTab="initialTab">
    <template #default>
      <AffiliateDefaultInfo :affiliate="props.affiliate" @update:affiliate="update"/>
    </template>
    <template #employee>
      <AffiliateEmployeeInfo :affiliate="props.affiliate" @create="emp => emits('create:employee', emp)"/>
    </template>
    <template #address>
      <AffiliateAddressInfo :affiliate="props.affiliate"/>
    </template>
    <template #operationhour>
      <AffiliateOperationHour :affiliate="props.affiliate"/>
    </template>
    <template #sales>
      <AffiliateSalesInfo :affiliate="props.affiliate"/>
    </template>
    <template #price>
      <AffiliatePriceInfo :affiliate="props.affiliate"/>
    </template>
  </DrawerBase>
</template>