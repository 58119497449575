<script setup>

import Toggle from "@/components/forms/Toggle.vue";
import {ref} from "vue";
import generateUuidString from "@/utils/generateUuidString.js";

const props = defineProps({
  checked: {type: Boolean, required: true},
  disabled: {type: Boolean, required: false, default: false},
})

const emits = defineEmits(['update:value'])

const uid = ref(generateUuidString())

const setValue = (value) => {
  emits('update:value', value)
}
</script>

<template>
  <Toggle :disabled="props.disabled" :enabled="props.checked"
          class="mt-4" :name="`permission-toggle-${uid}`"
          @update:value="value => setValue(value)">
    <template #label>
    <slot/>
<!--      <span class="text-sm font-medium text-gray-900">관리자 권한</span> {{ ' ' }}-->
<!--      <span class="text-sm text-gray-500">전체 기능에 접근 가능한 권한</span>-->
    </template>
  </Toggle>
</template>

<style scoped>

</style>