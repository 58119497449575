<script setup>

import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {onMounted, reactive, ref, watch} from "vue";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {Severities} from "@/constant.js";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";
import CompanySelector from "@/components/selector/apiSelector/CompanySelector.vue";
import VehicleSelector from "@/components/selector/apiSelector/VehicleSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
  work: {type: [null, Object], required: true, default: null},
  doTransition: {type: Function, required: true}
})
const emits = defineEmits(['update', 'close'])

const data = reactive({
  logistic_company: props.work?.logistic_company || null,
  vehicle: props.work?.vehicle || null,
  driver: props.work?.driver || null,
})

const assignData = (original) => {
  data.logistic_company = original?.logistic_company || null
  data.vehicle = original?.vehicle || null
  data.driver = original?.driver || null
}

onMounted(() => {
  assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  } else if (newValue) {
    assignData(newValue)
  }
}, {deep: true})

const model = {
  logistic_company: {externalValidator},
  vehicle: {
    required: requiredWithMessage('차량을 선택해주세요.')
  },
  driver: {
    required: requiredWithMessage('기사를 선택해주세요.')
  }
}

watch(() => data.vehicle, (newVehicle, oldVehicle) => {
  if (newVehicle === oldVehicle) return null
  if (newVehicle) {
    data.logistic_company = newVehicle?.company_use
    data.driver = newVehicle?.driver
  } else {
    data.logistic_company = null
    data.vehicle = null
  }
})

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})

const save = async () => {
  let valid = await validator.value.$validate()
  if (!valid) {
    return null
  }
  let prepare = {logistic_company: data.logistic_company.id, vehicle: data.vehicle.id, driver: data.driver.id}
  await props.doTransition('assign_driver', prepare)
  // let response = await useAuthAxios({
  //   method: 'POST',
  //   url: `/app/v1/work/small/${props.work.id}/waste/`,
  //   data: {
  //     waste_class: data.waste_class.value,
  //     waste_type: data.waste_type ? data.waste_type.value : null,
  //     volume: data.volume.value,
  //     notify: notify
  //   }
  // }, false, $externalResults.value)
  // layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
  // validator.value.$reset()
  // emits('update', response)
  return data
}

const close = () => {
  validator.value.$reset()
  emits('close')
}
</script>

<template>
  <div class="mt-2">
    <form @submit.prevent="save">
      <div class="grid grid-cols-6 justify-end md:grid-cols-9 gap-x-4">
        <VehicleSelector v-model:vehicle="data.vehicle"
                         :errors="validator.vehicle.$errors.map(e => e.$message)"
                         :required="true" class="col-span-2 md:col-span-2" label="차량"/>
        <EmployeeSelector v-model="data.driver" :errors="validator.driver.$errors.map(e => e.$message)"
                          :is_driver="true"
                          :messages="['인건비 정산의 대상']" :required="true" class="col-span-2 md:col-span-2"
                          label="기사"/>
        <AffiliateSelector v-model="data.logistic_company" :required="true"
                           :errors="validator.logistic_company.$errors.map(e => e.$message)"
                           :messages="['외주 사용시 외주비 정산의 대상']" class="col-span-2 md:col-span-3"
                           label="사용 회사"/>
        <div class="col-span-2 flex justify-start gap-x-2">
            <button class="btn btn-primary" type="button" @click="save">배정</button>
            <button class="btn btn-error" type="button" @click="close">취소</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>