<script setup>
import {ListBulletIcon} from "@heroicons/vue/24/outline";
import {nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {watchDebounced} from "@vueuse/core";
import MapTypeSelector from "@/components/selector/choices/MapTypeSelector.vue";
import DrawerCore from "@/components/drawer/DrawerCore.vue";
import Supercluster from "supercluster";


const route = useRoute()

const props = defineProps({
  entityName: {type: String, default: '항목', required: false},
  entityUnit: {type: String, default: '개', required: false},
  openAction: {type: [Function, null], default: null, required: false},
  tableActions: {type: Array, default: [], required: false},
  fetch: {type: Function, required: true},
  queryFilter: {
    type: [Function], required: false, default: () => {
    }
  },
  toggleMap: {type: [Function, null], required: false, default: null},
  lat: {type: [String, Number, null], default: 37.5405697, required: false},
  lng: {type: [String, Number, null], default: 127.05501479, required: false},
})

const map = ref(null)
const points = ref([])

const markers = ref([])
const clusters = ref([])
const boundingBox = ref([])

const clusterInstance = ref(null)

const loadMap = () => {
  let latlng = new naver.maps.LatLng(props.lat, props.lng)//
  map.value = new naver.maps.Map("map_div", {
    center: latlng,
    zoomControl: true,
    zoomControlOptions: {
      position: naver.maps.Position.TOP_LEFT,
      style: naver.maps.ZoomControlStyle.SMALL
    },
    // width: "100%",
    // height: `${window.innerHeight - 52}px`,
    zoom: 12
  });
  naver.maps.Event.addListener(map.value, 'bounds_changed', (bounds) => {
    boundingBox.value = [[bounds._sw.y, bounds._sw.x], [bounds._ne.y, bounds._ne.x]]
  })
}
const fetch = async () => {
  let bounding = boundingBox.value.map(p => p.map(v => v.toString()).join(",")).join(":")
  let query = {...route.query, bounding, map: 'true'}
  points.value = await props.fetch(query)
  return points.value
}

onMounted(() => {
  document.getElementById('map_div').innerHTML = null
  loadMap()
  nextTick(async () => {
    let bounds = map.value.getBounds()
    boundingBox.value = [[bounds._sw.y, bounds._sw.x], [bounds._ne.y, bounds._ne.x]]
    clusterInstance.value = new Supercluster({
      radius: 2,
      maxZoom: 8,
    })
    await fetch()
  })
})


watchDebounced(
    boundingBox, () => {
      fetch()
    }, {debounce: 500, maxWait: 1000},
)

const calcWidth = (name) => {
  // 마커를(클러스터 X, 장소 O) 그리기 전 텍스트의 길이를 계산하는 함수
  let div = document.createElement('div')
  let classes = "text-xs p-[0.768px] text-gray-900 bg-white border-[1px] border-gray-800 text-center bg-red-800 w-fit"
  div.innerText = name.length > 13 ? name.slice(0, 10) + '...' : name
  div.classList.add(...classes.split(" "))
  document.body.appendChild(div)
  let width = div.clientWidth
  let height = div.clientHeight
  div.remove()
  return {width, height}
}

const markerDrawer = (clusterItem, index, length, maxCount, minCount) => {
  // 마커를 그리는 함수
  // 불필요한 변수 제거 필요
  let itemType = clusterItem?.properties?.cluster === true ? 'cluster' : 'point'
  let latlng = new naver.maps.LatLng(clusterItem.geometry.coordinates[1], clusterItem.geometry.coordinates[0])
  if (itemType === 'point') {
    // 클러스터링이 안된 단일 장소인 경우
    let pointName = clusterItem.properties.name
    let {width, height} = calcWidth(pointName)
    let name = pointName.length > 10 ? pointName.slice(0, 7) + '...' : pointName
    let content = `<div class="text-xs p-[1px] text-gray-900 bg-white border-[1px] border-gray-800 truncate text-center hover:z-50">${name}</div>`
    content += '<div class="arrow-down" style="width: 0; height: 0; border-bottom: 5px solid transparent; border-top: 5px solid #ff0000; border-left: 5px solid transparent; border-right: 5px solid transparent;"></div>'
    let marker = new naver.maps.Marker({
      position: latlng,
      label: pointName,
      map: map.value,
      icon: {
        content: content,
        size: new naver.maps.Size(width, height),
        anchor: new naver.maps.Point(0, height + 3)
      }
    });
    naver.maps.Event.addListener(marker, 'click', (event) => {
      if (props.openAction) {
        props.openAction(clusterItem.properties)
      }
    })
    return marker
  } else if (itemType === "cluster") {
    // 클러스터
    let len = clusterItem.properties.point_count
    let colorString = `rgb(100,20,${255 * (len - minCount) / (maxCount - minCount)})`
    let iconStrings = `<svg fill="${colorString}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="40" height="40">
<circle cx="120" cy="120" opacity=".6" r="70"></circle>
<circle cx="120" cy="120" opacity=".3" r="90"></circle>
<circle cx="120" cy="120" opacity=".2" r="110"></circle>
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle">${len}</text>
</svg>`
    let icon = {
      content: iconStrings,
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    }
    return new naver.maps.Marker({
      position: latlng,
      label: len,
      map: map.value,
      icon: icon
    });
  }
}

watch(points, async (newValue, oldValue) => {
  // 실제 클러스터링을 수행하는 부분
  markers.value.map(m => m.setMap(null))
  markers.value = []
  // map Zoom level 6 ~ 21
  let mapZoom = map.value.getZoom()
  // map bounding 을 계산한다.
  let mapBound = map.value.getBounds()
  // clustering 수행
  clusterInstance.value.load(
      // https://github.com/mapbox/supercluster
      newValue.map(
          v => ({type: 'Feature', properties: {...v}, geometry: {type: 'Point', coordinates: [v.lon, v.lat]}})
      )
  )
  clusters.value = clusterInstance.value.getClusters(
      [mapBound._sw._lng, mapBound._sw._lat, mapBound._ne._lng, mapBound._ne._lat], mapZoom - 6
  )
  let length = clusters.value.length
  let extended = clusters.value.filter(clusterItem => clusterItem?.properties?.cluster === true).map(c => c.properties.point_count)
  let maxCount = Math.max(...extended)
  let minCount = Math.min(...extended)
  markers.value = clusters.value.map((c, index) => markerDrawer(c, index, length, maxCount, minCount))
}, {deep: true})

watch(() => route.query, newQuery => nextTick(async () => await fetch()), {deep: true})

// 지도 타입
const mapType = ref('normal')

watch(mapType, (newValue, oldValue) => {
  if (newValue === oldValue) return null
  if (typeof newValue === 'string') {
    map.value.setMapTypeId(newValue || 'normal')
  }  else {
    map.value.setMapTypeId(mapType.value?.value || 'normal')
  }
})

// 거리뷰 레이어
// const panorama = ref(null)
// const streetLayer = ref(null)
// const streetLayerVisible = ref(false)
// const panoMarker = ref(null)
//
// const initPanorama = () => {
//   console.log('으 아아아')
//     panorama.value = new naver.maps.Panorama("pano", {
//         position: new naver.maps.LatLng(37.3599605, 127.1058814),
//         pov: {
//             pan: -135,
//             tilt: 29,
//             fov: 100
//         },
//         minScale: 0, // default: 0
//         maxScale: 5  // default: 10
//     });
//
//     naver.maps.Event.addListener(panorama.value, "init", function() {
//         panorama.value.setVisible(true);
//     });
//
//     // naver.maps.Event.addListener(panorama.value, "pano_status", function(status) {
//     //     $("#pano_status_cell").text(status + ", " + pano.getPanoId());
//     //
//     //     if (status != naver.maps.PanoramaStatus.OK) {
//     //         alert("error");
//     //     }
//     // });
//
//     naver.maps.Event.addListener(panorama.value, "pano_changed", function() {
//         $("#id_cell").text(pano.getPanoId());
//         $("#title_cell").text(pano.getLocation().title);
//         $("#address_cell").text(pano.getLocation().address);
//         $("#photodate_cell").text(pano.getLocation().photodate);
//         console.log('Position: ' + pano.getPosition());
//     });
//
//     // naver.maps.Event.addListener(panorama.value, "pov_changed", function() {
//     //     $("#pan_cell").text(pano.getPov().pan);
//     //     $("#tilt_cell").text(pano.getPov().tilt);
//     //     $("#fov_cell").text(pano.getPov().fov);
//     //
//     //     $("#zoom_cell").text(pano.getZoom());
//     //     $("#min_zoom_cell").text(pano.getMinZoom());
//     //     $("#max_zoom_cell").text(pano.getMaxZoom());
//     //
//     //     $("#scale_cell").text(pano.getScale());
//     //     $("#min_scale_cell").text(pano.getMinScale());
//     //     $("#max_scale_cell").text(pano.getMaxScale());
//     //
//     //     updateZoomState();
//     // });
// }
// // naver.maps.onJSContentLoaded = initPanorama;
//
// const toggleStreeLayer = () => {
//   if (streetLayerVisible.value === true) {
//     streetLayer.value.setMap(null);
//     streetLayer.value = null
//   } else {
//     streetLayer.value = new naver.maps.StreetLayer()
//     streetLayer.value.setMap(map.value);
//     // const latlng = map.value.getCenter()
//     // panorama.value = new naver.maps.Panorama("panorama", {
//     //   position: latlng,
//     //   pov: {
//     //     pan: -133,
//     //     tilt: 0,
//     //     fov: 100
//     //   }
//     // })
//     // 파노라마 위치가 갱신되었을 때 발생하는 이벤트를 받아 지도의 중심 위치를 갱신합니다.
//     // naver.maps.Event.addListener(panorama.value, 'pano_changed', function () {
//     //   var latlng = panorama.value.getPosition();
//     //   if (!latlng.equals(map.value.getCenter())) {
//     //     map.value.setCenter(latlng);
//     //   }
//     //
//     //   // 지도를 클릭했을 때 발생하는 이벤트를 받아 파노라마 위치를 갱신합니다. 이때 거리뷰 레이어가 있을 때만 갱신하도록 합니다.
//     //   if (panoMarker.value) {
//     //     panoMarker.value.setMap(null)
//     //   }
//     //   panoMarker.value = new naver.maps.Marker({
//     //     position: latlng,
//     //     // icon: Tmapv3.asset.Icon.get('b_m_a'),
//     //     // label: point.name,
//     //     map: map.value
//     //   });
//     // });
//   }
//   streetLayerVisible.value = !streetLayerVisible.value
// }

const showFilter = ref(false)
const toggleFilter = () => {
  showFilter.value = !showFilter.value
}

const queryFilter = async () => {
  await props.queryFilter()
  showFilter.value = false
}
</script>

<template>
  <!-- 액션 바 시작 -->
  <div class="flex px-4 h-[52px] min-h-[52px]">
    <!--    <div class="flex items-center">-->
    <!--      <template v-if="props.tableActions.length > 0">-->
    <!--        <DropdownComponent :itemList="props.tableActions" position-class="top-8 left-0 mr-5"/>-->
    <!--      </template>-->
    <!--      <div class="flex flex-col items-end"><span-->
    <!--          class="text-sm text-slate-900">{{ props.entityName }} {{ points.length || 0 }}{{-->
    <!--          props.entityUnit-->
    <!--        }}</span></div>-->
    <!--    </div>-->
    <div class="flex grow items-center justify-end gap-x-2">
      <div class="hidden md:flex grow items-center overflow-x-auto h-full">
        <form
            class="flex flex-col md:flex-row grow items-start md:items-center space-y-3 md:space-y-0 md:space-x-5 pl-0 md:pl-1"
            method="get" @submit.prevent="props.queryFilter">
          <slot name="filter"/>
        </form>
      </div>
      <button class="md:hidden mr-2 btn btn-primary" @click="toggleFilter">
        검색
      </button>
      <div class="flex grow items-center justify-end ml-4 overflow-x-auto h-full">
        <template v-if="props.toggleMap">
          <MapTypeSelector v-model="mapType" class="mr-2" label="지도"/>
          <button class="btn btn-secondary" type="button" @click="props.toggleMap">
            <ListBulletIcon class="size-5"/>
          </button>
        </template>
      </div>
    </div>
  </div>
  <!-- 액션 바 끝 -->
  <div id="mapParent"
       class="border border-slate-300 cursor-pointer border-t-slate-400 flex h-[calc(100%_-_52px)] flex-col overflow-auto border-x-0 border-b-0">
    <div id="map_div" class="h-full"></div>
  </div>
  <DrawerCore :open="showFilter" :title="`${props.entityName} 검색`" w-class="w-72" @close="toggleFilter">
    <form
        class="flex flex-col md:flex-row grow items-start md:items-center space-y-3 md:space-y-0 md:space-x-5 pl-0 md:pl-1"
        method="get" @submit.prevent="queryFilter">
      <slot name="filter"/>
    </form>
  </DrawerCore>
</template>

<style scoped>
</style>