<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, onBeforeMount, onMounted, ref, toRef, watch} from "vue";
import StaticSelectorBase from "@/components/selector/base/StaticSelectorBase.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";

const props = defineProps({
  choiceType: {type: String, required: true},
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: true, default: '입력'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
})

const emits = defineEmits(['update:options'])

const model = defineModel()

const options = ref([])

onMounted(async () => {
  options.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/common/choice/`,
    params: {type: props.choiceType}
  }, false)
  model.value = converter(model.value)
  emits('update:options', options.value)
})

watch(model, (newValue, oldValue) => {
  if (newValue === oldValue) {return null}
  model.value = converter(newValue)
})

const converter = (value) => {
  if (typeof value === 'string') {
    return options.value.find(option => option.value === value) || null
  }
  return value
}
const toRepresentation = (obj) => {
  return obj.label
}

</script>

<template>
  <StaticSelectorBase v-model="model" :options="options"
                      :class="props.class" :labelClass="props.labelClass"
                      :inputClass="props.inputClass" :name="props.name"
                      :label="props.label" :placeholder="props.placeholder"
                      :required="props.required" :errors="props.errors" :readonly="props.readonly"
                      :messages="props.messages" :autoSelect="props.autoSelect"
                      :nullable="props.nullable"
                      :toRepresentation="toRepresentation"/>
</template>

<style scoped>
</style>