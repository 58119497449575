<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRef, watch} from 'vue'

import {Severities, Sizes} from "@/constant.js";
import ModalBase from "@/components/modals/ModalBase.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {Dialog, DialogPanel, DialogTitle, TransitionRoot} from "@headlessui/vue";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import {MapIcon} from "@heroicons/vue/24/solid/index.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import MapModal from "@/components/modals/MapModal.vue";
import TextInput from "@/components/forms/TextInput.vue";
import DrawerCore from "@/components/drawer/DrawerCore.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['select', 'close'])


const close = () => {
    emits('close')
    query.value = ''
    page.value = 1
    poiData.value = null
    poiList.value = []
}


const select = async (addressObject) => {
    emits('select', addressObject)
    close()
}

const addressSearchInput = ref(null)

watch(() => props.isOpen, (newValue) => {
    if (newValue === true) {
        nextTick(() => {
            addressSearchInput.value.focus()
        })
    }
})

const query = ref('')
const page = ref(1)
const poiData = ref(null)
const poiList = ref([])

const setQuery = (event) => {
    query.value = event.target.value
}

const search = async () => {
    if (query.value === '') {
        layoutStore.addErrorNotification('검색어를 입력하세요.', null, 1000)
        return null
    }
    page.value = 1
    poiList.value = []
    await fetch()
}

const paging = async (pageno) => {
    page.value = pageno
    await fetch()
}

const fetch = async () => {
    poiData.value = await useAuthAxios({
        "method": "GET",
        "url": `/app/v1/common/poi/`,
        "params": {
            "query": query.value,
            "page": page.value
        }
    }, false)
    poiList.value = poiData.value.pois
}
const fields = {
    'select': {
        label: '선택', width: 60, type: 'button', severity: Severities.PRIMARY,
        action: (row) => select(row)
    },
    'map': {
        label: '지도', width: 80, type: 'button', severity: Severities.SECONDARY,
        action: (row) => openMap(row), icon: MapIcon
    },
    'tmap_name': {label: '명칭', width: 200, type: 'text'},
    'full_address': {label: '주소', width: 200, type: 'text'},
    'tel_no': {label: '전화번호', width: 150, type: 'text'},
}

// 지도 표시
const lat = ref(null)
const lng = ref(null)
const address = ref(null)

const showMap = ref(false)
const openMap = (row) => {
    lat.value = row.noor_lat
    lng.value = row.noor_lon
    address.value = row.full_address
    showMap.value = true
}

const closeMap = () => {
    showMap.value = false
    lat.value = null
    lng.value = null
    address.value = null
}
</script>

<template>
    <DrawerCore :open="props.isOpen" title="주소 검색" @close="close">
        <div class="overflow-auto p-4">
            <form class="flex w-full mb-4" @submit.prevent="search">
                <TextInput ref="addressSearchInput" v-model="query" class="flex-grow" label="검색"
                           placeholder="주소, 장소명 등 검색"/>
                <button class="btn btn-primary ml-2" type="submit">검색</button>
            </form>
            <div class="w-full">
                <div class="text-sm">총 {{ (poiData?.total || 0).toLocaleString() }}개</div>
                <InlineTableComponent :fields="fields" :rows="poiList"/>
                <MapModal :address="address" :lat="lat" :lng="lng" :show="showMap" @close="closeMap"/>
                <div class="flex items-center justify-between mt-2">
                    <button :disabled="page === 1" class="btn btn-primary" @click="paging(page-1)">이전</button>
                    <div v-if="poiData" class="grow flex items-center justify-center gap-x-1">
                        <template v-for="pageNo in poiData.page_list">
                            <button :class="[pageNo === page ? 'btn-primary':'btn-secondary']" class="btn"
                                    @click="paging(pageNo)">
                                {{ pageNo }}
                            </button>
                        </template>
                    </div>
                    <button :disabled="!poiData?.next_page" class="btn btn-primary" @click="paging(page+1)">다음</button>
                </div>
            </div>
        </div>
    </DrawerCore>
</template>

<style scoped>

</style>