<script setup>
import TextInput from "@/components/forms/TextInput.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import {formatDateTime} from "@/utils/datetime.js";
import FileInlineDropzone from "@/components/forms/FileInlineDropzone.vue";
import dayjs from "dayjs";
import useAuthAxios from "@/composables/useAuthAxios.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import {Severities} from "@/constant.js";
import fileUpload from "@/composables/useFileUpload.js";
import ConfirmDialogComponent from "@/components/commons/Confirm/ConfirmDialogComponent.vue";
import {useViewer} from "@/components/commons/Viewer/useViewer.js";
import {useConfirm} from "@/components/commons/Confirm/useConfirm.js";

const props = defineProps({
    bill: {type: [null, Object], required: true, default: null}
})
const emits = defineEmits(['update', 'create'])

const update = (data) => {
    emits('update', data)
}


watch(() => props.bill, (newValue, oldValue) => {
    if (newValue === oldValue) {
        return null
    }
    // clear()
}, {deep: true})

const baseData = {
    "receipt": null,
    "receipt_issue_date": dayjs().format('YYYY-MM-DD'),
    "receipt_no": null,
    "receipt_memo": null,
}
const data = reactive({
    ...baseData
})

const clear = () => {
    Object.assign(data, baseData)
}

const model = {
    receipt_no: {required: requiredWithMessage('증빙 번호를 입력해주세요.')},
    receipt_issue_date: {required: requiredWithMessage('증빙 발급 일자를 입력해주세요.')},
    receipt: {required: requiredWithMessage('증빙 파일은 필수 항목입니다.')},
    receipt_memo: {externalValidator},
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})

const saveBillRegister = async () => {
    validator.value.$reset()
    validator.value.$clearExternalResults()
    let isValid = await validator.value.$validate()
    if (!isValid) return
    const fileUploadResult = await fileUpload(data.receipt)
    let temp = {
        no: data.receipt_no,
        register: fileUploadResult.id,
        issue_date: data.receipt_issue_date,
        memo: data.receipt_memo,
    }
    let response = await useAuthAxios({
        method: 'POST',
        url: `/app/v1/accounting/bill/${props.bill.id}/`,
        data: temp
    })
    update()
}


const receiptReadonly = computed(() => {
    return props.bill.status !== 'S'
})

const canCancel = computed(() => {
    return props.bill.status === 'S'
})

const confirm = ref(false)

const showCancelConfirm = () => {
    // 청구서 삭제 확인
    confirm.value = true
}

const closeConfirm = () => {
    confirm.value = false
}

const cancelBill = async () => {
    // 청구서 삭제
    let response = await useAuthAxios({
        method: 'DELETE',
        url: `/app/v1/accounting/bill/${props.bill.id}/`,
    })
    update()
    confirm.value = false
}

const toLocaleString = (value) => {
    try {
        return value.toLocaleString()
    } catch (e) {
        return value
    }
}

const entryTableFields = {
    serial: {label: '매출 일련번호', sortable: false, width: 150, type: 'text'},
    code: {label: '코드', sortable: false, width: 80, type: 'text'},
    code_name: {label: '코드명', sortable: false, width: 80, type: 'text'},
    work_type_display: {label: '품목', type: 'text', width: 80},
    origin_serial: {label: '매출 일련번호', type: 'text', width: 150},
    supplier_name: {label: '공급자', type: 'text', width: 150},
    buyer_name: {label: '공급받는자', type: 'text', width: 150},
    total: {label: '합계', type: 'number', align: 'right', width: 100},
    amount: {label: '공급가액', type: 'number', align: 'right', width: 100},
    tax: {label: '세액', type: 'number', align: 'right', width: 100},
    method_display: {label: '결제방법', type: 'text', width: 100},
    memo: {label: '비고', type: 'text', width: 150},
    user_name: {label: '작성자', type: 'text', width: 100},
    created_at: {label: '등록일시', type: 'datetime', width: 150},
}

const showRegister = () => {
    // 청구 증빙 보기
    const viewer = useViewer()
    const instance = {
        filename: props.bill.register.register_name.split('/').reverse()[0],
        loadFunc: async () => {
            let response = await useAuthAxios({
                method: 'GET',
                url: `/app/v1/accounting/bill/${props.bill.id}/register/`,
            })
            return response.register ? response.register.url : null
        }
    }
    viewer.show({instances: instance})
}

const showConfirmDeleteBillRegister = ref(false)

const deleteBillRegister = async () => {
    // 청구 증빙 삭제
    let response = await useAuthAxios({
        method: 'DELETE',
        url: `/app/v1/accounting/bill/${props.bill.id}/register/`,
    })
    update()
    showConfirmDeleteBillRegister.value = false
}
</script>

<template>

    <div v-if="props.bill" class="px-2 space-y-6">
        <div class="grid grid-cols-6 md:grid-cols-8 gap-2 mb-4 gap-y-4 border-b border-gray-200 pb-4">
            <TextInput v-model="props.bill.serial" :readonly="true" class="col-span-3 md:col-span-4" label="일련번호"/>
            <TextInput v-model="props.bill.status_display" :readonly="true" class="col-span-1 md:col-span-2"
                       label="상태"/>
            <TextInput v-model="props.bill.receive_status_display" :readonly="true" class="col-span-2 md:col-span-2"
                       label="수금상태"/>


            <TextInput v-model="props.bill.supplier_name" :readonly="true"
                       class="col-span-4 md:col-span-4"
                       label="공급자"/>
            <TextInput v-model="props.bill.buyer_name" :readonly="true"
                       class="col-span-4 md:col-span-4"
                       label="공급 받는자"/>


            <TextInput v-model="props.bill.method_display" :readonly="true" class="col-span-2 md:col-span-2"
                       label="청구 방법"/>
            <TextInput v-model="props.bill.gross_total" :displayFunction="toLocaleString" :readonly="true"
                       class="col-span-2 md:col-span-2" label="총액"/>
            <TextInput v-model="props.bill.reconcile_amount" :displayFunction="toLocaleString"
                       :readonly="true" class="col-span-2 md:col-span-2" label="수금액"/>
            <TextInput v-model="props.bill.receivable_amount" :displayFunction="toLocaleString"
                       :readonly="true" class="col-span-2 md:col-span-2" label="미수금액"/>


            <TextInput v-model="props.bill.amount" :displayFunction="toLocaleString" :readonly="true"
                       class="col-span-2 md:col-span-2" label="공급가액"/>
            <TextInput v-model="props.bill.tax" :displayFunction="toLocaleString" :readonly="true"
                       class="col-span-2 md:col-span-2" label="세액"/>
            <TextInput v-model="props.bill.work_type_display" :readonly="true" class="col-span-2 md:col-span-2"
                       label="작업타입"/>
            <TextInput v-model="props.bill.user_name" :readonly="true" class="col-span-2 md:col-span-2" label="등록자"/>
            <TextInput v-model="props.bill.created_at" :display-function="formatDateTime" :readonly="true"
                       class="col-span-3 md:col-span-2" label="등록일시"/>
            <button :disabled="!canCancel" class="btn btn-error col-span-full md:col-span-2" type="button"
                    @click="showCancelConfirm">청구서 취소
            </button>
        </div>
        <template v-if="props.bill.status === 'S'">
            <form class="grid grid-cols-6 md:grid-cols-8 gap-2 mb-4 gap-y-4" @submit.prevent="saveBillRegister">
                <TextInput v-model="data.receipt_no" :errors="validator.receipt_no.$errors.map(e => e.$message)"
                           :messages="['세금계산서 혹은 현금영수증 발급 번호']"
                           :readonly="receiptReadonly" :required="true"
                           class="col-span-6 md:col-span-3" label="증빙 번호"/>
                <FileInlineDropzone v-model="data.receipt" :errors="validator.receipt.$errors.map(e => e.$message)"
                                    :messages="['세금계산서 혹은 현금영수증 파일']"
                                    :readonly="receiptReadonly" :required="true"
                                    class="col-span-3 md:col-span-3" label="증빙"/>
                <TextInput v-model="data.receipt_issue_date"
                           :errors="validator.receipt_issue_date.$errors.map(e => e.$message)"
                           :readonly="receiptReadonly"
                           :required="true"
                           class="col-span-3 md:col-span-2" label="증빙 발급 일자"/>
                <TextInput v-model="data.receipt_memo" :readonly="receiptReadonly" class="col-span-full md:col-span-6"
                           label="증빙 특이사항"/>
                <button :disabled="receiptReadonly" class="btn btn-primary col-span-full md:col-span-2" type="submit">저장
                </button>
            </form>
        </template>
        <template v-else-if="props.bill.status === 'B'">
            <div class="grid grid-cols-6 md:grid-cols-8 gap-2 mb-4 gap-y-4">
                <TextInput v-model="props.bill.register.no"
                           :messages="['세금계산서 혹은 현금영수증 발급 번호']"
                           :readonly="true"
                           class="col-span-6 md:col-span-3" label="증빙 번호"/>
                <TextInput v-model="props.bill.register.register_name.split('/').reverse()[0]" :readonly="true"
                           class="col-span-3 md:col-span-3"
                           label="증빙" @click="showRegister"/>
                <TextInput v-model="props.bill.register.issue_date"
                           :readonly="true"
                           class="col-span-3 md:col-span-2" label="증빙 발급 일자"/>
                <TextInput v-model="props.bill.register.memo" :readonly="true" class="col-span-full md:col-span-6"
                           label="증빙 특이사항"/>
                <button class="btn btn-error col-span-full md:col-span-2" type="button"
                        @click="() => {showConfirmDeleteBillRegister = true}">
                    청구증빙 삭제
                </button>
                <ConfirmDialogComponent
                    :detail="['청구증빙 삭제하시겠습니까?', '이 작업은 되돌릴 수 없습니다.']"
                    :on-accept="deleteBillRegister"
                    :on-cancel="() => showConfirmDeleteBillRegister = false"
                    :severity="Severities.ERROR"
                    :show="showConfirmDeleteBillRegister"
                    accept-string="삭제"
                    summary="청구증빙 삭제"/>
            </div>
        </template>

        <InlineTableComponent :fields="entryTableFields"
                              :rows="props.bill?.bill_entry_list ? props.bill.bill_entry_list.map(bill_entry => bill_entry.entry) : []"/>
        <ConfirmDialogComponent :on-accept="cancelBill"
                                :on-cancel="() => {confirm = false}"
                                :severity="Severities.ERROR"
                                :show="confirm" detail="이 작업은 되돌릴 수 없습니다."
                                summary="청구를 취소하시겠습니까?"/>
    </div>
</template>


<style scoped>

</style>