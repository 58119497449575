<script setup>

import dayjs from "dayjs";
import {reactive, ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import {useClipboard} from '@vueuse/core'
import DeviceDrawer from "@/components/drawer/DeviceCreateDrawer.vue";
import CallLogDrawer from "@/components/drawer/CallLogDrawer.vue";
import SmallWorkCreateDrawer from "@/components/drawer/work/small/SmallWorkCreateDrawer.vue";
import {FolderArrowDownIcon} from "@heroicons/vue/24/outline";
import SmallWorkDrawer from "@/components/drawer/work/small/SmallWorkDrawer.vue";
const router = useRouter()
const route = useRoute()

// const copyKey = (key) => {
//   const { text, copy, copied, isSupported } = useClipboard({ source: key })
//   copy(key)
//   if (copied) alert('복사되었습니다.')
// }

const selectedWork = ref(null)

const openWork = (work) => {
  selectedWork.value = work
}

const fields = reactive({
  serial: {label: '일련번호', sortable: false, width: 150, type: 'action', action: openWork},
  work_manager_name: {label: '담당자', sortable: false, width: 100, type: 'action', action: openWork},
  at_date: {label: '진입일', sortable: false, width: 100, type: 'text'},
  at_time: {label: '진입시간', sortable: false, width: 70, type: 'text'},
  campaign_name: {label: '캠페인', sortable: false, width: 120, type: 'text'},
  address: {label: '현장 주소', sortable: false, width: 200, type: 'text'},
  company_name: {label: '거래처', sortable: false, width: 100, type: 'text'},
  manager_name: {label: '담당자', sortable: false, width: 100, type: 'text'},
  manager_cellphone: {label: '담당자 연락처', sortable: false, width: 100, type: 'text'},
  waste: {label: '성상', width: 100, type: 'text'},
  volume_display: {label: '부피', width: 100, type: 'text'},
  arrival: {label: '하차지', width: 100, type: 'text'},
  // arrival: {label: '하차지', sortable: false, width: 100, type: 'text'},
  status_display: {label: '작업상태', sortable: false, width: 100, type: 'text'},
  billing_status_display: {label: '청구상태', sortable: false, width: 100, type: 'text'},
  closing_status_display: {label: '종결상태', sortable: false, width: 100, type: 'text'},
  // manager: {label: '업무담당자', sortable: false, width: 100, type: 'user'},
  registerer_name: {label: '등록', sortable: false, width: 100, type: 'text'},
  performance_date: {label: '기준일', sortable: false, width: 120, type: 'date'},
})
// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]
const navigateToItem = () => {
  return ''
}
// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const tableKey = ref(1)

const fetch = async (query = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/work/small/`,
    params: query,
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  let temp = JSON.parse(JSON.stringify(data))
  temp.address = [temp.address, temp.address_detail].join(" ") || null
  temp.manager_cellphone = data.manager_cellphone ? formatPhoneNumber(data.manager_cellphone) : null
  // let departure_waypoint = data.waypoint_set.filter(waypoint => waypoint.type === "D")[0]
  // let arrival = data.waypoint_set.filter(waypoint => waypoint.type === "A")[0]
  //
  // let departure_full_address = departure_waypoint?.site?.poi?.full_address || null
  // let departure_address_detail = departure_waypoint?.site?.detail || null
  // let departure_address = [departure_full_address, departure_address_detail].filter(part => part !== null).join(' ') || null
  //
  // data['at_date'] = departure_waypoint?.at_datetime?.date || null
  // data['at_time'] = departure_waypoint?.at_datetime?.time || null
  // data['company'] = departure_waypoint?.site?.company?.name
  // data['departure'] = departure_address
  // data['departure_company'] = departure_waypoint?.site?.company?.name || null
  // data['departure_manager'] = departure_waypoint?.site || null
  temp['waste'] = [data.waste_class_display, data.waste_type_display].filter(part => typeof part === 'string').join('/') || null
  // data['manager_name'] = departure_waypoint?.site?.manager_name || departure_waypoint?.site?.manager?.customer?.name
  // let cellphone = departure_waypoint?.site?.manager_cellphone || departure_waypoint?.site?.manager?.customer?.cellphone
  // data['manager_cellphone'] = cellphone ? formatPhoneNumber(cellphone) : null
  // // data['departure_manager'] = departure_waypoint?.site?.manager_name || null
  // // data['departure_manager_cellphone'] = departure_waypoint?.site?.manager_cellphone ? formatPhoneNumber(departure_waypoint?.site?.manager_cellphone) : null
  // data['arrival'] = arrival?.company?.name || null
  // // data['number'] = formatPhoneNumber(data.number)
  // // data['type_display'] = data.missed_reason === "0" ? data.call_type_display : `${data.call_type_display}(${data.missed_reason_display})`
  // // data['manager_name'] = data.manager_name ? data.manager_name : '미지정'
  // // data['process'] = data.processed_at !== null
  temp['arrival'] = data.arrival?.company_name || null
  return temp
}
// let a = {"id": 29,
//   "company": {
//     "id": 18,
//     "company_type": "localgov",
//     "company_type_detail": null,
//     "name": "asdf",
//     "brn": null,
//     "tel": null,
//     "description": null,
//     "is_active": true,
//     "is_subsidiary": false,
//     "company_type_display": "지자체"
//   },
//   "manager": null,
//   "manager_name": null,
//   "manager_cellphone": null,
//   "poi": null,
//   "detail": null,
//   "description": null
// }


const queryList = () => {
  let query = {...route.query}
  router.push({query: query})
}

// drawer
const update = () => {
  tableKey.value += 1
}

const isOpenCreate = ref(false)
// const isOpenCreate = ref(true)

const showCreate = () => {
  isOpenCreate.value = true
}

const closeCreate = () => {
  isOpenCreate.value = false
}

const create = (workData) => {
  openWork(workData)
}
</script>

<template>
  <!--  <CallLogDrawer v-if="selectedCallLog" :open="selectedCallLog !== null" :callLog="selectedCallLog" @close="setCallLog(null)" @update="update"/>-->
  <!--  <DeviceDrawer :open="sele" title="기기 상세" @close="hideDeviceCreate"/>-->
  <DefaultLayoutBase title="소형배차 / 목록">
    <template #body>
      <TableComponent :key="tableKey" :dataFilter="dataFilter" :fetch="fetch"
                      :fields="fields" :open-action="openWork"
                      :open-add="showCreate" :query-filter="queryList" @update="update"
                      :rowActions="rowActions" :rowsAction="rowsAction" :tableActions="tableActions"
                      entityName="업무" entityUnit="건">
      </TableComponent>
    </template>
  </DefaultLayoutBase>
  <SmallWorkCreateDrawer :open="isOpenCreate" @close="closeCreate" @create:smallWork="update"/>
  <SmallWorkDrawer :work="selectedWork" :open="selectedWork !== null" @close="selectedWork = null" @update="update" @create="create"/>
</template>