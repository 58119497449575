<script setup>

import {nextTick, onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator, dateStringValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

import dayjs from "dayjs";
import {formatDateTime} from "../../../utils/datetime.js";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import TextArea from "@/components/forms/TextArea.vue";


onMounted(async () => {
  await fetch()
})
const layoutStore = useLayoutStore()

const props = defineProps({
  campaign: {type: [null, Object], required: true}
})

const emits = defineEmits(['update:campaign'])

const data = reactive({
  name: '',
  // start_date: '',
  // end_date: '',
  work_type: null,
  memo: '',
  manager: null
})

const registerer = ref(null)

const fetch = async () => {
  const response = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/campaign/${props.campaign.id}/`
  })
  data.name = response.name
  // data.start_date = response.start_date
  // data.end_date = response.end_date
  data.work_type = response.work_type
  data.memo = response.memo
  data.manager = response?.manager || null
  registerer.value = response.registerer
  // data.registerer = response.registerer
  // let temp = {
  //   "id": 4,
  //   "name": "ㅁㄴㅇㄹ",
  //   "start_date": null,
  //   "end_date": null,
  //   "memo": null,
  //   "manager": {
  //     "id": "07a9f9e3-ae0d-45cd-9b25-afabf1e3269e",
  //     "email": "margarite.cadogan@1coolplace.com",
  //     "name": "홍길동234"
  //   },
  //   "registerer": {
  //     "id": "07a9f9e3-ae0d-45cd-9b25-afabf1e3269e",
  //     "email": "margarite.cadogan@1coolplace.com",
  //     "name": "홍길동234"
  //   },
  //   "history_context": "2ba3c05c-2f6f-493e-ba29-9188ad82d90f"
  // }
}

const $externalResults = ref({})

const model = {
  name: {
    required: helpers.withMessage('캠페인명을 입력해주세요.', required),
    minLength: minLengthWithMessage('캠페인명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('캠페인명을 30글자 이하로 입력해주세요.', 30)
  },
  // start_date: {
  //   format: dateStringValidator(),
  // },
  // end_date: {
  //   format: dateStringValidator(),
  // },
  work_type: {externalValidator},
  memo: {externalValidator},
  manager: {externalValidator}
}

const validator = useVuelidate(model, data, {$externalResults})


const setName = event => {
  data.name = event.target.value
}

const setWorkType = selected => {
  data.work_type = selected
}
// const setStartDate = event => {
//   let value = event.target.value
//   if (value) {
//     data.start_date = dayjs(value).format('YYYY-MM-DD')
//   } else {
//     data.start_date = ''
//   }
// }
// const setEndDate = event => {
//   let value = event.target.value
//   if (value) {
//     data.end_date = dayjs(value).format('YYYY-MM-DD')
//   } else {
//     data.end_date = ''
//   }
// }
const setMemo = event => {
  data.memo = event.target.value
}

const prepareData = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare['work_type'] = prepare.work_type ? prepare.work_type.value : null
  prepare['manager'] = value?.manager?.id || null
  return prepare
}

const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    const campaign = await useAuthAxios({
      method: 'POST',
      url: `/app/v1/campaign/${props.campaign.id}/`,
      data: prepareData(data)
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    emits('update:campaign')
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>

<template>
  <form class="w-full p-4 mb-8" @submit.prevent="save">
    <TextInput v-model="data.name" :errors="validator.name.$errors.map(e => e.$message)" label="캠페인명" class="mb-4" :required="true"/>
    <!--    <div :class="{invalid: validator.start_date.$error}" class="input-group mb-4">-->
    <!--      <label class="w-16" for="id-for-start_date">시작일시</label>-->
    <!--      <div>-->
    <!--        <input id="id-for-start_date" ref="startDateInput" :value="data.start_date" data-1p-ignore name="start_date"-->
    <!--               type="date" @input="setStartDate">-->
    <!--        <template v-for="error in validator.start_date.$errors">-->
    <!--          <p class="error">{{ error.$message }}</p>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div :class="{invalid: validator.end_date.$error}" class="input-group mb-4">-->
    <!--      <label class="w-16" for="id-for-end_date">종료일시</label>-->
    <!--      <div>-->
    <!--        <input id="id-for-end_date" ref="startDateInput" :value="data.end_date" data-1p-ignore name="end_date"-->
    <!--               type="date" @input="setEndDate">-->
    <!--        <template v-for="error in validator.end_date.$errors">-->
    <!--          <p class="error">{{ error.$message }}</p>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </div>-->
    <ChoiceSelectorBase v-model="data.work_type" choice-type="WorkTypeChoice" label="타입" class="mb-4"
                          :errors="validator.work_type.$errors.map(e => e.$message)" :readonly="true"/>
    <TextArea v-model="data.memo" :errors="validator.memo.$errors.map(e => e.$message)" label="메모" class="mb-4"/>
    <EmployeeSelector :errors="validator.manager.$errors.map(e => e.$message)"
                          v-model="data.manager" class="mb-4"
                          label="담당자" placeholder="담당자 선택"/>
    <button class="btn btn-primary" type="submit">저장</button>
  </form>

<!--  <div class="px-4 input-group mb-4">-->
<!--    <label class="w-16" for="id-for-registerer">최초 등록자</label>-->
<!--    <div class="text-sm">-->
<!--      {{ props?.campaign?.registerer?.name }}-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="px-4 input-group mb-4">-->
<!--    <label class="w-16" for="id-for-registerer">등록일</label>-->
<!--    <div class="text-sm">-->
<!--      {{ props?.campaign?.created_at ? formatDateTime(props.campaign.created_at) : '-' }}-->
<!--    </div>-->
<!--  </div>-->

</template>

<style scoped>

</style>