<script setup>

import dayjs from "dayjs";
import {onMounted, watch, ref, computed} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute} from "vue-router";


const route = useRoute()
const data = ref(null)

const fetchData = async () => {
  data.value = await useAuthAxios({
    method: "GET",
    url: `/app/v1/dashboard/weekly_affiliate/`,
    params: {
      base_date: route.query.base_date || dayjs().format('YYYY-MM-DD')
    }
  })
}

// const resultData = computed(() => {
//   if (!data.value) {
//     return null
//   }
//   return data.value.weekly_affiliate.map(item => {
//     item['total'] = data.value.total_affiliate.find(total => total.manager === item.manager_id)
//     return item
//   })
// })

onMounted(() => {
  fetchData()
})

watch(() => route.query, async (newValue) => {
  await fetchData()
}, {deep: true})

const colTotal = computed(() => {
  return data.value.data.reduce((acc, item) => acc + item.total, 0)
})

const colWeekTotals = computed(() => {
  return data.value.week_no_list.map(weekData => {
    return data.value.data.reduce(
        (acc, item) => {
          return acc + (item.weeks.filter(week => week.week_no === weekData.week_no)[0]?.new_count || 0)
        }, 0
    )
  })
})
</script>

<template>
  <div class="font-semibold text-sm mb-2">주간 신규 거래처 등록 현황</div>
  <div v-if="data" class="border border-slate-300 border-t-slate-400 mt-2 w-full overflow-auto">
    <table class="text-sm w-full">
      <thead class="bg-slate-100 border-slate-400 border-b min-w-fit text-gray-900 font-normal">
      <tr class="text-gray-800">
        <th class="font-semibold" rowspan="2">성명</th>
        <th class="font-semibold" rowspan="2">{{data.current.year}}년 계</th>
<!--        <th class="font-semibold" rowspan="2">{{data.current.month}}월 계</th>-->
        <th v-for="week in data.week_no_list" class="font-semibold">{{ week.week_no }} 주차</th>
      </tr>
      <tr>
        <th v-for="week in data.week_no_list" class="font-normal text-xs">
          {{ week.start_date }} ~ {{ week.end_date }}
        </th>
      </tr>
      </thead>
      <tbody class="bg-white min-w-fit text-gray-800">
      <tr v-for="result in data.data" v-bind:key="result">
        <template v-if="result.manager.name">
          <td>{{ result.manager.name || '미지정' }}</td>
          <td>{{ result.total.toLocaleString() }}</td>
          <template v-for="weekData in data.week_no_list">
            <td>{{ result.weeks.filter(week => week.week_no === weekData.week_no)[0]?.new_count.toLocaleString() }}</td>
          </template>
        </template>
      </tr>
      <tr v-for="result in data.data" v-bind:key="result">
        <template v-if="!result.manager.name">
          <td>{{ result.manager.name || '미지정' }}</td>
          <td>{{ result.total.toLocaleString() }}</td>
<!--          <td>월계</td>-->
          <template v-for="weekData in data.week_no_list">
            <td>{{ result.weeks.filter(week => week.week_no === weekData.week_no)[0]?.new_count.toLocaleString() }}</td>
          </template>
        </template>
      </tr>
      <tr>
        <td class="font-semibold">계</td>
        <td class="font-semibold">{{colTotal.toLocaleString()}}</td>
<!--          <td>월계</td>-->
        <td class="font-semibold" v-for="colWeekTotal in colWeekTotals">
          {{colWeekTotal.toLocaleString()}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

th {
  @apply px-3 py-1 text-left border-r border-slate-200
}
td {
  @apply px-3 py-1 text-right border-r border-slate-200
}

td:first-child {
  @apply text-left
}

td:last-child {
  @apply border-r-0
}

table > tbody > tr {
  @apply border-b  border-slate-300
}
table > tbody > tr:last-child {
  @apply border-b-0
}

</style>