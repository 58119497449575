<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import InlinePagingTableComponent from "@/components/Inlinepagingtable/InlinePagingTableComponent.vue";
import FileViewer from "@/components/viewer/FileViewer.vue";
import FileDropzone from "@/components/forms/FileDropzone.vue";
import useFileUpload from "@/composables/useFileUpload.js";
import {useViewer} from "@/components/commons/Viewer/useViewer.js";

const layoutStore = useLayoutStore()

const props = defineProps({
    historyContextId: {type: [null, String], required: true}
})
const emits = defineEmits(['update:total'])

const data = ref([])
const viewerPosition = ref(null)

const fetchFiles = async (query) => {
    return await useAuthAxios({
        method: "GET",
        url: `/app/v1/history/${props.historyContextId}/file/`,
        params: {...query, ...(query.page ? query.page : 1)}
    })
}

const fileUrl = ref(null)
const viewerOpen = ref(false)
const viewerFilename = ref(null)

const showFile = async (row) => {
    const viewer = useViewer()
    const viewerOption = {
        instances: data.value.map(file => {
            let loadFunc = async () => {
                const response = await useAuthAxios({
                    method: "GET",
                    url: `/app/v1/history/${props.historyContextId}/file/${file.id}/`,
                })
                return response.file.file
            }
            return {
                id: file.id,
                filename: file.file,
                loadFunc: loadFunc
            }
        }),
        selected: {
            id: row.id,
            filename: row.file,
            loadFunc: async () => {
                const response = await useAuthAxios({
                    method: "GET",
                    url: `/app/v1/history/${props.historyContextId}/file/${row.id}/`,
                })
                return response.file.file
            }
        },
        parent: viewerPosition.value
    }
    viewer.show(viewerOption)
}

const tableField = {
    file: {label: '파일명', width: 300},
    showDownload: {label: '보기', width: 100, type: 'button', action: showFile},
    writer: {label: '업로더', width: 100},
    created_at: {label: '등록일시', width: 100, type: 'datetime'},
}

// 업로드
const tableKey = ref(1)

const tableFilter = (row) => {
    return {
        file: row.file,
        writer: row.writer.name,
        created_at: row.created_at,
    }
}

const fileList = ref([])

const setFiles = (files) => {
    fileList.value = files ? files : []
}

const uploadProgress = ref({})

const uploadFile = async () => {
    if (fileList.value.length === 0) {
        layoutStore.addErrorNotification('파일이 선택되지 않았습니다.')
        return null
    }
    layoutStore.showOverlay()
    try {
        uploadProgress.value = {}
        const uploadedResults = []
        fileList.value.map(file => {
            uploadProgress.value[file.id] = 0
        })
        const uploadPromise = fileList.value.map(async (file) => {
            const uploaded = await useFileUpload(file, (event) => {
                uploadProgress.value[file.id] = {
                    progress: parseInt(event.progress * 100),
                    complete: event.progress === 1
                }
            })
            uploadProgress.value[file.id] = {progress: 100, complete: true}
            uploadedResults.push(uploaded)
        })
        await Promise.all(uploadPromise)
        const registerHistory = await useAuthAxios({
            method: 'PUT',
            url: `/app/v1/history/${props.historyContextId}/file/`,
            data: {files: uploadedResults.map(result => result.id)}
        })
        uploadProgress.value = {}
        fileList.value = []
        tableKey.value += 1
        layoutStore.hideOverlay()
        emits('update:total')
    } catch (e) {
        layoutStore.hideOverlay()
        throw e
    }
}
</script>

<template>
    <div class="mb-4">
        <FileDropzone :files="fileList" :uploadProgress="uploadProgress" @update:files="setFiles"/>
        <div class="flex justify-end">
            <button class="btn btn-primary" type="button" @click.stop="uploadFile">업로드</button>
        </div>
    </div>
<!--    <div>-->
<!--        <pre>{{ data }}</pre>-->
<!--    </div>-->
    <InlinePagingTableComponent :key="tableKey" :fetch="fetchFiles" :fields="tableField" :filter="tableFilter"
                                @files:update="list => data = list"/>
    <div ref="viewerPosition">
<!--        viewerPosition-->
    </div>
</template>

<style scoped>

</style>