const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 12) {
        return phoneNumber.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (phoneNumber.length === 11) {
        return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (phoneNumber.length === 10) {
        if (phoneNumber.startsWith('02')) {
            return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
    } else if (phoneNumber.length === 9) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
        return phoneNumber
    }
}

const formatBrn = (brnNumber) => {
    if (brnNumber.length === 10) {
        return brnNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    } else {
        return brnNumber
    }
}

export {formatPhoneNumber, formatBrn}