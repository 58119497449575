<script setup>

import dayjs from "dayjs";
import {ArrowPathIcon, FolderArrowDownIcon} from '@heroicons/vue/20/solid'


import {formatBrn, formatPhoneNumber} from "@/utils/formatter.js";
import {ref} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useRoute, useRouter} from "vue-router";
import EmployeeRegisterModal from "@/components/modals/EmployeeRegisterModal.vue";

const router = useRouter()
const route = useRoute()
// 컬럼 목록
const fields = {
  name: {label: '성명', sortable: false, width: 100, type: 'text'},
  company: {label: '사업장', sortable: false, width: 150, type: 'text'},
  cellphone: {label: '휴대전화번호', sortable: false, width: 150, type: 'text'},
  email: {label: 'Email', sortable: false, width: 200, type: 'text'},
  is_driver: {label: '기사', sortable: false, width: 50, type: 'boolean'},
  is_admin: {label: '관리자', sortable: false, width: 100, type: 'boolean'},
  is_active: {label: '활성', sortable: false, width: 50, type: 'boolean'},
  date_joined: {label: '가입일', sortable: false, width: 200, type: 'text'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}

// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

const navigateToItem = (item) => {
  router.push({name: 'settings-employee-detail', params: {id: item.id}})
}

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/employment/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  let user = data.user
  data['name'] = user.name
  data['company'] = data?.company ? data.company.name : ''
  data['cellphone'] = formatPhoneNumber(user.cellphone)
  data['email'] = user.email
  data['is_driver'] = data.is_driver
  data['is_active'] = data.is_active
  data['is_admin'] = data.is_admin
  data['date_joined'] = dayjs(user['date_joined']).format("YYYY년 M월 D일")
  return data
}
const searchQuery = ref(route.query.query || '')
const onlyDriver = ref(route.query.only_driver === 'on')
const onlyAdmin = ref(route.query.only_admin === 'on')
const isActive = ref(route.query.is_active === 'on')

const queryList = () => {
  let query = {...route.query}
  query['query'] = searchQuery.value
  query['only_driver'] = onlyDriver.value
  query['only_admin'] = onlyAdmin.value
  query['is_active'] = isActive.value
  router.push({query: query})
}

const tableKey = ref(1)
const update = () => tableKey.value++
</script>

<template>
  <EmployeeRegisterModal :isOpen="showAdd" title="사용자 등록" @close="() => showAdd = false"/>
  <DefaultLayoutBase title="설정 / 사용자 관리">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields" :openAction="navigateToItem"
                      :openAdd="openAdd" :query-filter="queryList" @update="update" :key="tableKey"
                      :rowActions="rowActions" :rowsAction="rowsAction" :tableActions="tableActions"
                      entityName="사용자" entityUnit="명">
        <template #filter>
            <input :value="searchQuery" @input="event => searchQuery = event.target.value" class="search-input w-full md:w-auto" name="query" placeholder="검색" type="text">
            <div class="flex items-center gap-x-1">
              <input id="id-only_driver" type="checkbox" name="only_driver" :checked="onlyDriver" @change="event=>onlyDriver=event.target.checked">
              <label for="id-only_driver" class="ml-1 text-sm">기사만</label>
            </div>
            <div class="flex items-center gap-x-1">
              <input id="id-only_admin" type="checkbox" name="only_admin" :checked="onlyAdmin" @change="event=>onlyAdmin=event.target.checked">
              <label for="id-only_admin" class="ml-1 text-sm">관리자만</label>
            </div>
            <div class="flex items-center gap-x-1">
              <input id="id-is_active" type="checkbox" name="is_active" :checked="isActive" @change="event=>isActive=event.target.checked">
              <label for="id-is_active" class="ml-1 text-sm">활성 사용자만</label>
            </div>
            <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>