<script setup>

import dayjs from "dayjs";
import {ArrowPathIcon, FolderArrowDownIcon} from '@heroicons/vue/20/solid'


import {formatBrn} from "@/utils/formatter.js";
import {ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import MapComponent from "@/components/maps/MapComponent.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const route = useRoute()

const fields = {
  campaign: {label: '캠페인명', sortable: false, width: 150, type: 'text'},
  company: {label: '거래처', sortable: false, width: 150, type: 'text'},
  customer: {label: '고객', sortable: false, width: 150, type: 'text'},
  status_display: {label: '상태', sortable: false, width: 150, type: 'text'},

  performance_date: {label: '기준일', sortable: false, width: 150, type: 'date'},
  manager: {label: '담당', sortable: false, width: 100, type: 'user'},
  registerer: {label: '등록', sortable: false, width: 100, type: 'user'},

  // end_date: {label: '종료', sortable: false, width: 150, type: 'date'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/sales/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  data['campaign'] = data.target.campaign.name
  data['company'] = data.target?.company ? data.target.company.name : '-'
  data['customer'] = data.target?.customer ? data.target.customer.name : '-'
  return data
}
//query
const searchQuery = ref(route.query.query || '')
const selectedSalesStatus = ref(route.query.status || null)
const selectedManager = ref(route.query.manager || null)
const selectedCustomer = ref(route.query.customer || null)
const selectedCampaign = ref(route.query.campaign || null)
// const selectedCompanyType = ref(route.query.company_type || '')
// const selectedManager = ref(route.query.manager || '')

const queryList = () => {
  let query = {...route.query}
  query['query'] = searchQuery.value
  query['status'] = selectedSalesStatus.value?.value ? selectedSalesStatus.value.value : null
  query['manager'] = selectedManager.value?.id || null
  router.push({query: query})
}

watch(selectedSalesStatus, async (newValue) => await queryList())
watch(selectedManager, async (newValue) => await queryList())

// drawer
const isSalesOpen = ref(false)
const selectedSales = ref(null)

const showSales = (sales) => {
  selectedSales.value = sales
  isSalesOpen.value = true
}

const hideSales = () => {
  selectedSales.value = null
  isSalesOpen.value = false
}


// map view

const mapFetch = async (query, data) => {
  let options = {
    method: 'GET',
    url: `/app/v1/sales/map/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const start_date = ref(route.query?.start_date || dayjs().format('YYYY-MM-DD'))
const end_date = ref(route.query?.end_date || dayjs().format('YYYY-MM-DD'))
const mapMode = ref(route.query.map === 'true')

const toggleMap = () => {
  mapMode.value = !mapMode.value
}

const queryMap = async (event) => {
  let query = {
    start_date: start_date.value, end_date: end_date.value
  }
  query['manager'] = selectedManager.value?.id || null
  await router.push({query: query})
}

const tableKey = ref(1)
const update = () => tableKey.value++
</script>

<template>
  <SalesDrawer v-if="selectedSales" :open="isSalesOpen" :sales="selectedSales" title="영업 상세" @close="hideSales"/>
  <DefaultLayoutBase title="영업 / 영업">

    <template #body>
      <TableComponent v-if="mapMode === false" :dataFilter="dataFilter" :fetch="fetch" :fields="fields"
                      :openAction="showSales" @update="update" :key="tableKey"
                      :query-filter="queryList" :rowActions="rowActions"
                      :rowsAction="rowsAction" :tableActions="tableActions" :toggleMap="toggleMap"
                      entityName="영업" entityUnit="건">
        <template #filter>
          <TextInput v-model="searchQuery" label="검색" class="w-full md:w-auto"/>
          <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
          <ChoiceSelectorBase v-model="selectedSalesStatus" choice-type="SalesStatus" label="진행 상태"/>
          <EmployeeSelector v-model="selectedManager" label="담당"/>
        </template>
      </TableComponent>
      <MapComponent v-else :fetch="mapFetch" :openAction="showSales" :query-filter="queryMap" :toggleMap="toggleMap"
                    entityName="영업" entityUnit="건">
        <template #filter>
          <DatePickerInput v-model="start_date" label="기간 시작"/>
          <div class="md:text-left hidden md:block">~</div>
          <DatePickerInput v-model="end_date" label="기간 끝"/>
          <EmployeeSelector v-model="selectedManager" label="담당"/>
          <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </MapComponent>
    </template>
  </DefaultLayoutBase>
</template>