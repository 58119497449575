import {defineStore} from 'pinia';
import {useLocalStorage} from "@vueuse/core";
import generateUuidString from "@/utils/generateUuidString.js";
import {nextTick} from "vue";
import {Severities} from "@/constant.js";



export const useLayoutStore = defineStore({
    id: 'layout',
    state: () => ({
        overlay: useLocalStorage('overlay', false),
        isVisibleToast: false,
        // notifications: useLocalStorage('notifications', [
        //     {id: generateUuidString(), severity: 'info', summary: 'Info Message', detail: 'PrimeVue is the new library for Vue'},
        // ]),
        notifications: [],
        // serializer 설정을 하지 않은 경우 새로고침한 후 값이 변형되는 오류 발생
        confirm: useLocalStorage('confirm', null, {serializer:  {read: JSON.parse, write: JSON.stringify}}),
        alert: useLocalStorage('alert', null, {serializer:  {read: JSON.parse, write: JSON.stringify}}),
        navOpens: useLocalStorage('navOpens', []),
        mobileNav: true
    }),
    actions: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav
        },
        // 우상단에 작게 노출되는 알림 표시
        addNotification(severity, summary, detail) {
            let uid = generateUuidString()
            let notification = {uid: uid, severity, summary, detail}
            this.notifications.push(notification)
            setTimeout(() => {
                this.removeNotification(notification)
            }, 5000)
        },
        addSuccessNotification(summary, detail) {
            this.addNotification(Severities.PRIMARY, summary, detail)
        },
        addInfoNotification(summary, detail) {
            this.addNotification(Severities.SECONDARY, summary, detail)
        },
        addErrorNotification(summary, detail) {
            this.addNotification(Severities.ERROR, summary, detail)
        },
        removeNotification(notification) {
            this.notifications = this.notifications.filter(n => n.uid !== notification.uid)
        },
        // Yes or No 선택을 요구하는 팝업
        showConfirmDialog(
            severity, summary, detail, acceptString, rejectString, acceptCallback, rejectCallback
        ) {
            this.confirm = {
                severity,  // error success info
                summary,
                detail,
                acceptString: acceptString || '확인',
                rejectString: rejectString || '취소',
                acceptCallback: acceptCallback || (() => {}),
                rejectCallback: rejectCallback || (() => {}),
            }
        },
        async confirmAccept() {
            if (this.confirm?.acceptCallback) {
                await this.confirm.acceptCallback()
            }
            this.confirm = null
        },
        async confirmReject() {
            if (this.confirm?.rejectCallback) {
                await this.confirm.rejectCallback()
            }
            this.confirm = null
        },
        // 확인만 요구하는 팝업
        showAlertDialog(
            severity, summary, detail, acceptString, acceptCallback
        ) {
            this.alert = {
                severity,
                summary,
                detail,
                acceptString: acceptString || '확인',
                acceptCallback,
            }
        },
        alertAccept() {
            if (this.alert?.acceptCallback) {
                this.alert.acceptCallback()
            }
            this.alert = null
        },
        // 로딩 오버레이
        showOverlay() {
            this.overlay = true
        },
        hideOverlay() {
            this.overlay = false
        },
        toggleOverlay() {
            this.overlay = !this.overlay
        },
    },
});