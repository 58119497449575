<script setup>

import dayjs from "dayjs";
import {ArrowPathIcon, FolderArrowDownIcon} from '@heroicons/vue/20/solid'


import {formatBrn, formatPhoneNumber} from "@/utils/formatter.js";
import {ref} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {Severities} from "@/constant.js";
import {useRoute, useRouter} from "vue-router";
import CompanyCreateModal from "@/components/modals/CompanyCreateModal.vue";

const router = useRouter()
const route = useRoute()
// 컬럼 목록
const fields = {
  name: {label: '명칭', sortable: false, width: 250, type: 'text'},
  company_type_display: {label: '타입', sortable: false, width: 100, type: 'text'},
  company_type_detail: {label: '타입 상세', sortable: false, width: 100, type: 'text'},
  tel: {label: '대표번호', sortable: false, width: 200, type: 'text'},
  brn: {label: '사업자등록번호', sortable: false, width: 200, type: 'text'},
  address: {label: '주소', sortable: false, width: 300, type: 'text'},
  description_short: {label: '설명', sortable: false, width: 300, type: 'text'},
  // has_stock: {label: '수요/공급 업체', sortable: false, width: 100, type: 'boolean'},
  // has_logistics: {label: '운반 업체', sortable: false, width: 100, type: 'boolean'},
  is_active: {label: '활성', sortable: false, width: 100, type: 'boolean'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}

// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

const navigateToItem = (item) => {
  router.push({name: 'settings-company-detail', params: {id: item.id}})
}

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/company/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  data['created_at'] = dayjs(data['created_at']).format("YYYY년 M월 D일")
  data['brn'] = data['brn'] ? formatBrn(data['brn']) : ''
  data['tel'] = data?.tel ? formatPhoneNumber(data.tel) : ''
  return data
}

const searchQuery = ref(route.query.query || '')

const queryList = () => {
  let query = {...route.query}
  query['query'] = searchQuery.value
  router.push({query: query})
}

const tableKey = ref(1)
const update = () => tableKey.value++

</script>

<template>
  <!--  <CompanyCreateModal v-model="showAdd" @close="showAdd = false" @save="fetch"/>-->
  <CompanyCreateModal :isOpen="showAdd" title="사업장 등록" @close="() => showAdd = false"/>
  <DefaultLayoutBase title="설정 / 사업장 관리">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields" :openAction="navigateToItem"
                      :openAdd="openAdd" :query-filter="queryList" @update="update" :key="tableKey"
                      :rowActions="rowActions" :rowsAction="rowsAction" :tableActions="tableActions"
                      entityName="사업장" entityUnit="개">
        <template #filter>
            <input class="search-input w-full md:w-auto" placeholder="검색" type="text" name="query" :value="searchQuery" @input="event => searchQuery = event.target.value">
            <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>