<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, onBeforeMount, onMounted, ref, toRef, watch} from "vue";
import StaticSelectorBase from "@/components/selector/base/StaticSelectorBase.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";

const props = defineProps({
  workType: {type: [String, null], required: false, default: null},
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '부피'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
})


const model = defineModel()

const options = ref([])


const fetch = async () => {
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/common/choice/`,
    params: {type: 'VolumeTypeChoice'}
  }, false)
}

onMounted(async () => {
  options.value = await fetch()
  await nextTick(() => {
    model.value = converter(model.value)
  })
})

watch(model, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  model.value = converter(newValue)
}, {deep: true})

watch(() => props.workType, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  nextTick(() => {
    model.value = converter(model.value)
  })
})

const converter = (value) => {
  if (typeof value === 'string') {
    return optionList.value.find(option => option.value === value) || null
  }
  return value
}

const optionList = computed(() => {
  if (props?.workType) {
    return options.value.filter(option => option.value.startsWith(props?.workType))
  } else {
    return options.value
  }
})


const toRepresentation = (obj) => {
  return obj.label
}
</script>

<template>
  <StaticSelectorBase v-model="model" :autoSelect="props.autoSelect"
                      :class="props.class" :errors="props.errors"
                      :inputClass="props.inputClass" :label="props.label"
                      :labelClass="props.labelClass" :messages="props.messages"
                      :name="props.name" :nullable="props.nullable"
                      :options="optionList" :placeholder="props.placeholder"
                      :readonly="props.readonly" :required="props.required"
                      :toRepresentation="toRepresentation"/>
</template>

<style scoped>
</style>