<script setup>

import {onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  cellphoneValidator, dateStringValidator,
  emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage, nullBrnValidator, phoneValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InlinePagingTableComponent from "@/components/Inlinepagingtable/InlinePagingTableComponent.vue";
import {useAuthStore} from "@/stores/authStore.js";
import {formatPhoneNumber} from "@/utils/formatter.js";
import CustomerDrawer from "@/components/drawer/CustomerDrawer.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";

const layoutStore = useLayoutStore()
const authStore = useAuthStore()
const props = defineProps({
  affiliate: {type: [null, Object], required: true}
})
const emits = defineEmits(['create'])

const data = reactive({
  employee_type: null,
  employee_type_detail: '',
  name: '',
  role: '',
  rank: '',
  email: '',
  cellphone: '',
  phone: '',
  memo: '',
  manager: props.affiliate.manager || authStore.user,
  channel: null
})


const model = {
  employee_type: {required: requiredWithMessage('필수 항목입니다.')},
  employee_type_detail: {externalValidator},
  name: {required: requiredWithMessage('필수 항목입니다.')},
  role: {externalValidator},
  rank: {externalValidator},
  email: {format: emailValidator()},
  cellphone: {format: cellphoneValidator()},
  phone: {format: phoneValidator()},
  memo: {externalValidator},
  manager: {
    required: requiredWithMessage('필수 항목입니다.')
  },
  channel: {externalValidator},
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})



const setMemo = (event) => {
  data.memo = event.target.value
}


const getPrepare = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  let prepareData = {}
  prepareData.employee_type = prepare.employee_type ? prepare.employee_type.value : null
  prepareData.employee_type_detail = prepare.employee_type_detail === '' ? null : prepare.employee_type_detail
  prepareData.company = props.affiliate.id
  prepareData.customer = {}
  prepareData.customer.name = prepare.name === '' ? null : prepare.name
  prepareData.customer.role = prepare.role === '' ? null : prepare.role
  prepareData.customer.rank = prepare.rank === '' ? null : prepare.rank
  prepareData.customer.email = prepare.email === '' ? null : prepare.email
  prepareData.customer.cellphone = prepare.cellphone === '' ? null : prepare.cellphone
  prepareData.customer.phone = prepare.phone === '' ? null : prepare.phone
  prepareData.customer.memo = prepare.memo === '' ? null : prepare.memo
  prepareData.customer.manager = prepare.manager.id
  prepareData.customer.company = props.affiliate.id
  prepareData.customer.channel = props.channel ? props.channel.value : null
  return prepareData
}
const clean = () => {
  data.employee_type = null
  data.employee_type_detail = ''
  data.name = ''
  data.role = ''
  data.rank = ''
  data.email = ''
  data.cellphone = ''
  data.phone = ''
  data.memo = ''
  data.manager = props.affiliate.manager.employment || authStore.user.employment
  data.channel = null
  validator.value.$reset()
}

const save = async () => {
  validator.value.$reset()
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) {
    return null
  }
  layoutStore.showOverlay()
  try {
    const employeeResponse = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/affiliate/employee/`,
      data: getPrepare(data)
    })
    tableKey.value += 1
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    clean()
    validator.value.$reset()
    layoutStore.hideOverlay()
    emits('create', employeeResponse)
  } catch (e) {
    layoutStore.hideOverlay()
    if (e instanceof APIError && e?.code === 'V0001') {
      Object.assign($externalResults, e.field_errors)
    }
    throw e
  }
}

// 테이블 갱신을 위한 키
const tableKey = ref(1)


const fetchFunction = async (query, data) => {
  query['company'] = props.affiliate.id
  return await useAuthAxios({
    method: 'GET',
    url: `/app/v1/affiliate/employee/`,
    params: query,
    data: data
  })
}

// customer drawer
const selectedCustomer = ref(null)
const customerDrawerOpen = ref(false)
const showCustomerDrawer = (customer) => {
  selectedCustomer.value = customer?.customer
  customerDrawerOpen.value = true
}
const hideCustomerDrawer = () => {
  selectedCustomer.value = null
  customerDrawerOpen.value = false
}

const tableFields = {
  // company: {label: '거래처', width: 100, type: 'text'},
  show: {label: '상세', width: 100, type: 'button', action: showCustomerDrawer},
  employee_type_display: {label: '타입', width: 100, type: 'text'},
  employee_type_detail: {label: '타입 상세', width: 100, type: 'text'},
  name: {label: '성명', width: 100, type: 'text'},
  cellphone: {label: '휴대전화', width: 100, type: 'text'},
  role: {label: '직책', width: 100, type: 'text'},
  rank: {label: '직급', width: 100, type: 'text'},
  phone: {label: '전화', width: 100, type: 'text'},
  email: {label: '이메일', width: 100, type: 'text'}
}

const employeeFilter = (row) => {
  let newRow = JSON.parse(JSON.stringify(row))
  newRow.name = newRow.customer.name
  newRow.role = newRow.customer.role
  newRow.rank = newRow.customer.rank
  newRow.cellphone = newRow.customer?.cellphone ? formatPhoneNumber(newRow.customer.cellphone) : ''
  newRow.phone = newRow.customer?.phone ? formatPhoneNumber(newRow.customer.phone) : ''
  newRow.email = newRow.customer.email
  return newRow
}

</script>

<template>
  <form class="mb-4" method="post" @submit.prevent.stop="save">
    <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-2 mb-2">
      <TextInput v-model="data.name" :errors="validator.name.$errors.map(e => e.$message)" :required="true" label="성명"
                 placeholder="성명"/>
      <TextInput v-model="data.role" :errors="validator.role.$errors.map(e => e.$message)" label="직책"
                 placeholder="직책"/>
      <TextInput v-model="data.rank" :errors="validator.rank.$errors.map(e => e.$message)" label="직급"
                 placeholder="직급"/>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-2 mb-2">
      <TextInput v-model="data.cellphone" :errors="validator.cellphone.$errors.map(e => e.$message)" :required="true"
                 label="휴대전화번호" placeholder="휴대전화번호"/>
      <TextInput v-model="data.email" :errors="validator.email.$errors.map(e => e.$message)" label="이메일"
                 placeholder="이메일"/>
      <TextInput v-model="data.phone" :errors="validator.phone.$errors.map(e => e.$message)" label="전화번호"
                 placeholder="전화번호"/>
    </div>
    <div class="flex w-full items-start justify-start gap-x-2 mb-2">
      <div :class="[validator.memo.$error ? 'invalid':'', 'vertical']" class="input-group w-full">
        <label class="w-24" for="id-for-memo">상세</label>
        <div class="w-full">
        <textarea id="id-for-memo" :value="data.memo" class="textarea w-full" data-1p-ignore name="memo"
                  rows="5" type="text" @input="setMemo"/>
          <p v-for="error in validator.memo.$errors" class="error">{{ error.$message }}</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-2 mb-2">
      <ChoiceSelectorBase v-model="data.employee_type" :errors="validator.employee_type.$errors.map(e => e.$message)"
                          :required="true" choice-type="CompanyEmployeeTypeChoice" label="임직원 타입"/>
      <TextInput v-model="data.employee_type_detail" :errors="validator.employee_type_detail.$errors.map(e => e.$message)"
                 label="임직원 타입 상세" placeholder="임직원 타입 상세"/>
      <EmployeeSelector v-model="data.manager" :errors="validator.manager.$errors.map(e => e.$message)"
                        :required="true" label="담당자"/>
    </div>
    <div class="flex w-full items-start justify-start gap-x-2 mb-2">
      <button class="btn btn-primary" type="submit">저장</button>
    </div>
    <!--    <pre>{{props.affiliate}}</pre>-->
  </form>
  <InlinePagingTableComponent :key="tableKey" :fetch="fetchFunction" :fields="tableFields" :filter="employeeFilter"/>
  <CustomerDrawer v-if="selectedCustomer" :customer="selectedCustomer" :open="customerDrawerOpen"
                  @close="hideCustomerDrawer"/>
</template>

<style scoped>

</style>