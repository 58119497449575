<script setup>
import {computed} from "vue";
import UserCell from "@/components/tables/cells/UserCell.vue";
import BooleanCell from "@/components/tables/cells/BooleanCell.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import CompanyCell from "@/components/tables/cells/CompanyCell.vue";
import dayjs from "dayjs";
import {Severities} from "@/constant.js";
import SiteManagerCell from "@/components/tables/cells/SiteManagerCell.vue";
import EntryOriginCell from "@/components/tables/cells/EntryOriginCell.vue";

const emits = defineEmits('update')
const props = defineProps({
  fieldKey: {
    type: String,
    required: true
  },
  fieldValue: {
    type: [null, String, Boolean, Date, Object, Number],
    required: true
  },
  fieldData: {
    type: Object,
    required: true,
  },
  row: {
    type: Object,
    required: true
  }
})

const width = computed(() => {
  return `${props.fieldData.width || 200}px`
})

const fieldType = computed(() => {
  return props.fieldData.type
})


const update = (event) => {
  emits('update', event)
}

const alignClass = computed(() => {
  if (props.fieldData.align === 'right') {
    return 'justify-end'
  } else if (props.fieldData.align === 'center') {
    return 'justify-center'
  } else {
    // left
    return 'justify-start'
  }
})
</script>
<template>
  <div
      :style="{width: width, 'min-width': width}"
      class="border-r flex items-center truncate outline-primary-200 -outline-offset-2" :class="[alignClass]">
    <div class="group/outbox text-sm flex  items-center  truncate px-2" >
      <template v-if="fieldType === 'text'">
        {{ props.fieldValue }}
      </template>
      <template v-else-if="fieldType === 'company'">
        <CompanyCell :company="props.fieldValue"/>
      </template>
      <template v-else-if="fieldType === 'cellphone'">
        {{ props.fieldValue ? formatPhoneNumber(props.fieldValue) : '' }}
      </template>
      <template v-else-if="fieldType === 'boolean'">
        <BooleanCell :value="props.fieldValue"/>
      </template>
      <template v-else-if="fieldType === 'datetime'">
        <template v-if="props.fieldValue">
          {{
            dayjs(props.fieldValue).isValid() ? dayjs(props.fieldValue).format("YYYY-MM-DD HH:mm") : props.fieldValue
          }}
        </template>
        <template v-else>-</template>
      </template>
      <template v-else-if="fieldType === 'date'">
        <template v-if="props.fieldValue">
          {{ dayjs(props.fieldValue).isValid() ? dayjs(props.fieldValue).format("YYYY-MM-DD") : props.fieldValue }}
        </template>
        <template v-else>-</template>
      </template>
      <template v-else-if="fieldType === 'user'">
        <UserCell :user="props.fieldValue"/>
      </template>
      <template v-else-if="fieldType === 'action'">
        <button type="button" @click.prevent.stop="props.fieldData.action(props.row)">
          {{ props.fieldValue }}
        </button>
      </template>
      <template v-else-if="fieldType === 'button'">
        <button
            :class="['btn', `btn-primary`, 'btn-xs', 'flex', 'items-center', 'gap-x-1']"
            type="button" @click.prevent.stop="props.fieldData.action(props.row)">
          <Component :is="props.fieldData.icon" v-if="props.fieldData.icon" class="size-4"/>
          {{ props.fieldData.btnLabel || props.fieldData.label }}
        </button>
      </template>
      <template v-else-if="fieldType === 'site_manager'">
        <SiteManagerCell :siteData="props.fieldValue"/>
      </template>
      <template v-else-if="fieldType === 'origin'">
        <EntryOriginCell :field-key="props.fieldKey" :field-value="props.fieldValue" :field-data="props.fieldData" :row="props.row" @update="update"/>
      </template>
      <template v-else>
        {{ props.fieldValue }}
      </template>
    </div>
  </div>
</template>

<style scoped>

</style>