<script setup>
import {formatPhoneNumber} from "../../../utils/formatter.js";
import {computed, ref} from "vue";
import CustomerDrawer from "@/components/drawer/CustomerDrawer.vue";
import SimplePopover from "@/components/commons/SimplePopover.vue";
import CopyButton from "@/components/commons/CopyButton.vue";

const props = defineProps({
  siteData: {
    type: [Object, null],
    required: false,
    default: null
  }
})


const manager = computed(() => {
  let managerData = {
    id: props.siteData?.manager?.customer?.id || null,
    name: props.siteData?.manager_name || props.siteData?.manager?.customer?.name,
    cellphone: props.siteData?.manager_cellphone || props.siteData?.manager?.customer?.cellphone,
  }
  managerData.name = managerData.name || null
  managerData.cellphone = managerData.cellphone || null
  if (!managerData.id && !managerData.name && !managerData.cellphone) {
    return null
  }
  return managerData
})

const managerOpen = ref(false)
const showCustomer = () => {
  managerOpen.value = true
}
const hideCustomer = () => {
  managerOpen.value = false
}
const clickManager = () => {
  alert(JSON.stringify(manager.value))
}
</script>

<template>
  <template v-if="manager">
    <SimplePopover>
      <template #button>
        <template v-if="manager?.id">
          <!--등록된 고객이 담당자로 지정된 경우-->
          <button type="button" @click.stop="showCustomer">{{ manager.name }}</button>
          <template v-if="manager?.id && managerOpen">
            <CustomerDrawer :customer="props.siteData.manager.customer" :open="managerOpen" @close="hideCustomer"/>
          </template>
        </template>
        <template v-else>
          <!--고객 등록 없이 이름 전화번호만 입력한 경우-->
          {{ manager.name || manager.cellphone }}
        </template>
      </template>
      <template #body>
        <div class="flex gap-x-2 items-center bg-white">
          <template v-if="manager.cellphone">
            <div>{{ formatPhoneNumber(manager.cellphone) }}</div>
            <CopyButton :source="manager.cellphone"/>
          </template>
          <template v-else>
            전화번호 미등록
          </template>
        </div>
      </template>
      <!--        <div #body>-->
      <!--            <TextData label="전화번호">-->
      <!--              <template #default>{{ manager.cellphone }}</template>-->
      <!--            </TextData>-->
      <!--        </template>-->
    </SimplePopover>


  </template>
  <template v-else>
    <span class="text-red-400">미지정</span>
  </template>
</template>

<style scoped>

</style>