<script setup>

import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useRoute} from "vue-router";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import {onMounted, ref} from "vue";
import {Severities} from "@/constant.js";
import router from "@/router/index.js";
import {MapIcon} from "@heroicons/vue/24/solid/index.js";
import MapModal from "@/components/modals/MapModal.vue";
import AddressSelectModal from "@/components/modals/AddressSelectModal.vue";

const route = useRoute()

const companyId = route.params.id

const props = defineProps({
  data: Object
})

const emits = defineEmits(['fetch'])

const addressList = ref([])

const fields = {
  is_default: {label: '대표주소', sortable: false, width: 80, type: 'boolean'},
  name: {label: '별칭', sortable: false, width: 80, type: 'text'},
  full_address: {label: '주소', width: 200, type: 'text'},
  detail: {label: '상세주소', width: 200, type: 'text'},
  'map': {
    label: '지도', width: 100, type: 'button', severity: Severities.SECONDARY,
    action: (row) => openMap(row), icon: MapIcon
  },
  'setDefault': {
    label: '대표주소', width: 100, type: 'button', severity: Severities.SECONDARY,
    action: (row) => setDefault(row)
  },
  'delete': {
    label: '삭제', width: 100, type: 'button', severity: Severities.ERROR,
    action: (row) => deleteAddress(row)
  },
}
const filter = (row) => {
  return {
    is_default: row.is_default,
    name: row.name,
    full_address: row.poi.full_address,
    detail: row.detail,
  }
  // return row
}
const fetch = async () => {
  addressList.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/company/${companyId}/address/`,
  })
}

// 지도 표시
const lat = ref(null)
const lng = ref(null)
const address = ref(null)

const showMap = ref(false)
const openMap = (row) => {
  lat.value = row.poi.noor_lat
  lng.value = row.poi.noor_lon
  address.value = row.full_address
  showMap.value = true
}

const closeMap = () => {
  showMap.value = false
  lat.value = null
  lng.value = null
  address.value = null
}

// 대표주소 설정
const setDefault = async (row) => {
  await useAuthAxios({
    method: 'POST',
    url: `/app/v1/company/${companyId}/address/${row.id}/`,
    data: {is_default: true}
  })
  await fetch()
}

// 주소 등록
const addressSelectModalShow = ref(false)
const closeAddressSelectModal = () => {
  addressSelectModalShow.value = false
  fetch()
}

const poi = ref(null)
const detail = ref('')

const setPoi = async (poiData) => {
  poi.value = poiData
}
const registerAddress = async () => {
  const poiResponse = await useAuthAxios({
    method: 'PUT',
    url: `/app/v1/company/${companyId}/address/`,
    data: {poi: poi.value, detail: detail.value === '' ? null: detail.value}
  })
  await fetch()
  poi.value = null
  detail.value = ''
}
onMounted(() => {
  fetch()
})
// 삭제
const deleteAddress = async (row) => {
  await useAuthAxios({
    method: 'DELETE',
    url: `/app/v1/company/${companyId}/address/${row.id}/`,
  })
  await fetch()
}

onMounted(() => {
  fetch()
})
</script>

<template>
  <div>
    <div class="mb-2">
      <span class="mr text-lg text-slate-900">주소</span>
    </div>
    <div class="flex justify-start mb-4 gap-x-2">
      <div class="input-group">
        <label class="sr-only">주소</label>
        <div>
          <input type="text" disabled :value="poi?.full_address" placeholder="주소"/>
        </div>
      </div>
      <button class="btn btn-secondary" @click="addressSelectModalShow = true">검색</button>
      <div class="input-group">
        <label class="sr-only">상세 주소</label>
        <div>
          <input type="text" placeholder="상세 주소" @input="event => detail=event.target.value"/>
        </div>
      </div>
      <button class="btn btn-primary" @click="registerAddress">등록</button>
    </div>
    <AddressSelectModal :isOpen="addressSelectModalShow" @close="closeAddressSelectModal" @select="setPoi"/>
    <MapModal :lat="lat" :lng="lng" :address="address" :show="showMap" @close="closeMap"/>
    <InlineTableComponent :fields="fields" :rows="addressList" :filter="filter"/>
  </div>
</template>

<style scoped>

</style>