<script setup>
import ModalBase from "@/components/modals/ModalBase.vue";
import {Severities, Sizes} from "@/constant.js";
import {nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {
    externalValidator,
    maxLengthWithMessage,
    minLengthWithMessage,
    nullBrnValidator, phoneValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRouter} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import {useAuthStore} from "@/stores/authStore.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";
import AddressInput from "@/components/forms/AddressInput.vue";
import DrawerCore from "@/components/drawer/DrawerCore.vue";
import TextArea from "@/components/forms/TextArea.vue";

const authStore = useAuthStore()
const layoutStore = useLayoutStore()
const router = useRouter()

const props = defineProps({
    isOpen: {type: Boolean, required: true},
    autoShow: {type: Boolean, required: false, default: true}
})
const emits = defineEmits(['close', 'showAffiliate', 'create'])


const data = reactive({
    name: '',
    company_type: null,
    company_type_detail: '',
    tel: '',
    brn: '',
    address_poi: null,
    address_detail: '',
    description: '',
    manager: authStore.user.employment,
    channel: null
})

const $externalResults = ref({})

const model = {
    name: {
        required: helpers.withMessage('거래처명 입력해주세요.', required),
        minLength: minLengthWithMessage('거래처명을 2글자 이상으로 입력해주세요.', 2),
        maxLength: maxLengthWithMessage('거래처명을 30글자 이하로 입력해주세요.', 30)
    },
    company_type: {required: helpers.withMessage('타입을 선택해주세요.', required)},
    company_type_detail: {externalValidator},
    tel: {externalValidator},
    brn: {format: nullBrnValidator},
    address_poi: {externalValidator},
    address_detail: {externalValidator},
    description: {externalValidator},
    manager: {
        required: requiredWithMessage('필수 항목입니다.')
    },
    channel: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const getPrepare = (value) => {
    let prepare = JSON.parse(JSON.stringify(value))
    prepare.company_type = prepare.company_type.value
    prepare.company_type_detail = prepare.company_type_detail ? prepare.company_type_detail : null
    prepare.tel = prepare.tel ? prepare.tel : null
    prepare.brn = prepare.brn ? prepare.brn : null
    prepare.address_detail = prepare.address_detail ? prepare.address_detail : null
    prepare.description = prepare.description ? prepare.description : null
    prepare.manager = prepare.manager?.id || null
    if (prepare.channel) {
        prepare.channel = typeof prepare.channel === 'string' ? prepare.channel : prepare.channel.value
    }
    return prepare
}

const save = async () => {
    await validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    if (!valid) {
        return
    }
    try {
        const affiliate = await useAuthAxios({
            method: 'PUT',
            url: `/app/v1/affiliate/`,
            data: getPrepare(data)
        })
        layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
        clean()
        validator.value.$reset()
        emits('create', affiliate)
        emits('close')
        if (props.autoShow) {
            await showAffiliate(affiliate)
        }
    } catch (e) {
        if (e instanceof APIError && e?.code === 'V0001') {
            $externalResults.value = e.field_errors
        }
        throw e
    }
}

// show Customer
const showAffiliate = async (affiliate) => {
    const affiliateData = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/affiliate/${affiliate.id}/`
    })
    emits('showAffiliate', affiliateData)
}

// 자동완성
const similarAffiliate = ref([])

const fetchSimilar = async (name, brn, tel) => {
    let options = {
        method: 'POST',
        url: `/app/v1/affiliate/`,
        data: {name: name, brn: brn, tel: tel},
    }
    similarAffiliate.value = await useAuthAxios(options, false)
}

watch(() => [data.name, data.brn, data.tel], ([newName, newBrn, newTel]) => {
    fetchSimilar(newName, newBrn, newTel)
})

const similarField = {
    open: {
        label: '선택', width: 100, type: 'button', severity: Severities.PRIMARY,
        action: (row) => showAffiliate(row)
    },
    name: {label: '거래처명', width: 100, type: 'text'},
    tel: {label: '대표번호', width: 120, type: 'text'},
    brn: {label: '사업자등록번호', width: 120, type: 'text'},
    address__poi__full_address: {label: '주소', width: 120, type: 'text'},
    address__detail: {label: '상세주소', width: 120, type: 'text'},
}

const similarFilter = (row) => {
    return row
}
// 초기화

const clean = () => {
    data.name = ''
    data.brn = ''
    data.tel = ''
    data.address_poi = null
    data.address_detail = ''
    data.company_type = null
    data.company_type_detail = ''
    data.description = ''
    data.manager = authStore.user || null
    data.channel = null
    similarAffiliate.value = []
}

const close = () => {
    clean()
    validator.value.$reset()
    emits('close')
}

const nameInput = ref(null)

onMounted(() => {
    data.manager = authStore.user
})

watch(() => props.isOpen, (newValue, oldValue) => {
    if (newValue === oldValue) return null
    if (!newValue) return null
    nextTick(() => {
        nameInput.value.focus()
    })
})

</script>

<template>
    <DrawerCore :open="props.isOpen" title="사업장 등록" @close="close">
        <div class="w-full">
            <form class="w-full grid grid-cols-1 md:grid-cols-3 gap-4  p-4" @submit.prevent="save">
                <TextInput v-model="data.name" :errors="validator.name.$errors.map(e => e.$message)"
                           :required="true" class="mb-4 col-span-full" label="거래처명"
                           placeholder="거래처명"/>
                <ChoiceSelectorBase v-model="data.company_type"
                                    :errors="validator.company_type.$errors.map(ct => ct.$message)" :required="true"
                                    choice-type="CompanyTypeChoice" class="mb-4 col-span-1"
                                    label="업종"/>
                <TextInput v-model="data.company_type_detail"
                           :errors="validator.company_type_detail.$errors.map(e => e.$message)" class="mb-4 col-span-2"
                           label="업종 상세" placeholder="업종 상세"/>
                <TextInput v-model="data.brn"
                           :errors="validator.brn.$errors.map(e => e.$message)" class="mb-4 col-span-2" label="사업자등록번호"
                           placeholder="사업자등록번호"/>
                <TextInput v-model="data.tel"
                           :errors="validator.tel.$errors.map(e => e.$message)" class="mb-4 col-span-1" label="대표번호"
                           placeholder="대표번호"/>
                <AddressInput v-model="data.address_poi"
                              :errors="validator.address_poi.$errors.map(e => e.$message)" class="mb-4 col-span-1" label="주소"
                              placeholder="주소"/>
                <TextInput v-model="data.address_detail"
                           :errors="validator.address_detail.$errors.map(e => e.$message)"
                           :readonly="data.address_poi === null" class="mb-4 col-span-2" label="상세주소" placeholder="상세주소"/>
                <TextArea v-model="data.description"
                           :errors="validator.description.$errors.map(e => e.$message)" class="mb-4 col-span-full" label="메모"
                           placeholder="메모"/>
                <EmployeeSelector v-model="data.manager" :errors="validator.manager.$errors.map(e => e.$message)"
                                  :required="true" class="mb-4 col-span-1" label="담당자" placeholder="영업 담당자"/>
                <ChoiceSelectorBase v-model="data.channel" :errors="validator.channel.$errors.map(ct => ct.$message)"
                                    choice-type="AcquireChannelChoice" class="mb-4 col-span-1" label="인입경로"/>
                <button class="btn btn-primary w-full col-span-1" type="submit">저장</button>
            </form>
            <div class="border-l border-gray-200 flex-grow h-full p-4 overflow-y-auto">
                <div class="text-sm mb-2 font-semibold">중복 거래처 확인</div>
                <p class="text-xs">거래처명, 주소, 사업자등록번호로 기존 거래처를 검색합니다.</p>
                <InlineTableComponent :fields="similarField" :filter="similarFilter" :rows="similarAffiliate"/>
            </div>
        </div>
    </DrawerCore>
</template>

<style scoped>

</style>