<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import NoteInfo from "@/components/drawer/partial/NoteInfo.vue";
import HistoryInfo from "@/components/drawer/partial/HistoryInfo.vue";
import SMSInfo from "@/components/drawer/partial/SMSInfo.vue";
import FileInfo from "@/components/drawer/partial/FileInfo.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useDraggable} from "@vueuse/core";
import {EqualsIcon} from "@heroicons/vue/24/solid/index.js"

const props = defineProps({
    title: {type: String, required: true},
    open: {type: Boolean, required: true},
    target: {type: [null, Object, Number], required: true},
    maxWidthClass: {type: String, default: 'md:max-w-2xl'},
    initialTab: {type: String, required: false, default: 'default'},
    tabItems: {
        type: Array, required: false, default: () => [
            {name: '기본정보', key: 'default'},
            {name: '메모', key: 'note'},
            {name: '파일', key: 'file'},
            {name: 'SMS', key: 'sms'},
            {name: '히스토리', key: 'history'},
        ]
    }
})

const emits = defineEmits(['close'])

// open and close
const drawer = ref(null)

const close = () => {
    tab.value = null
    emits('close')
}

watch(() => props.open, (newValue, oldValue) => {
    if (newValue === true && oldValue === false)
        nextTick(() => {
            tab.value = props.initialTab
        })
})

const noteTotal = ref(0)
const fileTotal = ref(0)

const setTotal = (hc_id) => {
    useAuthAxios({
        method: "GET",
        url: `/app/v1/history/${hc_id || props.target.history_context}/`,
    }).then(response => {
        noteTotal.value = response.note_count
        fileTotal.value = response.file_count
    })
}

watch(() => props.target, (newValue, oldValue) => {
    if (newValue === oldValue) {
        return null
    }
    if (!!newValue && !!newValue?.history_context) {
        setTotal(newValue.history_context)
    }
})


const tabs = computed(() => {
    return props.tabItems.map(tabItem => {
        tabItem.current = tabItem.key === tab.value
        if (tabItem.key === 'note') {
            tabItem.name = `메모(${noteTotal.value})`
        }
        if (tabItem.key === 'file') {
            tabItem.name = `파일(${fileTotal.value})`
        }
        return tabItem
    })
})

const tab = ref('default')

const setTab = (tabItem) => {
    tab.value = tabItem.key
}
const panelWrapper = ref(null)
const focusPanel = (event) => {
    let key = event.key
    let currentTabKey = tab.value
    let currentIndex = tabs.value.findIndex(tab => tab.key === currentTabKey)
    let nextIndex, nextTab
    if (key === 'ArrowDown') {
        event.preventDefault()
        let focusable = panelWrapper.value.querySelectorAll(
            'button:not([hidden]):not([style*="display: none"]), [href]:not([hidden]):not([style*="display: none"]), input:not([hidden]):not([style*="display: none"]), select:not([hidden]):not([style*="display: none"]), textarea:not([hidden]):not([style*="display: none"]), [tabindex]:not([tabindex="-1"]):not([hidden]):not([style*="display: none"])'
        );
        if (focusable.length === 0) return
        focusable[0].focus()
    } else if (key === 'ArrowLeft' || (key === 'Tab' && event.shiftKey)) {
        event.preventDefault()
        nextIndex = currentIndex - 1 < 0 ? tabs.value.length - 1 : currentIndex - 1
        nextTab = tabs.value[nextIndex]
        setTab(nextTab)
    } else if (key === 'ArrowRight' || (key === 'Tab' && !event.shiftKey)) {
        event.preventDefault()
        nextIndex = currentIndex + 1 > tabs.value.length - 1 ? 0 : currentIndex + 1
        nextTab = tabs.value[nextIndex]
        setTab(nextTab)
    }
}

const resize = ref(false)
const widthStyle = ref({maxWidth: '100%'})
const resizeHandler = ref(null)


const {x} = useDraggable(resizeHandler, {
    initialValue: {x: 300},
})

watch(() => x, (newValue, oldValue) => {
    resize.value = true
    widthStyle.value = {maxWidth: `calc(100% - ${newValue.value}px)`}
}, {deep: true})

</script>

<template>
    <TransitionRoot :show="open" as="template">
        <Dialog ref="drawer" class="relative z-50 overflow-y-auto" @close="close">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                             enter-to="opacity-100"
                             leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 md:pl-16 justify-end">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-300"
                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                         leave="transform transition ease-in-out duration-300"
                                         leave-from="translate-x-0" leave-to="translate-x-full">

                            <DialogPanel ref="dialogPanel"
                                         :class="[!resize ? props.maxWidthClass : '']"
                                         :style="[resize ? widthStyle : null]"
                                         class="pointer-events-auto w-screen flex">
                                <div ref="resizeHandler" class="h-full w-2 bg-gray-200 flex-shrink cursor-col-resize p-0 flex justify-center items-center">
                                    <EqualsIcon class="size-2 rotate-90 text-black"/>
                                </div>
                                <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl flex-grow">
                                    <div class="flex-1">
                                        <!-- Header -->
                                        <div class="bg-primary-500 px-4 py-2">
                                            <div class="flex items-start justify-between space-x-3">
                                                <div class="space-y-1">
                                                    <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                        {{ props.title }}
                                                    </DialogTitle>
                                                </div>
                                                <div class="flex h-7 items-center">
                                                    <button class="relative text-white" tabindex="-1" type="button"
                                                            @click="close">
                                                        <XMarkIcon aria-hidden="true" class="size-6"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <slot name="header"/>
                                        <div class="border-b border-gray-200">
                                            <nav aria-label="Tabs"
                                                 class="-mb-px flex space-x-8 px-4 w-full overflow-x-auto">
                                                <button v-for="tab in tabs" v-bind:key="tab.key"
                                                        :class="[tab.current ? 'tab current' : 'tab', 'focus-visible:outline-none']"
                                                        :data-tab="tab.key"
                                                        type="button" @click="setTab(tab)"
                                                        @keydown="event => focusPanel(event)">{{ tab.name }}
                                                </button>
                                            </nav>
                                        </div>
                                        <div ref="panelWrapper" class="px-3 py-3 overflow-x-hidden">
                                            <template v-for="tabSlot in tabs">
                                                <template v-if="tabSlot.key==='sms' && props.target?.history_context">
                                                    <SMSInfo v-if="tab === 'sms'"
                                                             :history-context-id="props.target?.history_context"/>
                                                </template>
                                                <template
                                                    v-else-if="tabSlot.key==='history' && props.target?.history_context">
                                                    <HistoryInfo v-if="tab === 'history'"
                                                                 :history-context-id="props.target?.history_context"/>
                                                </template>
                                                <template
                                                    v-else-if="tabSlot.key==='file' && props.target?.history_context">
                                                    <FileInfo v-if="tab === 'file'"
                                                              :history-context-id="props.target?.history_context"
                                                              @update:total="setTotal"/>
                                                </template>
                                                <template
                                                    v-else-if="tabSlot.key==='note' && props.target?.history_context">
                                                    <NoteInfo v-if="tab === 'note'"
                                                              :historyContextId="props?.target?.history_context"
                                                              @update:total="setTotal"/>
                                                </template>
                                                <template v-else>
                                                    <slot v-if="tab===tabSlot.key" :name="tabSlot.key"></slot>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<style scoped>
.tab {
    @apply whitespace-nowrap border-b-2 px-1 py-3 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700
}

.tab.current {
    @apply border-primary-500 text-primary-600
}
</style>