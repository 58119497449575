<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import SmallWorkEstimateTemplate from "@/components/drawer/work/small/SmallWorkEstimateTemplate.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
    work: {type: [null, Object], required: true, default: null}
})
const emits = defineEmits(['update'])

const update = () => {
    emits('update',)
}


const entries = ref([])

const fetchEntry = async (work) => {
    const option = {
        method: "GET",
        url: `/app/v1/accounting/entry/?work=${work.id}&all=1&is_deleted=false`
    }
    const response = await useAuthAxios(option)
    entries.value = response.list || []
}

onMounted(() => {
    fetchEntry(props.work)
})

</script>
<!--['code', 'target', 'amount', 'memo']-->
<template>
    <div>
        <SmallWorkEstimateTemplate :entries="entries" :work="props.work"/>
    </div>
    <!--    <pre>{{ entries }}</pre>-->
    <!--    <pre>{{ buyers }}</pre>-->
    <!--    <pre>{{ targetEntries }}</pre>-->
    <!--  <div class="text-sm flex flex-flow gap-x-6 mb-6 px-2">-->
    <!--    <div><span class="font-semibold">매출</span> : {{ summary.sales.toLocaleString() }}</div>-->
    <!--    <div><span class="font-semibold">매입</span> : {{ summary.purchase.toLocaleString() }}</div>-->
    <!--    <div><span class="font-semibold">계</span> : {{ summary.total.toLocaleString() }}</div>-->
    <!--  </div>-->
    <!--  <div class="px-2">-->
    <!--    <form @submit.prevent="save">-->
    <!--      <div class="grid grid-cols-12 gap-x-2 gap-y-4 w-full mb-4">-->
    <!--        <AffiliateSelector v-model="data.supplier_company" :errors="validator.supplier_company.$errors.map(err => err.$message)"-->
    <!--                           :readonly="readonly || !!data.supplier_customer" :required="true" class="col-span-4 md:col-span-3"-->
    <!--                           label="공급 거래처"/>-->
    <!--        <CustomerSelector v-model="data.supplier_customer" :errors="validator.supplier_customer.$errors.map(err => err.$message)"-->
    <!--                          :readonly="readonly || !!data.supplier_company" :required="true" class="col-span-4 md:col-span-3"-->
    <!--                          label="공급 고객"/>-->
    <!--        <AffiliateSelector v-model="data.buyer_company" :errors="validator.buyer_company.$errors.map(err => err.$message)"-->
    <!--                           :readonly="readonly || !!data.buyer_customer" :required="true" class="col-span-4 md:col-span-3"-->
    <!--                           label="공급받는 거래처"/>-->
    <!--        <CustomerSelector v-model="data.buyer_customer" :errors="validator.buyer_customer.$errors.map(err => err.$message)"-->
    <!--                          :readonly="readonly || !!data.buyer_company" :required="true" class="col-span-4 md:col-span-3"-->
    <!--                          label="공급받는 고객"/>-->
    <!--      </div>-->
    <!--      <div class="grid grid-cols-12 gap-x-2 gap-y-4 w-full mb-4">-->
    <!--        <EntryCodeSelector v-model="data.code" :errors="validator.code.$errors.map(err => err.$message)"-->
    <!--                           :readonly="readonly" :required="true"-->
    <!--                           class="col-span-4 md:col-span-3" label="코드"/>-->
    <!--        <TransactionMethodSelector v-model="data.method" :errors="validator.method.$errors.map(err => err.$message)"-->
    <!--                                   :messages="[`공급가액 + 세액 = ${total.toLocaleString()}`]" :readonly="readonly"-->
    <!--                                   :required="true"-->
    <!--                                   class="col-span-4 md:col-span-3"/>-->
    <!--        <TextInput v-model="data.amount" :auto-select="true" :errors="validator.amount.$errors.map(err => err.$message)"-->
    <!--                   :readonly="readonly || data.method === null"-->
    <!--                   :required="true" class="col-span-4 md:col-span-3"-->
    <!--                   label="공급가액" type="number"/>-->
    <!--        <TextInput v-model="data.tax" :auto-select="true" :errors="validator.tax.$errors.map(err => err.$message)"-->
    <!--                   :messages="['면세일 경우 빈 값으로 두세요.']" :readonly="readonly || data.method === null"-->
    <!--                   class="col-span-4 md:col-span-3" label="세액" type="number"/>-->
    <!--      </div>-->
    <!--      <TextInput v-model="data.memo" :errors="validator.memo.$errors.map(err => err.$message)" :readonly="readonly"-->
    <!--                 class="w-full mb-4"-->
    <!--                 label="비고"/>-->
    <!--      <div class="col-span-4  md:col-span-1">-->
    <!--        <button :disabled="readonly" class="btn btn-primary" type="submit">저장</button>-->
    <!--      </div>-->
    <!--    </form>-->
    <!--    <InlineTableComponent :fields="tableField" :rows="computedEntries" heightClass="max-h-96"/>-->
    <!--    <ConfirmDialogComponent :open="registerInverseDialogShow" :severity="Severities.PRIMARY"-->
    <!--                            detail="이 작업은 되돌릴 수 없습니다." summary="반대 거래를 등록하시겠습니까?" accept-string="등록"-->
    <!--                            @accept="deleteCallback" @close="registerInverseDialogShow = false"/>-->
    <!--  </div>-->
</template>

<style scoped>
</style>