<script setup>
import {useLayoutStore} from "@/stores/layoutStore.js";
import GlobalOverlayBase from "@/components/commons/GlobalOverlayBase.vue";

const layoutStore = useLayoutStore()
</script>

<template>
<Teleport to="body">
  <GlobalOverlayBase v-if="layoutStore.overlay">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </GlobalOverlayBase>
<!--  <div class="fixed top-0 left-0 w-full h-full bg-gray-900/30 flex justify-center items-center" v-if="layoutStore.overlay">-->

<!--  </div>-->
</Teleport>
</template>

<style scoped>

</style>