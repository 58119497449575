<!--<script setup>-->
<!--import useAuthAxios from "@/composables/useAuthAxios.js";-->
<!--import {API_URL} from "@/service/api.js";-->
<!--import {computed, onMounted, ref} from "vue";-->
<!--import Logo from "@/components/Logo.vue";-->

<!--const props = defineProps({-->
<!--  label: {type: [String], default: '타입'},-->
<!--  labelClass: {type: [Object, String, Array, null], required: false, default: null},-->
<!--  class: {type: [Object, String, Array, null], required: false, default: null},-->
<!--  selected: {type: [Object, String, null], required: false, default: null},-->
<!--  has_stock: {type: [Boolean, null], required: false, default: null},-->
<!--  has_logistics: {type: [Boolean, null], required: false, default: null},-->
<!--  is_active: {type: [Boolean, null], required: false, default: true},-->
<!--  vertical: {type: Boolean, required: false, default: false},-->
<!--  invalid: {type: Boolean, required: false, default: false},-->
<!--  errors: {type: Array, required: false, default: []},-->
<!--  placeholder: {type: [String, null], default: null},-->
<!--  required: {type: Boolean, required: false, default: false},-->
<!--})-->

<!--const emits = defineEmits(['update:select'])-->

<!--const typeData = ref([])-->


<!--onMounted(async () => {-->
<!--  typeData.value = Object.entries(naver.maps.MapTypeId).map(mapType => {-->
<!--    let label = {NORMAL: '일반', TERRAIN: '지형', SATELLITE: '위성', HYBRID: '겹쳐보기'}[mapType[0]] || '일반'-->
<!--    return {-->
<!--      label: label,-->
<!--      value: mapType[1]-->
<!--    }-->
<!--  })-->
<!--  //{ "NORMAL": "normal", "TERRAIN": "terrain", "SATELLITE": "satellite", "HYBRID": "hybrid" }-->
<!--  return typeData.value-->
<!--})-->

<!--const filteredList = (query) => {-->
<!--  if (query === '') return typeData.value-->
<!--  return typeData.value.filter(data => {-->
<!--    return props.toRepresentation(data).includes(query)-->
<!--  })-->
<!--}-->

<!--const toRepresentation = (value) => {-->
<!--  return value?.label-->
<!--}-->

<!--const selected = computed({-->
<!--  get() {-->
<!--    if (typeof props.selected === 'string') {-->
<!--      return typeData.value.find(data => data.value === props.selected) || null-->
<!--    } else if (props.selected === null) {-->
<!--      return null-->
<!--    } else {-->
<!--      return props.selected-->
<!--    }-->
<!--  },-->
<!--  set(selectedObject) {-->
<!--    return selectedObject-->
<!--  }-->
<!--})-->

<!--const updateSelect = (select) => {-->
<!--  if (select === null) {-->
<!--    select = typeData.value[0]-->
<!--  }-->
<!--  emits('update:select', select)-->
<!--}-->

<!--</script>-->

<!--<template>-->
<!--  <SelectorBase :class="props.class" :errors="props.errors" :fetch="filteredList"-->
<!--                :invalid="props.invalid" :label="props.label" v-if="typeData.length > 0"-->
<!--                :label-class="props.labelClass" :placeholder="props.placeholder"-->
<!--                :required="props.required" :selected="selected"-->
<!--                :toRepresentation="toRepresentation" :vertical="props.vertical" @update:select="updateSelect"/>-->
<!--</template>-->


<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, onBeforeMount, onMounted, ref, toRef, watch} from "vue";
import StaticSelectorBase from "@/components/selector/base/StaticSelectorBase.vue";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: true, default: '입력'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
})

const emits = defineEmits(['update:options'])

const model = defineModel()

const options = ref([])

onMounted(async () => {

  options.value = Object.entries(naver.maps.MapTypeId).map(mapType => {
    let label = {NORMAL: '일반', TERRAIN: '지형', SATELLITE: '위성', HYBRID: '겹쳐보기'}[mapType[0]] || '일반'
    return {
      label: label,
      value: mapType[1]
    }
  })
  model.value = converter(model.value)
  emits('update:options', options.value)
})

watch(model, (newValue, oldValue) => {
  if (newValue === oldValue) {return null}
  model.value = converter(newValue)
})

const converter = (value) => {
  if (typeof value === 'string') {
    return options.value.find(option => option.value === value) || null
  }
  return value
}
const toRepresentation = (obj) => {
  return obj.label
}

</script>

<template>
  <StaticSelectorBase v-model="model" :options="options"
                      :class="props.class" :labelClass="props.labelClass"
                      :inputClass="props.inputClass" :name="props.name"
                      :label="props.label" :placeholder="props.placeholder"
                      :required="props.required" :errors="props.errors"
                      :messages="props.messages" :autoSelect="props.autoSelect"
                      :nullable="props.nullable" :readonly="props.readonly"
                      :toRepresentation="toRepresentation"/>
</template>

<style scoped>
</style>