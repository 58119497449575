<script setup>

import InlineTableColHeader from "@/components/Inlinetable/InlineTableColHeader.vue";
import {ref} from "vue";
import InlineTableRow from "@/components/Inlinetable/InlineTableRow.vue";

const props = defineProps({
  fields: {type: Object, required: true},
  rows: {type: Array, required: true},
  filter: {type: Function, required: false, default: (row) => row},
  heightClass: {type: [String, Array, Object], required: false, default: 'min-h-64 max-h-64'}
})

const emits = defineEmits(['resize'])

const fields = ref(props.fields)

</script>

<template>
  <div
      class="select-none border border-slate-300 border-t-slate-400 mt-2 flex grow flex-col overflow-auto h-full" :class="heightClass">
<!--    <div class="bg-slate-100 flex min-h-[2rem] sticky top-0 z-10 border-slate-400 border-b min-w-fit">-->
    <div class="bg-slate-100 flex min-h-[2rem] sticky top-0 z-0 border-slate-400 border-b min-w-fit">
      <template v-for="[fieldKey, fieldData] in Object.entries(fields)">
        <InlineTableColHeader :fieldData="fieldData" :fieldKey="fieldKey"
                              @resize="width => fieldData['width'] = width"/>
      </template>
    </div>
    <template v-if="props.rows.length > 0">
      <template v-for="(row, index) in rows" v-bind:key="index">
        <InlineTableRow :fields="fields" :filter="props.filter" :row="row"/>
      </template>
    </template>
    <template v-else>
      <div class="flex w-full h-full items-center justify-center grow">
        <span class="text-sm text-slate-400">데이터가 없습니다.</span>
      </div>
    </template>
  </div>
</template>

<style scoped>

</style>