import axios, {AxiosError} from "axios";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {APIError} from "@/exceptions.js";

const useNoAuthAxios = async (options, globalOverlay = false) => {
    const layoutStore = useLayoutStore()
    try {
        if (globalOverlay) {
            layoutStore.showOverlay()
        }
        axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
        const response = await axios(options)
        return response.data
    } catch (e) {
        if (e instanceof AxiosError) {
            const responseData = e.response.data
            throw new APIError(responseData.code, responseData.message, responseData.field_errors, responseData.non_field_errors, e)
        } else {
            throw new APIError(null, '알 수 없는 오류가 발생했습니다.', null, null, e)
        }
    } finally {
        if (globalOverlay) {
            layoutStore.hideOverlay()
        }
    }
}

export default useNoAuthAxios