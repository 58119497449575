import {required, helpers} from '@vuelidate/validators'
import dayjs from "dayjs";
//https://vuelidate-next.netlify.app/


export const requiredWithMessage = (message) => helpers.withMessage(
    message, required
)

export const minLengthWithMessage = (message, length) => helpers.withMessage(
    message, (value) => {
        if (!value) return true
        return value.length >= length
    }
)

export const maxLengthWithMessage = (message, length) => helpers.withMessage(
    message, (value) => {
        if (!value) return true
        return value.length <= length
    }
)

export const emailValidator = () => helpers.withMessage(
    '이메일 형식이 올바르지 않습니다.', (value) => {
        if (!value) return true
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return pattern.test(value)
    }
)

export const cellphoneValidator = () => helpers.withMessage(
    '휴대전화번호 형식이 올바르지 않습니다.', (value) => {
        if (!value) return true
        const pattern = /^010[0-9]{8}$/
        return pattern.test(value)
    }
)

export const phoneValidator = () => helpers.withMessage(
    '전화번호 형식이 올바르지 않습니다.', (value) => {
        if (!value) return true
        const pattern = /^0[0-9]{8,10}$/
        return pattern.test(value)
    }
)

export const nullBrnValidator = () => helpers.withMessage(
    '사업자번호 형식이 올바르지 않습니다.', (value) => {
        if (value === null || value === '') {
            return true
        }
        const pattern = /^[1-9]{1}[0-9]{9}$/
        return pattern.test(value)
    }
)

export const brnValidator = () => helpers.withMessage(
    '사업자번호 형식이 올바르지 않습니다.', (value) => {
        const pattern = /^[1-9]{1}[0-9]{9}$/
        return pattern.test(value)
    }
)

export const vinValidator = () => helpers.withMessage(
    '차대번호의 형식이 올바르지 않습니다.', (value) => {
        if (value === null || value === '') {
            return true
        }
        const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/;
        return vinRegex.test(value)
    }
)

export const carNoValidator = () => helpers.withMessage(
    '차량번호의 형식이 올바르지 않습니다.', (value) => {
        if (value === null || value === '') {
            return true
        }
        const regex1 = /^[0-9]{2,3}[가-힣]{1}[0-9]{4}$/
        const regex2 = /^[가-힣]{2}[0-9]{1,2}[가-힣]{1}[0-9]{4}$/
        return regex1.test(value) || regex2.test(value)
    }
)

export const dateStringValidator = () => helpers.withMessage(
    '날짜 형식이 올바르지 않습니다.', (value) => {
        if (value === null || value === '') {
            return true
        }
        return dayjs(value, 'YYYY-MM-DD', true).isValid()
    }
)

export const externalValidator = () => {
    return true
}