<script setup>

import DefaultLayout from "@/layout/DefaultLayout.vue";
import BaseInfoBlock from "@/views/profile/BaseInfoBlock.vue";
import PasswordChangeBlock from "@/views/profile/PasswordChangeBlock.vue";
import {useAuthStore} from "@/stores/authStore.js";
import HorizontalBar from "@/components/forms/HorizontalBar.vue";

const authStore = useAuthStore()
const logout = () => {
  authStore.logout()
}
</script>

<template>
  <DefaultLayout title="프로필">
    <template #content>
      <div class="space-y-8">
        <BaseInfoBlock/>
        <HorizontalBar/>
        <PasswordChangeBlock/>
        <HorizontalBar/>
        <div>
          <div class="mb-4">
            <span class="mr-4 text-lg text-slate-900">로그아웃</span>
          </div>
          <button class="btn btn-secondary" type="button" @click="logout">로그아웃</button>
        </div>
      </div>
    </template>
  </DefaultLayout>
</template>

<style scoped>

</style>