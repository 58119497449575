<script setup>

import DefaultButton from "@/components/buttons/DefaultButton.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRoute} from "vue-router";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import {Severities} from "@/constant.js";


const layoutStore = useLayoutStore()
const route = useRoute()

const props = defineProps({
  userData: Object
})
const emits = defineEmits(['update'])

const resetPassword = async () => {
  layoutStore.showConfirmDialog(Severities.ERROR, '경고', '비밀번호를 초기화하시겠습니까?', '초기화', '취소', async () => {
    await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/employment/${route.params.id}/user/`,
    })
    layoutStore.showAlertDialog(Severities.PRIMARY, '성공', '비밀번호 초기화 후 사용자의 휴대전화로 초기화된 비밀번호를 발송했습니다.', '확인')
  }, null)
}

const inactiveEmployee = async () => {
  layoutStore.showConfirmDialog(Severities.ERROR, '경고', '정말로 비활성화 하시겠습니까?', '비활성화', '취소', async () => {
    await useAuthAxios({
      method: 'DELETE',
      url: `/app/v1/employment/${route.params.id}/`,
    })
    layoutStore.showAlertDialog(Severities.PRIMARY, '성공', '사용자가 비활성화 되었습니다.', '확인')
    emits('update')
  }, null)
}


</script>

<template>
  <div>
    <span class="mr-4 text-lg text-slate-900">주의</span>
    <InfoBlock :description="{text: '새 비밀번호를 설정합니다. 변경된 비밀번호는 사용자의 이메일로 전송됩니다.', severity: Severities.ERROR}"/>
    <button class="btn btn-error mt-4" @click="resetPassword" :disabled="!props.userData.is_active">비밀번호 초기화</button>
    <InfoBlock class="mt-8" :description="{text: '사용자를 비활성화 합니다. 비활성화된 사용자는 시스템에 로그인할 수 없습니다.', severity: Severities.ERROR}"/>
    <button class="btn btn-error mt-4" @click="inactiveEmployee" :disabled="!props.userData.is_active">비활성화</button>
  </div>
</template>

<style scoped>

</style>