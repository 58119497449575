import axios, {AxiosError} from "axios";
import {APIError, BaseError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useAuthStore} from "@/stores/authStore.js";
import {RoutesData} from "@/router/index.js";
import router from "@/router";
import {jwtDecode} from "jwt-decode";
import {Exception} from "sass";


const useAuthAxios = async (options, globalOverlay = true, $externalResults = null) => {
    const layoutStore = useLayoutStore()
    const authStore = useAuthStore()
    if (!authStore.access) {
        layoutStore.addErrorNotification('인증 오류', '로그인이 필요한 서비스 입니다.', 3000)
        await router.push(RoutesData.Auth.children.Login.path)
        return null
    }
    if (globalOverlay) {
        await layoutStore.showOverlay()
    }
    try {
        let decoded = jwtDecode(authStore.access);
        let exp = decoded?.exp
        if (!exp || decoded?.iss !== 'erp.zguhada.com') {
            await authStore.logout()
            throw new Error('인증 토큰이 변조된 것으로 의심됩니다. 다시 로그인해 주세요.')
        }
        const currentTime = Date.now() / 1000;
        if (exp < currentTime - 5) {
            authStore.access = await authStore.refreshToken()
        }
        // authStore.access = await authStore.refreshToken()
        options.headers = options.headers ? {
            ...options.headers,
            Authorization: `Bearer ${authStore.access}`
        } : {Authorization: `Bearer ${authStore.access}`}
        axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
        const response = await axios(options)
        return response.data
    } catch (error) {
        if (!!$externalResults) {
            Object.assign($externalResults, error?.field_errors || {})
        }
        if (error instanceof APIError) {
            throw error
        } else {
            throw new APIError(null, '알 수 없는 오류가 발생했습니다.', null, null, error)
        }
        // throw error
    } finally {
        if (globalOverlay) {
            await layoutStore.hideOverlay()
        }
    }
}

export default useAuthAxios