<script setup>

import dayjs from "dayjs";
import {computed, onMounted, reactive, ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import {useClipboard} from '@vueuse/core'
import DeviceDrawer from "@/components/drawer/DeviceCreateDrawer.vue";
import CallLogDrawer from "@/components/drawer/CallLogDrawer.vue";
import SmallWorkCreateDrawer from "@/components/drawer/work/small/SmallWorkCreateDrawer.vue";
import {FolderArrowDownIcon} from "@heroicons/vue/24/outline";
import SmallWorkDrawer from "@/components/drawer/work/small/SmallWorkDrawer.vue";
import EntryCodeCreateDrawer from "@/components/drawer/EntryCodeCreateDrawer.vue";
import {Severities} from "@/constant.js";
import TransactionTypeSelector from "@/components/selector/choices/TransactionTypeSelector.vue";
import EntryCodeSelector from "@/components/selector/apiSelector/EntryCodeSelector.vue";
import BillDrawer from "@/components/drawer/accounting/BillDrawer.vue";

const router = useRouter()
const route = useRoute()

// const copyKey = (key) => {
//   const { text, copy, copied, isSupported } = useClipboard({ source: key })
//   copy(key)
//   if (copied) alert('복사되었습니다.')
// }

const selectedCode = ref(null)


let data = {
    "id": 6,
    // "serial": "20241226-000004",
    // "work_type": "S1",
    // "method": "T",
    "memo": null,
    // "status": "X",
    "created_at": "2024-12-26T04:40:25.153223Z",
    // "supplier": 1,
    // "supplier_id": 1,
    // "buyer": 2,
    // "buyer_id": 2,
    // "user": "63e53ed2-e06d-4911-96c0-b9849002a249",
    // "history_context": null,
    // "supplier_type": "company",
    // "supplier_name": "지구하다",
    // "buyer_type": "company",
    // "buyer_name": "땡땡디자인",
    // "user_name": "홍찬의",
    // "status_order": 2,
    "receive_status_order": 3,
    // "amount": 520000,
    // "tax": 52000,
    // "gross_total": 572000,
    // "work_type_display": "1T 수집운반"
}

const fields = computed(() => {
    let base = {
        serial: {label: '일련번호', sortable: true, width: 150, type: 'text'},
        status_display: {label: '상태', sortable: 'status_order', width: 80, type: 'text'},
        receive_status_display: {label: '수금상태', sortable: 'receive_status', width: 80, type: 'text'},
        supplier_name: {label: '공급자', width: 150, type: 'text', sortable: false},
        buyer_name: {label: '공급받는자', width: 150, type: 'text', sortable: false},
        work_type_display: {label: '품목', sortable: 'work_type', width: 100, type: 'text'},
        method_display: {label: '청구방법', sortable: 'method', width: 100, type: 'text'},
        gross_total: {label: '총액', sortable: false, width: 100, type: 'number', align: 'right'},
        reconcile_amount: {label: '수금액', sortable: false, width: 100, type: 'number', align: 'right'},
        receivable_amount: {label: '미수금액', sortable: false, width: 100, type: 'number', align: 'right'},
        // amount: {label: '공급가액', sortable: false, width: 100, type: 'number', align: 'right'},
        // tax: {label: '세액', sortable: false, width: 80, type: 'number', align: 'right'},
        user_name: {label: '등록', sortable: false, width: 100, type: 'text'},
        created_at: {label: '등록일시', sortable: 'created_at', width: 140, type: 'datetime'},
        // transaction_target_name: {label: '거래대상', sortable: 'transaction_target', width: 150, type: 'text'},
        // work_type_display: {label: '작업타입', sortable: 'work_type', width: 150, type: 'text'},
        // method_display: {label: '청구방법', sortable: 'method', width: 150, type: 'text'},
        // gross_total: {label: '총액', sortable: 'gross_total', width: 100, type: 'number', left: false},
        // // amount: {label: '공급가액', sortable: 'gross_total', width: 100, type: 'number', left: false},
        // // tax: {label: '세액', sortable: 'gross_total', width: 100, type: 'number', left: false},
        // reconcile_amount: {label: '수입액', sortable: 'gross_total', width: 100, type: 'number', left: false},
        // receivable_amount: {label: '미수액', sortable: 'receivable_amount', width: 100, type: 'number', left: false},
        // status_display: {label: '상태', sortable: 'status_order', width: 150, type: 'datetime'},
        // receive_status_display: {label: '수금상태', sortable: 'receive_status_order', width: 100, type: 'number'},
        // user_name: {label: '등록', sortable: 'user__name', width: 150, type: 'text'},
        // created_at: {label: '등록일시', sortable: true, width: 150, type: 'datetime'},
        // // code_name: {label: '코드명', sortable: false, width: 150, type: 'text'},
        // // code_description: {label: '코드상세', sortable: false, width: 150, type: 'text'},
        // // code_transaction_type_display: {label: '구분', sortable: false, width: 50, type: 'text'},
        // method_display: {label: '거래방법', sortable: 'method', width: 100, type: 'text'},
        // 'total': {label: '합계', sortable: true, width: 120, type: 'text', left: false},
        // amount: {label: '공급가액', sortable: true, width: 120, type: 'text', left: false},
        // tax: {label: '세액', sortable: false, width: 120, type: 'text', left: false},
        // // purchase: {label: '매입', sortable: false, width: 120, type: 'text', left: false},
        // transaction_target_name: {label: '거래대상', sortable: false, width: 150, type: 'text'},
        // origin: {label: '원거래', sortable: true, width: 150, type: 'origin'},
        // user_name: {label: '등록', sortable: true, width: 150, type: 'text'},
        // created_at: {label: '등록', sortable: true, width: 150, type: 'datetime'},
    }
    return {
        ...base,

    }
})
// 테이블 전역 액션
const tableActions = [
    // {
    //   label: 'XLSX 다운로드',
    //   leftIcon: FolderArrowDownIcon,
    //   action: () => {
    //     console.log('xlsx')
    //   }
    // },
    // {
    //   label: '새로고침',
    //   leftIcon: ArrowPathIcon,
    //   action: () => {
    //     console.log('refresh')
    //   }
    // }
]
const navigateToItem = () => {
    return ''
}
// 단행 액션
const rowActions = [
    // {
    //   label: '상세보기',
    //   icon: FolderArrowDownIcon,
    //   action: navigateToItem
    // },
    // {
    //   label: '비활성화',
    //   icon: ArrowPathIcon,
    //   action: (item) => {
    //     console.log('deactivate ', item)
    //   }
    // }
]
// 행들 액션
const rowsAction = [
    // {
    //   label: '청구',
    //   icon: FolderArrowDownIcon,
    //   severity: Severities.PRIMARY,
    //   action: async (selected) => {
    //     let options = {
    //       method: 'POST',
    //       url: `/app/v1/accounting/bill/`,
    //       data: {
    //         entries: selected.map(entry => entry.id)
    //       }
    //     }
    //     let response =  await useAuthAxios(options, false)
    //     update()
    //   }
    // },
]

// fetch
const tableKey = ref(1)

const fetch = async (query = null) => {
    let q = {
        ...query,
    }
    let options = {
        method: 'GET',
        url: `/app/v1/accounting/bill/`,
        params: q,
    }
    return await useAuthAxios(options, false)
}

// drawer
const update = () => {
    tableKey.value += 1
}


const dataFilter = (data) => {
    let temp = JSON.parse(JSON.stringify(data))
    // temp['transaction_target_type'] = temp.transaction_target_type === 'customer' ? '고객' : '거래처'
    temp['receivable_amount'] = temp.receivable_amount.toLocaleString()
    temp['reconcile_amount'] = temp.reconcile_amount.toLocaleString()
    temp['gross_total'] = temp.gross_total.toLocaleString()
    temp['amount'] = temp.amount.toLocaleString()
    temp['tax'] = temp.tax ? temp.tax.toLocaleString() : null
    // temp['reconcile_amount'] = temp.reconcile_amount.toLocaleString()
    // temp['receivable_amount'] = temp.receivable_amount.toLocaleString()

    // temp['total'] = (Number(temp.amount) + (temp.tax === null ? 0 : Number(temp.tax))).toLocaleString()
    // temp['amount'] = temp.amount.toLocaleString()
    // temp.address = [temp.address, temp.address_detail].join(" ") || null
    // temp.manager_cellphone = data.manager_cellphone ? formatPhoneNumber(data.manager_cellphone) : null
    // let departure_waypoint = data.waypoint_set.filter(waypoint => waypoint.type === "D")[0]
    // let arrival = data.waypoint_set.filter(waypoint => waypoint.type === "A")[0]
    //
    // let departure_full_address = departure_waypoint?.site?.poi?.full_address || null
    // let departure_address_detail = departure_waypoint?.site?.detail || null
    // let departure_address = [departure_full_address, departure_address_detail].filter(part => part !== null).join(' ') || null
    //
    // data['at_date'] = departure_waypoint?.at_datetime?.date || null
    // data['at_time'] = departure_waypoint?.at_datetime?.time || null
    // data['company'] = departure_waypoint?.site?.company?.name
    // data['departure'] = departure_address
    // data['departure_company'] = departure_waypoint?.site?.company?.name || null
    // data['departure_manager'] = departure_waypoint?.site || null
    // temp['waste'] = [data.waste_class_display, data.waste_type_display].filter(part => typeof part === 'string').join('/') || null
    // data['manager_name'] = departure_waypoint?.site?.manager_name || departure_waypoint?.site?.manager?.customer?.name
    // let cellphone = departure_waypoint?.site?.manager_cellphone || departure_waypoint?.site?.manager?.customer?.cellphone
    // data['manager_cellphone'] = cellphone ? formatPhoneNumber(cellphone) : null
    // // data['departure_manager'] = departure_waypoint?.site?.manager_name || null
    // // data['departure_manager_cellphone'] = departure_waypoint?.site?.manager_cellphone ? formatPhoneNumber(departure_waypoint?.site?.manager_cellphone) : null
    // data['arrival'] = arrival?.company?.name || null
    // // data['number'] = formatPhoneNumber(data.number)
    // // data['type_display'] = data.missed_reason === "0" ? data.call_type_display : `${data.call_type_display}(${data.missed_reason_display})`
    // // data['manager_name'] = data.manager_name ? data.manager_name : '미지정'
    // // data['process'] = data.processed_at !== null
    // temp['arrival'] = data.arrival?.company_name || null
    return temp
}

const filter = reactive({
    transaction_type: null,
    code: null,
})


const queryList = () => {
    let query = {...route.query, page: 1}
    // query['transaction_type'] = filter.transaction_type?.value ? filter.transaction_type.value : null
    // query['code'] = filter.code ? filter.code?.id || filter.code : null
    router.push({query: query})
}

onMounted(() => {
    // filter.transaction_type = route.query.transaction_type || null
    // filter.code = route.query.code || null
})

watch(() => route.query, (newValue, oldValue) => {
    // if (newValue === oldValue) {
    //   return null
    // }
    // filter.transaction_type = route.query.transaction_type || null
    // filter.code = route.query.code || null
}, {deep: true})

const selectedBill = ref(null)

const showDrawer = ref(false)
const closeDrawer = () => {
    showDrawer.value = false
}
const openDrawer = (bill) => {
    selectedBill.value = bill
    showDrawer.value = true
}
</script>

<template>
    <DefaultLayoutBase title="회계 / 청구서">
        <template #body>
            <TableComponent :key="tableKey" :dataFilter="dataFilter" :fetch="fetch"
                            :fields="fields" :open-action="openDrawer" :query-filter="queryList"
                            :rowActions="rowActions"
                            :rowsAction="rowsAction" :sorts="['status_order', 'created_at']"
                            :tableActions="tableActions"
                            entityName="매출" entityUnit="건" @update="update">
                <template #filter>
                    <EntryCodeSelector v-model="filter.code"/>
                    <TransactionTypeSelector v-model="filter.transaction_type" :nullable="true"/>
                </template>
            </TableComponent>
            <BillDrawer :bill="selectedBill" :open="showDrawer" @close="closeDrawer" @update:bill="update"/>
        </template>
    </DefaultLayoutBase>
    <!--  <EntryCodeCreateDrawer :open="isOpenCreate" @close="closeCreate" @create="update"/>-->
    <!--  <SmallWorkCreateDrawer :open="isOpenCreate" @close="closeCreate" @create:smallWork="update"/>-->
    <!--  <SmallWorkDrawer :work="selectedCode" :open="selectedCode !== null" @close="selectedCode = null" @update="update" @create="create"/>-->
</template>