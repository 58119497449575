import {Severities} from "@/constant";
import {createVNode, render} from "vue";
import ConfirmDialogComponent from "@/components/commons/Confirm/ConfirmDialogComponent.vue";

/**
 * useConfirm 함수
 * Confirm Dialog를 쉽게 띄우기 위한 헬퍼 함수
 */
export function useConfirm() {
    let confirmContainer = null;

    /**
     * Confirm Dialog 호출 함수
     * @param {Object} options - 다이얼로그에 표시할 옵션
     * @param {string} options.summary - 다이얼로그 제목
     * @param {string | Array} options.detail - 다이얼로그 세부내용
     * @param {string} options.severity - 다이얼로그 타입 (ERROR, PRIMARY, SECONDARY)
     * @param {string} options.acceptString - 확인 버튼 텍스트
     * @param {string} options.cancelString - 취소 버튼 텍스트
     * @param {Function} options.onAccept - 확인 버튼 클릭 시 실행할 콜백
     * @param {Function} options.onCancel - 취소 버튼 클릭 시 실행할 콜백
     */
    const show = ({
                      summary = "",
                      detail = "",
                      severity = Severities.PRIMARY,
                      acceptString = "확인",
                      cancelString = "취소",
                      onAccept = () => {
                      },
                      onCancel = () => {
                      },
                  }) => {
        // DOM 컨테이너 생성
        confirmContainer = document.createElement("div");
        document.body.appendChild(confirmContainer);

        // Confirm 컴포넌트 생성
        const vueNode = createVNode(ConfirmDialogComponent, {
            show: true,
            summary,
            detail,
            severity,
            acceptString,
            cancelString,
            onAccept: async () => {
                await onAccept();
                closeConfirm();
            },
            onCancel: async () => {
                await onCancel();
                closeConfirm();
            },
        });
        render(vueNode, confirmContainer);
    };

    const closeConfirm = () => {
        render(null, confirmContainer); // 마운트 해제
        document.body.removeChild(confirmContainer); // DOM 제거
        confirmContainer = null;
    };

    return {show, closeConfirm};
}