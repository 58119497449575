<script setup>

import {useLayoutStore} from "@/stores/layoutStore.js";


const layoutStore = useLayoutStore()

const props = defineProps({
  vehicle: {type: [null, Object], required: true}
})
</script>

<template>
asdf
</template>

<style scoped>

</style>