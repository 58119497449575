<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import {useLayoutStore} from "@/stores/layoutStore.js";

const layoutStore = useLayoutStore()

const props = defineProps({
  historyContextId: {type: [null, String], required: true}
})

const data = ref(null)
// onMounted(async () => {
//   data.value = useAuthAxios({
//     method: "GET",
//     url: `/app/v1/customer/${props.customer.id}/history/`
//   })
// })
</script>

<template>
<!--  <div>{{ data }}</div>-->
  기능 준비중
</template>

<style scoped>

</style>