<script setup>

import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {Severities} from "@/constant.js";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";
import dayjs from "dayjs";

const layoutStore = useLayoutStore()

const props = defineProps({
  work: {type: [null, Object], required: true, default: null},
  canUpdateWaypoint: {type: Boolean, required: false, default: false},
})
const emits = defineEmits(['update'])

const data = reactive({
  at_date: props.work?.departure?.at_date || null,
  at_time: props.work?.departure?.at_time || null,
  description: props.work?.departure?.description || null,
})

const assignData = (original) => {
  data.at_date = original?.departure?.at_date || null
  data.at_time = original?.departure?.at_time || null
  data.description = original?.departure?.description || null
}

onMounted(() => {
  assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  } else if (newValue) {
    assignData(newValue)
  }
}, {deep: true})

const model = {
  at_date: {
    required: requiredWithMessage('진입일 선택해주세요.')
  },
  at_time: {externalValidator},
  description: {externalValidator},
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const save = async (notify) => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return null
  let response = await useAuthAxios({
    method: 'POST',
    url: `/app/v1/work/small/${props.work.id}/departure/`,
    data: {
      at_date: data.at_date,
      at_time: data.at_time || null,
      description: data.description || null,
      notify: notify
    }
  }, false, $externalResults.value)
  layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
  validator.value.$reset()
  emits('update', response)
  return data
}

const showConfirm = ref(false)

const beforeSave = () => {
  validator.value.$touch()
  if (validator.value.$error) {
    return null
  }
  if (props.driver) {
    showConfirm.value = true
  } else {
    save(false)
  }
}

const readonly = computed(() => {
  return !props.canUpdateWaypoint
})
</script>

<template>
  <div class="pt-4 mt-4">
    <div class="flex items-center justify-start gap-x-3 mb-3">
      <div class="text-sm font-semibold">진입</div>
    </div>
<!--    <pre>{{data}}</pre>-->
    <form @submit.prevent="beforeSave">
      <div class="grid grid-cols-6 md:grid-cols-12 gap-x-4 gap-y-4">
        <DatePickerInput v-model="data.at_date" class="col-span-3 md:col-span-3" :required="true" :readonly="readonly"
                         :errors="validator.at_date.$errors.map(e => e.$message)" label="진입일" :min-date="props?.work?.performance_date ? dayjs(props.work.performance_date).format('YYYY-MM-DD') : null"/>
        <TimePickerInput v-model="data.at_time" class="col-span-3 md:col-span-3" :readonly="readonly"
                         :errors="validator.at_time.$errors.map(e => e.$message)" label="진입 시간"/>
        <TextInput v-model="data.description" class="col-span-4 md:col-span-4" :readonly="readonly"
                   :errors="validator.description.$errors.map(e => e.$message)"
                   label="특이사항"/>
        <button class="btn btn-primary col-span-2 md:col-span-2" :disabled="readonly" type="submit">저장</button>
      </div>
    </form>
  </div>
  <ConfirmDialogComponent :open="showConfirm" @close="showConfirm = false"
                          title="알림 발송" :detail="'기사에게 알림을 발송하겠습니까?'"
                          accept-string="발송" reject-string="미발송"
                          @accept="save(true)" @reject="save(false)"/>
</template>

<style scoped>

</style>