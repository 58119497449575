<script setup>

import dayjs from "dayjs";
import {onMounted, reactive, ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import SalesDrawer from "@/components/drawer/SalesDrawer.vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import {useClipboard, useConfirmDialog} from '@vueuse/core'
import DeviceDrawer from "@/components/drawer/DeviceCreateDrawer.vue";
import CallLogDrawer from "@/components/drawer/CallLogDrawer.vue";
import SmallWorkCreateDrawer from "@/components/drawer/work/small/SmallWorkCreateDrawer.vue";
import {FolderArrowDownIcon} from "@heroicons/vue/24/outline";
import SmallWorkDrawer from "@/components/drawer/work/small/SmallWorkDrawer.vue";
import EntryCodeCreateDrawer from "@/components/drawer/EntryCodeCreateDrawer.vue";
import {Severities} from "@/constant.js";
import TransactionTypeSelector from "@/components/selector/choices/TransactionTypeSelector.vue";
import EntryCodeSelector from "@/components/selector/apiSelector/EntryCodeSelector.vue";
import BillDrawer from "@/components/drawer/accounting/BillDrawer.vue";
import {useAlert} from "@/components/commons/Alert/useAlert.js";
import {useConfirm} from "@/components/commons/Confirm/useConfirm.js";

const router = useRouter()
const route = useRoute()

// const copyKey = (key) => {
//   const { text, copy, copied, isSupported } = useClipboard({ source: key })
//   copy(key)
//   if (copied) alert('복사되었습니다.')
// }

const selectedCode = ref(null)


const fields = reactive({
    serial: {label: '일련번호', sortable: true, width: 150, type: 'text'},
    code: {label: '코드', type: 'text', width: 100},
    code_name: {label: '코드명', type: 'text', width: 150},
    origin_type: {label: '품목', type: 'text', width: 80},
    origin_serial: {label: '매출 일련번호', type: 'text', width: 150},
    supplier_name: {label: '공급자', type: 'text', width: 150},
    buyer_name: {label: '공급받는자', type: 'text', width: 150},
    'total': {label: '합계', type: 'number', align: 'right', width: 100},
    amount: {label: '공급가액', type: 'number', align: 'right', width: 100},
    tax: {label: '세액', type: 'number', align: 'right', width: 100},
    method_display: {label: '결제방법', type: 'text', width: 100},
    memo: {label: '비고', type: 'text', width: 150},
    user_name: {label: '작성자', type: 'text', width: 100},
    created_at: {label: '등록일시', type: 'datetime', width: 150},
})
// 테이블 전역 액션
const tableActions = [
    // {
    //   label: 'XLSX 다운로드',
    //   leftIcon: FolderArrowDownIcon,
    //   action: () => {
    //     console.log('xlsx')
    //   }
    // },
    // {
    //   label: '새로고침',
    //   leftIcon: ArrowPathIcon,
    //   action: () => {
    //     console.log('refresh')
    //   }
    // }
]
const navigateToItem = () => {
    return ''
}
// 단행 액션
const rowActions = [
    // {
    //   label: '상세보기',
    //   icon: FolderArrowDownIcon,
    //   action: navigateToItem
    // },
    // {
    //   label: '비활성화',
    //   icon: ArrowPathIcon,
    //   action: (item) => {
    //     console.log('deactivate ', item)
    //   }
    // }
]
// 행들 액션
const rowsAction = [
    {
        label: '청구서 등록',
        icon: FolderArrowDownIcon,
        severity: Severities.PRIMARY,
        action: async (selected) => {
            const confirmDialog = useConfirm()
            confirmDialog.show({
                summary: '청구 등록',
                detail: '청구를 등록하시겠습니까?',
                acceptString: '등록',
                onAccept: async () => {
                    let options = {
                        method: 'POST',
                        url: `/app/v1/accounting/bill/`,
                        data: {
                            entries: selected.map(entry => entry.id)
                        }
                    }
                    let response = await useAuthAxios(options, false)
                    openDrawer(response)
                    update()
                }
            })
        }
    },
]

// fetch
const tableKey = ref(1)

const fetch = async (query = null) => {
    let q = {
        ...query,
        can_register_bill: true,
        subsidiary_type: 'supplier'
    }
    let options = {
        method: 'GET',
        url: `/app/v1/accounting/entry/`,
        params: q,
    }
    return await useAuthAxios(options, false)
}

// drawer
const update = () => {
    tableKey.value += 1
}


const dataFilter = (data) => {
    let temp = JSON.parse(JSON.stringify(data))
    temp['total'] = temp.total.toLocaleString()
    temp['amount'] = temp.amount.toLocaleString()
    temp['tax'] = temp.tax ? temp.tax.toLocaleString() : null
    temp['origin_type'] = {work: '작업'}[data.origin_type]
    return temp
}

const filter = reactive({
    transaction_type: null,
    code: null,
})


const queryList = () => {
    let query = {...route.query, page: 1}
    query['transaction_type'] = filter.transaction_type?.value ? filter.transaction_type.value : null
    query['code'] = filter.code ? filter.code?.id || filter.code : null
    router.push({query: query})
}

onMounted(() => {
    filter.transaction_type = route.query.transaction_type || null
    filter.code = route.query.code || null
})

watch(() => route.query, (newValue, oldValue) => {
    if (newValue === oldValue) {
        return null
    }
    filter.transaction_type = route.query.transaction_type || null
    filter.code = route.query.code || null
}, {deep: true})


const selectedBill = ref(null)

const showDrawer = ref(false)
const closeDrawer = () => {
    showDrawer.value = false
}
const openDrawer = (bill) => {
    selectedBill.value = bill
    showDrawer.value = true
}
</script>

<template>
    <DefaultLayoutBase title="회계 / 미청구 매출">
        <template #body>
            <TableComponent :key="tableKey" :dataFilter="dataFilter" :fetch="fetch"
                            :fields="fields" :query-filter="queryList" :rowActions="rowActions"
                            :rowsAction="rowsAction" :tableActions="tableActions" entityName="매출"
                            entityUnit="건" @update="update">
                <template #filter>
                    <EntryCodeSelector v-model="filter.code"/>
                    <TransactionTypeSelector v-model="filter.transaction_type" :nullable="true"/>
                </template>
            </TableComponent>
            <BillDrawer :bill="selectedBill" :open="showDrawer" @close="closeDrawer" @update:bill="update"/>
        </template>
    </DefaultLayoutBase>
    <!--  <EntryCodeCreateDrawer :open="isOpenCreate" @close="closeCreate" @create="update"/>-->
    <!--  <SmallWorkCreateDrawer :open="isOpenCreate" @close="closeCreate" @create:smallWork="update"/>-->
    <!--  <SmallWorkDrawer :work="selectedCode" :open="selectedCode !== null" @close="selectedCode = null" @update="update" @create="create"/>-->
</template>