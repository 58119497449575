<script setup>
import {onBeforeUnmount, reactive, ref, toRef} from 'vue'
import {cellphoneValidator, emailValidator, maxLengthWithMessage, minLengthWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRouter} from "vue-router";
import {Sizes} from "@/constant.js";
import ModalBase from "@/components/modals/ModalBase.vue";
import CompanySelector from "@/components/selector/apiSelector/CompanySelector.vue";
import {helpers, required} from "@vuelidate/validators";
import TextInput from "@/components/forms/TextInput.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close'])

const layoutStore = useLayoutStore()

const data = reactive({
  name: null,
  email: null,
  cellphone: null,
  company: null
})

const close = () => {
  data.name = null
  data.email = null
  data.cellphone = null
  data.company = null
  emits('close')
  validator.value.$reset()
}

const $externalResults = ref({})
const model = {
  name: {
    required: helpers.withMessage('고객명을 입력해주세요.', required),
    minLength: minLengthWithMessage('성명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('성명을 30글자 이하로 입력해주세요.', 30)
  },
  email: {
    required: helpers.withMessage('필수 항목입니다.', required),
    format: emailValidator()
  },
  cellphone: {
    required: helpers.withMessage('필수 항목입니다.', required),
    format: cellphoneValidator(),
  },
  company: {
    required: helpers.withMessage('사업장을 선택해주세요.', (value) => {
      return value !== null
    })
  }
}

const validator = useVuelidate(model, data, {$externalResults})
const router = useRouter()


const save = async () => {
  console.log('create')
  let valid = await validator.value.$validate()
  $externalResults.value = {}
  console.log(valid)
  if (!valid) return null
  let prepare = {
    name: data.name,
    email: data.email,
    company: data.company?.id || null,
    cellphone: data.cellphone
  }
  try {
    const employee = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/employment/`,
      data: prepare
    }, false, $externalResults.value)
    close()
    await router.push(`/settings/employee/${employee.id}/`)
    layoutStore.addSuccessNotification('성공', `사용자 ${employee.user.name}이 등록되었습니다.`, 3000)
  } catch (e) {
  await validator.value.$reset()
  await validator.value.$touch()
  await validator.value.$clearExternalResults()
    throw e
  }
}

onBeforeUnmount(close)
</script>

<template>
  <ModalBase :callback="save" :isOpen="props.isOpen" :size="Sizes.SMALL" title="사업장 등록" @close="close">
    <form class="w-full p-4" @submit.prevent="save">
      <TextInput v-model="data.name" :required="true" :errors="validator.name.$errors.map(e => e.$message)"
                 class="mb-4" label="성명"/>
      <CompanySelector v-model="data.company" :errors="validator.company.$errors.map(e => e.$message)"
                       :required="true" class="mb-4"/>
      <TextInput v-model="data.email" :required="true" :errors="validator.email.$errors.map(e => e.$message)"
                 class="mb-4" label="이메일" type="email" :messages="['로그인시 사용되는 아이디 입니다.']"/>

      <TextInput v-model="data.cellphone" :required="true" :errors="validator.cellphone.$errors.map(e => e.$message)"
                 class="mb-4" label="휴대전화번호"/>
      <button type="submit" class="sr-only">저장</button>
    </form>
  </ModalBase>
</template>

<style scoped>

</style>