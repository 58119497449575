<script setup>
import ModalBase from "@/components/modals/ModalBase.vue";
import {Sizes} from "@/constant.js";
import {onBeforeMount, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage,
  nullBrnValidator,
  requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRouter} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import TextInput from "@/components/forms/TextInput.vue";
import TextArea from "@/components/forms/TextArea.vue";

const layoutStore = useLayoutStore()
const router = useRouter()

const props = defineProps({
  isOpen: {type: Boolean, required: true},
})
const emits = defineEmits(['close'])

const data = reactive({
  name: '',
  brn: '',
  tel: '',
  company_type: null,
  description: '',
})

const $externalResults = ref({})
const model = {
  name: {
    required: helpers.withMessage('고객명을 입력해주세요.', required),
    minLength: minLengthWithMessage('사업자명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('사업자명을 30글자 이하로 입력해주세요.', 30)
  },
  brn: {
    required: nullBrnValidator('필수 항목입니다.')
  },
  tel: {externalValidator},
  company_type: {required: helpers.withMessage('타입을 선택해주세요.', required)},
  description: {}
}

const validator = useVuelidate(model, data, {$externalResults})
const setCompanyType = (companyType) => {
  data.company_type = companyType
}

const save = async () => {
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) return

  data.brn = data.brn === '' ? null : data.brn
  data.description = data.description === '' ? null : data.description
  data.tel = data.tel === '' ? null : data.tel
  data.company_type = data.company_type.value
  try {
    const company = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/company/`,
      data: {...data, is_subsidiary: true}
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    emits('close')
    await router.push({name: 'settings-company-detail', params: {id: company.id}})
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const close = () => {
  emits('close')
}

onBeforeUnmount(() => {
  data.name = ''
  data.tel = ''
  data.brn = ''
  data.description = ''
})

</script>

<template>
  <ModalBase :callback="save" :isOpen="props.isOpen" :size="Sizes.SMALL" title="사업장 등록" @close="close">
    <form class="w-full p-4" @submit.prevent="save">
      <TextInput v-model="data.name" :errors="validator.name.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="사업장명"/>
      <ChoiceSelectorBase v-model="data.company_type" choice-type="CompanyTypeChoice" label="타입" class="mb-4"/>
      <TextInput v-model="data.tel" :errors="validator.tel.$errors.map(e=>e.$message)" class="mb-4" label="대표번호"/>
      <TextInput v-model="data.brn" :errors="validator.brn.$errors.map(e=>e.$message)" class="mb-4" label="사업자등록번호"/>
      <TextArea v-model="data.description" :errors="validator.description.$errors.map(e=>e.$message)" label="설명"/>
    </form>
  </ModalBase>
</template>

<style scoped>

</style>