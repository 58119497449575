<script setup>

import {ChevronUpIcon, EllipsisVerticalIcon} from "@heroicons/vue/24/outline/index.js";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {computed, ref} from "vue";
import {useElementBounding, useWindowSize} from "@vueuse/core";


const props = defineProps({
  class: {
    type: [Array, Object, String, null],
    required: false,
    default: ['relative', 'mr-2']
  },
  header: {
    type: [String, null], required: false, default: null
  },
  rowActions: {type: Array, default: [], required: false},
  positionClass: {
    type: [Array, Object, String, null],
    required: false,
    default: ['bottom-0', 'right-0']
  },
  icon: {
    type: [Function, null], required: false, default: null
  },
  text: {
    type: [String, null], required: false, default: null
  },
  data: {
    type: Object, required: false, default: null
  }
})

const buttonRef = ref(null)
const itemsRef = ref(null)

const buttonBounding = useElementBounding(buttonRef, {immediate: false})
const screenSize = useWindowSize()

const calculatePosition = (buttonBounding, screenSize) => {
  const position = {};
  const x = buttonBounding.left.value + buttonBounding.width.value/2
  const y = buttonBounding.top.value + buttonBounding.height.value/2
  const screenWidth = screenSize.width.value
  const screenHeight = screenSize.height.value
  // 화면 중심을 기준으로 x, y의 사분면을 결정
  const isRight = x > screenWidth / 2;
  const isBottom = y > screenHeight / 2;
  if (isRight && !isBottom) {
    // 1사분면: 팝오버의 오른쪽 위 모서리를 기준점에 위치
    position.right = `${screenWidth - x}px`;
    position.top = `${y}px`;
  } else if (!isRight && !isBottom) {
    // 2사분면: 팝오버의 왼쪽 위 모서리를 기준점에 위치
    position.left = `${x}px`;
    position.top = `${y}px`;
  } else if (!isRight && isBottom) {
    // 3사분면: 팝오버의 왼쪽 아래 모서리를 기준점에 위치
    position.left = `${x}px`;
    position.bottom = `${screenHeight - y}px`;
  } else if (isRight && isBottom) {
    // 4사분면: 팝오버의 오른쪽 아래 모서리를 기준점에 위치
    position.right = `${screenWidth - x}px`;
    position.bottom = `${screenHeight - y}px`;
  }

  return position;
}

const itemPosition = computed(() => {
  return calculatePosition(buttonBounding, screenSize)
})
</script>

<template>
  <Menu :class="props.class" as="div">
    <slot name="button">
      <MenuButton :class="props.text ? 'btn btn-secondary':'rounded bg-white hover:bg-slate-100 flex items-center justify-center size-[30px] min-h-[30px] min-w-[30px] border'"
                  as="button" ref="buttonRef"
                  type="button">
        <template v-if="props.icon">
          <ChevronUpIcon class="size-4"/>
        </template>
        <template v-else-if="props.text">
          {{ props.text }}
        </template>
        <template v-else>
          <EllipsisVerticalIcon class="size-4"/>
        </template>
      </MenuButton>
    </slot>
    <!--    <DropdownItems :header="props.header" :rowActions="props.rowActions" :positionClass="positionClass"/>-->
    <MenuItems :class="props.positionClass" as="div" ref="itemsRef"
               class="absolute z-50 min-w-40 bg-white space-y-0 shadow-md border border-1 border-gray-200 focus:outline-none">
      <div v-if="props.header" class="flex h-[40px] w-full select-none items-center px-2 border-b">
        {{ props.header }}
      </div>
      <MenuItem v-for="item in props.rowActions" v-slot="{ active }" as="div">
        <button
            class="flex h-[40px] w-full select-none items-center justify-start px-2 hover:bg-slate-100 cursor-pointer gap-x-3"
            @click="item.action(props.data)">
          <component :is="item.leftIcon" v-if="item.leftIcon" class="size-5 text-slate-900"/>
          <span :class="[item.current ? 'font-bold' : '']" class="text-sm text-slate-900">
            <span class="text-sm text-slate-900">{{ item.label }}</span>
          </span>
          <component :is="item.rightIcon" v-if="item.rightIcon" class="size-4 text-slate-900"/>
        </button>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>

<style scoped>

</style>