<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import CustomerDefaultInfo from "@/components/drawer/partial/CustomerDefaultInfo.vue";
import {onMounted, ref} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";

const props = defineProps({
  title: {type: String, required: false, default: '고객 상세'},
  open: {type: Boolean, required: true},
  customer: {type: [null, Object], required: true}
})

const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}

const customer = ref(null)

onMounted(async () => {
  customer.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/customer/${props.customer.id}/`
  })
})

</script>

<template>
  <DrawerBase :open="props.open" :target="props.customer" :title="props.title" @close="close">
    <template #default>
      <CustomerDefaultInfo :customer="customer" v-if="customer"/>
    </template>
  </DrawerBase>
</template>