<script setup>
import {nextTick, onMounted, ref, watch} from "vue";
import {Dialog, DialogDescription, DialogPanel, DialogTitle} from "@headlessui/vue";
import {XMarkIcon} from "@heroicons/vue/24/solid/index.js";

const props = defineProps({
  show: {type: Boolean, default: false, required: true},
  lat: {type: [String, Number, null], default: 37.5405697, required: false},
  lng: {type: [String, Number, null], default: 127.05501479, required: false},
  address: {type: [String, null], default: null, required: false}
})

const emits = defineEmits(['close'])

const map = ref(null)
const marker = ref(null)
// 지도 표시
const loadMap = () => {
  let latlng = new Tmapv3.LatLng(props.lat, props.lng)
  map.value = new Tmapv3.Map("map_div", {
    center: latlng,
    width: "100%",
    height: "600px",
    zoom: 16
  });
  marker.value = new Tmapv3.Marker({
    position: latlng,
    map: map.value
  });
}

const clearMapMarker = () => {
  marker.value.setMap(null)
  map.value.destroy()
}

const close = () => {
  emits('close')
  clearMapMarker()
}

//37.53823715
//127.08384556

watch(() => props.show, (newValue) => {
  if (newValue) {
    nextTick(() => {
      document.getElementById('map_div').innerHTML = null
      loadMap()
    })
  }
})
</script>

<template>
  <Dialog :open="props.show" @close="close">
    <div class="fixed inset-0 flex items-center justify-center bg-gray-900/30 z-50">
      <DialogPanel class="w-1/2 bg-primary-500 height-[600px] ">
        <div class="w-full flex items-center justify-between px-4 py-2">
          <DialogTitle class="text-sm font-semibold text-white ">지도</DialogTitle>
          <XMarkIcon class="size-5 text-white" @click="close"/>
        </div>
        <div class="px-4 pt-0 pb-2 text-white text-sm" v-if="props.address">
          {{props.address}}
        </div>
        <div id="map_div"></div>
      </DialogPanel>
    </div>
  </Dialog>

</template>

<style scoped>

</style>