<script setup>
const props = defineProps({
  company: {
    type: [Object, null],
    required: true
  }
})
</script>

<template>
<template v-if="props.company">
  {{ props.company.name }}
</template>
<template v-else>
</template>
</template>

<style scoped>

</style>