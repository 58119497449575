<script setup>

import useVuelidate from "@vuelidate/core";
import {reactive, computed, ref} from "vue";
import logo from "@/assets/image/logo.svg";
import useNoAuthAxios from "@/composables/useNoAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import {cellphoneValidator, requiredWithMessage} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {RoutesData} from "@/router/index.js";
import {helpers, required} from "@vuelidate/validators";
import {Stage} from "@/constant.js";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const route = useRoute()

const layoutStore = useLayoutStore()

const data = reactive({
  cellphone: Stage === 'LOCAL' ? '01024846313' : route.query.cellphone
})

const model = {
  cellphone: {
    required: helpers.withMessage('필수 항목입니다.', required),
    format: cellphoneValidator(),
  }
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})

const codeData = reactive({
  code: '',
  password1: '',
  password2: '',
  reset: null,
})
const codeModel = {
  code: {
    required: requiredWithMessage('필수 항목입니다.'),
  },
  password1: {
    required: requiredWithMessage('필수 항목입니다.'),
  },
  password2: {
    same: {
      $validator: (value) => value === codeData.password1,
      $message: '비밀번호가 일치하지 않습니다.'
    }
  },
}

const codeExternalResults = ref({})

const codeValidator = useVuelidate(codeModel, codeData, {$externalResults: codeExternalResults})


const timeLimit = ref(180)
const counter = ref(null)

const requestCode = async () => {
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    const options = {
      method: 'PUT',
      url: `/app/v1/user/password/reset/`,
      data: data,
    }
    const response = await useNoAuthAxios(options)
    codeData.reset = response.id
    counter.value = setInterval(() => {
      timeLimit.value -= 1
    }, 1000)
    layoutStore.addInfoNotification('발송완료', '휴대전화번호로 인증번호가 발송되었습니다.', 2000)
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    } else {
      throw e
    }
  }
}

const codeValidation = async () => {
  codeExternalResults.value = {}
  let valid = await codeValidator.value.$validate()
  if (!valid) return
  try {
    const options = {
      method: 'POST',
      url: `/app/v1/user/password/reset/`,
      data: codeData,
    }
    const response = await useNoAuthAxios(options)
    layoutStore.addInfoNotification('완료', '비밀번호가 변경되었습니다. 변경된 비밀번호로 로그인 해주세요.', 2000)
    await router.push(RoutesData.Auth.children.Login.path)
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const reset = async () => {
  $externalResults.value = {}
  codeExternalResults.value = {}
  codeData.reset = null
  timeLimit.value = 180
  clearInterval(counter.value)
}

const timeLimitDisplay = computed(() => {
  let minute = Math.floor(timeLimit.value / 60)
  let second = `${timeLimit.value % 60}`.length === 1 ? `0${timeLimit.value % 60}` : `${timeLimit.value % 60}`
  return `${minute}:${second}`
})

</script>
<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="md:mx-auto md:w-full md:max-w-sm">
      <img :src="logo" alt="지구하다" class="mx-auto h-8 w-auto"/>
    </div>

    <div class="mt-10 md:mx-auto sm:w-full sm:max-w-sm">
      <form v-if="codeData.reset === null" class="space-y-6" novalidate @submit.prevent="requestCode">
        <TextInput v-model="data.cellphone"
                   :errors="validator.cellphone.$errors.map(e => e.$message)"
                   :messages="['등록된 휴대전화 번호로 인증번호를 발송합니다.']" :required="true"
                   label="휴대전화번호" placeholder="휴대전화번호"/>
        <!-- TODO captcha -->
        <div>
          <button class="btn btn-primary w-full" type="submit">인증번호 발송</button>
          <button class="btn btn-secondary w-full mt-2" type="button"
                  @click="() => router.push(RoutesData.Auth.children.Login.path)">취소
          </button>
        </div>
      </form>
      <form v-if="codeData.reset !== null" class="space-y-6" novalidate @submit.prevent="codeValidation">
        <TextInput :errors="codeValidator.code.$errors.map(error => error.$message)" :messages="['휴대전화번호로 전송된 인증번호를 입력하세요.']"
                   v-model="codeData.code" label="인증번호" name="code">
          <template #button>
            <div class="text-sm">
              <span class="text-primary-600 font-semibold">{{ timeLimitDisplay }}</span>
            </div>
          </template>
        </TextInput>
        <TextInput :errors="codeValidator.password1.$errors.map(error => error.$message)" v-model="codeData.password1"
                   label="비밀번호" name="password1" type="password"/>
        <TextInput :errors="codeValidator.password2.$errors.map(error => error.$message)" v-model="codeData.password2"
                   label="비밀번호 확인" name="password2" type="password"/>
        <div>
          <button type="submit" class="btn btn-primary w-full">비밀번호 변경</button>
          <button type="button" class="btn btn-secondary w-full mt-2" @click="reset">초기화</button>
        </div>
      </form>
    </div>
  </div>
</template>
