<script setup>
import {useClipboard} from '@vueuse/core'
import {ClipboardDocumentIcon} from "@heroicons/vue/24/solid/index.js";
import {nextTick, ref} from "vue";
import SimplePopover from "@/components/commons/SimplePopover.vue";
import {PopoverButton} from "@headlessui/vue";

const props = defineProps({
  source: {type: String, required: true},
})
const {text, copy, copied, isSupported} = useClipboard({source: props.source})

</script>

<template>
    <button type="button" @click="copy(source)" v-if="isSupported" class="flex gap-x-1">
      <ClipboardDocumentIcon class="size-4 text-primary-400"/>
      <!-- by default, `copied` will be reset in 1.5s -->
<!--      <span v-if="!copied">Copy</span>-->
      <span v-if="copied" class="text-xs">복사됨</span>
    </button>
<!--    <p>Current copied: <code>{{ text || 'none' }}</code></p>-->
</template>

<style scoped>

</style>