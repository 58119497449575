<script setup>

import {onMounted, reactive} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import {
  cellphoneValidator,
  maxLengthWithMessage,
  minLengthWithMessage,
} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import {helpers, required} from "@vuelidate/validators";

const authStore = useAuthStore()

const props = defineProps({
  userData: Object
})

const data = reactive({
  name: props.userData.user.name,
  cellphone: props.userData.user.cellphone,
})

const $externalResults = ref({})
const model = {
  name: {
    required: helpers.withMessage('고객명을 입력해주세요.', required),
    minLength: minLengthWithMessage('성명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('성명을 30글자 이하로 입력해주세요.', 30)
  },
  cellphone: {
    required: helpers.withMessage('필수 항목입니다.', required),
    format: cellphoneValidator(),
  }
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()


const save = async () => {
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    await useAuthAxios({
      method: 'POST',
      url: `/app/v1/employment/${props.userData.id}/user/`,
      data: data
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

</script>

<template>

  <div>
    <span class="mr-4 text-lg text-slate-900">기본정보</span>
    <InfoBlock :description="{text: '모든 사용자에게 공개되는 기본 정보입니다.'}"/>
    <form class="w-96" method="post" @submit.prevent="save">
      <div class="input-group mt-4">
        <label class="w-20" for="id-for-email">이메일</label>
        <div>
          <input id="id-for-email" :value="props.userData.user.email" data-1p-ignore disabled type="email">
          <p class="info">이메일은 변경할 수 없습니다.</p>
        </div>
      </div>
      <div :class="{invalid: validator.name.$errors.length > 0}" class="input-group mt-4">
        <label class="w-20" for="id-for-name">성명</label>
        <div>
          <input id="id-for-name" :value="data.name" name="name" type="text"
                 :disabled="!props.userData.is_active"
                 @input="event => data.name = event.target.value">
          <template v-for="error in validator.name.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div :class="{invalid: validator.cellphone.$errors.length > 0}" class="input-group mt-4">
        <label class="w-20" for="id-for-cellphone">휴대전화번호</label>
        <div>
          <input id="id-for-cellphone" :value="data.cellphone" name="cellphone" type="text"
                 :disabled="!props.userData.is_active"
                 @input="event => data.cellphone = event.target.value">
          <template v-for="error in validator.cellphone.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <button class="btn btn-primary" type="submit" :disabled="!props.userData.is_active">저장</button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>