<script setup>

import InlineTableColHeader from "@/components/Inlinetable/InlineTableColHeader.vue";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import InlineTableRow from "@/components/Inlinetable/InlineTableRow.vue";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ExclamationTriangleIcon
} from "@heroicons/vue/24/outline/index.js";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import {onClickOutside} from "@vueuse/core";

const props = defineProps({
    fields: {type: Object, required: true},
    // rows: {type: Array, required: true},
    filter: {type: Function, required: false, default: (row) => row},
    fetch: {type: Function, required: true}
})

const emits = defineEmits(['resize', 'files:update'])

const fields = ref(props.fields)


const data = ref(null)
const page = ref(1)
const currentLimit = ref(50)

const fetch = async (query) => {
    return await props.fetch(query)
}

onMounted(async () => {
    data.value = await fetch({page: page.value, limit: currentLimit.value})
})

const movePage = async (pageNo) => {
    pageNo = parseInt(pageNo)
    if (isNaN(pageNo)) {
        alert('페이지 번호가 올바르지 않습니다.');
        return;
    }
    if (pageNo < 1 || pageNo > data.value.last_page) {
        alert('페이지 번호가 올바르지 않습니다.')
        await movePage(1)
        return;
    }
    page.value = pageNo
    data.value = await fetch({page: page.value, limit: currentLimit.value})
}

const movePageFromNumber = (event) => {
    togglePageInput()
    movePage(event.target.value)
}
// watch
watch(data, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        emits('files:update', newValue.list)
    }
}, {deep: true})

// 페이지당 표시
const setLimit = async (limit) => {
    page.value = 1
    currentLimit.value = limit
    data.value = await fetch({page: page.value, limit: currentLimit.value})
}

const limitList = computed(() => [
    {label: '50개', action: () => setLimit(50), current: currentLimit.value === 50},
    {label: '100개', action: () => setLimit(100), current: currentLimit.value === 100},
    {label: '250개', action: () => setLimit(250), current: currentLimit.value === 250},
    {
        label: '500개',
        action: () => setLimit(500),
        current: currentLimit.value === 500,
        rightIcon: ExclamationTriangleIcon
    },
])

// 페이지 이동
const pageInput = ref(null)

const pageInputShow = ref(false)

const togglePageInput = () => {
    pageInputShow.value = !pageInputShow.value
    nextTick(() => {
        if (pageInputShow.value) {
            pageInput.value.focus()
        }
    })
}

onClickOutside(pageInput, event => {
    togglePageInput()
}, {immediate: false})
</script>

<template>
    <!--  {{data.list[0]}}-->
    <template v-if="data">
        <div
            class="select-none border border-slate-300 border-t-slate-400 mt-2 flex grow flex-col overflow-auto h-full min-h-96 max-h-96">


            <div class="bg-slate-100 flex min-h-[2rem] sticky top-0 z-10 border-slate-400 border-b min-w-fit">
                <template v-for="[fieldKey, fieldData] in Object.entries(fields)">
                    <InlineTableColHeader :fieldData="fieldData" :fieldKey="fieldKey"
                                          @resize="width => fieldData['width'] = width"/>
                </template>
            </div>
            <template v-if="data.list.length > 0">
                <template v-for="row in data.list">
                    <InlineTableRow :fields="fields" :filter="props.filter" :row="row"/>
                </template>
            </template>
            <template v-else>
                <div class="flex w-full h-full items-center justify-center grow">
                    <span class="text-sm text-slate-400">데이터가 없습니다.</span>
                </div>
            </template>
        </div>
        <div class="flex h-10 min-h-10 max-w-full border-t bg-slate-50">
            <div class="mx-2 flex items-center justify-between">
                <button :class="[data.has_prev ? '':'cursor-not-allowed']"
                        :disabled="!data.has_prev"
                        class="rounded p-0.5 hover:bg-slate-200 mr-2 text-slate-400" @click="movePage(data.page_no-1)">
                    <ChevronLeftIcon class="size-5 min-w-5"/>
                </button>
                <div class="flex min-w-max items-center">
                    <span class="mr-2 text-sm text-slate-900">Page</span>
                    <template v-if="pageInputShow">
                        <input ref="pageInput"
                               class="input-text text-sm size-7 rounded-md border-gray-300 p-0 text-center focus:outline-none focus:border-gray-300 focus:ring-0"
                               @keyup.enter="movePageFromNumber">
                    </template>
                    <template v-else>
        <span class="cursor-pointer rounded p-1 text-sm text-slate-900 hover:bg-slate-200" @click="togglePageInput">
          {{ data.page_no }}<!-- --> / <!-- -->{{ data.last_page }}
        </span>
                    </template>
                </div>
                <button :class="[data.has_next ? '':'cursor-not-allowed']"
                        :disabled="!data.has_next"
                        class="rounded p-0.5 hover:bg-slate-200 mx-2 text-slate-400" @click="movePage(data.page_no+1)">
                    <ChevronRightIcon class="size-5 min-w-5"/>
                </button>
                <div class="flex w-6">
                    <DropdownComponent :icon="ChevronUpIcon" :rowActions="limitList" header="페이지당 표시 갯수"/>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped>

</style>