<script setup>

import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useRoute} from "vue-router";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import {onMounted, ref} from "vue";
import {Severities} from "@/constant.js";
import router from "@/router/index.js";

const route = useRoute()

const companyId = route.params.id

const props = defineProps({
  data: Object
})

const emits = defineEmits(['fetch'])

const employeeList = ref([])
const fields = {
  name: {label: '성명', sortable: false, width: 100, type: 'text'},
  cellphone: {label: '휴대전화번호', sortable: false, width: 150, type: 'text'},
  email: {label: 'Email', sortable: false, width: 200, type: 'text'},
  is_driver: {label: '기사', sortable: false, width: 50, type: 'boolean'},
  is_admin: {label: '관리자', sortable: false, width: 100, type: 'boolean'},
  open: {
    label: '자세히', width: 100, type: 'button', severity: Severities.PRIMARY,
    action: (row) => router.push({name: 'settings-employee-detail', params: {id: row.id}})
  }
}
const filter = (row) => {
  return {
    name: row.user.name,
    cellphone: row.user.cellphone,
    email: row.user.email,
    is_driver: row.is_driver,
    is_admin: row.is_admin,
  }
}
const fetch = async () => {
  employeeList.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/company/${companyId}/employee/`,
  })
}

onMounted(() => {
  fetch()
})
</script>

<template>
  <div>
    <span class="mr-4 text-lg text-slate-900">사용자</span>
    <InlineTableComponent :fields="fields" :rows="employeeList" :filter="filter"/>
  </div>
</template>

<style scoped>

</style>