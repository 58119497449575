<script setup>
import useAuthAxios from "@/composables/useAuthAxios.js";
import {nextTick, onMounted, ref, watch} from "vue";
import generateUuidString from "@/utils/generateUuidString.js";
import BaseSelector from "@/components/selector/base/BaseSelector.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";

const props = defineProps({
  // has_stock: {type: [Boolean, null], required: false, default: null},
  // has_logistics: {type: [Boolean, null], required: false, default: null},
  // is_active: {type: [Boolean, null], required: false, default: true},
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '캠페인'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
  options: {type: Array, required: false, default: []},
})

const layoutStore = useLayoutStore()

const emits = defineEmits(['focus', 'blur', 'input:query'])

const companyUse = defineModel('companyUse')
const vehicle = defineModel('vehicle')

const query = ref(null)

const fetchVehicles = async (companyUseValue, searchQuery) => {
  let companyUseId = companyUseValue?.id || companyUseValue || null
  searchQuery = searchQuery || null
  const response = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/vehicle/`,
    params: {
      'use_company': companyUseId,
      'query': searchQuery
    }
  }, false)
  return response?.list || []
}

const filteredOptions = ref([])

onMounted(async () => {
  filteredOptions.value = await fetchVehicles(companyUse.value, null)
})

watch(companyUse, async (newCompany, oldCompany) => {
  if (newCompany === oldCompany) return null
  query.value = null
  vehicle.value = null
  filteredOptions.value = await fetchVehicles(newCompany, query.value)
}, {deep: true})
// 회사 -> 차량 흐름의 데이터는 고려하지 않는다.
//
// watch([affiliate, model], async ([newAffiliate, newEmployee], [oldAffiliate, oldEmployee]) => {
//   if (newAffiliate !== oldAffiliate) {
//     filteredOptions.value = []
//     if (!newAffiliate) {
//       model.value = null
//       return null
//     }
//     if (newAffiliate?.id) {
//       filteredOptions.value = await fetch(newAffiliate.id)
//     } else {
//       filteredOptions.value = await fetch(newAffiliate)
//     }
//   }
//   if (newEmployee !== oldEmployee) {
//     if (!newEmployee) {
//       model.value = null
//       return null
//     }
//     let candidate
//     if (newEmployee?.id) {
//       candidate = filteredOptions.value.find(emp => emp.id === newEmployee.id)
//     } else {
//       candidate = filteredOptions.value.find(emp => emp.id === newEmployee)
//     }
//     if (!candidate) {
//       model.value = null
//       layoutStore.addErrorNotification('해당 직원이 없습니다.', null, 1000)
//     } else {
//       model.value = candidate
//     }
//   }
//
// }, {deep: true})

const setQuery = (value) => {
  query.value = value
}

const focus = (event) => {
  emits('focus', event)
}
const blur = (event) => {
  emits('blur', event)
}

const toRepresentation = (obj) => {
  return obj?.car_no || null
}
const toRepresentationSub = (obj) => {
  return obj?.company_use?.name
}

</script>

<template>
  <BaseSelector v-model="vehicle" :autoSelect="props.autoSelect" :class="props.class" :errors="props.errors"
                :inputClass="props.inputClass" :label="props.label" :labelClass="props.labelClass"
                :messages="props.messages" :name="props.name" :nullable="props.nullable" :options="filteredOptions"
                :placeholder="props.placeholder" :readonly="props.readonly" :required="props.required"
                :toRepresentation="toRepresentation" :toRepresentationSub="toRepresentationSub"
                @blur="blur" @focus="focus" @input:query="setQuery"/>
</template>

<style scoped>
</style>
