<script setup>
import {XMarkIcon, DocumentArrowDownIcon} from "@heroicons/vue/24/solid";
import {nextTick, onMounted, ref} from "vue";
import useNoAuthAxios from "@/composables/useNoAuthAxios.js";
import PDFViewer from 'pdf-viewer-vue'
import download from 'downloadjs'


const props = defineProps({
    instances: {type: Array, required: true},
    selected: {type: Object, required: false, default: null},
    closeFunc: {type: Function, required: true},
})

const emits = defineEmits(['close'])

const close = () => {
    props.closeFunc()
}

const imgElem = ref(null)
const instances = ref([])
const fileId = ref(null)
const file = ref(null)
const filename = ref(null)
const fileType = ref(null)
const fileBlob = ref(null)

const clearInstance = () => {
    fileId.value = null
    file.value = null
    filename.value = null
    fileType.value = null
    fileBlob.value = null
}

const fetchInstance = async (instance) => {
    if (!instance.file) {
        // 캐쉬 없을 때
        const url = await instance.loadFunc()
        if (url === null) {
            throw new Error('파일이 존재하지 않습니다.')
        }
        const response = await useNoAuthAxios({
            method: "GET",
            url: url,
            responseType: 'blob'
        })
        instance.file = response
        instance.fileType = response.type.toLowerCase()
        instance.fileBlob = URL.createObjectURL(response)
    }
    return instance
}

const loadInstance = async (instance) => {
    clearInstance()
    instance = await fetchInstance(instance)
    filename.value = instance.filename
    fileId.value = instance.id
    file.value = instance.file
    fileType.value = instance.fileType
    fileBlob.value = instance.fileBlob
    if (fileType.value.startsWith('image')) {
        await nextTick(() => {
            imgElem.value.src = fileBlob.value
        })
    }
}

onMounted(async () => {
    instances.value = props.instances
    if (!!props.selected) {
        await loadInstance(props.selected)
    } else {
        await loadInstance(instances.value[0])
    }
})

const downloadFile = async (instance) => {
    if (instance.file) {
        download(instance.file, instance.filename, instance.fileType)
    } else {
        instance = await fetchInstance(instance)
        download(instance.file, instance.filename, instance.fileType)
    }
}

const downloadCurrentFile = () => {
    download(file.value, filename.value, fileType.value)
}


</script>

<template>
    <div class="fixed w-full h-[100dvh] bg-gray-900/80 top-0 left-0 z-50">
        <div class="w-full h-[40px]] bg-primary-400 flex justify-between items-center px-4">
            <div class="text-white text-xs font-bold">
                뷰어
            </div>
            <button class="flex  items-center justify-start text-xs p-2 bg-primary-400 text-white" type="button"
                    @click="close">
                닫기
                <XMarkIcon class="size-4"/>
            </button>
        </div>
        <div class=" grid grid-cols-6 w-full h-[calc(100dvh-40px)]">
            <div class="col-span-1 h-full overflow-y-auto border-r border-gray-300 bg-white space-y-1">
                <div v-for="(instance, index) in instances" :key="instance.id"
                     :class="[fileId === instance.id ? 'bg-primary-500 text-white' : 'text-slate-900']"
                     class="flex items-center justify-between gap-x-2 p-2">
                    <button class="text-xs text-left" type="button" @click="() => loadInstance(instance)">
                        {{ index + 1 }}. {{ instance.filename }}
                    </button>
                    <button class="" type="button" @click="() => downloadFile(instance)">
                        <DocumentArrowDownIcon class="size-5"/>
                    </button>
                </div>
            </div>
            <div class="col-span-5 h-full flex flex-col">
                <template v-if="file && fileBlob">
                    <div class="flex-grow overflow-auto bg-white">
                        <template v-if="fileType.startsWith('image')">
                            <div class="image-container">
                                <img ref="imgElem" :alt="file.name"/>
                            </div>
                        </template>
                        <template v-else-if="fileType.startsWith('application/pdf')">
                            <PDFViewer :controls="['print', 'rotate', 'zoom', 'switchPage', 'catalog']"
                                       :filename="'filename'" :source="fileBlob"
                                       class="w-full"/>
                        </template>
                        <template v-else>
                            <div class="flex items-center justify-center h-full">
                                <div>
                                    <div class="text-sm">미리보기를 지원하지 않는 파일 형식입니다.</div>
                                    <button class="btn btn-primary" type="button" @click="downloadCurrentFile">다운로드
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <div v-else>파일을 선택하세요.</div>
            </div>
        </div>


    </div>


</template>

<style scoped>
.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.image-container img {
    object-fit: contain; /* 이미지가 부모 영역을 꽉 채우도록 함 */
}
</style>