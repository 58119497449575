import {Severities} from "@/constant";
import {createVNode, render} from "vue";
import ViewerComponent from "@/components/commons/Viewer/ViewerComponent.vue";

/**
 * useViewer 함수
 * Viewer를  쉽게 띄우기 위한 헬퍼 함수
 */

export function useViewer() {
    let viewerContainer = null;

    /**
     * Confirm Dialog 호출 함수
     * @param {Object} options - 뷰어 옵션
     * @param {Array<Object> | Object} options.instances - 인스턴스들 (배열 혹은 객체)
     *
     * @param {number} [options.instances.id] - 배열이 아닌 단일 객체의 아이디
     * @param {string} [options.instances.filename] - 배열이 아닌 단일 객체의 파일명
     * @param {Function} [options.instances.loadFunc] - 배열이 아닌 단일 객체의 로드함수. url string을 반환한다.
     *
     * @param {number} [options.instances[].id] - 배열 내 객체의 id
     * @param {string} [options.instances[].filename] - 배열 내 객체의 파일명
     * @param {Function} [options.instances[].loadFunc] - 배열 내 객체의 로드함수
     *
     * @param {Object} [options.selected] - 선택된 인스턴스
     * @param {number} [options.selected.id] - 선택된 객체의 id
     * @param {string} [options.selected.filename] - 선택된 객체의 파일명
     * @param {Function} [options.selected.loadFunc] - 선택된 객체의 로드함수
     */
    const show = ({instances, selected}) => {
        // DOM 컨테이너 생성
        viewerContainer = document.createElement("div");
        document.body.appendChild(viewerContainer);
        instances = Array.isArray(instances) ? instances : [instances];
        // Confirm 컴포넌트 생성
        const vueNode = createVNode(ViewerComponent, {
            instances,
            selected,
            closeFunc: closeViewer
        },);
        render(vueNode, viewerContainer);
    };

    const closeViewer = () => {
        if (viewerContainer) {
            render(null, viewerContainer); // 마운트 해제
            document.body.removeChild(viewerContainer); // DOM 제거
            viewerContainer = null;
        }
    };

    return {show};
}