<script setup>

import InfoBlock from "@/components/forms/InfoBlock.vue";
import {Severities} from "@/constant.js";
import {reactive, ref} from "vue";
import {
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage,
  nullBrnValidator,
  phoneValidator
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import {useLayoutStore} from "@/stores/layoutStore.js";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useRoute} from "vue-router";
import Toggle from "@/components/forms/Toggle.vue";

const route = useRoute()

const companyId = route.params.id
const props = defineProps({
  data: Object
})
const emits = defineEmits(['fetch'])

const data = reactive({
  rep_name: props.data.rep_name,
  rep_tel: props.data.rep_tel,
  mng_name: props.data.mng_name,
  mng_tel: props.data.mng_tel,
  mng_email: props.data.mng_email,
})

const $externalResults = ref({})
const model = {
  rep_name: {externalValidator},
  rep_tel: {phoneValidator},
  mng_name: {externalValidator},
  mng_tel: {phoneValidator},
  mng_email: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()


const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    await useAuthAxios({
      method: 'POST',
      url: `/app/v1/company/${companyId}/contact/`,
      data: data
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    emits('fetch')
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>
//'rep_name', 'rep_tel', 'mng_name', 'mng_tel', 'mng_email'
<template>

  <div>
    <span class="mr-4 text-lg text-slate-900">담당자 정보</span>
    <form class="w-96" method="post" @submit.prevent="save">
      <div :class="{invalid: validator.rep_name.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-rep_name">대표자명</label>
        <div>
          <input id="id-for-rep_name" :disabled="props.data.is_active === false" :value="data.rep_name"
                 name="rep_name"  class="w-full"
                 type="text" @input="event => data.rep_name = event.target.value">
          <template v-for="error in validator.rep_name.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div :class="{invalid: validator.rep_name.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-rep_tel">대표 연락처</label>
        <div>
          <input id="id-for-rep_tel" :disabled="props.data.is_active === false" :value="data.rep_tel"
                 name="rep_tel"  class="w-full"
                 type="text" @input="event => data.rep_tel = event.target.value">
          <template v-for="error in validator.rep_tel.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div :class="{invalid: validator.rep_name.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-mng_name">담당자명</label>
        <div>
          <input id="id-for-mng_name" :disabled="props.data.is_active === false" :value="data.mng_name"
                 name="mng_name"  class="w-full"
                 type="text" @input="event => data.mng_name = event.target.value">
          <template v-for="error in validator.mng_name.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div :class="{invalid: validator.mng_tel.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-mng_tel">담당자 연락처</label>
        <div>
          <input id="id-for-mng_tel" :disabled="props.data.is_active === false" :value="data.mng_tel"
                 name="mng_tel"  class="w-full"
                 type="text" @input="event => data.mng_tel = event.target.value">
          <template v-for="error in validator.mng_tel.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div :class="{invalid: validator.mng_email.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-mng_email">담당자 이메일</label>
        <div>
          <input id="id-for-mng_email" :disabled="props.data.is_active === false" :value="data.mng_email"
                 name="mng_email"  class="w-full"
                 type="text" @input="event => data.mng_email = event.target.value">
          <template v-for="error in validator.mng_email.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <button class="btn btn-primary" type="submit" :disabled="!props.data.is_active">저장</button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>