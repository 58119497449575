<script setup>
import {computed, ref, watch} from "vue";
import {useDraggable} from "@vueuse/core";

const props = defineProps({
  fieldKey: {type: String, required: true},
  fieldData: {type: Object, required: true},
})
const emits = defineEmits(['resize'])

const widthString = computed(() => {
  return props.fieldData?.width ? `${props.fieldData.width}px` : '200px'
})

const colStyle = computed(() => {
  return {
    width: widthString.value,
    minWidth: widthString.value,
    overflow: 'visible'
  }
})

const handlerStyle = computed(() => {
  return {
    width: widthString.value,
    //height: '32px'
  }
})

const handler = ref(null)
const parent = ref(null)

const {x, y, style} = useDraggable(handler, {
  // initialValue: {x: Number(props.fieldData.width) || 200, y: 32},
  initialValue: {x: 0, y: 0},
})

watch(() => x, (newValue) => {
  let rect = parent.value.getBoundingClientRect()
  emits('resize', newValue.value - rect.x)
}, {deep: true})

</script>

<template>
  <div ref="parent" :style="colStyle"
       class="flex relative border-r border-slate-400 items-center truncate">
    <div class="z-10 group items-center justify-between w-full truncate mx-2">
      <template v-if="props.fieldData.labels">
        <div class="flex items-center truncate" v-for="label in props.fieldData.labels">
          {{label}}
        </div>
      </template>
      <template v-else>
        <div class="flex items-center truncate">
          <span class="truncate text-sm font-medium text-slate-900">{{ props.fieldData.label }}</span>
        </div>
      </template>
    </div>
    <div :style="handlerStyle" class="!absolute left-0 top-0 h-full react-resizable">
      <span ref="handler"
            class="opacity-0  hover:opacity-100 cursor-col-resize w-[4px] h-full absolute right-0 bg-primary-400"></span>
    </div>
  </div>
</template>

<style scoped>

</style>