<script setup>

import DrawerCore from "@/components/drawer/DrawerCore.vue";
import {onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  dateStringValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {APIError} from "@/exceptions.js";
import TextInput from "@/components/forms/TextInput.vue";
import TransactionTypeSelector from "@/components/selector/choices/TransactionTypeSelector.vue";
import TextArea from "@/components/forms/TextArea.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
  open: {
    type: Boolean,
    required: false,
    default: false
  }
})
const emits = defineEmits(['close', 'create'])

const close = () => {
  emits('close')
  reset()
}

const data = reactive({
  id: null,
  name: null,
  description: null,
})

const model = {
  id: {
    required: helpers.withMessage('코드를 입력해주세요.', required),
    minLength: minLengthWithMessage('코드를 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('코드를 10글자 이하로 입력해주세요.', 10)
  },
  name: {
    required: helpers.withMessage('코드명을 입력해주세요', required),
  },
  description: {externalValidator},
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})

const reset = () => {
  data.id = null
  data.name = null
  data.description = null
  validator.value.$reset()
}
const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  let prepare = {...data}
  let options = {
    method: 'PUT',
    url: `/app/v1/accounting/code/`,
    data: prepare
  }
  try {
    const newCode = await useAuthAxios(options, false, $externalResults.value)
    layoutStore.addSuccessNotification('코드가 성공적으로 등록되었습니다.')
    emits('create', newCode)
    close()
  } catch (e) {
    throw e
  }
}

onBeforeUnmount(() => {
  reset()
})
</script>

<template>
  <DrawerCore :open="props.open" title="기기" @close="close">
    <form class="w-96 p-4" @submit.prevent="save">
      <TextInput v-model="data.id" :errors="validator.id.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="코드"/>
      <TextInput v-model="data.name" :errors="validator.name.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="코드명"/>
      <TextArea v-model="data.description" :errors="validator.description.$errors.map(e=>e.$message)" class="mb-4" label="설명"/>
<!--      <TextInput v-model="data.phone_no" :errors="validator.phone_no.$errors.map(e=>e.$message)" class="mb-4" :required="true" label="휴대전화번호"/>-->
<!--      <EmployeeSelector :required="true" v-model="data.user" class="mb-4" label="소유자"/>-->
      <button class="btn btn-primary" type="submit">저장</button>
    </form>
  </DrawerCore>
</template>

<style scoped>

</style>