<script setup>

import {ref} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useRoute, useRouter} from "vue-router";
import CampaignDrawer from "@/components/drawer/CampaignDrawer.vue";
import CampaignCreateModal from "@/components/modals/CampaignCreateModal.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const route = useRoute()

// group = models.ForeignKey(Group, null=False, blank=False, verbose_name='등록 그룹', on_delete=models.PROTECT)
// name = models.CharField('고객명', max_length=256, null=False, blank=False)
//     organization = models.CharField('소속', max_length=256, null=True, blank=True)
//     role = models.CharField('직책', max_length=256, null=True, blank=True)
//     rank = models.CharField('직급', max_length=256, null=True, blank=True)
// email = models.EmailField('이메일', max_length=256, null=True, blank=True)
// cellphone = models.CharField('휴대전화', max_length=11, null=True, blank=True)
// phone = models.CharField('전화', max_length=12, null=True, blank=True)
// memo = models.TextField('메모', null=True, blank=True)
// manager = models.ForeignKey(
//     User, null=True, blank=True, on_delete=models.PROTECT, verbose_name='담당자', related_name='customer_manager'
// )
// registerer = models.ForeignKey(
//     User, null=True, blank=True, on_delete=models.PROTECT, verbose_name='등록자', related_name='customer_registerer'
// )
// 컬럼 목록
const fields = {
  name: {label: '캠페인명', sortable: false, width: 150, type: 'text'},
  work_type_display: {label: '타입', sortable: false, width: 150, type: 'text'},
  manager: {label: '담당', sortable: false, width: 100, type: 'user'},
  registerer: {label: '등록', sortable: false, width: 100, type: 'user'},
  start_date: {label: '시작', sortable: false, width: 150, type: 'date'},
  end_date: {label: '종료', sortable: false, width: 150, type: 'date'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]
// filter
const searchQuery = ref(route.query.query || '')
const selectedManager = ref(route.query.manager || null)

const queryList = () => {
  let query = {...route.query}
  query['query'] = searchQuery.value
  query['manager'] = selectedManager.value?.id || null
  router.push({query: query})
}
// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/campaign/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  // data['organization'] = data.organization ? data.organization.name : ''
  // data['manager'] = data.manager ? data.manager.name : ''
  // data['registerer'] = data.registerer ? data.registerer.name : ''
  return data
}

// drawer
const isCampaignOpen = ref(false)
const selectedCampaign = ref(null)
const showCampaign = (campaign) => {
  selectedCampaign.value = campaign
  isCampaignOpen.value = true
}

const tableKey = ref(1)
const hideCampaign = () => {
  selectedCampaign.value = null
  isCampaignOpen.value = false
}

const update = () => tableKey.value++
</script>

<template>
  <CampaignDrawer :campaign="selectedCampaign" :open="isCampaignOpen" title="캠페인 상세" @close="hideCampaign" @update:campaign="() => tableKey++"/>
  <CampaignCreateModal :is-open="showAdd" title="캠페인 등록" @close="() => {showAdd = false; tableKey++}" @showCampaign="showCampaign" @create:campaign="() => tableKey++"/>
  <DefaultLayoutBase title="영업 / 캠페인">
    <template #body>
      <TableComponent :dataFilter="dataFilter" :fetch="fetch" :fields="fields" :openAction="showCampaign"
                      :openAdd="openAdd" :rowActions="rowActions" :query-filter="queryList" @update="update"
                      :rowsAction="rowsAction" :tableActions="tableActions" entityName="캠페인"
                      entityUnit="개" :key="tableKey">
        <template #filter>
          <TextInput v-model="searchQuery" label="검색"/>
            <EmployeeSelector v-model="selectedManager" label="담당자"/>
            <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>