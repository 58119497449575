<script setup>
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import generateUuidString from "@/utils/generateUuidString.js";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '결제방법'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
})

const emits = defineEmits(['update:options'])

const model = defineModel()

const updateOption = (option) => {
  emits('update:options', option)
}

</script>

<template>
  <ChoiceSelectorBase v-model="model" choice-type="TransactionMethodChoice" @update:options="updateOption"
                      :class="props.class" :labelClass="props.labelClass" :inputClass="props.inputClass"
                      :label="props.label" :name="props.name" :placeholder="props.placeholder" :readonly="props.readonly"
                      :required="props.required" :errors="props.errors" :messages="props.messages"
                      :autoSelect="props.autoSelect" :nullable="props.nullable"
  />
</template>