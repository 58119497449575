<script setup>

import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {computed, ref} from "vue";
import {useElementBounding, useWindowSize} from "@vueuse/core";
const props = {
  hover: {type: Boolean, default: true}
}
const show = ref(false)
const mouseenter = (event) => {
  if (props.hover === true) {
    show.value = true
  }
}
const mouseleave = () => {
  if (props.hover === true) {
    show.value = false
  }
}

const calculatePosition = (buttonBounding, screenSize) => {
  const position = {};
  const x = buttonBounding.left.value + buttonBounding.width.value/2
  const y = buttonBounding.top.value + buttonBounding.height.value/2
  const screenWidth = screenSize.width.value
  const screenHeight = screenSize.height.value
  // 화면 중심을 기준으로 x, y의 사분면을 결정
  const isRight = x > screenWidth / 2;
  const isBottom = y > screenHeight / 2;
  if (isRight && !isBottom) {
    // 1사분면: 팝오버의 오른쪽 위 모서리를 기준점에 위치
    position.right = `${screenWidth - x}px`;
    position.top = `${y}px`;
  } else if (!isRight && !isBottom) {
    // 2사분면: 팝오버의 왼쪽 위 모서리를 기준점에 위치
    position.left = `${x}px`;
    position.top = `${y}px`;
  } else if (!isRight && isBottom) {
    // 3사분면: 팝오버의 왼쪽 아래 모서리를 기준점에 위치
    position.left = `${x}px`;
    position.bottom = `${screenHeight - y}px`;
  } else if (isRight && isBottom) {
    // 4사분면: 팝오버의 오른쪽 아래 모서리를 기준점에 위치
    position.right = `${screenWidth - x}px`;
    position.bottom = `${screenHeight - y}px`;
  }

  return position;
}

const buttonRef = ref(null)
const panelRef = ref(null)

const buttonBounding = useElementBounding(buttonRef, {immediate: false})
const screenSize = useWindowSize()

const calculatedPosition = computed(() => {
  return calculatePosition(buttonBounding, screenSize)
})
</script>

<template>
  <Popover @mouseenter="mouseenter" @mouseleave="mouseleave">
    <PopoverButton ref="buttonRef">
      <slot name="button"></slot>
    </PopoverButton>

    <PopoverPanel v-if="show" ref="panelRef" static>
      <div class="z-50 absolute bg-white border-[1px] rounded-sm border-gray-300 p-2" :style="calculatedPosition">
        <slot name="body"></slot>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<style scoped>

</style>