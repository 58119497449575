<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, ref} from "vue";
import {useDropZone} from "@vueuse/core";
import {XMarkIcon} from "@heroicons/vue/24/solid/index.js";

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  label: {type: [String], required: false, default: '파일'},
  required: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
})

const emits = defineEmits(['focus', 'blur', 'change'])

const model = defineModel()

const focused = ref(false)
const inputId = `id_for_${props.name}`

const invalid = computed(() => {
  return props.errors.length > 0
})

const labelClass = computed(() => {
  if (props.readonly === true) {
    return ['text-gray-700', 'font-medium', 'z-20']
  }
  let validClass = invalid.value ? ['text-red-600'] : ['text-gray-700']
  let focusedClass = focused.value ? ['font-semibold', 'text-primary-600'] : ['font-medium']
  return [
    props.labelClass,
    ...validClass,
    ...focusedClass,
    'z-20'
  ]
})

const inputClass = computed(() => {
  let validClass = []
  if (props.readonly === true) {
    validClass = ['border-gray-300', 'bg-gray-900']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
    return validClass
  }
  if (invalid.value) {
    validClass = ['text-red-600', 'border-red-300',]
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-red-600', 'border-red-600']
    }
  } else {
    validClass = ['text-gray-700', 'border-gray-300']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
  }
  return [props.inputClass, ...validClass]
})


const focus = (event) => {
  focused.value = true
  emits('focus', event)
}
const blur = (event) => {
  focused.value = false
  emits('blur', event)
}

const onChange = (event) => {
  onDrop(Array.from(event.target.files))
}

const onDrop = (files) => {
  let fileInstance = files[0]
  if (fileInstance) {
    model.value = {
      id: generateUuidString(),
      file: fileInstance,
      name: fileInstance.name,
      size: fileInstance.size,
      type: fileInstance.type,
      lastModified: fileInstance.lastModified,
    }
  }
}
const dropZoneRef = ref(null)
const fileInput = ref(null)

let options = {onDrop: onDrop}

const {isOverDropZone} = useDropZone(dropZoneRef, options)


const enter = (event) => {
  fileInput.value.click()
}
const removeFile = (file) => {
  model.value = null
}
</script>

<template>
  <div :class="props.class" class="relative">
    <label :class="[...labelClass, props.readonly ? 'readonly':'']" :for="inputId">
      {{ props.label }} <span v-if="props.required" class="text-red-600">*</span>
    </label>

    <div ref="dropZoneRef" :class="[...inputClass, props.readonly ? 'readonly':'']" class="input-wrapper" tabindex="0">
      <div class="flex-grow text-sm select-none cursor-pointer truncate" @blur="blur" @change="onChange" @click="enter"
           @focus="focus" @keydown.enter="enter">
        <div :class="[isOverDropZone ? 'over':'', model ? '':'text-gray-400/70']" class="dz-label truncate">
          <template v-if="model">
            {{ model.name }}
          </template>
          <template v-else>
            {{ props.label }}을(를) 선택<span class="pl-1">하거나 끌어다 놓으세요.</span>
          </template>
        </div>
      </div>
      <button v-if="model !== null" type="button" @click="removeFile">
        <XMarkIcon aria-hidden="true" class="h-5 w-5"/>
      </button>

      <input :id="inputId" ref="fileInput" :name="props.name" class="" hidden tabindex="-1" type="file"
             @change="onChange">
      <slot name="button"/>
    </div>
    <template v-if="invalid">
      <div class="message-wrapper error">
        <p v-for="error in props.errors">- {{ error }}</p>
      </div>
    </template>
    <template v-else-if="props.messages.length > 0">
      <div class="message-wrapper">
        <p v-for="message in props.messages">- {{ message }}</p>
      </div>
    </template>
  </div>
</template>

<style scoped>
label {
  @apply absolute text-xs -top-2 left-2 inline-block bg-white px-1 py-0
}

label.readonly {
  @apply bg-transparent
}

.input-wrapper {
  @apply flex w-full leading-5 px-2 py-0 bg-white rounded h-[2rem] border-[1px] gap-x-2 items-center
}

.input-wrapper.readonly {
  @apply bg-gray-300
}


.message-wrapper {
  @apply mt-[2px] text-xs text-gray-500
}

.message-wrapper.error {
  @apply text-red-500
}
</style>