<script setup>
import useAuthAxios from "@/composables/useAuthAxios.js";
import generateUuidString from "@/utils/generateUuidString.js";
import BaseSelector from "@/components/selector/base/BaseSelector.vue";
import {onMounted, ref, watch} from "vue";
import {formatPhoneNumber} from "@/utils/formatter.js";
import CustomerRegisterModal from "@/components/modals/CustomerRegisterModal.vue";

const props = defineProps({
    has_stock: {type: [Boolean, null], required: false, default: null},
    has_logistics: {type: [Boolean, null], required: false, default: null},
    is_active: {type: [Boolean, null], required: false, default: true},
    class: {type: [String, Object, null], required: false, default: null},
    labelClass: {type: [String, Object, null], required: false, default: null},
    inputClass: {type: [String, Object, null], required: false, default: null},
    label: {type: [String], required: false, default: '고객'},
    name: {type: String, required: false, default: `field-${generateUuidString()}`},
    placeholder: {type: [String, null], default: null},
    required: {type: Boolean, required: false, default: false},
    readonly: {type: Boolean, required: false, default: false},
    errors: {type: Array, required: false, default: []},
    messages: {type: Array, required: false, default: []},
    autoSelect: {type: Boolean, required: false, default: true},
    nullable: {type: Boolean, required: false, default: true},
    options: {type: Array, required: false, default: []},
    add: {type: Boolean, required: false, default: false}
})

const emits = defineEmits(['focus', 'blur', 'input:query'])

const model = defineModel()
const query = ref(null)


const fetch = async (searchQuery, params) => {
    let queryDict = {'query': searchQuery}
    const response = await useAuthAxios({
        method: 'GET',
        url: `/app/v1/customer/`,
        params: queryDict
    }, false)
    return response.list
}


onMounted(async () => {
    filteredOptions.value = await fetch()
    if (Number.isInteger(model.value)) {
        model.value = await useAuthAxios({
            method: 'GET',
            url: `/app/v1/customer/${model.value}/`
        })
    }
})

const filteredOptions = ref([])

watch(model, async (newValue, oldValue) => {
    if (Number.isInteger(newValue)) {
        model.value = await useAuthAxios({
            method: 'GET',
            url: `/app/v1/customer/${newValue}/`
        })
    }
})

watch(query, async (newValue, oldValue) => {
    if (newValue === oldValue) {
        return null
    }
    filteredOptions.value = await fetch(newValue)
})

const setQuery = (value) => {
    query.value = value
}

const focus = (event) => {
    emits('focus', event)
}
const blur = (event) => {
    emits('blur', event)
}

const toRepresentation = (obj) => {
    return obj?.name
}
const toRepresentationSub = (obj) => {
    return obj?.cellphone ? formatPhoneNumber(obj.cellphone) : null
}

const showCustomerRegisterModal = ref(false)

const addCustomer = () => {
    if (props.readonly) {
        return null
    }
    showCustomerRegisterModal.value = true
}

const createCallback = (customer) => {
    model.value = customer
    showCustomerRegisterModal.value = false
}
</script>

<template>
    <BaseSelector v-model="model" :add-function="props.add ? addCustomer : null" :autoSelect="props.autoSelect" :class="props.class"
                  :errors="props.errors" :inputClass="props.inputClass" :label="props.label"
                  :labelClass="props.labelClass" :messages="props.messages" :name="props.name" :nullable="props.nullable"
                  :options="filteredOptions" :placeholder="props.placeholder" :readonly="props.readonly"
                  :required="props.required" :toRepresentation="toRepresentation"
                  :toRepresentationSub="toRepresentationSub" @blur="blur" @focus="focus" @input:query="setQuery"/>
    <CustomerRegisterModal :is-open="showCustomerRegisterModal" @close="showCustomerRegisterModal = false"
                           @create="createCallback"/>
</template>

<style scoped>
</style>
