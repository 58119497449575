<script setup>

import InfoBlock from "@/components/forms/InfoBlock.vue";
import {Severities} from "@/constant.js";
import {onMounted, reactive, ref} from "vue";
import {externalValidator, maxLengthWithMessage, minLengthWithMessage, nullBrnValidator} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import {useLayoutStore} from "@/stores/layoutStore.js";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useRoute} from "vue-router";
import Toggle from "@/components/forms/Toggle.vue";
import {helpers, required} from "@vuelidate/validators";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";

const route = useRoute()

const companyId = route.params.id
const props = defineProps({
  data: Object
})
const emits = defineEmits(['fetch'])

const data = reactive({
  name: props.data.name,
  brn: props.data.brn,
  tel: props.data.tel,
  company_type: props.data.company_type,
  company_type_detail: props.data.company_type_detail,
  description: props.data.description,
})

onMounted(async () => {
  const companyTypes = await useAuthAxios({
    method: "GET",
    url: `/app/v1/common/choice/`,
    params: {type: 'CompanyTypeChoice'}
  })
  data.company_type = companyTypes.filter(ct => ct.value === data.company_type)[0]
})

const $externalResults = ref({})

const model = {
  name: {
    required: helpers.withMessage('고객명을 입력해주세요.', required),
    minLength: minLengthWithMessage('사업자명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('사업자명을 30글자 이하로 입력해주세요.', 30)
  },
  brn: {
    required: nullBrnValidator('필수 항목입니다.')
  },
  tel: {externalValidator},
  company_type: {required: helpers.withMessage('타입은 필수 항목입니다.', required),},
  company_type_detail: {externalValidator},
  description: {externalValidator},
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()


const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    await useAuthAxios({
      method: 'POST',
      url: `/app/v1/company/${companyId}/`,
      data: {
        name: data.name,
        brn: data.brn,
        tel: data.tel,
        company_type: typeof data.company_type === 'string' ? data.company_type : data.company_type.value,
        company_type_detail: data.company_type_detail ? data.company_type_detail : null,
        description: data.description ? data.description : null,
      }
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    emits('fetch')
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>

<template>

  <div>
    <span class="mr-4 text-lg text-slate-900">기본정보</span>
    <InfoBlock :description="{text: '모든 사용자에게 공개되는 기본 정보입니다.'}"/>
    <form class="w-96" method="post" @submit.prevent="save">
      <div :class="{invalid: validator.name.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-name">사업장명</label>
        <div>
          <input id="id-for-name" :disabled="props.data.is_active === false" :value="data.name" class="w-full"
                 name="name" type="text" @input="event => data.name = event.target.value">
          <template v-for="error in validator.name.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>

      <ChoiceSelectorBase v-model="data.company_type" choice-type="CompanyTypeChoice" class="mt-4" label="타입" :errors="validator.company_type.$errors.map(err => err.$message)" />
      <div :class="{invalid: validator.company_type_detail.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-company_type_detail">타입 상세</label>
        <div>
          <input id="id-for-company_type_detail" :disabled="props.data.is_active === false" :value="data.company_type_detail"
                 class="w-full" maxlength="30"
                 name="company_type_detail" type="text" @input="event => data.company_type_detail = event.target.value">
          <template v-for="error in validator.company_type_detail.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>

      <div :class="{invalid: validator.brn.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-brn">사업자등록번호</label>
        <div>
          <input id="id-for-brn" :disabled="props.data.brn !== null || props.data.is_active === false" :value="data.brn"
                 class="w-full" maxlength="10"
                 name="brn" type="text" @input="event => data.brn = event.target.value">
          <template v-for="error in validator.brn.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>

      <div :class="{invalid: validator.tel.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-tel">대표번호</label>
        <div>
          <input id="id-for-tel" :disabled="props.data.is_active === false" :value="data.tel"
                 class="w-full" maxlength="30"
                 name="tel" type="text" @input="event => data.tel = event.target.value">
          <template v-for="error in validator.tel.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>

      <div :class="{invalid: validator.description.$errors.length > 0}" class="input-group mt-4">
        <label class="w-24" for="id-for-description">설명</label>
        <div>
          <input id="id-for-description" :disabled="props.data.is_active === false" :value="data.description"
                 class="w-full" name="description"
                 type="text" @input="event => data.description = event.target.value">
          <template v-for="error in validator.description.$errors">
            <p class="error">{{ error.$message }}</p>
          </template>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <button :disabled="!props.data.is_active" class="btn btn-primary" type="submit">저장</button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>