
export class BaseError extends Error {
    constructor(code, message, field_errors, non_field_errors, error) {
        super(message);
        this.code = code || 'Z9999'
        this.name = 'BaseError';
        this.field_errors = field_errors || {};
        this.non_field_errors = non_field_errors || [];
        this.error = error
    }

    toMap() {
        return {
            code: this.code,
            message: this.message,
            field_errors: this.field_errors,
            non_field_errors: this.non_field_errors,
            error: this.error
        }
    }
}

export class APIError extends BaseError {
    // 사용자가 입력항목을 빼먹었거나, 서버에서 처리 로직상 받아들일 수 없는 경우 등등
    constructor(code, message, field_errors, non_field_errors, error) {
        super(code, message, field_errors, non_field_errors, error);
        this.name = 'APIError';
    }
}