<script setup>

import {nextTick, onMounted, reactive, ref, watch} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import TextArea from "@/components/forms/TextArea.vue";
import dayjs from "dayjs";
import CommonPagination from "@/components/CommonPagination.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  historyContextId: {type: [null, String], required: true},
})
const emits = defineEmits(['update:total'])

const noteData = ref(null)
const page = ref(1)
const limit = ref(50)

const data = reactive({note: ''})
const model = {note: {required: requiredWithMessage('메모를 입력해주세요.', required)}}

const validator = useVuelidate(model, data)

const fetch = async () => {
  noteData.value = await useAuthAxios({
    method: "GET",
    url: `/app/v1/history/${props.historyContextId}/note/`,
    params: {limit: limit.value, page: page.value}
  })
}

const save = async () => {
  let valid = await validator.value.$validate()
  if (!valid) return
  const response = await useAuthAxios({
    method: "PUT",
    url: `/app/v1/history/${props.historyContextId}/note/`,
    data: data
  })
  data.note = ''
  noteData.value.result = [response, ...noteData.value.result]
  emits('update:total')
}

onMounted(async () => {
  page.value = 1
  await fetch()
})

watch([page, limit], async () => {
  await nextTick(async () => {
    await fetch()
  })
})
</script>

<template>
  <form class="px-3" @submit.prevent="save">
    <TextArea v-model="data.note" label="메모" name="note"/>
    <div class="flex items-center justify-end">
      <button class="btn btn-primary mt-2" type="submit">저장</button>
    </div>
  </form>
  <div class="divide-y-[1px] divide-gray-200 pb-10">
    <template v-if="noteData?.result.length === 0">
      <div class="text-center pt-4 pb-4 text-gray-400 text-sm">작성된 메모가 없습니다.</div>
    </template>
    <template v-else>
      <div class="pt-4 pb-4 px-3" v-for="note in noteData?.result || []" v-bind:key="note.id">
        <div class="flex items-center justify-between text-sm mb-2">
          <div class="font-semibold">{{ note.writer.name }}</div>
          <div class="text-xs">{{ dayjs(note.created_at).format("YYYY-MM-DD hh:mm") }}</div>
        </div>
        <div class="text-sm" v-html="note.note"></div>
      </div>
    </template>
  </div>
  <div class="fixed bottom-0 w-full">
    <CommonPagination :limit="limit" :page="page" :max-page="noteData?.max_page || 1" @set:page="v => page = v" @set:limit="v => limit = v"/>
  </div>
</template>

<style scoped>

</style>