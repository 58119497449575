<script setup>


import {formatBrn, formatPhoneNumber} from "@/utils/formatter.js";
import {computed, nextTick, ref, watch} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useRoute, useRouter} from "vue-router";
import AffiliateRegisterModal from "@/components/modals/AffiliateRegisterModal.vue";
import AffiliateDrawer from "@/components/drawer/AffiliateDrawer.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import MapComponent from "@/components/maps/MapComponent.vue";
import TextInput from "@/components/forms/TextInput.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";

const layoutStore = useLayoutStore()
const router = useRouter()
const route = useRoute()

// 컬럼 목록
const fields = {
  name: {label: '거래처명', sortable: false, width: 200, type: 'text'},
  company_type_display: {label: '타입', sortable: false, width: 100, type: 'text'},
  company_type_detail: {label: '타입 상세', sortable: false, width: 100, type: 'text'},
  brn: {label: '사업자등록번호', sortable: false, width: 120, type: 'text'},
  tel: {label: '대표번호', sortable: false, width: 120, type: 'text'},
  address: {label: '주소', sortable: false, width: 120, type: 'text'},
  address_detail: {label: '상세주소', sortable: false, width: 120, type: 'text'},
  manager: {label: '담당', sortable: false, width: 100, type: 'user'},
  registerer: {label: '등록', sortable: false, width: 100, type: 'user'},
  created_at: {label: '등록일시', sortable: false, width: 150, type: 'datetime'},
}

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}


// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]

// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]
// filter
const selectedOrganization = ref(null)

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/affiliate/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

const dataFilter = (data) => {
  // data['organization'] = data.organization ? data.organization.name : ''
  // data['manager'] = data.manager ? data.manager.name : ''
  // data['registerer'] = data.registerer ? data.registerer.name : ''
  data['brn'] = data?.brn ? formatBrn(data.brn) : ''
  data['tel'] = data?.tel ? formatPhoneNumber(data.tel) : ''
  data['address'] = data?.address?.main || ''
  data['address_detail'] = data?.address?.detail || ''
  return data
}

// drawer
const isAffiliateOpen = ref(false)
const selectedAffiliate = ref(null)
const showAffiliate = async (affiliate) => {
  selectedAffiliate.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/affiliate/${affiliate.id}/`
  })
  isAffiliateOpen.value = true
}

const hideAffiliate = () => {
  selectedAffiliate.value = null
  isAffiliateOpen.value = false
}

// map view

const mapFetch = async (query, data) => {
  let options = {
    method: 'GET',
    url: `/app/v1/affiliate/map/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}


const mapMode = ref(route.query.map === 'true')
// const mapMode = ref(true)

const toggleMap = () => {
  mapMode.value = !mapMode.value
}

const queryMap = async (event) => {
  let query = {
    company_type: selectedCompanyType.value?.value ? selectedCompanyType.value.value : null
  }
  await router.push({query: query})
}

const searchQuery = ref(route.query.query || '')
const selectedCompanyType = ref(route.query.company_type || '')
const selectedManager = ref(route.query.manager || '')

const queryList = async () => {
  let query = {
    ...route.query,
    query: searchQuery.value,
    company_type: selectedCompanyType.value?.value ? selectedCompanyType.value.value : null,
    manager: selectedManager.value?.id ? selectedManager.value.id : null
  }
  await router.push({query: query})
}

watch(selectedCompanyType, async (newValue) => {
  await queryList()
})

watch(selectedManager, async (newValue) => {
  await queryList()
})

const updateRow = ref(null)

const updateData = (affiliate) => {
  selectedAffiliate.value = affiliate
  updateRow.value = affiliate
}

const tableKey = ref(1)

const update = () => tableKey.value++

</script>

<template>
  <AffiliateDrawer :affiliate="selectedAffiliate" :open="isAffiliateOpen" title="거래처 상세" @close="hideAffiliate"
                   @update:affiliate="updateData"/>
  <AffiliateRegisterModal :isOpen="showAdd" title="거래처 등록" @close="() => showAdd = false"
                          @showAffiliate="showAffiliate"/>
  <DefaultLayoutBase title="거래처">
    <template #body>
      <TableComponent v-if="mapMode === false" :dataFilter="dataFilter" :fetch="fetch" :fields="fields" :key="tableKey"
                      :openAction="showAffiliate" :openAdd="openAdd" :query-filter="queryList" :rowActions="rowActions"
                      :rowsAction="rowsAction" :tableActions="tableActions" :toggleMap="toggleMap" @update="update"
                      :update="updateRow" entityName="거래처" entityUnit="개">
        <template #filter>
          <TextInput v-model="searchQuery" label="검색" name="query" placeholder="검색"/>
          <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
          <ChoiceSelectorBase v-model="selectedCompanyType" choice-type="CompanyTypeChoice" label="타입"/>
        </template>
      </TableComponent>
      <MapComponent v-else :fetch="mapFetch" :openAction="showAffiliate" :query-filter="queryMap" :toggleMap="toggleMap"
                    entityName="거래처" entityUnit="개">
        <template #filter>
          <ChoiceSelectorBase v-model="selectedCompanyType" choice-type="CompanyTypeChoice" label="타입"/>
          <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </MapComponent>
    </template>
  </DefaultLayoutBase>
</template>