<script setup>
import {onMounted, ref, watch} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import generateUuidString from "@/utils/generateUuidString.js";
import BaseSelector from "@/components/selector/base/BaseSelector.vue";

const props = defineProps({
  is_admin: {type: [Boolean, null], required: false, default: null},
  is_driver: {type: [Boolean, null], required: false, default: null},
  is_active: {type: [Boolean, null], required: false, default: true},
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  label: {type: [String], required: false, default: '임직원'},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
  options: {type: Array, required: false, default: []},
})


const emits = defineEmits(['focus', 'blur', 'input:query'])

const model = defineModel()


// const user = defineModel({user
const query = ref(null)


const fetch = async (searchQuery, params) => {
  const response = await useAuthAxios({
    method: 'POST',
    url: `/app/v1/employment/`,
    data: {
      'query': searchQuery,
      'is_admin': props.is_admin,
      'is_driver': props.is_driver,
      'is_active': props.is_active,
    },
  }, false)
  return response.map(emp => {
    return {
      ...emp.user, company: emp?.company?.name
    }
  })
}

const setUser = async (userId) => {
  model.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/user/${userId}/`
  })
}

watch(model, async (nv, ov) => {
  if (nv === ov) return null
  if (typeof nv === 'string') {
    await setUser(nv)
  }
})


onMounted(async () => {
  filteredOptions.value = await fetch()
  if (typeof model.value === 'string') {
    await setUser(model.value)
  }
})

const filteredOptions = ref([])

watch(query, async (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  filteredOptions.value = await fetch(newValue)
})

const setQuery = (value) => {
  query.value = value
}

const focus = (event) => {
  emits('focus', event)
}
const blur = (event) => {
  emits('blur', event)
}

const toRepresentation = (obj) => {
  return obj.name
}
const toRepresentationSub = (obj) => {
  return obj.company
}

</script>

<template>
  <BaseSelector v-model="model" :autoSelect="props.autoSelect" :class="props.class" :errors="props.errors"
                :inputClass="props.inputClass" :label="props.label" :labelClass="props.labelClass"
                :messages="props.messages" :name="props.name" :nullable="props.nullable" :options="filteredOptions"
                :placeholder="props.placeholder" :readonly="props.readonly" :required="props.required"
                :toRepresentation="toRepresentation" :toRepresentationSub="toRepresentationSub"
                @blur="blur" @focus="focus" @input:query="setQuery"/>
</template>

<style scoped>
</style>
