<script setup>
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

const layoutStore = useLayoutStore()

const props = defineProps({
  callLog: {type: [Object, null], required: false, default: null}
})

const emits = defineEmits(['update'])
const setMine = () => {
  useAuthAxios({
    method: 'PUT',
    url: `/app/v1/call_log/${props.callLog.id}/`
  })
  layoutStore.addSuccessNotification('나에게 배정되었습니다.')
  emits('update')
}
</script>

<template>
  <div class="flex items-center justify-start gap-x-3">
    <div class="font-semibold text-sm">담당자 <span class="font-normal">: {{ props.callLog.manager_name }}</span></div>
    <button class="btn btn-primary btn-sm" @click="setMine">나에게 배정</button>
  </div>
</template>

<style scoped>

</style>