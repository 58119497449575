const Severities = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ERROR: 'error',
    DISABLED: 'light',
}

const Sizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
}

const Stage = import.meta.env.VITE_APP_STAGE

Object.freeze(Severities)
Object.freeze(Sizes)
Object.freeze(Stage)

export {Severities, Sizes, Stage}