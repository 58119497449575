<script setup>
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {helpers, required} from "@vuelidate/validators";
import {externalValidator} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import AffiliateEmployeeSelector from "@/components/selector/apiSelector/AffiliateEmployeeSelector.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import AddressInput from "@/components/forms/AddressInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import Toggle from "@/components/forms/Toggle.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";
import OptionDialogComponent from "@/components/commons/OptionDialogComponent.vue";
import CustomerSelector from "@/components/selector/apiSelector/CustomerSelector.vue";

const layoutStore = useLayoutStore()

const props = defineProps({
    work: {type: [null, Object], required: true, default: null},
    canUpdateSite: {type: Boolean, required: false, default: false},
})

const emits = defineEmits(['update', 'create'])

const data = reactive({
    company: props.work?.departure?.company || null,
    manager: props.work?.departure?.manager || null,
    manager_name: props.work?.departure?.manager_name || null,
    manager_cellphone: props.work?.departure?.manager_cellphone || null,
    address_poi: props.work?.departure?.address_poi || null,
    address_detail: props.work?.departure?.address_detail || null,
    description: props.work?.departure?.description || null,
    overwrite: true,
})

const assignData = (original) => {
    data.company = original?.departure?.company || null
    data.manager = original?.departure?.manager || null
    data.manager_name = original?.departure?.manager_name || null
    data.manager_cellphone = original?.departure?.manager_cellphone || null
    data.address_poi = original?.departure?.address_poi || null
    data.address_detail = original?.departure?.address_detail || null
    data.description = original?.departure?.description || null
    data.overwrite = true
}

onMounted(() => {
    assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
    if (newValue === oldValue) {
        return null
    }
    assignData(newValue)
}, {deep: true})


watch(() => data.manager, (newValue, oldValue) => {
    if (newValue === oldValue) return
    data.manager_name = newValue?.name || null
    data.manager_cellphone = newValue?.cellphone || null
}, {deep: true})

const model = {
    company: {
        required: helpers.withMessage('거래처를 선택해주세요.', required)
    },
    manager: {
        required: helpers.withMessage('담당자를 선택해주세요.', required)
    },
    manager_name: {externalValidator},
    manager_cellphone: {externalValidator},
    address_poi: {
        required: helpers.withMessage('주소를 입력해주세요.', required)
    },
    address_detail: {externalValidator},
    description: {externalValidator},
    overwrite: {externalValidator},
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const optionDialog = reactive({
    show: false,
    summary: null,
    detail: null,
    acceptString: null,
    rejectString: null,
    onAccept: null,
    onReject: null,
})

const closeOptionDialog = () => {
    Object.assign(optionDialog, {
        show: false,
        summary: null,
        detail: null,
        acceptString: null,
        rejectString: null,
        onAccept: null,
        onReject: null,
    })
}

const showSaveSiteConfirm = (notify) => {
    optionDialog.show = true
    optionDialog.summary = '저장 옵션'
    optionDialog.detail = [
        '현장 정보를 저장할 방법을 선택하세요.',
        '덮어쓰기 : 이 현장 정보를 공유하는 모든 배차의 현장 정보가 업데이트 됩니다.',
        '새로저장 : 이 배차의 현장 정보만 수정됩니다.'
    ]
    optionDialog.acceptString = '덮어쓰기'
    optionDialog.rejectString = '새로저장'
    optionDialog.onAccept = () => saveSite(notify, true)
    optionDialog.onReject = () => saveSite(notify, false)
}
const saveSite = async (notify, overwrite) => {
    validator.value.$clearExternalResults()
    let valid = await validator.value.$validate()
    if (!valid) return null
    let prepare = {
        company: data.company.id,
        manager: data.manager?.id || null,
        manager_name: data.manager_name || null,
        manager_cellphone: data.manager_cellphone || null,
        poi: data.address_poi || null,
        detail: data.address_detail || null,
        description: data.description || null,
        notify: notify.value || null,
        overwrite: overwrite,
    }
    let options = {
        method: overwrite ? 'POST' : 'PUT',
        url: `/app/v1/work/small/${props.work.id}/departure/site/`,
        data: prepare,
    }
    const response = await useAuthAxios(options, false, $externalResults)
    validator.value.$reset()
    emits('update')
    layoutStore.addSuccessNotification('저장되었습니다.')
}

const showDuplicateConfirm = ref(false)

const beforeDuplicate = () => {
    showDuplicateConfirm.value = true
}
const duplicate = async () => {
    let options = {
        method: 'PUT',
        url: `/app/v1/work/small/${props.work.id}/`,
    }
    const response = await useAuthAxios(options, false, $externalResults)
    layoutStore.addSuccessNotification('추가되었습니다.')
    emits('create', response)
}

const readonly = computed(() => {
    return !props.canUpdateSite
})
</script>

<template>
    <!--현장 정보-->
    <!--  <pre>{{props.work}}</pre>-->
    <!--  <pre>{{data}}</pre>-->
    <form class="pt-4 mt-4" @submit.prevent="showSaveSiteConfirm">
        <div class="flex items-center justify-between gap-x-3 mb-3">
            <div class="text-sm font-semibold">현장</div>
            <button class="btn btn-primary-outline btn-xs" type="button" @click="beforeDuplicate">추가배차</button>
        </div>
        <div class="mb-4 grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
            <CustomerSelector label="담당자" v-model="data.manager" :add="true" :readonly="true"
                              :errors="validator.manager.$errors.map(e => e.$message)"/>
            <TextInput v-model="data.manager_name"
                       :errors="validator.manager_name.$errors.map(e => e.$message)"
                       :readonly="!!data?.manager?.name || readonly" label="담당자명"/>
            <TextInput v-model="data.manager_cellphone"
                       :errors="validator.manager_cellphone.$errors.map(e => e.$message)"
                       :readonly="!!data?.manager?.cellphone || readonly" label="담당자 연락처"/>
            <AffiliateSelector v-model="data.company" :errors="validator.company.$errors.map(e => e.$message)" label="거래처" :readonly="readonly"/>
        </div>
        <div class="mb-4 grid grid-cols-1 md:grid-cols-7 gap-x-4 gap-y-4">
            <div class="md:col-span-3">
                <AddressInput v-model="data.address_poi" :errors="validator.address_poi.$errors.map(e => e.$message)"
                              :readonly="readonly" :required="true"
                              class="flex-grow" label="주소"/>
            </div>
            <div v-if="data.address_poi !== null" class="md:col-span-4">
                <TextInput v-model="data.address_detail" :errors="validator.address_detail.$errors.map(e => e.$message)"
                           :readonly="readonly" label="상세주소"/>
            </div>
        </div>
        <div class="mb-4">
        <TextArea v-model="data.description" :errors="validator.description.$errors.map(e => e.$message)"
                  :readonly="readonly" label="현장 상세"/>
        </div>
        <div class="grid grid-cols-12 gap-x-4">
<!--            <div class="col-span-9">-->
<!--                        <Toggle :enabled="data.overwrite" :disabled="readonly" name="saveType" @update:value="value => data.overwrite = value">-->
<!--                          <template #label>-->
<!--                            <template v-if="data.overwrite">-->
<!--                              <div class="font-semibold text-sm">덮어쓰기</div>-->
<!--                              <div class="text-xs">이 현장 정보를 사용하는 모든 배차의 현장 정보가 업데이트 됩니다.</div>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                              <div class="font-semibold text-sm">새로 저장</div>-->
<!--                              <div class="text-xs">이 배차의 현장 정보만 수정됩니다.</div>-->
<!--                            </template>-->
<!--                          </template>-->
<!--                        </Toggle>-->
<!--            </div>-->
            <button :disabled="readonly" class="btn btn-primary col-span-3" type="submit">저장</button>
        </div>
        <ConfirmDialogComponent :open="showDuplicateConfirm" accept-string="확인" detail="이 현장에 동일한 캠페인의 배차를 추가합니다."
                                summary="추가 배차" @accept="duplicate" @close="showDuplicateConfirm = false"/>
        <OptionDialogComponent v-bind="optionDialog" @close="closeOptionDialog"/>
    </form>
</template>

<style scoped>

</style>