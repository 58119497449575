<script setup>
import {useRoute} from "vue-router";
import {ref, onMounted} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import {Severities} from "@/constant.js";
import {
  maxLengthWithMessage,
  minLengthWithMessage, nullBrnValidator
} from "@/validators/fields.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";

import CompanyDefaultInfo from "@/views/settings/partials/company/CompanyDefaultInfo.vue";
import CompanyAddress from "@/views/settings/partials/company/CompanyAddress.vue";
import HorizontalBar from "@/components/forms/HorizontalBar.vue";
import CompanyEmployeeList from "@/views/settings/partials/company/CompanyEmployeeList.vue";
import CompanyType from "@/views/settings/partials/company/CompanyType.vue";
import CompanyContactInfo from "@/views/settings/partials/company/CompanyContactInfo.vue";

const route = useRoute()

const companyId = route.params.id


const companyData = ref(null)

const fetch = async () => {
  companyData.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/company/${companyId}/`
  })
}

onMounted(async () => {
  await fetch()
})
</script>

<template>
  <DefaultLayout title="설정 / 사업장 관리">
    <template v-if="companyData" #content>
      <div class="space-y-8">
        <InfoBlock v-if="!companyData.is_active"
                   :description="{text: '비활성화된 사업장 입니다.', severity: Severities.ERROR}"/>
        <CompanyDefaultInfo :data="companyData" @fetch="fetch"/>
        <HorizontalBar/>
        <CompanyContactInfo :data="companyData" @fetch="fetch"/>
        <HorizontalBar/>
<!--        <CompanyType :data="companyData" @fetch="fetch"/>-->
<!--        <HorizontalBar/>-->
        <CompanyAddress :data="companyData" @fetch="fetch"/>
        <HorizontalBar/>
        <CompanyEmployeeList :data="companyData" @fetch="fetch"/>
      </div>
    </template>
  </DefaultLayout>
</template>