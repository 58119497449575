<script setup>
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {helpers, required} from "@vuelidate/validators";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import TimePickerInput from "@/components/forms/TimePickerInput.vue";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import {MapIcon, XMarkIcon} from "@heroicons/vue/24/outline/index.js";
import AddressInput from "@/components/forms/AddressInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import SmallWorkWaste from "@/components/drawer/work/small/SmallWorkWaste.vue";
import SmallWorkWaypoint from "@/components/drawer/work/small/SmallWorkWaypoint.vue";
import SmallWorkSite from "@/components/drawer/work/small/SmallWorkSite.vue";
import SmallWorkDriver from "@/components/drawer/work/small/SmallWorkDriver.vue";
import SmallWorkArrival from "@/components/drawer/work/small/SmallWorkArrival.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import EntryCodeSelector from "@/components/selector/apiSelector/EntryCodeSelector.vue";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";
import CustomerSelector from "@/components/selector/apiSelector/CustomerSelector.vue";
import BaseSelector from "@/components/selector/base/BaseSelector.vue";
import dayjs from "dayjs";
import TransactionMethodSelector from "@/components/selector/choices/TransactionMethodSelector.vue";
import {Severities} from "@/constant.js";
import ConfirmDialog from "@/components/commons/ConfirmDialog.vue";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
  work: {type: [null, Object], required: true, default: null}
})
const emits = defineEmits(['update'])

const update = () => {
  emits('update',)
}


const entries = ref([])

const fetchEntry = async (work) => {
  const option = {
    method: "GET",
    url: `/app/v1/accounting/entry/?work=${work.id}&all=1&is_deleted=false`
  }
  const response = await useAuthAxios(option)
  entries.value = response.list || []
}

onMounted(() => {
  fetchEntry(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  fetchEntry(newValue)
}, {deep: true})

const data = reactive({
  code: null,
  supplier_company: null,
  supplier_customer: null,
  buyer_company: null,
  buyer_customer: null,
  amount: 0,
  tax: null,
  memo: null,
  method: null
})

const clean = () => {
  data.code = null
  data.supplier_company = null
  data.supplier_customer = null
  data.buyer_company = null
  data.buyer_customer = null
  data.amount = 0
  data.tax = null
  data.memo = null
  data.method = null
  validator.value.$reset()
}

const model = {
  code: {required: requiredWithMessage('코드를 선택해주세요.')},
  supplier_company: {
    bothNotNull: helpers.withMessage('공급자를 선택해주세요.', (value) => {
      if (!!data.supplier_customer) {
        return value === null
      } else {
        return value !== null
      }
    })
  },
  supplier_customer: {
    bothNotNull: helpers.withMessage('공급자를 선택해주세요.', (value) => {
      if (!!data.supplier_company) {
        return value === null
      } else {
        return value !== null
      }
    })
  },
  buyer_company: {
    bothNotNull: helpers.withMessage('공급받는자를 선택해주세요.', (value) => {
      if (!!data.buyer_customer) {
        return value === null
      } else {
        return value !== null
      }
    })
  },
  buyer_customer: {
    bothNotNull: helpers.withMessage('공급받는자를 선택해주세요.', (value) => {
      if (!!data.buyer_company) {
        return value === null
      } else {
        return value !== null
      }
    })
  },
  amount: {
    required: requiredWithMessage('금액을 입력해주세요.'),
    notZero: helpers.withMessage('금액은 0이 될 수 없습니다.', (value) => {
      return value !== 0
    })
  },
  tax: {externalValidator},
  method: {required: requiredWithMessage('코드를 선택해주세요.')},
  memo: {externalValidator},
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults})

const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  let prepare = {...data}
  prepare['work'] = props.work.id
  prepare['code'] = data.code.id
  prepare['supplier_company'] = data.supplier_company?.id || null
  prepare['supplier_customer'] = data.supplier_customer?.id || null
  prepare['buyer_company'] = data.buyer_company?.id || null
  prepare['buyer_customer'] = data.buyer_customer?.id || null
  prepare['method'] = data.method?.value || null
  let options = {
    method: 'POST',
    url: `/app/v1/accounting/entry/`,
    data: prepare
  }
  try {
    await useAuthAxios(options, false, $externalResults.value)
    layoutStore.addSuccessNotification('매출/매입이 성공적으로 등록되었습니다.')
    await fetchEntry(props.work)
    clean()
    update()
  } catch (e) {
    throw e
  }
}

// cons


const tableKey = ref(1)

const readonly = computed(() => {
  return !props?.work?.action_available?.add_recognition
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue.action_available?.add_recognition === oldValue.action_available?.add_recognition) {
    return null
  }
  tableKey.value += 1
}, {deep: true})

const registerInverse = async (entry) => {
  const prepare = {
    work: entry.origin_id,
    code: entry.code,
    supplier_company: entry.supplier_type === 'company' ? entry.supplier_id : null,
    supplier_customer: entry.supplier_type !== 'company' ? entry.supplier_id : null,
    buyer_company: entry.buyer_type === 'company' ? entry.buyer_id : null,
    buyer_customer: entry.buyer_type !== 'company' ? entry.buyer_id : null,
    method: entry.method,
    amount: -entry.amount,
    tax: entry.tax === null ? null : -entry.tax,
    memo: `${entry.serial} 반대거래`
  }
  let options = {
    method: 'POST',
    url: `/app/v1/accounting/entry/`,
    data: prepare
  }
  try {
    await useAuthAxios(options, false, $externalResults.value)
    layoutStore.addSuccessNotification('반대거래가 성공적으로 등록되었습니다.')
    await fetchEntry(props.work)
    clean()
    update()
  } catch (e) {
    throw e
  }
}

const deleteCallback = ref(null)

const registerInverseDialogShow = ref(false)

const showRegisterInverseDialog = (entry) => {
  registerInverseDialogShow.value = true
  deleteCallback.value = () => registerInverse(entry)
}

const tableField = computed(() => {
  return {
    // code_transaction_type_display: {label: '구분', type: 'text', width: 50},
    code: {label: '코드', type: 'text', width: 100},
    code_name: {label: '코드명', type: 'text', width: 150},
    supplier_name: {label: '공급자', type: 'text', width: 150},
    buyer_name: {label: '공급받는자', type: 'text', width: 150},
    'total': {label: '합계', type: 'number', align: 'right', width: 100},
    amount: {label: '공급가액', type: 'number', align: 'right', width: 100},
    tax: {label: '세액', type: 'number', align: 'right', width: 100},
    method_display: {label: '결제방법', type: 'text', width: 100},
    memo: {label: '비고', type: 'text', width: 150},
    serial: {label: '일련번호', type: 'text', width: 150},
    user_name: {label: '작성자', type: 'text', width: 100},
    created_at: {label: '등록일시', type: 'datetime', width: 150},
    delete: {
      label: '반대거래 등록',
      type: 'button',
      width: 100,
      action: showRegisterInverseDialog
    }
  }
})

const computedEntries = computed(() => entries.value.map(entry => {
  return {
    ...entry,
    // code_transaction_type_display: entry.code_transaction_type === 'S' ? '매출' : '매입',
    created_at: dayjs(entry.created_at).format('YYYY-MM-DD HH:mm'),
    'total': entry.tax ? Number(entry.amount) + Number(entry.tax) : Number(entry.amount),
    // transaction_target_name: entry.company ? entry.company.name : entry.customer.name
  }
}))

const summary = computed(() => {
  let s = {
    sales: entries.value.filter(entry => entry.code_transaction_type === 'S').reduce((acc, cur) => {
      return acc + cur.amount
    }, 0),
    purchase: entries.value.filter(entry => entry.code_transaction_type === 'P').reduce((acc, cur) => {
      return acc + cur.amount
    }, 0),
  }
  s.total = s.sales - s.purchase
  return s
})

const calculateTax = (amount) => {
  return Math.round(amount * 0.1)
}

watch(() => data.amount, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  }
  data.tax = calculateTax(newValue) || null
})

const total = computed(() => {
  if (data.tax !== null) {
    return Number(data.amount) + Number(data.tax)
  }
  return Number(data.amount)
})
</script>
<!--['code', 'target', 'amount', 'memo']-->
<template>

  <div class="text-sm flex flex-flow gap-x-6 mb-6 px-2">
    <div><span class="font-semibold">매출</span> : {{ summary.sales.toLocaleString() }}</div>
    <div><span class="font-semibold">매입</span> : {{ summary.purchase.toLocaleString() }}</div>
    <div><span class="font-semibold">계</span> : {{ summary.total.toLocaleString() }}</div>
  </div>
  <div class="px-2">
    <form @submit.prevent="save">
      <div class="grid grid-cols-12 gap-x-2 gap-y-4 w-full mb-4">
        <AffiliateSelector v-model="data.supplier_company" :errors="validator.supplier_company.$errors.map(err => err.$message)"
                           :readonly="readonly || !!data.supplier_customer" :required="true" class="col-span-4 md:col-span-3"
                           label="공급 거래처"/>
        <CustomerSelector v-model="data.supplier_customer" :errors="validator.supplier_customer.$errors.map(err => err.$message)"
                          :readonly="readonly || !!data.supplier_company" :required="true" class="col-span-4 md:col-span-3"
                          label="공급 고객"/>
        <AffiliateSelector v-model="data.buyer_company" :errors="validator.buyer_company.$errors.map(err => err.$message)"
                           :readonly="readonly || !!data.buyer_customer" :required="true" class="col-span-4 md:col-span-3"
                           label="공급받는 거래처"/>
        <CustomerSelector v-model="data.buyer_customer" :errors="validator.buyer_customer.$errors.map(err => err.$message)"
                          :readonly="readonly || !!data.buyer_company" :required="true" class="col-span-4 md:col-span-3"
                          label="공급받는 고객"/>
      </div>
      <div class="grid grid-cols-12 gap-x-2 gap-y-4 w-full mb-4">
        <EntryCodeSelector v-model="data.code" :errors="validator.code.$errors.map(err => err.$message)"
                           :readonly="readonly" :required="true"
                           class="col-span-4 md:col-span-3" label="코드"/>
        <TransactionMethodSelector v-model="data.method" :errors="validator.method.$errors.map(err => err.$message)"
                                   :messages="[`공급가액 + 세액 = ${total.toLocaleString()}`]" :readonly="readonly"
                                   :required="true"
                                   class="col-span-4 md:col-span-3"/>
        <TextInput v-model="data.amount" :auto-select="true" :errors="validator.amount.$errors.map(err => err.$message)"
                   :readonly="readonly || data.method === null"
                   :required="true" class="col-span-4 md:col-span-3"
                   label="공급가액" type="number"/>
        <TextInput v-model="data.tax" :auto-select="true" :errors="validator.tax.$errors.map(err => err.$message)"
                   :messages="['면세일 경우 빈 값으로 두세요.']" :readonly="readonly || data.method === null"
                   class="col-span-4 md:col-span-3" label="세액" type="number"/>
      </div>
      <TextInput v-model="data.memo" :errors="validator.memo.$errors.map(err => err.$message)" :readonly="readonly"
                 class="w-full mb-4"
                 label="비고"/>
      <div class="col-span-4  md:col-span-1">
        <button :disabled="readonly" class="btn btn-primary" type="submit">저장</button>
      </div>
    </form>
    <InlineTableComponent :fields="tableField" :rows="computedEntries" heightClass="max-h-96"/>
    <ConfirmDialogComponent :open="registerInverseDialogShow" :severity="Severities.PRIMARY"
                            detail="이 작업은 되돌릴 수 없습니다." summary="반대 거래를 등록하시겠습니까?" accept-string="등록"
                            @accept="deleteCallback" @close="registerInverseDialogShow = false"/>
  </div>
</template>

<style scoped>

</style>