<script setup>
import {Cog6ToothIcon, ArrowRightStartOnRectangleIcon, MapIcon, MagnifyingGlassIcon, ArrowPathIcon} from "@heroicons/vue/24/outline";
import {computed, inject, nextTick, onMounted, ref, watch} from "vue";
import TableRowComponent from "@/components/tables/TableRowComponent.vue";
import TablePagination from "@/components/tables/TablePagination.vue";
import {useRoute, useRouter} from "vue-router";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import TableColHeaderComponent from "@/components/tables/TableColHeaderComponent.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import DrawerCore from "@/components/drawer/DrawerCore.vue";
import {cibInternetExplorer} from "@coreui/icons";

const route = useRoute()
const router = useRouter()
const layoutStore = useLayoutStore()

const props = defineProps({
  entityName: {type: String, default: '항목', required: false},
  entityUnit: {type: String, default: '개', required: false},
  openAdd: {type: Function, default: null, required: false},
  openAction: {type: [Function, null], default: null, required: false},
  tableActions: {type: Array, default: [], required: false},
  rowActions: {type: Array, default: [], required: false},
  rowsAction: {type: Array, default: [], required: false},
  fetch: {type: Function, required: true},
  queryFilter: {
    type: [Function], required: false, default: () => {
    }
  },
  dataFilter: {
    type: Function, required: false, default: (data) => {
      return data
    }
  },
  fields: {
    type: Object,
    required: true
  },
  toggleMap: {type: [Function, null], required: false, default: null},
  update: {type: [null, Object], required: false, default: null},
  sorts: {type: Array, required: false, default: () => []},
})

const emits = defineEmits(['openAdd', 'update'])

//fields
const fields = ref(props.fields)
// data
const objectData = ref(null)

//scroll control
const rowBox = ref(null)

// fetch data
const fetch = async (query = null, data = null) => {
  try {
    layoutStore.showOverlay()
    if (!query?.sort) {
      query = query || {}
      query['sort'] = props.sorts.join('|')
    }
    let response = await props.fetch(query, data)
    response['list'] = response['list'].map(item => props.dataFilter(item))
    objectData.value = response
    if (rowBox.value) {
      await nextTick(() => {
        if (rowBox.value.firstElementChild) {
          rowBox.value.firstElementChild.scrollIntoView({behavior: 'instant', block: 'end'})
        }
      })
    }
  } catch (e) {
    layoutStore.hideOverlay()
    throw e
  } finally {
    layoutStore.hideOverlay()
  }
}

onMounted(async () => {
  await fetch(route.query)
  // response['list'] = response['list'].map(item => props.dataFilter(item))
  // objectData.value = response
})

watch(() => route.query, async (newValue) => {
  await fetch(newValue)
  // let response = await props.fetch(query)
  // response['list'] = response['list'].map(item => props.dataFilter(item))
  // objectData.value = response
}, {deep: true})

watch(() => props.update, async (newValue, oldValue) => {
  if (newValue === oldValue) return null
  if (newValue) {
    let index = objectData.value['list'].findIndex(item => item.id === newValue.id)
    if (index > -1) {
      objectData.value['list'][index] = props.dataFilter(newValue)
    }
  }
})

// 다중 선택 처리
const selectedItems = ref([])

const selectAll = computed({
  get: () => {
    if (objectData.value?.list) {
      return selectedItems.value.length === objectData.value.list.length
    }
    return false
  },
  set: (value) => {
    if (value) {
      selectedItems.value = objectData.value.list
    } else {
      unSelectAll()
    }
  },
})

const unSelectAll = () => {
  selectedItems.value = []
}

const selectItem = (select, item) => {
  let index = selectedItems.value.indexOf(item)
  if (index < 0 && select === true) {
    selectedItems.value.push(item)
  }
  if (index > -1 && select === false) {
    selectedItems.value.splice(index)
  }
}
const searchKey = ref(1)

const queryFilter = () => {
  showFilter.value = false
  props.queryFilter()
}
const showFilter = ref(false)
const toggleFilter = () => {
  showFilter.value = !showFilter.value
}

const resetSearch = () => {
  router.push({query: {}})
  showFilter.value = false
  searchKey.value++
}


const setSort = (sortFieldName, sortValue) => {
  let index = objectData.value.sorts.map(s => s.startsWith('-') ? s.slice(1) : s).findIndex(sort => sort === sortFieldName)
  if (index > -1) {
    if (sortValue === null) {
      objectData.value.sorts.splice(index, 1)
    } else {
      objectData.value.sorts[index] = sortValue === '-' ? `-${sortFieldName}` : sortFieldName
    }
  } else {
    objectData.value.sorts.push(sortFieldName)
  }
  if (objectData.value.sorts.length > 0) {
    // router.push({query: {sort: objectData.value.sorts.map(sort => sort.value === '-' ? `-${sort.field}`:sort.field).join('|')}})
    router.push({query: {sort: objectData.value.sorts.join('|')}})
  } else {
    router.push({query: {}})
  }
}

const sorts = computed(() => {
  return objectData.value?.sorts.map(s => s.startsWith('-') ? {field: s.slice(1), value: '-'} : {field: s, value: '+'})
})

const update = async (event) => {
  emits('update', event)
  await fetch(route.query)
}

</script>

<template>
  <!-- 액션 바 시작 -->
<!--  {{objectData?.sorts}}-->
  <div class="flex px-4 h-[52px] min-h-[52px]">
    <div class="flex items-center gap-x-2">
      <template v-if="props.tableActions.length > 0">
        <DropdownComponent :itemList="props.tableActions" position-class="top-8 left-0 mr-5"/>
      </template>
      <div class="flex justify-start items-center gap-x-2">
        <span class="text-sm text-slate-900">
          {{ props.entityName }} {{ objectData?.total || 0 }}{{ props.entityUnit }}
        </span>
          <button type="button" class="bg-gray-200 p-1 rounded-sm" @click="() => fetch(route.query)">
            <ArrowPathIcon class="size-3"/>
          </button>
      </div>
    </div>
    <template v-if="selectedItems.length === 0">
      <div class="flex grow items-center justify-end gap-x-2">
        <button class="mr-2 btn btn-primary flex items-center justify-center gap-x-2" @click="toggleFilter">
          <MagnifyingGlassIcon class="size-4"/> 검색
        </button>
        <button v-if="props.openAdd" class="mr-2 btn btn-primary" @click="props.openAdd">+ {{ props.entityName }}
        </button>
        <template v-if="props.toggleMap">
          <button class="btn btn-secondary" type="button" @click="props.toggleMap">
            <MapIcon class="size-5"/>
          </button>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="flex grow items-center justify-end gap-x-2">
        <template v-for="action in rowsAction">
          <button :class="['btn', `btn-${action.severity}`]" @click="action.action(selectedItems)">{{ action.label }}
          </button>
        </template>
        <div class="mx-4 h-5 bg-slate-300 w-[1px]"></div>
        <button class="btn btn-secondary" @click="unSelectAll">취소</button>
        <span class="text-sm font-medium text-slate-900">({{ selectedItems.length }}{{ props.entityUnit }} 선택됨)</span>
      </div>
    </template>
  </div>
  <!-- 액션 바 끝 -->
  <div
      class="border border-slate-300 border-t-slate-400 flex h-[calc(100%_-_52px)] flex-col overflow-auto border-x-0 border-b-0">
    <!-- 테이블 헤더 시작 -->
    <div class="bg-slate-100 flex min-h-8 sticky top-0 border-slate-400 border-b min-w-fit z-10">
      <template v-if="props.rowsAction.length > 0">
        <div class="sticky left-0 border-r border-r-slate-400 bg-slate-100 min-w-8">
          <label class="flex cursor-pointer items-center justify-center size-full">
            <input v-model="selectAll" class="input-checkbox" type="checkbox">
          </label>
        </div>
      </template>
      <template v-for="[fieldKey, fieldData] in Object.entries(fields)" v-bind:key="fieldKey">
        <TableColHeaderComponent :field-data="fieldData" :field-key="fieldKey"
                                 @update:sort="setSort" :sorts="sorts || []"
                                 @update:width="width => fieldData['width'] = width"/>
      </template>
      <div class="grow"></div>
      <div v-if="props.rowActions.length > 0"
           class="sticky right-12 border-l border-l-slate-300 bg-inherit w-8">
        <div class="flex cursor-pointer items-center justify-center size-full">
          <Cog6ToothIcon class="size-4"/>
        </div>
      </div>
      <div v-if="props.openAction"
           class="sticky right-0 border-l border-l-slate-300 bg-inherit w-12">
        <div class="flex cursor-pointer items-center justify-center size-full">
          <ArrowRightStartOnRectangleIcon class="size-4"/>
        </div>
      </div>
    </div>
    <!-- 테이블 헤더 끝 -->

    <!-- 행 시작 -->
    <div v-if="objectData?.list" ref="rowBox" :class="['relative', 'w-100', `h-[${objectData.list.length * 32}px]`]">
      <TableRowComponent v-for="(objectItem, index) in objectData.list" v-bind:key="objectItem.id" :fields="fields"
                         :index="index" :item="objectItem" :openAction="props.openAction"
                         :row="objectItem" :rowAction="props.rowsAction"
                         :rowActions="rowActions" :rowsAction="props.rowsAction" :selectedItems="selectedItems"
                         @update:select="select => selectItem(select, objectItem)" @update="update"
      />
    </div>
    <!-- 행 끝 -->
  </div>
  <template v-if="objectData">
    <TablePagination :data="objectData"/>
  </template>

  <DrawerCore v-if="selectedItems.length === 0" :open="showFilter" :title="`${props.entityName} 검색/필터`"
              @close="toggleFilter" :key="searchKey">
    <div class="px-4">
      <form class="space-y-4" @submit.prevent="queryFilter" ref="searchForm">
        <slot name="filter"/>
        <button class="btn btn-primary w-full flex items-center justify-center gap-x-2" type="submit">
          <MagnifyingGlassIcon class="size-4"/> 검색
        </button>
        <button class="btn btn-secondary w-full" type="button" @click="resetSearch">초기화</button>
      </form>
    </div>
  </DrawerCore>
</template>

<style scoped>

</style>