<script setup>
import {BellIcon, Bars3Icon} from "@heroicons/vue/24/outline";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {ref} from "vue";

const layoutStore = useLayoutStore()

const props = defineProps({
  title: {
    type: String,
    default: "페이지 타이틀"
  }
})

</script>

<template>
  <div class="relative flex h-12 min-h-12 w-full items-center justify-between border-b">
    <div class="flex items-center justify-start px-4 gap-x-4">
      <button :class="layoutStore.mobileNav ? 'hidden':'block'"
              @click="layoutStore.toggleMobileNav()">
        <Bars3Icon class="size-6"/>
      </button>
      <div>
      <span class="text-sm font-medium text-slate-900">
        {{ props.title }}
      </span>
      </div>
    </div>

    <div class="relative">
      <button class="bg-white mr-5 flex cursor-pointer items-center justify-end rounded-full p-1 hover:bg-slate-100">
        <BellIcon class="size-6"/>
      </button>
    </div>
  </div>
</template>

<style scoped>

</style>