<script setup>
import {computed} from "vue";
import InlineTableCell from "@/components/Inlinetable/InlineTableCell.vue";

const props = defineProps({
  fields: {type: Object, required: true},
  row: {type: Object, required: true},
  filter: {type: Function, required: false, default: (row) => row},
})
</script>

<template>
  <div class="border-b flex min-w-fit min-h-[2rem] h-[2rem] bg-white hover:bg-slate-100">
    <template v-for="[fieldKey, fieldData] in Object.entries(fields)">
      <InlineTableCell :fieldKey="fieldKey" :fieldData="fieldData" :row="props.row" :filter="props.filter"/>
    </template>
  </div>
</template>

<style scoped>

</style>