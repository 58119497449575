<script setup>

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon
} from "@heroicons/vue/24/outline/index.js";
import {computed, nextTick, ref} from "vue";
import DropdownComponent from "@/components/dropdown/DropdownComponent.vue";
import {onClickOutside} from "@vueuse/core";

const props = defineProps({
  page: {type: Number, default: 1, required: true},
  maxPage: {type: Number, default: 1, required: true},
  limit: {type: Number, default: 50, required: false},
})

const emits = defineEmits(['set:limit', 'set:page'])


const movePage = (pageNo) => {
  pageNo = parseInt(pageNo)
  if (isNaN(pageNo)) {alert('페이지 번호가 올바르지 않습니다.'); return;}
  if (pageNo < 1 || pageNo > props.maxPage) {
    alert('페이지 번호가 올바르지 않습니다.')
    return;
  }
  emits('set:page', pageNo)
}
const movePageFromNumber = (event) => {
  togglePageInput()
  let pageNo = event.target.value
  movePage(pageNo)
}

const setLimit = (limit) => {
  emits('set:limit', limit)
}

const limitListValue = [20, 50, 100, 200, 500]

const limitList = computed(() => {
  return limitListValue.map(limitValue => {
    return {
      label: `${limitValue}개`,
      action: () => setLimit(limitValue),
      current: props.limit === limitValue,
      rightIcon: limitValue === 500 ? ExclamationTriangleIcon : null
    }
  })
})
const pageInput = ref(null)

const pageInputShow = ref(false)

const togglePageInput = () => {
  pageInputShow.value = !pageInputShow.value
  nextTick(() => {
    if (pageInputShow.value) {
      pageInput.value.focus()
    }
  })
}

onClickOutside(pageInput, event => {
  togglePageInput()
}, {immediate: false})

const has_prev = computed(() => props.page > 1)
const has_next = computed(() => props.page < props.maxPage)
</script>

<template>
  <div class="flex h-10 min-h-10 max-w-full border-t bg-slate-50">
    <div class="mx-2 flex items-center justify-between">
      <button :class="[has_prev ? '':'cursor-not-allowed']"
              :disabled="!has_prev"
              class="rounded p-0.5 hover:bg-slate-200 mr-2 text-slate-400" @click="movePage(props.page-1)">
        <ChevronLeftIcon class="size-5 min-w-5"/>
      </button>
      <div class="flex min-w-max items-center">
        <span class="mr-2 text-sm text-slate-900">Page</span>
        <template v-if="pageInputShow">
          <input ref="pageInput" @keyup.enter="movePageFromNumber"
              class="input-text text-sm size-7 rounded-md border-gray-300 p-0 text-center focus:outline-none focus:border-gray-300 focus:ring-0">
        </template>
        <template v-else>
        <span class="cursor-pointer rounded p-1 text-sm text-slate-900 hover:bg-slate-200" @click="togglePageInput">
          {{ props.page }}<!-- --> / <!-- -->{{ props.maxPage }}
        </span>
        </template>
      </div>
      <button :class="[has_next ? '':'cursor-not-allowed']"
              :disabled="!has_next"
              class="rounded p-0.5 hover:bg-slate-200 mx-2 text-slate-400" @click="movePage(props.page+1)">
        <ChevronRightIcon class="size-5 min-w-5"/>
      </button>
      <div class="flex w-6">
        <DropdownComponent :icon="ChevronUpIcon" :rowActions="limitList" header="페이지당 표시 갯수"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>