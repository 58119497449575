<script setup>

import {computed, ref, watch} from "vue";
import {useDraggable, useElementBounding} from "@vueuse/core";
import {ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
  fieldKey: {
    type: String,
    required: true
  },
  fieldData: {
    type: Object,
    required: true,
  },
  sorts: {type: Array, required: false, default: () => []},
})

const emits = defineEmits(['update:width', 'update:sort'])

const handler = ref(null)
const parent = ref(null)



const width = computed(() => {
  let width = props.fieldData?.width ? Number(props.fieldData.width) : 200
  return `${width}px`
})

const {x, y, style} = useDraggable(handler, {
  // initialValue: {x: Number(props.fieldData.width) || 200, y: 32},
  initialValue: {x: 0, y:0},
})

watch(() => x, (newValue) => {
  let rect = parent.value.getBoundingClientRect()
  emits('update:width', newValue.value - rect.x)
}, {deep: true})

const sortable = computed(() => {
  return props.fieldData.sortable !== false;
})

const sortFieldName = computed(() => {
  if (typeof props.fieldData.sortable === 'string') {
    return props.fieldData.sortable
  } else {
    return props.fieldKey
  }
})

// const sort = ref(null)

const sortOrder = computed(() => {
  let order = props.sorts.findIndex(item => item.field === sortFieldName.value) || 0
  return order + 1
})

const sort = computed(() => {
  let order = props.sorts.findIndex(item => item.field === sortFieldName.value)
  if (order > -1) {
    let sort = props.sorts.find(item => item.field === sortFieldName.value)
    sort.order = order + 1
    return sort
  } else {
    return null
  }
})

const changeSort = () => {
  if (!sortable.value) return
  let sortValue = null
  if (sort.value === null) {
    sortValue =  '+'
  } else if (sort.value.value === '+') {
    sortValue = '-'
  } else if (sort.value.value === '-') {
    sortValue  = null
  }
  emits('update:sort', sortFieldName.value, sortValue)
}
</script>

<template>
  <div :style="{width: width, 'min-width': width, 'overflow': 'visible'}" ref="parent"  @click="changeSort"
       class="flex relative border-r border-slate-400 items-center truncate" :class="[sortable ? 'cursor-pointer':'']">
    <div class="flex z-0 group items-center justify-between w-full truncate mx-2">
      <div class="flex items-center truncate">
        <span class="truncate text-sm font-medium text-slate-900 select-none">{{ props.fieldData.label }}</span>
      </div>
      <div v-if="sortable" class="flex items-center">
        <span class="ml-1" v-if="sort===null">
          <ChevronUpDownIcon class="size-4 text-slate-400"/>
        </span>
        <span class="ml-1" v-if="sort?.value==='+'">
          <ChevronUpIcon class="size-4 inline-flex"/> <span class="text-xs text-slate-500">{{sort.order}}</span>
        </span>
        <span class="ml-1" v-if="sort?.value==='-'">
          <ChevronDownIcon class="size-4 inline-flex"/> <span class="text-xs text-slate-500">{{sort.order}}</span>
        </span>
      </div>
    </div>
    <div :style="{width: width, height: `32px`}" class="!absolute left-0 top-0 h-full react-resizable">
      <span ref="handler" class="opacity-0  hover:opacity-100 cursor-col-resize w-[4px] h-full absolute right-0 bg-primary-400"></span>
    </div>
  </div>
</template>

<style scoped>

</style>