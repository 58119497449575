<script setup>
import ModalBase from "@/components/modals/ModalBase.vue";
import {Severities, Sizes} from "@/constant.js";
import {nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {
  cellphoneValidator, dateStringValidator, emailValidator,
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage,
  nullBrnValidator, requiredWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useRouter} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import {useAuthStore} from "@/stores/authStore.js";
import TextArea from "@/components/forms/TextArea.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";

const authStore = useAuthStore()
const layoutStore = useLayoutStore()
const router = useRouter()

const props = defineProps({
  isOpen: {type: Boolean, required: true},
})
const emits = defineEmits(['close', 'showCampaign', 'create:campaign'])

const data = reactive({
  name: '',
  // start_date: '',
  // end_date: '',
  work_type: null,
  memo: '',
  manager: authStore.user || null
})

const $externalResults = ref({})

const model = {
  name: {
    required: helpers.withMessage('캠페인명을 입력해주세요.', required),
    minLength: minLengthWithMessage('캠페인명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('캠페인명을 30글자 이하로 입력해주세요.', 30)
  },
  // start_date: {
  //   format: dateStringValidator(),
  // },
  // end_date: {
  //   format: dateStringValidator(),
  // },
  work_type: {externalValidator},
  memo: {externalValidator},
  manager: {
    required: requiredWithMessage('필수 항목입니다.')
  },
}

const validator = useVuelidate(model, data, {$externalResults})

const getPrepare = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  // prepare.start_date = value.start_date ? value.start_date : null
  // prepare.end_date = value.end_date ? value.end_date : null
  prepare.work_type = prepare.work_type ? prepare.work_type.value : null
  prepare.memo = value.memo ? value.memo : null
  prepare.manager = prepare.manager?.id || null
  return prepare
}

const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  try {
    let campaign = await useAuthAxios({
      method: 'PUT',
      url: `/app/v1/campaign/`,
      data: getPrepare(data)
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    showCampaign(campaign)
    clean()
    emits('close')
    validator.value.$reset()
    emits('create:campaign')
    // await router.push({name: 'sales-campaign-list', params: {id: company.id}})
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const setName = event => {
  data.name = event.target.value
}

const setWorkType = selected => {
  data.work_type = selected
}
// const setStartDate = event => {
//   data.start_date = event.target.value
// }
// const setEndDate = event => {
//   data.end_date = event.target.value
// }


// show Customer
const showCampaign = (campaign) => {
  emits('showCampaign', campaign)
}


// 초기화

const clean = () => {
  data.name = ''
  // data.start_date = ''
  // data.end_date = ''
  data.memo = ''
  data.manager = authStore.user || null
}

const close = () => {
  clean()
  emits('close')
}

const nameInput = ref(null)

onMounted(() => {
  data.manager = authStore.user || null
})

watch(() => props.isOpen, (newValue, oldValue) => {
  if (newValue === oldValue) return null
  if (!newValue) return null
  nextTick(() => {
    nameInput.value.focus()
  })
})

</script>

<template>
  <ModalBase :isOpen="props.isOpen" :size="Sizes.SMALL" title="캠페인 등록" @close="close">
    <div class="flex items-start justify-start w-full">
      <form class="w-full p-4" @submit.prevent="save">
        <TextInput v-model="data.name" label="캠페인명" name="name" class="mb-4" :required="true"
                   :errors="validator.name.$errors.map(e => e.$message)"/>
        <ChoiceSelectorBase v-model="data.work_type" choice-type="WorkTypeChoice" label="타입" class="mb-4"
                          :errors="validator.work_type.$errors.map(e => e.$message)"/>
<!--        <div :class="{invalid: validator.start_date.$error}" class="input-group mb-4">-->
<!--          <label class="w-16" for="id-for-start_date">시작일시</label>-->
<!--          <div>-->
<!--            <input id="id-for-start_date" ref="startDateInput" :value="data.start_date" data-1p-ignore name="start_date"-->
<!--                   type="date" @input="setStartDate">-->
<!--            <template v-for="error in validator.start_date.$errors">-->
<!--              <p class="error">{{ error.$message }}</p>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div :class="{invalid: validator.end_date.$error}" class="input-group mb-4">-->
<!--          <label class="w-16" for="id-for-end_date">종료일시</label>-->
<!--          <div>-->
<!--            <input id="id-for-end_date" ref="startDateInput" :value="data.end_date" data-1p-ignore name="end_date"-->
<!--                   type="date" @input="setEndDate">-->
<!--            <template v-for="error in validator.end_date.$errors">-->
<!--              <p class="error">{{ error.$message }}</p>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
        <TextArea v-model="data.memo" label="메모" name="note" class="mb-4"/>
        <EmployeeSelector :errors="validator.manager.$errors.map(e => e.$message)" v-model="data.manager" class="mt-4"
                          label="담당자" :required="true" placeholder="캠페인 담당자"/>
        <button class="btn btn-primary mt-4" type="submit">저장</button>
      </form>
    </div>
    <template #footer>
      <button id="cancel-button" class="btn btn-secondary mr-2" @click="close">닫기</button>
    </template>
  </ModalBase>
</template>

<style scoped>

</style>