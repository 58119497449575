<script setup>

import VolumeSelector from "@/components/selector/choices/VolumeSelector.vue";
import WasteClassSelector from "@/components/selector/choices/WasteClassSelector.vue";
import WasteTypeSelector from "@/components/selector/choices/WasteTypeSelector.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {externalValidator, requiredWithMessage} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {Severities} from "@/constant.js";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";

const layoutStore = useLayoutStore()
const props = defineProps({
  work: {type: [null, Object], required: true, default: null},
  canUpdateWaste: {type: Boolean, required: false, default: false},
})
const emits = defineEmits(['update'])

const data = reactive({
  waste_class: props.work?.waste_class || null,
  waste_type: props.work?.waste_type || null,
  volume: props.work?.volume || null,
  work_type: props.work?.campaign_work_type || null,
})

const assignData = (original) => {
  data.waste_class = original?.waste_class || null
  data.waste_type = original?.waste_type || null
  data.volume = original?.volume || null
  data.work_type = original?.campaign_work_type || null
}

onMounted(() => {
  assignData(props.work)
})

watch(() => props.work, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return null
  } else if (newValue) {
    assignData(newValue)
  }
}, {deep: true})

const model = {
  waste_class: {
    required: requiredWithMessage('성상을 선택해주세요.')
  },
  waste_type: {externalValidator},
  volume: {
    required: requiredWithMessage('부피를 선택해주세요.')
  }
}

const $externalResults = ref({})
const validator = useVuelidate(model, data, {$externalResults, $scope: false})

const save = async (notify) => {
  if (validator.value.$error) {
    return null
  }
  try {
    let response = await useAuthAxios({
      method: 'POST',
      url: `/app/v1/work/small/${props.work.id}/waste/`,
      data: {
        waste_class: data.waste_class.value,
        waste_type: data.waste_type ? data.waste_type.value : null,
        volume: data.volume.value,
        notify: notify
      }
    }, false, $externalResults.value)
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    validator.value.$reset()
    emits('update', response)
    return data
  } catch (e) {
    throw e
  }
}

const showConfirm = ref(false)

const beforeSave = () => {
  validator.value.$touch()
  if (validator.value.$error) {
    return null
  }
  if (props.driver) {
    showConfirm.value = true
  } else {
    save(false)
  }
}

const readonly = computed(() => {
  return !props.canUpdateWaste
})
</script>

<template>
  <div class="">
    <div class="flex items-center justify-start gap-x-3 mb-3">
      <div class="text-sm font-semibold">성상</div>
    </div>

    <form @submit.prevent="beforeSave">
      <div class="grid grid-cols-6 justify-end md:grid-cols-10 gap-x-4 gap-y-4 mb-2">
        <WasteClassSelector v-model="data.waste_class" :errors="validator.waste_class.$errors.map(e => e.$message)"
                            :required="true" class="col-span-3 md:col-span-3" label="성상" :readonly="readonly"/>
        <WasteTypeSelector v-model="data.waste_type" :errors="validator.waste_type.$errors.map(e => e.$message)"
                           :wasteClass="data.waste_class" class="col-span-3 md:col-span-3" label="성상 상세"
                  :readonly="readonly"/>
        <VolumeSelector v-model="data.volume" :errors="validator.volume.$errors.map(e => e.$message)"
                        :required="true" :work-type="data.work_type || null" class="col-span-3 md:col-span-3"
                        label="부피" :readonly="readonly"/>
        <button class="btn btn-primary col-span-2 md:col-span-1" :disabled="readonly" type="submit">저장</button>
      </div>
    </form>
  </div>
  <ConfirmDialogComponent :detail="'기사에게 알림을 발송하겠습니까?'" :open="showConfirm"
                          accept-string="발송" reject-string="미발송"
                          title="알림 발송" @accept="save(true)"
                          @close="showConfirm = false" @reject="save(false)"/>
</template>

<style scoped>

</style>