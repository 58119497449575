import {createRouter, createWebHistory} from "vue-router";
import LoginPage from "@/views/auth/LoginPage.vue";
import LogoutPage from "@/views/auth/LogoutPage.vue";
import PasswordResetPage from "@/views/auth/PasswordResetPage.vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import {useAuthStore} from "@/stores/authStore";
import ErrorView from "@/views/errors/ErrorView.vue";
import NoNavLayout from "@/layout/NoNavLayout.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import EmployeeListPage from "@/views/settings/EmployeeListPage.vue";
import CompanyListPage from "@/views/settings/CompanyListPage.vue";
import EmployeeDetailPage from "@/views/settings/EmployeeDetailPage.vue";
import CompanyDetailPage from "@/views/settings/CompanyDetailPage.vue";
import VehicleListPage from "@/views/vehicle/VehicleListPage.vue";
import CustomerListPage from "@/views/sales/customer/CustomerListPage.vue";
import AffiliateListPage from "@/views/sales/affiliate/AffiliateListPage.vue";
import CampaignListPage from "@/views/sales/campaign/CampaignListPage.vue";
import ProfilePage from "@/views/profile/ProfilePage.vue";
import SalesListPage from "@/views/sales/sales/SalesListPage.vue";
import TestPage from "@/views/settings/TestPage.vue";
import AffiliatePricePage from "@/views/sales/affiliate/AffiliatePricePage.vue";
import DeviceList from "@/views/call_log/DeviceList.vue";
import CallLogList from "@/views/call_log/CallLogList.vue";
import SmallWorkListPage from "@/views/work/SmallWorkListPage.vue";
import {useWindowSize} from "@vueuse/core";
import EntryCodeListPage from "@/views/accounting/EntryCodeListPage.vue";
import EntryListPage from "@/views/accounting/EntryListUnBilledPage.vue";
import BillListPage from "@/views/accounting/BillListPage.vue";

export const RoutesData = {
    // routes wide하게 name과 path가 중복되면 안됨!
    Auth: {
        path: '/auth', component: NoNavLayout,
        children: {
            Login: {
                path: '/auth/login', name: 'login', component: LoginPage, meta: {authRequired: false}
            },
            Logout: {
                path: '/auth/logout', name: 'logout', component: LogoutPage, meta: {authRequired: true}
            },
            PasswordReset: {
                path: '/auth/password-reset', name: 'password-reset', component: PasswordResetPage, meta: {authRequired: false}
            }
        }
    },
    Main: {
        path: '/',
        children: {
            Home: {
                path: '/', name: 'home', component: Dashboard, meta: {authRequired: true}
            },
            Error: {
                path: '/error', name: 'error', component: ErrorView, meta: {authRequired: null}
            },
        }
    },
    Personal: {
        path: '/personal',
        name: 'profile', component: ProfilePage, meta: {authRequired: true}
    },
    SmallWork: {
        path: '/work/small',
        children: {
            List: {
                path: '/work/small', name: 'work-small',
                children: {
                    List: {path: '/work/small', name: 'work-small-list', component: SmallWorkListPage, meta: {authRequired: true}},
                }
            }
        }
    },
    Work: {
        path: '/work',
        children: {
            Small: {
                path: '/work/small', name: 'work-small',
                children: {
                    List: {path: '/work/small', name: 'work-small-list', component: SmallWorkListPage, meta: {authRequired: true}},
                }
            }
        }
    },
    Sales: {
        path: '/sales',
        children: {
            Sales: {
                path: '/sales/sales', name: 'sales', component: SalesListPage, meta: {
                    authRequired: true
                }
            },
            Customer: {
                path: '/sales/customer', name: 'contact-customer-list', component: CustomerListPage, meta: {
                    authRequired: true
                }
            },
        }
    },
    Affiliate: {
        path: '/affiliate',
        children: {
            Affiliate: {
                path: '/affiliate/affiliate', name: 'affiliate-affiliate-list', component: AffiliateListPage, meta: {
                    authRequired: true
                }
            },
            AffiliatePrice: {
                path: '/affiliate/affiliate/price', name: 'affiliate-affiliate-price', component: AffiliatePricePage, meta: {
                    authRequired: true
                }
            }
        }
    },
    Accounting: {
        path: '/accounting',
        children: {
            Code: {
                path: '/accounting/code', name: 'accounting-code', component: EntryCodeListPage, meta: {authRequired: true}
            },
            Bill: {
                path: '/accounting/bill', name: 'accounting-bill', component: BillListPage, meta: {authRequired: true}
            },
            EntryUnbilled: {
                path: '/accounting/entry/unbiled', name: 'accounting-entry-unbiled', component: EntryListPage, meta: {authRequired: true}
            },
        }
    },
    CallLog: {
        path: '/call-log',
        children: {
            CallLog: {
                path: '/call-log/log', name: 'call-log-log', component: CallLogList, meta: {authRequired: true}
            },
            Device: {
                path: '/call-log/device', name: 'call-log-device', component: DeviceList, meta: {authRequired: true}
            }
        }
    },
    Vehicle:{
        path: '/vehicle',
        children: {
            List: {
                path: '/vehicle', name: 'vehicle-list', component: VehicleListPage, meta: {authRequired: true}
            }
        }

    },
    Settings: {
        path: '/settings',
        children: {
            Employee: {
                path: '/settings/employee', name: 'settings-employee', component: EmployeeListPage, meta: {authRequired: true}
            },
            EmployeeDetail: {
                path: '/settings/employee/:id', name: 'settings-employee-detail', component: EmployeeDetailPage, meta: {authRequired: true}
            },
            Company: {
                path: '/settings/company', name: 'settings-company', component: CompanyListPage, meta: {authRequired: true}
            },
            CompanyDetail: {
                path: '/settings/company/:id', name: 'settings-company-detail', component: CompanyDetailPage, meta: {authRequired: true}
            },
            Campaign: {
                path: '/settings/campaign', name: 'settings-campaign-list', component: CampaignListPage, meta: {
                    authRequired: true
                }
            },
            Test: {
                path: '/settings/test', name: 'testview', component: TestPage, meta: {authRequired: true}
            }
        }
    },
}

const unpackRoute = (route) => {
    let routerData = {}
    routerData.path = route.path
    routerData.component = route.component
    if (route.meta) {
        routerData.meta = route.meta
    }
    if (!route.children) {
        routerData.name = route.name
    } else {
        let children = []
        Object.entries(route.children).forEach(([key, value]) => {
            children.push(unpackRoute(value))
        })
        routerData.children = children
    }
    return routerData
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: Object.entries(RoutesData).map(([key, value]) => unpackRoute(value))
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const layoutStore = useLayoutStore()
    const {width: windowWidth} = useWindowSize()
    // 보여지고 있는 모달을 닫는다.
    layoutStore.confirm = null
    layoutStore.alert = null
    layoutStore.notifications = []
    layoutStore.mobileNav = windowWidth.value >= 768
    let authRequired = [true, false, null].indexOf(to.meta?.authRequired) > -1 ? to.meta.authRequired : null;
    if (authRequired === true) {
        if (authStore.access == null) {
            authStore.user = null;
            authStore.access = null;
            authStore.refresh = null;
            layoutStore.addErrorNotification('로그인 필요', '로그인이 필요한 서비스 입니다.', 2000)
            next(RoutesData.Auth.children.Login.path)
        } else {
            await authStore.verifyToken()
            next()
        }
    } else if (authRequired === false) {
        if (authStore.access === null) {
            next()
        } else {
            layoutStore.addInfoNotification('로그인 확인', '이미 로그인 되었습니다.', 2000)
            next('/')
        }
    } else {
        next();
    }
});

router.onError(async (error) => {
    console.log(error)
    alert('error')
    // if (error.nextRoute) {
    //     await router.push(error.nextRoute);
    // } else {
    //     await router.push("/");
    // }
});

export default router;
