<script setup>

import DrawerCore from "@/components/drawer/DrawerCore.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import {
  externalValidator,
  maxLengthWithMessage,
  minLengthWithMessage
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {APIError} from "@/exceptions.js";
import CallLogManager from "@/components/drawer/partial/CallLogManager.vue";
import VueAudioPlayer from '@liripeng/vue-audio-player'
import CallLogProcess from "@/components/drawer/partial/CallLogProcess.vue";
import {useAuthStore} from "@/stores/authStore.js";

const authStore = useAuthStore();
const layoutStore = useLayoutStore()

const props = defineProps({
  open: {
    type: Boolean,
    required: false,
    default: false
  },
  callLog: {
    type: [Object, null],
    required: false,
    default: null
  }
})

const emits = defineEmits(['close', 'update'])

const update = async () => {
  emits('update')
  await fetchCallLog()
}

const close = () => {
  emits('close')
}

const callLog = ref(null)

const fetchData = async () => {
  return useAuthAxios({
    method: 'GET',
    url: `/app/v1/call_log/${props.callLog.id}/`
  })
}

const fetchCallLog = async () => {
  callLog.value = await fetchData()
}

onMounted(async () => {
  await fetchCallLog()
})


const data = reactive({
  name: null,
  phone_no: null,
  user: null,
})

const model = {
  name: {
    required: helpers.withMessage('기기명을 입력해주세요.', required),
    minLength: minLengthWithMessage('기기명을 2글자 이상으로 입력해주세요.', 2),
    maxLength: maxLengthWithMessage('기기명을 10글자 이하로 입력해주세요.', 10)
  },
  phone_no: {
    required: helpers.withMessage('휴대전화번호를 입력해주세요', required),
  },
  user: {externalValidator}
}
const $externalResults = ref({})

const validator = useVuelidate(model, data, {$externalResults})

const reset = () => {
  data.name = null
  data.phone_no = null
  data.user = null
  validator.value.$reset()
}
const save = async () => {
  validator.value.$clearExternalResults()
  let valid = await validator.value.$validate()
  if (!valid) return
  let prepare = {
    ...data
  }
  prepare['user'] = prepare.user.user.id
  let options = {
    method: 'PUT',
    url: `/app/v1/call_log/device/`,
    data: prepare
  }
  try {
    const newDevice = await useAuthAxios(options, false)
    layoutStore.addSuccessNotification('기기가 성공적으로 저장되었습니다.')
    reset()
    close()
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const sentences = computed(() => {
  return callLog.value?.callrecord?.transcribe_data?.sentences || null
})

const callFile = computed(() => {
  return callLog.value?.callrecord?.file ? [{
    src: callLog.value.callrecord.file,
    title: callLog.value.callrecord.filename
  }] : []
})
const audioPlayer = ref(null)

const play = async (next) => {
  currentTime.value = 0
  next()
}

const move = (position) => {
  audioPlayer.value.play({currentTime: parseInt(position) / 1000})
}

const currentTime = ref(0)

const timeupdate = () => {
  currentTime.value = audioPlayer.value.currentTime * 1000
}

const showProgress = computed(() => {
  return callLog.value?.manager === authStore.user.id
})


</script>

<template>
  <DrawerCore v-if="callLog" :open="props.open" title="통화기록" @close="close" @update="update">
    <div class="px-4">
      <div class="mb-4">
        <CallLogManager :callLog="callLog" @update="update"/>
      </div>
      <div v-if="showProgress" class="mb-4">
        <CallLogProcess :callLog="callLog" @update="update"/>
      </div>

      <!--      <template v-if="callLog.callrecord">-->
      <!--        <div class="mb-2 font-semibold text-sm">처리내용</div>-->
      <!--        <div class="mb-4">-->
      <!--          {{ callLog.processed_at }} {{ callLog.processed_by }} {{ callLog.process }}-->
      <!--        </div>-->
      <template v-if="callLog?.callrecord">
        <div class="mb-2 font-semibold text-sm">통화내용</div>
        <div class="mb-4">
          <VueAudioPlayer ref="audioPlayer" :audio-list="callFile" :before-play="next => play(next)"
                          :show-next-button="false" :show-play-loading="true"
                          :show-prev-button="false" theme-color="#1e64ff" @playing="timeupdate"/>
        </div>
        <template v-if="sentences">
          <div class="relative w-full h-[500px] overflow-y-auto">
            <template v-for="sentence in sentences">
              <div :class="[sentence.speaker === 'A' ? 'justify-start':'justify-end']" class="w-full flex mb-2">
                <div :class="[sentence.speaker === 'A' ? 'bg-primary-200/80':'bg-yellow-200/80']"
                     class="w-3/4 p-2 rounded-md text-sm cursor-pointer select-none"
                     @click="move(sentence.start)">
                  {{ sentence.text }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center">
            <div class="text-sm text-gray-500 mb-2">녹음파일 처리 중입니다.</div>
            <button class="btn btn-primary btn-sm" type="button" @click="fetchCallLog">새로고침</button>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center">
          <div class="text-sm text-gray-500 mb-2">아직 녹음 파일이 업로드 되지 않았습니다.</div>
          <button class="btn btn-primary btn-sm" type="button" @click="fetchCallLog">새로고침</button>
        </div>
      </template>
    </div>
    <!--    <form class="w-96 p-4" @submit.prevent="save">-->
    <!--      <div :class="{invalid: validator.name.$error}" class="input-group mb-4">-->
    <!--        <label class="w-24" for="id-for-name">기기명 <span class="text-red-600">*</span></label>-->
    <!--        <div>-->
    <!--          <input id="id-for-name" ref="nameInput" :value="data.name" data-1p-ignore name="name"-->
    <!--                 type="text" @input="event => data.name = event.target.value">-->
    <!--          <template v-for="error in validator.name.$errors">-->
    <!--            <p class="error">{{ error.$message }}</p>-->
    <!--          </template>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div :class="{invalid: validator.phone_no.$error}" class="input-group mb-4">-->
    <!--        <label class="w-24" for="id-for-phone_no">휴대전화번호 <span class="text-red-600">*</span></label>-->
    <!--        <div>-->
    <!--          <input id="id-for-phone_no" :value="data.phone_no" data-1p-ignore name="phone_no"-->
    <!--                 type="text" @input="event => data.phone_no = event.target.value">-->
    <!--          <template v-for="error in validator.phone_no.$errors">-->
    <!--            <p class="error">{{ error.$message }}</p>-->
    <!--          </template>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <EmployeeSelector :required="true" :selected="data.user" label="소유자" @update:select="user => data.user=user"/>-->
    <!--      <button class="btn btn-primary mt-4" type="submit">저장</button>-->
    <!--    </form>-->
  </DrawerCore>
</template>

<style scoped>

</style>