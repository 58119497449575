<script setup>

import useVuelidate from "@vuelidate/core";
import {reactive, ref} from "vue";
import loginValidator from "@/validators/loginValidator.js";
import logo from "@/assets/image/logo.svg";
import useNoAuthAxios from "@/composables/useNoAuthAxios.js";
import {useAuthStore} from "@/stores/authStore.js";
import {useRouter} from "vue-router";
import {Stage} from "@/constant.js";
import Logo from "@/components/Logo.vue";
import {layouts} from "chart.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import dayjs from "dayjs";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const loginData = Stage === 'LOCAL' ? reactive({
    email: 'hce@ichunil.com',
    password: 'ghdcksdml3#'
}) : reactive({
    email: '',
    password: ''
})
const validator = useVuelidate(loginValidator, loginData)
const authStore = useAuthStore()

const layoutStore = useLayoutStore()
const loginUser = async () => {
    let valid = await validator.value.$validate()
    if (!valid) return
    const options = {
        method: 'post',
        url: `/app/v1/user/token/`,
        data: loginData,
    }
    try {
        const response = await useNoAuthAxios(options, true)
        authStore.access = response.access
        authStore.refresh = response.refresh
        await authStore.setUser()
        await router.push("/");
        loginData.password = ''
    } catch (e) {
        console.log(typeof e.error?.response?.status)
        if (e.error?.response?.status === 401) {
            layoutStore.addErrorNotification('이메일 또는 비밀번호가 일치하지 않습니다.')
            return null
        } else {
            throw e
        }
    }
}

const date = ref(dayjs().format('YYYY-MM-DD'))

const findPassword = () => {
    router.push({name: 'password-reset'})
}
// const date = ref('')
</script>
<template>
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="md:mx-auto md:w-full md:max-w-sm">
            <img :src="logo" alt="지구하다" class="mx-auto h-8 w-auto"/>
            <!--      <h2 class="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">원자재/납품 배차</h2>-->
        </div>

        <div class="mt-10 md:mx-auto md:w-full md:max-w-sm">
            <form novalidate @submit.prevent="loginUser">
                <TextInput v-model="loginData.email" :errors="validator.email.$errors.map(error => error.$message)"
                           class="mb-4" label="이메일" name="email"
                           type="email"/>
                <TextInput v-model="loginData.password" :errors="validator.password.$errors.map(error => error.$message)"
                           class="mb-4" label="비밀번호"
                           name="password"
                           type="password"/>
                <button class="btn btn-primary w-full btn-xl mb-2" type="submit">로그인</button>
                <button class="btn btn-secondary w-full btn-xl" type="button" @click="findPassword">비밀번호 찾기</button>
            </form>

            <div>
                <div class="mt-10 text-center text-sm text-gray-500">인가된 사용자만 로그인할 수 있습니다.</div>
                <div class="text-center text-sm text-gray-500">dev@ichunil.com 으로 이용 문의 바랍니다.</div>
            </div>
        </div>
    </div>

</template>
