<script setup>

import {onMounted, reactive} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useAuthStore} from "@/stores/authStore.js";
import useVuelidate from "@vuelidate/core";
import {ref} from "vue";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import InfoBlock from "@/components/forms/InfoBlock.vue";
import CompanySelector from "@/components/selector/apiSelector/CompanySelector.vue";
import {helpers, required} from "@vuelidate/validators";

const authStore = useAuthStore()

const props = defineProps({
  userData: Object
})
const emits = defineEmits(['fetch'])

const data = reactive({
  company: props.userData.company
})

const $externalResults = ref({})
const model = {
  company: {
    requiredCompany: helpers.withMessage('사업장을 선택하세요.', required)
  }
}

const validator = useVuelidate(model, data, {$externalResults})

const layoutStore = useLayoutStore()


const save = async () => {
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) return
  let prepare = {company: data.company?.id || null}
  try {
    await useAuthAxios({
      method: 'POST',
      url: `/app/v1/employment/${props.userData.id}/company/`,
      data: prepare
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    onChange.value = false
    emits('fetch')
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      $externalResults.value = e.field_errors
    }
    throw e
  }
}

const setCompany = (company) => {
  data.company = company ? company.id : null
}

const onChange = ref(false)

const cancelChange = () => {
  validator.value.$clearExternalResults = {}
  onChange.value = false
}
</script>

<template>

  <div v-if="props.userData">
    <span class="mr-4 text-lg text-slate-900">사업장</span>
    <InfoBlock :description="{text: '사용자가 속한 사업장을 선택해주세요.'}"/>
    <div class="w-96">
      <template v-if="!onChange">
        <div class="input-group mt-4">
          <label class="w-20" for="id-for-company">사업장</label>
          <div class="flex items-center justify-start gap-x-2">
            <input id="id-for-company" :value="props.userData.company.name" data-1p-ignore disabled
                   type="text">
            <button :disabled="!props.userData.is_active" class="btn btn-primary" @click="() => onChange = true">변경
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-start mt-4 gap-x-2">
          <CompanySelector v-model="data.company" :errors="validator.company.$errors.map(e => e.$message)"/>
          <button class="btn btn-error" type="button" @click="cancelChange">취소</button>
          <button class="btn btn-primary" type="button" @click="save">저장</button>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>

</style>