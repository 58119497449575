<script setup>

import {nextTick, onMounted, reactive, ref, watch} from "vue";
import {
  externalValidator,
} from "@/validators/fields.js";
import useVuelidate from "@vuelidate/core";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {APIError} from "@/exceptions.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import AffiliateSelector from "@/components/selector/apiSelector/AffiliateSelector.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import TextInput from "@/components/forms/TextInput.vue";


const layoutStore = useLayoutStore()

const props = defineProps({
  vehicle: {type: [null, Object], required: true}
})

const emits = defineEmits(['update'])

const data = reactive({
  car_no: null,
  vin: null,
  vehicle_type: null,
  vehicle_size: null,
  company_own: null,
  company_use: null,
  company_manage: null,
  driver: null
})


const $externalResults = ref({})

const model = {
  car_no: {externalValidator},
  vin: {externalValidator},
  vehicle_type: {externalValidator},
  vehicle_size: {externalValidator},
  company_own: {externalValidator},
  company_use: {externalValidator},
  company_manage: {externalValidator},
  driver: {externalValidator}
}

const validator = useVuelidate(model, data, {$externalResults})

const setCarNo = (e) => {
  data.car_no = e.target.value
  if (data.car_no === '') {
    data.car_no = null
  }
}

const setVin = (e) => {
  data.vin = e.target.value
  if (data.vin === '') {
    data.vin = null
  }
}


const setData = vehicleData => {
  data.car_no = vehicleData?.car_no || null
  data.vin = vehicleData?.vin || null
  data.vehicle_type = vehicleData?.vehicle_type || null
  data.vehicle_size = vehicleData?.vehicle_size || null
  data.company_own = vehicleData?.company_own || null
  data.company_use = vehicleData?.company_use || null
  data.company_manage = vehicleData?.company_manage || null
  data.driver = vehicleData?.driver || null
}

onMounted(() => {
  setData(props.vehicle)
})
watch(() => props.vehicle, newVehicle => setData(newVehicle))


const prepareData = (value) => {
  let prepare = JSON.parse(JSON.stringify(value))
  prepare['vehicle_type'] = prepare.vehicle_type?.value || null
  prepare['vehicle_size'] = prepare.vehicle_size?.value || null
  prepare['company_own'] = prepare.company_own?.id || null
  prepare['company_use'] = prepare.company_use?.id || null
  prepare['company_manage'] = prepare.company_manage?.id || null
  prepare['driver'] = prepare.driver?.id || null
  return prepare
}

const save = async () => {
  await validator.value.$reset()
  $externalResults.value = {}
  let valid = await validator.value.$validate()
  if (!valid) {
  }
  try {
    const vehicle = await useAuthAxios({
      method: 'POST',
      url: `/app/v1/vehicle/${props.vehicle.id}/`,
      data: prepareData(data)
    })
    layoutStore.addSuccessNotification('저장되었습니다.', null, 1000)
    // await router.push({name: 'settings-company-detail', params: {id: company.id}})
    emits('update', vehicle)
  } catch (e) {
    if (e instanceof APIError && e?.code === 'V0001') {
      Object.assign($externalResults.value, e.field_errors)
      // $externalResults.value = e.field_errors
    }
    throw e
  }
}
</script>

<template>
  <form class="w-full p-4 mb-8" @submit.prevent="save">
    <div class="grid grid-cols-1 md:grid-cols-3 mb-4 gap-x-2">
      <TextInput class="col-span-1" v-model="data.car_no" :errors="validator.car_no.$errors.map(e => e.$message)"
                 label="차량번호" :messages="['차량번호와 차대번호 중 하나는 필수 입니다.']"/>
      <TextInput class="col-span-2" v-model="data.vin" :errors="validator.vin.$errors.map(e => e.$message)"
                 label="차대번호" :messages="['차대번호는 한번 입력한 후 변경할 수 없습니다.']" :readonly="props?.vehicle?.vin !== null"/>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mb-4 gap-x-2">
      <ChoiceSelectorBase v-model="data.vehicle_type" choice-type="VehicleTypeChoice" label="차량 타입" :required="true"
                          :errors="validator.vehicle_type.$errors.map(e => e.$message)"/>
      <ChoiceSelectorBase v-model="data.vehicle_size" choice-type="VehicleSizeChoice" label="차량 중량" :required="true"
                          :errors="validator.vehicle_size.$errors.map(e => e.$message)"/>
      <EmployeeSelector :errors="validator.driver.$errors.map(e => e.$message)" label="기사"
                        v-model="data.driver" :is_driver="true"/>
    </div>
      <AffiliateSelector :errors="validator.company_own.$errors.map(e => e.$message)" v-model="data.company_own"
                         label="소유업체" class="mb-4" :messages="['자동차 등록증 상 소유주입니다.']"/>
      <AffiliateSelector :errors="validator.company_use.$errors.map(e => e.$message)" v-model="data.company_use"
                         label="사용업체" class="mb-4" :messages="['실제로 운행을 지시하고 차량을 활용하는 업체입니다.']"/>
      <AffiliateSelector :errors="validator.company_manage.$errors.map(e => e.$message)" v-model="data.company_manage"
                         label="관리업체" class="mb-4" :messages="['보험계약을 관리하고 할부금을 납부하는 등 차량을 관리하는 업체입니다.']"/>
    <button class="btn btn-primary" type="submit">저장</button>
  </form>

  <!--  <div class="px-4 input-group mb-4">-->
  <!--    <label class="w-16" for="id-for-registerer">최초 등록자</label>-->
  <!--    <div class="text-sm">-->
  <!--      {{ props?.campaign?.registerer?.name }}-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="px-4 input-group mb-4">-->
  <!--    <label class="w-16" for="id-for-registerer">등록일</label>-->
  <!--    <div class="text-sm">-->
  <!--      {{ props?.campaign?.created_at ? formatDateTime(props.campaign.created_at) : '-' }}-->
  <!--    </div>-->
  <!--  </div>-->

</template>

<style scoped>

</style>