<script setup>
import generateUuidString from "@/utils/generateUuidString.js";
import {computed, nextTick, ref, toRef} from "vue";
import {Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions} from "@headlessui/vue";
import {PlusCircleIcon, CheckIcon, ChevronUpDownIcon, XMarkIcon} from "@heroicons/vue/24/solid/index.js";
import {useElementBounding} from "@vueuse/core";
import {useWindowSize} from '@vueuse/core'

const props = defineProps({
  class: {type: [String, Object, null], required: false, default: null},
  labelClass: {type: [String, Object, null], required: false, default: null},
  inputClass: {type: [String, Object, null], required: false, default: null},
  name: {type: String, required: false, default: `field-${generateUuidString()}`},
  label: {type: [String], required: false, default: '입력'},
  placeholder: {type: [String, null], default: null},
  required: {type: Boolean, required: false, default: false},
  readonly: {type: Boolean, required: false, default: false},
  errors: {type: Array, required: false, default: []},
  messages: {type: Array, required: false, default: []},
  autoSelect: {type: Boolean, required: false, default: true},
  nullable: {type: Boolean, required: false, default: true},
  options: {type: Array, required: false, default: []},
  toRepresentation: {type: [Function, null], required: false, default: null},
  toRepresentationSub: {type: [Function, null], required: false, default: null},
  addFunction: {type: [Function, null], required: false, default: null},
})

const emits = defineEmits(['focus', 'blur', 'input:query'])

const model = defineModel()
const query = ref(null)

const focused = ref(false)
const inputId = `id_for_${props.name}`
const placeholder = computed(() => {
  if (props.options.length === 0) {
    return '검색 결과가 없습니다.'
  }
  return props.placeholder ? props.placeholder : props.label
})
const mainWrapper = ref(null)
const queryInput = ref(null)
const queryInputWrapper = ref(null)
const comboboxElem = ref(null)
const openOptionBtn = ref(null)


const queryInputPosition = useElementBounding(queryInputWrapper, {immediate: false})
const windowSize = useWindowSize()

const positionStyle = computed(() => {
  let anchorYBase = queryInputPosition.y.value + queryInputPosition.height.value
  let style = {}
  if (anchorYBase > windowSize.height.value / 2) {
    // bottom
    style['bottom'] = `${windowSize.height.value - queryInputPosition.top.value}px`
  } else {
    //top
    style['top'] = `${queryInputPosition.bottom.value}px`
  }
  return style
})

const invalid = computed(() => {
  return props.errors.length > 0
})

const labelClass = computed(() => {
  if (props.readonly === true) {
    return ['text-gray-700', 'font-medium', 'z-20']
  }
  let validClass = invalid.value ? ['text-red-600'] : ['text-gray-700']
  let focusedClass = focused.value ? ['font-semibold', 'text-primary-600'] : ['font-medium']
  return [
    props.labelClass,
    ...validClass,
    ...focusedClass,
    'z-20'
  ]
})

const inputClass = computed(() => {
  let validClass = []
  if (props.readonly === true) {
    validClass = ['border-gray-300', 'bg-gray-900']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
    return validClass
  }
  if (invalid.value) {
    validClass = ['text-red-600', 'border-red-300',]
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-red-600', 'border-red-600']
    }
  } else {
    validClass = ['text-gray-700', 'border-gray-300']
    if (focused.value) {
      validClass = [...validClass, 'ring-1', 'ring-primary-600', 'border-primary-600']
    }
  }
  return [props.inputClass, ...validClass]
})

const {width: queryInputWrapperWidth} = useElementBounding(queryInputWrapper, {immediate: false}, {
  width: 150,
  height: 0
})


const toRepresentation = (object) => {
  if (object) {
    return props.toRepresentation ? props.toRepresentation(object) : object
  } else {
    return null
  }
}

const toRepresentationSub = (object) => {
  return props.toRepresentationSub === null ? null : props.toRepresentationSub(object)
}

const setQuery = (event) => {
  if (props.readonly === true) return
  query.value = event.target.value === '' ? null : event.target.value
  emits('input:query', query.value)
}

const focus = (event) => {
  focused.value = true
  if (props.autoSelect) {
    event.target.select()
  }
  emits('focus')
}
const blur = () => {
  focused.value = false
  emits('blur')
}

const enter = (event) => {
  if (props.readonly === true) return
  if (props.options.length === 0) {
    event.preventDefault()
  }
}

const clearValue = () => {
  if (props.readonly === true) return
  model.value = null
  queryInputWrapper.value.focus()
}

const clickEvent = (event) => {
  if (props.readonly === true) return
  if (props.autoSelect) {
    event.target.select()
  }
  openOptionBtn.value.el.click()
}

const readonly = () => {
  return props.readonly || props.options.length === 0
}
</script>

<template>
  <Combobox ref="mainWrapper" v-model="model" :class="props.class" as="div" class="relative">
    <ComboboxLabel :class="[...labelClass, props.readonly ? 'readonly':'']" :for="inputId" as="label">
      {{ props.label }} <span v-if="props.required" class="text-red-600">*</span>
    </ComboboxLabel>
    <div ref="queryInputWrapper" :class="[...inputClass, props.readonly ? 'readonly':'']" class="input-wrapper">
      <ComboboxInput :id="inputId" ref="queryInput" :display-value="(obj) => toRepresentation(obj)"
                     :name="props.name" aria-autocomplete="list" autocomplete="off"
                     :placeholder="placeholder" :readonly="props.readonly" as="input" type="text"
                     @blur="blur" @click="clickEvent"
                     @focus="focus" @input="setQuery" @keydown.enter="enter" @keydown.esc.prevent="clearValue"/>
      <template v-show="props.readonly === false">
        <ComboboxButton v-show="model === null" ref="openOptionBtn" as="button" type="button">
          <ChevronUpDownIcon aria-hidden="true" class="h-5 w-5"/>
        </ComboboxButton>
      </template>
      <button v-if="model === null && props.addFunction" type="button" @click="props.addFunction" :disabled="props.readonly">
        <PlusCircleIcon aria-hidden="true" class="h-5 w-5" :class="props.readonly ? 'text-gray-600':'text-primary-600'"/>
      </button>
      <button v-if="model !== null" type="button" @click="clearValue">
        <XMarkIcon aria-hidden="true" class="h-5 w-5"/>
      </button>
    </div>

    <ComboboxOptions :style="{minWidth: `${queryInputWrapperWidth}px`, ...positionStyle}"
                     class="fixed z-50 my-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg border border-gray-900/20 focus:outline-none md:text-sm">
      <template v-if="props.options.length > 0">
      <ComboboxOption v-for="object in props.options" :key="object" v-slot="{ active, selected }" :value="object"
                      as="template">
        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary-600 text-white' : 'text-gray-900']">
          <div class="flex">
              <span :class="['truncate', selected && 'font-semibold']">
                {{ toRepresentation(object) }}
              </span>
            <span v-if="props.toRepresentationSub"
                  :class="['ml-2 truncate text-gray-500', active ? 'text-primary-200' : 'text-gray-500']">
                              {{ toRepresentationSub(object) }}
              </span>
          </div>
          <span v-if="selected"
                :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary-600']">
              <CheckIcon aria-hidden="true" class="h-5 w-5"/>
            </span>
        </li>
      </ComboboxOption>
      </template>
      <template v-else>
        <li class="py-2 pl-3 pr-9 text-gray-900">
          <span>검색 결과가 없습니다.</span>
        </li>
      </template>
    </ComboboxOptions>


    <template v-if="invalid">
      <div class="message-wrapper error">
        <p v-for="error in props.errors">- {{ error }}</p>
      </div>
    </template>
    <template v-else-if="props.messages.length > 0">
      <div class="message-wrapper">
        <p v-for="message in props.messages">- {{ message }}</p>
      </div>
    </template>
  </Combobox>
</template>

<style scoped>
label {
  @apply absolute text-xs -top-2 left-2 inline-block bg-white px-1 py-0
}

label.readonly {
  @apply bg-transparent
}

.input-wrapper {
  @apply flex w-full leading-5 px-2 py-0 bg-white rounded h-[2rem] border-[1px] gap-x-2 items-center
}

.input-wrapper.readonly {
  @apply bg-gray-300
}

input {
  all: unset;
  @apply focus:ring-0 flex-grow min-w-0 text-sm
}

input::placeholder {
  @apply text-gray-400/70
}

input:read-only {
  @apply bg-gray-300
}


.message-wrapper {
  @apply mt-[2px] text-xs text-gray-500
}

.message-wrapper.error {
  @apply text-red-500
}
</style>