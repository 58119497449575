<script setup>

import dayjs from "dayjs";
import {computed, onMounted, ref} from "vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import TableComponent from "@/components/tables/TableComponent.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";
import {useRoute, useRouter} from "vue-router";
import VehicleDrawer from "@/components/drawer/VehicleDrawer.vue";
import VehicleCreateDrawer from "@/components/drawer/VehicleCreateDrawer.vue";
import TextInput from "@/components/forms/TextInput.vue";

const router = useRouter()
const route = useRoute()

// 항목 추가
const showAdd = ref(false)
const openAdd = () => {
  showAdd.value = true;
}
const closeAdd = () => {
  showAdd.value = false;
}

// 테이블 전역 액션
const tableActions = [
  // {
  //   label: 'XLSX 다운로드',
  //   leftIcon: FolderArrowDownIcon,
  //   action: () => {
  //     console.log('xlsx')
  //   }
  // },
  // {
  //   label: '새로고침',
  //   leftIcon: ArrowPathIcon,
  //   action: () => {
  //     console.log('refresh')
  //   }
  // }
]
// 단행 액션
const rowActions = [
  // {
  //   label: '상세보기',
  //   icon: FolderArrowDownIcon,
  //   action: navigateToItem
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   action: (item) => {
  //     console.log('deactivate ', item)
  //   }
  // }
]
// 행들 액션
const rowsAction = [
  // {
  //   label: '삭제',
  //   icon: FolderArrowDownIcon,
  //   severity: Severities.ERROR,
  //   action: (selected) => {
  //     console.log('delete');
  //     console.log(selected)
  //   }
  // },
  // {
  //   label: '비활성화',
  //   icon: ArrowPathIcon,
  //   severity: Severities.SECONDARY,
  //   action: () => {
  //     console.log('deactivate')
  //   }
  // }
]

// fetch
const fetch = async (query = null, data = null) => {
  let options = {
    method: 'GET',
    url: `/app/v1/vehicle/`,
    params: query,
    data: data
  }
  return await useAuthAxios(options, false)
}

// 컬럼 목록
const fields = {
  car_no: {label: '차량번호', sortable: false, width: 100, type: 'text'},
  vehicle_type: {label: '타입', sortable: false, width: 100, type: 'text'},
  vehicle_size: {label: '중량', sortable: false, width: 100, type: 'text'},
  driver: {label: '기사', sortable: false, width: 100, type: 'user'},
  company_own: {label: '소유', sortable: false, width: 100, type: 'company'},
  company_use: {label: '사용', sortable: false, width: 100, type: 'company'},
  company_manage: {label: '관리', sortable: false, width: 100, type: 'company'},
  is_active: {label: '활성', sortable: false, width: 50, type: 'boolean'},
  created_at: {label: '등록일', sortable: false, width: 200, type: 'text'}
}

// 데이터
const dataFilter = (data) => {
  data['vehicle_type'] = data.vehicle_type.label
  data['vehicle_size'] = data.vehicle_size.label
  data['created_at'] = dayjs(data.created_at).format("YYYY년 M월 D일")
  return data
}

const searchQuery = ref(route.query.query || '')
const selected_vehicle_type = ref(route.query.vehicle_type)
const selected_vehicle_size = ref(route.query.vehicle_size)

const queryList = () => {
  let query = {...route.query}
  query['query'] = searchQuery.value
  query['vehicle_type'] = selected_vehicle_type.value
  query['vehicle_size'] = selected_vehicle_size.value
  query['is_active'] = document.getElementById('id-is_active').checked ? 'on' : null
  router.push({query: query})
}

const selectedVehicle = ref(null)

const vehicleIsOpen = computed(() => selectedVehicle.value !== null)
const closeVehicle = () => {
  selectedVehicle.value = null
}

const openVehicle = (item) => {
  selectedVehicle.value = item
}

const tableKey = ref(1)
const update = async () => {
  tableKey.value++
}

const updateAndOpen = async (item) => {
  await update()
  openVehicle(item)
}

</script>

<template>
  <VehicleDrawer v-if="selectedVehicle" :open="vehicleIsOpen" :vehicle="selectedVehicle"
                 @close="closeVehicle" @update="update"/>
  <VehicleCreateDrawer :open="showAdd" title="차량 등록" @close="closeAdd" @update="updateAndOpen"/>
  <DefaultLayoutBase title="차량 / 목록">
    <template #body>
      <TableComponent :key="tableKey" :dataFilter="dataFilter" :fetch="fetch" :fields="fields"
                      :openAction="openVehicle" :openAdd="openAdd" @update="update"
                      :query-filter="queryList" :rowActions="rowActions" :rowsAction="rowsAction"
                      :tableActions="tableActions" entityName="차량" entityUnit="대">
        <template #filter>
          <TextInput v-model="searchQuery" name="query" placeholder="검색"/>
          <div class="flex items-center gap-x-1">
            <input id="id-is_active" :checked="route.query.is_active === 'on'" name="is_active" type="checkbox">
            <label class="ml-1 text-sm" for="id-is_active">활성 차량만</label>
          </div>
          <button class="btn btn-primary w-full md:w-auto" type="submit">검색</button>
        </template>
      </TableComponent>
    </template>
  </DefaultLayoutBase>
</template>