<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import AffiliateDefaultInfo from "@/components/drawer/partial/AffiliateDefaultInfo.vue";
import AffiliateAddressInfo from "@/components/drawer/partial/AffiliateAddressInfo.vue";
import AffiliatePriceInfo from "@/components/drawer/partial/AffiliatePriceInfo.vue";
import AffiliateSalesInfo from "@/components/drawer/partial/AffiliateSalesInfo.vue";
import AffiliateEmployeeInfo from "@/components/drawer/partial/AffiliateEmployeeInfo.vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {computed, onMounted, reactive, ref, watch} from "vue";
import SmallWorkDefault from "@/components/drawer/work/small/SmallWorkDefault.vue";
import SmallWorkSite from "@/components/drawer/work/small/SmallWorkSite.vue";
import TextInput from "@/components/forms/TextInput.vue";
import CampaignSelector from "@/components/selector/apiSelector/CampaignSelector.vue";
import CopyButton from "@/components/commons/CopyButton.vue";
import EmployeeSelector from "@/components/selector/apiSelector/EmployeeSelector.vue";
import {useAuthStore} from "@/stores/authStore.js";
import {useLayoutStore} from "@/stores/layoutStore.js";
import SmallWorkDriver from "@/components/drawer/work/small/SmallWorkDriver.vue";
import DateTimePickerInput from "@/components/forms/DateTimePickerInput.vue";
import dayjs from "dayjs";
import FileInlineDropzone from "@/components/forms/FileInlineDropzone.vue";
import fileUpload from "@/composables/useFileUpload.js";
import ConfirmDialogComponent from "@/components/commons/ConfirmDialogComponent.vue";
import {Severities} from "@/constant.js";
import SmallWorkEntry from "@/components/drawer/work/small/SmallWorkEntry.vue";
import SmallWorkEstimate from "@/components/drawer/work/small/SmallWorkEstimate.vue";

const authStore = useAuthStore()
const layoutStore = useLayoutStore()

const props = defineProps({
    title: {type: String, required: false, default: '배차 상세'},
    open: {type: Boolean, required: true},
    work: {type: [null, Object, Number], required: true}
})

const emits = defineEmits(['close', 'update', 'create'])

const close = () => {
    work.value = null
    currentTrigger.value = null
    emits('close')
}
const tabItems = [
    {name: '기본정보', key: 'default'},
    {name: '매출매입', key: 'entry'},
    {name: '견적서', key: 'estimate'},
    {name: '메모', key: 'note'},
    {name: '파일', key: 'file'},
    {name: 'SMS', key: 'sms'},
    {name: '히스토리', key: 'history'},
]

const work = ref(null)

const fetch = async () => {
    let options = {
        method: 'GET',
        url: `/app/v1/work/small/${props.work?.id || props.work}/`,
    }
    work.value = await useAuthAxios(options, true)
}

onMounted(async () => {
    if (props.work) {
        await fetch()
    }
})

watch(() => props.work, async (newValue, oldValue) => {
    if (newValue === oldValue || !newValue?.id) {
        work.value = null
    } else {
        await fetch()
    }
}, {deep: true})

const update = async (work) => {
    emits('update', work)
    await fetch()
}

const create = (workData) => {
    // work.value = null
    emits('create', workData)
}

const availableAction = computed(() => {
    return work.value?.action_available || {}
})

const currentTrigger = ref(null)
const assignedManager = ref(authStore.user)
const eventDatetime = ref(dayjs().format('YYYY-MM-DD HH:mm'))
const receipt_weight = ref(null)
const imageFile = ref(null)
const uploadProgress = ref(0)

const confirm = reactive({
    acceptString: '승인',
    closeString: '취소',
    rejectString: null,
    detail: '',
    open: false,
    severity: Severities.PRIMARY,
    summary: null,
    accept: null,
    reject: null,
})

const closeConfirm = () => {
    Object.assign(confirm, {
        acceptString: '승인',
        closeString: '취소',
        rejectString: null,
        detail: '',
        open: false,
        severity: Severities.PRIMARY,
        summary: null,
        accept: null,
        reject: null,
    })
}

const checkTrigger = async (trigger) => {
    let options = {
        method: 'POST',
        url: `/app/v1/work/small/${props.work?.id || work.value?.id}/`,
        data: {trigger: trigger}
    }
    const response = await useAuthAxios(options, false)
}

const doTransition = async (trigger, data) => {
    let options = {
        method: 'POST',
        url: `/app/v1/work/small/${props.work?.id || work.value?.id}/transition/`,
        data: {trigger: trigger, ...data}
    }
    const response = await useAuthAxios(options, false)
    closeTrigger()
    await update()
}

const closeTrigger = () => {
    currentTrigger.value = null
    assignedManager.value = authStore.user
    eventDatetime.value = dayjs().format('YYYY-MM-DD HH:mm')
    receipt_weight.value = null
    imageFile.value = null
    uploadProgress.value = 0
}

const showAssignManager = async () => {
    try {
        await checkTrigger('assign_manager')
        currentTrigger.value = 'assign_manager'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}
const assignManager = async () => {
    try {
        await doTransition('assign_manager', {manager: assignedManager.value?.id})
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}
const showAssignDriver = async () => {
    try {
        await checkTrigger('assign_driver')
        currentTrigger.value = 'assign_driver'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const confirmCancel = () => {
    confirm.open = true
    confirm.summary = '작업 취소'
    confirm.detail = '이 작업은 되돌릴 수 없습니다.'
    confirm.severity = Severities.ERROR
    confirm.accept = async () => {
        try {
            await doTransition('cancel')
        } catch (e) {
            throw e
        } finally {
            // closeConfirm()
        }
    }
}

const showStart = async () => {
    try {
        await checkTrigger('start')
        currentTrigger.value = 'start'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const triggerStart = async () => {
    try {
        await doTransition('start', {event_datetime: eventDatetime.value})
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}

const showReturn = async () => {
    try {
        await checkTrigger('return')
        currentTrigger.value = 'return'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const confirmReturn = () => {
    confirm.open = true
    confirm.summary = '회차'
    confirm.detail = '정말로 회차 처리하시겠습니까? 이 작업은 되돌릴 수 없습니다.'
    confirm.severity = Severities.ERROR
    confirm.accept = async () => {
        try {
            await doTransition('return', {event_datetime: eventDatetime.value})
        } catch (e) {
            throw e
        } finally {
            closeTrigger()
        }
    }
}

const showArrive = async () => {
    try {
        await checkTrigger('arrive')
        currentTrigger.value = 'arrive'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const triggerArrive = async () => {
    try {
        await doTransition('arrive', {event_datetime: eventDatetime.value})
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}
const showStartLoading = async () => {
    try {
        await checkTrigger('start_loading')
        currentTrigger.value = 'start_loading'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const triggerStartLoading = async () => {
    try {
        await doTransition('start_loading', {event_datetime: eventDatetime.value})
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}
const showFinishLoading = async () => {
    try {
        await checkTrigger('finish_loading')
        currentTrigger.value = 'finish_loading'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const triggerFinishLoading = async () => {
    try {
        await doTransition('finish_loading', {event_datetime: eventDatetime.value})
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}

const showRegisterReceipt = async () => {
    try {
        await checkTrigger('register_receipt')
        currentTrigger.value = 'register_receipt'
    } catch (e) {
        currentTrigger.value = null
        throw e
    }
}

const triggerRegisterReceipt = async () => {
    try {
        let data = {
            event_datetime: eventDatetime.value, receipt_weight: receipt_weight.value
        }
        if (imageFile.value) {
            let uploadResponse = await fileUpload(imageFile.value, uploadProgress)
            data['file'] = uploadResponse.id
        }
        await doTransition('register_receipt', data)
    } catch (e) {
        throw e
    } finally {
        closeTrigger()
    }
}

</script>
<template>
    <DrawerBase :open="props.open" :tab-items="tabItems" :target="work" :title="props.title" @close="close">
        <template v-if="work" #header>
            <div class="border-b border-gray-200 grid grid-cols-6 md:grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-4 flex gap-x-2 items-center">
                    <div>
                        <div class="text-xs font-semibold">시리얼</div>
                    </div>
                    <div class="text-xs flex gap-x-2 items-center">
                        {{ work.serial }}
                        <CopyButton :source="work.serial"/>
                    </div>
                </div>
                <div class="col-span-3 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">캠페인</div>
                    <div class="text-xs">{{ work.campaign_name }}</div>
                </div>
                <div class="col-span-3 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">기준일</div>
                    <div class="text-xs">{{ work.performance_date }}</div>
                </div>
                <div class="col-span-3 md:col-span-2 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">담당자</div>
                    <div class="text-xs">{{ work.work_manager }}</div>
                </div>
                <div class="col-span-3 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">작업상태</div>
                    <div class="text-xs font-bold">{{ work.status_display }}</div>
                </div>
                <div class="col-span-3 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">정산상태</div>
                    <div class="text-xs font-bold">{{ work.billing_status_display }}</div>
                </div>
                <div class="col-span-3 flex gap-x-2 items-center">
                    <div class="text-xs font-semibold">종결상태</div>
                    <div class="text-xs font-bold">{{ work.closing_status_display }}</div>
                </div>
                <!--          <TextInput v-model="work.serial" :readonly="true" label="일련번호"/>-->
                <!--          <CampaignSelector v-model="work.campaign" :readonly="true" label="캠페인"/>-->
                <!--          <TextInput v-model="work.performance_date" :readonly="true" label="기준일"/>-->
            </div>
            <!--      <template v-if="trigger==='assign_manager'">-->
            <!--        <div v-if="props.work?.status !== 'M_CC' || trigger === null" class="border-b border-gray-200 flex flex-wrap gap-2 px-4 py-2">-->
            <!--      </template>-->
            <template v-if="Object.keys(availableAction).some((key) => availableAction[key] === true)">
                <div class="border-b border-gray-200 flex flex-wrap gap-2 px-4 py-2">
                    <template v-if="!currentTrigger">
                        <button v-if="availableAction?.assign_manager === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showAssignManager">배당
                        </button>
                        <button v-if="availableAction?.assign_driver === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showAssignDriver">기사배정
                        </button>
                        <button v-if="availableAction?.release_driver === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('release_driver')">기사해제
                        </button>
                        <button v-if="availableAction?.cancel === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="confirmCancel">취소
                        </button>
                        <button v-if="availableAction?.return === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showReturn">회차
                        </button>
                        <button v-if="availableAction?.start === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showStart">출발
                        </button>
                        <button v-if="availableAction?.arrive === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showArrive">현장 도착
                        </button>
                        <button v-if="availableAction?.start_loading === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showStartLoading">상차 시작
                        </button>
                        <button v-if="availableAction?.finish_loading === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showFinishLoading">상차 종료
                        </button>
                        <button v-if="availableAction?.register_receipt === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="showRegisterReceipt">계근표 등록
                        </button>
                        <button v-if="availableAction?.finish_job === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('finish_job')">작업 완료
                        </button>
                        <button v-if="availableAction?.finish_recognition === true"
                                class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('finish_recognition')">매출매입 등록완료
                        </button>
                        <button v-if="availableAction?.unfinish_recognition === true"
                                class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('unfinish_recognition')">등록완료 해제
                        </button>
                        <!--          <button v-if="availableAction.finishVoucher === true" class="btn btn-primary-outline btn-sm" type="button"-->
                        <!--                  @click="doTransition('finish_entry')">전표 입력 완료-->
                        <!--          </button>-->
                        <!--          <button v-if="availableAction.setDebtor === true" class="btn btn-primary-outline btn-sm" type="button"-->
                        <!--                  @click="doTransition('set_debtor')">미수 등록-->
                        <!--          </button>-->
                        <button v-if="availableAction?.closing === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('closing')">종결
                        </button>
                        <button v-if="availableAction?.cancel_close === true" class="btn btn-primary-outline btn-sm"
                                type="button"
                                @click="doTransition('cancel_close')">종결 취소
                        </button>
                    </template>
                    <template v-else-if="currentTrigger==='assign_manager'">
                        <EmployeeSelector v-model="assignedManager" label="담당자"/>
                        <button class="btn btn-primary" type="button" @click="assignManager">배당</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='assign_driver'">
                        <SmallWorkDriver :doTransition="doTransition" :work="props.work" @close="closeTrigger"
                                         @update="update"/>
                    </template>
                    <template v-else-if="currentTrigger==='return'">
                        <DateTimePickerInput v-model="eventDatetime" label="회차일시"/>
                        <button class="btn btn-primary" type="button" @click="confirmReturn">회차</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='start'">
                        <DateTimePickerInput v-model="eventDatetime" label="출발일시"/>
                        <button class="btn btn-primary" type="button" @click="triggerStart">출발</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='arrive'">
                        <DateTimePickerInput v-model="eventDatetime" label="도착일시"/>
                        <button class="btn btn-primary" type="button" @click="triggerArrive">도착</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='start_loading'">
                        <DateTimePickerInput v-model="eventDatetime" label="상차 시작일시"/>
                        <button class="btn btn-primary" type="button" @click="triggerStartLoading">상차 시작</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='finish_loading'">
                        <DateTimePickerInput v-model="eventDatetime" label="상차 종료일시"/>
                        <button class="btn btn-primary" type="button" @click="triggerFinishLoading">상차 종료</button>
                        <button class="btn btn-error" type="button" @click="closeTrigger">취소</button>
                    </template>
                    <template v-else-if="currentTrigger==='register_receipt'">
                        <div class="grid grid-cols-12 gap-2">
                            <DateTimePickerInput v-model="eventDatetime" :required="true"
                                                 class="col-span-8 md:col-span-4"
                                                 label="계근일시"/>
                            <TextInput v-model="receipt_weight" :required="true" class="col-span-4 md:col-span-2"
                                       label="계근중량(kg)"
                                       type="number"/>
                            <FileInlineDropzone v-model="imageFile" class="col-span-6 md:col-span-4" label="계근표"/>
                            <button class="btn btn-primary col-span-2 md:col-span-1" type="button"
                                    @click="triggerRegisterReceipt">등록
                            </button>
                            <button class="btn btn-error col-span-2 md:col-span-1" type="button" @click="closeTrigger">
                                취소
                            </button>
                        </div>
                    </template>
                </div>
            </template>
        </template>
        <template #default>
            <SmallWorkDefault :work="work" @create="create" @update="update"/>
            <ConfirmDialogComponent :acceptString="confirm.acceptString" :closeString="confirm.closeString"
                                    :detail="confirm.detail"
                                    :open="confirm.open" :rejectString="confirm.rejectString"
                                    :severity="confirm.severity" :summary="confirm.summary"
                                    @accept="confirm.accept" @close="closeConfirm" @reject="confirm.reject"/>
        </template>
        <template #estimate>
            <SmallWorkEstimate :work="work" @update="update"/>
        </template>
        <template #entry>
            <SmallWorkEntry :work="work" @update="update"/>
        </template>
        <!--    <template #employee>-->
        <!--      <AffiliateEmployeeInfo :affiliate="props.affiliate"/>-->
        <!--    </template>-->
        <!--    <template #address>-->
        <!--      <AffiliateAddressInfo :affiliate="props.affiliate"/>-->
        <!--    </template>-->
        <!--    <template #sales>-->
        <!--      <AffiliateSalesInfo :affiliate="props.affiliate"/>-->
        <!--    </template>-->
        <!--    <template #price>-->
        <!--      <AffiliatePriceInfo :affiliate="props.affiliate"/>-->
        <!--    </template>-->
    </DrawerBase>
</template>