<script setup>
import DrawerBase from "@/components/drawer/DrawerBase.vue";
import {onBeforeMount, onMounted, ref} from "vue";
import useAuthAxios from "@/composables/useAuthAxios.js";
import VehicleDefaultInfo from "@/components/drawer/partial/VehicleDefaultInfo.vue";
import VehicleHistoryInfo from "@/components/drawer/partial/VehicleHistoryInfo.vue";

const props = defineProps({
  title: {type: String, required: false, default: '차량 상세'},
  open: {type: Boolean, required: true},
  vehicle: {type: [null, Object], required: true}
})

const emits = defineEmits(['close', 'update'])

const close = () => {
  emits('close')
}
const update = (data) => {
  emits('update', data)
}

const vehicle = ref(null)

const tabItems = [
  {name: '기본정보', key: 'default'},
  {name: '히스토리', key: 'vehiclehistory'},
]

onBeforeMount(async () => {
  vehicle.value = await useAuthAxios({
    method: 'GET',
    url: `/app/v1/vehicle/${props.vehicle.id}/`
  })
})

</script>

<template>
  <DrawerBase :open="props.open" :tab-items="tabItems" :target="props.vehicle" :title="props.title" @close="close">
    <template #default>
      <VehicleDefaultInfo :vehicle="vehicle" @update="update"/>
    </template>
    <template #vehiclehistory>
      <VehicleHistoryInfo :vehicle="vehicle"/>
    </template>
  </DrawerBase>
</template>