<script setup>
import {computed, nextTick, onMounted, ref} from "vue";
import dayjs from "dayjs";
import useAuthAxios from "@/composables/useAuthAxios.js";
import {API_URL} from "@/service/api.js";

const companyType = ref({
  "label": "목재파쇄",
  "value": "woodshred"
})
// const wasteClass = ref(null)
const wasteClass = ref({
  "label": "목재",
  "value": "W"
})


const date = ref(dayjs().format('YYYY-MM-DD'))
// const date = ref('')

const showTable = ref(false)
const calculatedRows = ref([])
const calculatedFields = ref([])

const calculateFields = (rawData) => {
  let base = {
    company_name: {label: '거래처명', sortable: false, width: 100, type: 'text'},
  }
  if (rawData) {
    rawData.columns.forEach(col => {
      let colKey = col.split("|")
      let vehicle_type_display = rawData.keys.vehicle_type_data[colKey[1]]
      let vehicle_size_display = rawData.keys.vehicle_size_data[colKey[2]]
      let waste_class_display = rawData.keys.waste_class_data[colKey[0].split("_")[0]]
      let waste_type_display = rawData.keys.waste_type_data[colKey[0]]
      base[`${col}_string`] = {
        label: col, sortable: false, width: 100, type: 'text',
        labels: [waste_class_display, waste_type_display, vehicle_type_display, vehicle_size_display]
      }
    })
    return base
  }
  return base
}

const companyTypes = ref([])
const wasteClasses = ref([])

const data = ref(null)

const lookup = async () => {
  showTable.value = false
  data.value = await useAuthAxios({
    method: "GET",
    url: `/app/v1/affiliate/price/`,
    params: {
      company_type: companyType.value ? companyType.value.value : null,
      waste_class: wasteClass.value ? wasteClass.value.value : null,
      date: date.value
    }
  }, true)
  calculatedRows.value = calculateRows(data.value)
  calculatedFields.value = calculateFields(data.value)
  await nextTick(() => {
    showTable.value = true
  })
}
const clear = () => {
  companyType.value = null
  date.value = dayjs().format('YYYY-MM-DD')
}

onMounted(async () => {
  await lookup()
})

const calculateRows = (rawData) => {
  let rows = rawData.data.map(item => {
    let results = {
      id: item.id,
      company_name: item.name,
      company_type: item.company_type,
      company_type_display: item.company_type_display
    }
    Object.entries(item.prices).forEach(priceData => {
      let key = priceData[0]
      let price = JSON.parse(JSON.stringify(priceData[1]))
      price['vehicle_type_display'] = rawData.keys.vehicle_type_data[price['vehicle_type']]
      price['vehicle_size_display'] = rawData.keys.vehicle_size_data[price['vehicle_size']]
      price['waste_class_display'] = rawData.keys.waste_class_data[price['waste_class']]
      price['waste_type_display'] = rawData.keys.waste_type_data[price['waste_type']]
      price['per_unit_display'] = rawData.keys.per_unit_data[price['per_unit']] || null
      if (price.free === true) {
        price['price_string'] = '무료'
      } else {
        let unit = `원/${price['per_unit_display']}`
        if (price.max_price) {
          price['price_string'] = `${price.min_price.toLocaleString()}${unit} ~ ${price.max_price.toLocaleString()}${unit}`
        } else {
          price['price_string'] = `${price.min_price.toLocaleString()}${unit}`
        }
      }
      results[priceData[0]] = price
      results[`${key}_string`] = price.price_string
    })
    return results
  })
  return rows
}

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import InlineTableComponent from "@/components/Inlinetable/InlineTableComponent.vue";

import * as Xlsx from "xlsx";
import DatePickerInput from "@/components/forms/DatePickerInput.vue";
import DefaultLayoutBase from "@/layout/base/DefaultLayoutBase.vue";
import ChoiceSelectorBase from "@/components/selector/choices/ChoiceSelectorBase.vue";


const getXlsxData = () => {
  let xlsxData = []
  calculatedRows.value.forEach(row => {
    let xlsxRow = {}
    Object.entries(calculatedFields.value).forEach(([key, fieldValue]) => {
      xlsxRow[fieldValue.labels ? fieldValue.labels.join("|") : fieldValue.label] = row[key] ? row[key] : ''
    })
    xlsxData.push(xlsxRow)
  })
  return xlsxData
}

const download = () => {
  let xlsxData = getXlsxData()
  const workBook = Xlsx.utils.book_new()
  const workSheet = Xlsx.utils.json_to_sheet(xlsxData)
  Xlsx.utils.book_append_sheet(workBook, workSheet, '가격정보')
  Xlsx.writeFile(workBook, `price_data.xlsx`)
}
</script>

<template>
  <DefaultLayoutBase title="거래처 가격">
    <template #body>
      <div class="flex w-full grow flex-col items-center  overflow-auto bg-slate-100">
        <div class="flex flex-col w-full grow max-2 md:w-full bg-white">
          <div class="flex grow flex-col p-4 pt-4 pb-0">
            <div class="flex items-start justify-start gap-x-4 mb-2">
              <ChoiceSelectorBase v-model="companyType" choice-type="CompanyTypeChoice" label="거래 타입"/>
              <ChoiceSelectorBase v-model="wasteClass" choice-type="WasteClassChoice" label="성상"/>
<!--              <div class="input-group">-->
<!--                <label>기준일자</label>-->
<!--                <div>-->
<!--                  <input v-model="date" class="input" placeholder="기준 일자" type="text"/>-->
<!--                </div>-->
<!--              </div>-->
              <DatePickerInput v-model="date"/>
              <button class="btn btn-primary" @click="lookup">조회</button>
              <button class="btn btn-secondary" @click="clear">초기화</button>
              <button class="btn btn-secondary" @click="download">다운로드</button>
            </div>
          </div>
          <template v-if="showTable">
            <InlineTableComponent :fields="calculatedFields" :rows="calculatedRows" height-class=""/>
          </template>
        </div>
      </div>
    </template>
  </DefaultLayoutBase>
</template>

<style scoped>

</style>